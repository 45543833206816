export default {
  main: {
    title: 'Sportwetten Quotenrechner - Was ist ein Systemwetten Quotenrechner? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/',
    },
    h1: 'Sportwetten Quotenrechner',
    opening: 'Unser gratis Quotenrechner für Ihre Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Egal, ob Kombiwette, Lucky 15 Wette oder eine Goliath Wette – mit unserem gratis Online Quotenrechner können Sie schnell und einfach Ihren potentiellen Gewinn berechnen.',
    questions: [
      {
        q: 'Was ist ein Systemwetten Quotenrechner?',
        a: 'Ein Wettrechner ermittelt Ihre potenziellen Gewinne. Geben Sie einfach die für Ihre Wette verfügbaren Quoten und den von Ihnen gewählten Einsatz ein. Daraufhin teilt Ihnen der Rechner mit, wie viel Sie gewinnen können.',
      },
      {
        q: 'Wie benutze ich den Sportwetten Quotenrechner?',
        a: 'Es ist sehr einfach, den Quotenrechner von BetVictor zu verwenden.<br />Zunächst müssen Sie sich, wenn es sich um Einzelwetten handelt, für Ihre Wettart entscheiden. Wir bieten jedoch auch Berechnungen für zum Beispiel Kombiwetten, Goliathwetten und Round Robins an, um nur eine kleine Auswahl zu nennen.<br />Nachdem Sie Ihre Auswahl getroffen haben, wählen Sie Ihre Wettart auf dem Rechner aus (welche wir auch unten nochmals einzeln und einfach klickbar aufgeführt haben) und entscheiden, ob die Quoten im als Bruch- oder Dezimalformat angezeigt werden sollen.<br />Danach geben Sie Ihren Einsatz ein – wie viel Sie wetten möchten. Sie können dann entscheiden, ob Ihre Wette Sieg und Platz, Notizen, Kombiwetten Optionen oder eine Regel 4 beinhalten. Wir haben ein Fragezeigen  über einige davon gestellt, um Ihnen diese Begriffe zu erklären, falls Sie mit diesen nicht vertraut sind.<br />Abschließend geben Sie die Quoten für das Ergebnis ein, basierend darauf, ob die Wette gewonnen, verloren, ungültig ist oder ein totes Rennen beinhaltet. Wenn Sie mehrere Auswahlen getroffen haben, geben Sie die Quote für jede Einzelne ein. Klicken Sie dann einfach auf “Berechnen”, und Ihre potenziellen Gewinne werden angezeigt.<br />Wenn Sie bereits wissen, welche Wettart Sie verwenden möchten, nutzen Sie die einfachen Navigationslinks weiter unten.',
      },
    ],
  },

  single: {
    title: 'Einzelwetten Systemwetten Quotenrechner – Was ist eine Einzelwette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Einzelwetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/single/',
    },
    h1: 'Einzelwette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Einzelwetten berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Einzelwette?',
        a: 'Eine Einzelwette ist eine Wette auf ein einzelnes Ereignis. Sie haben dann gewonnen, wenn das Ergebnis entsprechend Ihrer Wette eintritt.',
      },
      {
        q: 'Wie funktioniert eine Einzelwette?',
        a: 'Als Beispiel für die Berechnung einer Einzelwette nehmen wir an, dass Sie haben eine Einzelwette mit einem Einsatz von 10€ gesetzt haben. Die Auswahl Ihrer Wette hat eine Quote von 4,5. Ihr Gewinn wird berechnet, indem Sie Ihren Einsatz von 10€ mit der Quote von 4,5 multiplizieren. Sie erhalten somit für Ihre Wette eine maximale Auszahlung von 45€.',
      },
    ],
  },

  'each-way': {
    title: 'Sieg/Platz Systemwetten Quotenrechner – Was ist eine Sieg/Platz Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Sieg/Platz Wette. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/each-way/',
    },
    h1: 'Sieg/Platz Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Sieg/Platz Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Sieg/Platz Wette?',
        a: 'Eine Sieg/PlatzWette ist eine Wette die aus zwei verschiedenen Teilen besteht: einer Wette auf Sieg und einer Wette auf Platz.',
      },
      {
        q: 'Wie funktioniert eine Sieg/Platz Wette?',
        a: 'Eine Sieg/Platz Wette ist eine Wette, bei der Sie zu gleichen Einsätzen auf Sieg und auf Platz in einem einzigen Ereignis wetten. Die Anzahl der Plätze, die ausbezahlt werden, sind in der Regel die Plätze 2, 3 und 4. Gewinnt Ihre Auswahl, werden beide Teile der Wette ausbezahlt. Gewinnt Ihre Auswahl nicht, sondern kommt nur auf Platz), erhalten Sie ¼ bzw. 1/5 (abhängig von den Sieg/Platz Bedingungen) der Siegquote für Ihre Platzwette ausbezahlt.',
      },
      {
        q: 'Wie berechnet man eine Sieg/Platz Wette?',
        a: 'Als Beispiel für die Berechnung einer Sieg/Platz Wette nehmen wir an, dass Sie 5€ Sieg/Platz Wette gesetzt haben. Das macht einen Gesamteinsatz von 10€, da es sich um zwei Wetten handelt. Wenn Ihre Auswahl mit einer Quote von 9,0 gewinnt, erhalten Sie für 5€ Einsatz auf Sieg eine Auszahlung in der Höhe von 45€ (5€ x 9,0 = 45€). Das ist der Siegteil Ihrer Wette. Da Sie auch 5€ auf Platz gesetzt haben, erhalten Sie unter der Sieg/Platz Bedingung von ¼ der Siegquote auch eine Auszahlung auf Platz. Um diese zu berechnen, nimmt man die Quote von 9,0 und zieht 1 ab, um die Nettogewinnquote (= Gewinnquote minus einen Anteil für den Einsatz) zu erhalten, was eine Nettogewinnquote von 8,0 ergibt. ¼ von 8,0 ist 2,0. Zu dieser Nettgewinnquote wird nun wieder der Anteil für Ihren Einsatz hinzugefügt, so dass wir auf eine Quote von 3,0 für Platz kommen. Diese Quote wird mit 5€ Einsatz multipliziert und ergibt eine Auszahlung von 15€ für den Platzteil Ihrer Wette (5€ x 3,0 = 15€). Summiert mit dem Siegteil von 45€ erhalten Sie eine maximale Auszahlung von 60€. Kommt Ihre Auswahl nur auf Platz, erhalten Sie nur den Platzteil Ihrer Wette, in diesem Fall 15€. Der Siegteil ist verloren.',
      },
    ],
  },

  double: {
    title: '2er Kombiwette Systemwetten Quotenrechner – Was ist eine 2er Kombiwette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre 2er Kombiwette. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/double/',
    },
    h1: '2er Kombiwette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer 2er Kombiwette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine 2er Kombiwette?',
        a: 'Eine 2er Kombiwette ist eine Wette mit zwei Auswahlen, bei der beide gewinnen müssen, um eine Auszahlung zu erhalten.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine 2er Kombiwette?',
        a: 'Eine 2er Kombiwette ist eine Wette mit zwei Auswahlen.',
      },
      {
        q: 'Wie funktioniert eine 2er Kombiwette?',
        a: 'Wenn Ihre erste Auswahl gewonnen hat, ist dieser Gewinn der Einsatz auf Ihre zweite Auswahl. Beide Auswahlen müssen sich auf unterschiedliche  Ereignisse beziehen, damit die 2er Kombiwette gültig ist.',
      },
      {
        q: 'Wie berechnet man eine 2er Kombiwette?',
        a: 'Als Beispiel für die Berechnung einer 2er Kombiwette nehmen wir an, dass Sie haben eine 2er Kombiwette mit einem Einsatz von 10€ gesetzt  haben. Eine Auswahl Ihrer Wette hat eine Quote von 4,0. Ein Sieg würde einen Gewinn von 40€ ausmachen. Ihre zweite Auswahl hat eine Quote von 3,0. Der Gewinn der Wette auf die erste Auswahl, 40€, wird als Einsatz für die Wette auf die zweite Auswahl mit der Quote 3,0 genommen. Somit würde die mögliche Gesamtauszahlung Ihrer Wette 120€ betragen (10€ Einsatz x 4,0 x 3,0 = 120€).',
      },
    ],
  },

  treble: {
    title: '3er Kombiwette Systemwetten Quotenrechner – Was ist eine 3er Kombiwette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre 3er Kombiwette. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/treble/',
    },
    h1: '3er Kombiwette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer 3er Kombiwette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine 3er Kombiwette?',
        a: 'Eine 3er Kombiwette ist eine Wette mit drei Auswahlen, bei der alle drei gewinnen müssen, um eine Auszahlung zu erhalten.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine 3er Kombiwette?',
        a: 'Eine 3er Kombiwette ist eine Wette mit drei Auswahlen.',
      },
      {
        q: 'Wie funktioniert eine 3er Kombiwette?',
        a: 'Wenn Ihre erste Auswahl gewonnen hat, ist dieser Gewinn der Einsatz auf Ihre zweite Auswahl. Gewinnt die zweite Auswahl ist dieser Gewinn der Einsatz auf Ihre dritte Auswahl.',
      },
      {
        q: 'Wie berechnet man eine 3er Kombiwette?',
        a: 'Als Beispiel für die Berechnung einer 3er Kombiwette nehmen wir an, dass Sie haben eine 3er Kombiwette mit einem Einsatz von 10€ gesetzt haben. Eine Auswahl Ihrer Wette hat eine Quote von 4,0. Ein Sieg würde einen Gewinn von 40€ ausmachen. Ihre zweite Auswahl hat eine Quote von 3,0. Der Gewinn der Wette auf die erste Auswahl, 40€, wird als Einsatz für die Wette auf die zweite Auswahl mit der Quote 3,0 genommen. Ein Sieg würde einen Gewinn von 120€ ausmachen. Ihre dritte Auswahl hat eine Quote von 4,0. Gewinnt diese ebenfalls, ergibt das mit dem Gewinn der ersten beiden Auswahlen eine mögliche Gesamtauszahlung von 480€ (10€ Einsatz x 4,0 x 3,0 x 4,0 = 480€).',
      },
    ],
  },

  accumulator: {
    title: 'Kombinationswetten Systemwetten Quotenrechner – Was ist eine Kombiwette? – BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Kombiwette. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/accumulator/',
    },
    h1: 'Kombiwette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Kombinationswette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Kombiwette?',
        a: 'Eine Kombiwette ist eine Wette mit vier oder mehr Auswahlen auf verschiedene Ereignisse, bei der alle Auswahlen gewinnen müssen, um eine Auszahlung zu erhalten. Je nach der Anzahl der Auswahlen, spricht man von einer 4er Kombiwette, 5er Kombiwette, 6er Kombiwette etc.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Kombiwette?',
        a: 'Eine Kombiwette ist eine Wette mit vier oder mehr Auswahlen.',
      },
      {
        q: 'Wie funktioniert eine Kombiwette?',
        a: 'Wenn Ihre erste Auswahl gewonnen hat, ist dieser Gewinn der Einsatz auf Ihre zweite Auswahl. Gewinnt die zweite Auswahl ist dieser Gewinn der Einsatz auf Ihre dritte Auswahl und so weiter. Jeder Gewinn auf eine Auswahl wird zum Einsatz auf die nächste Auswahl. Alle Auswahlen müssen gewinnen, damit Sie eine Auszahlung erhalten.',
      },
      {
        q: 'Wie berechnet man eine Kombiwette?',
        a: 'Als Beispiel für die Berechnung einer Kombiwette nehmen wir an, dass Sie haben eine Kombiwette mit vier Auswahlen mit einem Einsatz von 10€ gesetzt haben. Eine Auswahl Ihrer Wette hat eine Quote von 4,0. Ein Sieg würde einen Gewinn von 40€ ausmachen. Ihre zweite Auswahl hat eine Quote von 3,0. Der Gewinn der Wette auf die erste Auswahl, 40€, wird als Einsatz für die Wette auf die zweite Auswahl mit der Quote 3,0 genommen. Ein Sieg ergibt einen Gewinn von 120€. Ihre dritte Auswahl hat eine Quote von 4,0. Ein Sieg würde einen mit einem Einsatz von 120€ einen Gewinn von 480€ ausmachen. Ihre vierte Auswahl hat ebenfalls eine Quote von 4,0. Gewinnt diese ebenfalls, ergibt das mit dem Gewinn der ersten drei Auswahlen eine mögliche Gesamtauszahlung von 1.920€ (10€ Einsatz x 4,0 x 3,0 x 4,0 x 4,0  = 1.920€). ',
      },
    ],
  },

  trixie: {
    title: 'Trixie Systemwetten Quotenrechner – Was ist eine Trixie Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Trixie Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/trixie/',
    },
    h1: 'Trixie Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Trixie Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Trixie Wette?',
        a: 'Eine Trixie Wette ist eine Wette mit drei Auswahlen auf verschiedene Ereignisse, die aus drei 2er Kombiwetten und einer 3er Kombiwette besteht.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Trixie Wette?',
        a: 'Eine Trixie Wette besteht insgesamt aus vier Kombiwetten.',
      },
      {
        q: 'Wie funktioniert eine Trixie Wette?',
        a: 'Mit den drei Auswahlen werden drei Kombiwetten mit zwei Auswahlen und eine Kombiwetten mit allen drei Auswahlen gebildet.',
      },
      {
        q: 'Wie berechnet man eine Trixie Wette?',
        a: 'Bei einer Trixie Wette werden die Gewinne der 2er und der 3er Kombiwetten summiert. Mindestens zwei Ihrer Auswahlen müssen gewinnen, damit Sie eine Auszahlung erhalten. Der Vorteil einer Trixie Wette liegt darin, dass Sie auch dann eine Auszahlung erhalten, wenn eine Auswahl verliert.',
      },
    ],
  },
  patent: {
    title: 'Patent Wette Systemwetten Quotenrechner - Was ist eine Patent Wette? – BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Patent Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/patent/',
    },
    h1: 'Patent Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Patent Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Patent Wette?',
        a: 'Eine Patent Wette ist eine Wette mit drei Auswahlen auf verschiedene Ereignisse, die aus drei Einzelwetten, drei 2er Kombiwetten und einer 3er Kombiwette besteht. Eine Patent Wette ist eine Trixie Wette mit zusätzlich drei Einzelwetten auf die drei Auswahlen.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Patent Wette?',
        a: 'Eine Patent Wette besteht insgesamt aus sieben Wetten.',
      },
      {
        q: 'Wie funktioniert eine Patent Wette?',
        a: 'Mit den drei Auswahlen werden alle drei Kombiwetten mit zwei Auswahlen und eine Kombiwetten mit allen drei Auswahlen gebildet. Zusätzlich werden drei Einzelwetten auf die drei Auswahlen gesetzt.',
      },
      {
        q: 'Wie berechnet man eine Patent Wette?',
        a: 'Bei einer Patent Wette werden die Gewinne der Einzelwetten sowie der 2er und der 3er Kombiwetten summiert. Mindestens eine Ihrer Auswahlen muss gewinnen, damit Sie eine Auszahlung erhalten. Der Vorteil einer Patent Wette liegt darin, dass Sie auch dann eine Auszahlung erhalten, wenn nur eine Auswahl gewinnt.',
      },
    ],
  },
  yankee: {
    title: 'Yankee Wette Systemwetten Quotenrechner – Was ist eine Yankee Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Yankee Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/yankee/',
    },
    h1: 'Yankee Wetten Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Yankee Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Yankee Wette?',
        a: 'Eine Yankee Wette ist eine Wette mit vier Auswahlen auf verschiedene Ereignisse, die aus sechs 2er Kombiwetten, vier 3er Kombiwetten und einer 4er Kombiwette besteht.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Yankee Wette?',
        a: 'Eine Yankee Wette besteht insgesamt aus 11 Kombiwetten.',
      },
      {
        q: 'Wie funktioniert eine Yankee Wette?',
        a: 'Mit den vier Auswahlen werden sechs Kombiwetten mit zwei Auswahlen, vier Kombiwetten mit drei Auswahlen sowie eine Kombiwette mit allen vier Auswahlen gebildet.',
      },
      {
        q: 'Wie berechnet man eine Yankee Wette?',
        a: 'Bei einer Yankee Wette werden die Gewinne der 2er, 3er und der 4er Kombiwetten summiert. Mindestens zwei Ihrer Auswahlen müssen gewinnen, damit Sie eine Auszahlung erhalten. Der Vorteil einer Yankee Wette liegt darin, dass Sie auch dann eine Auszahlung erhalten, wenn zwei Auswahlen verlieren.',
      },
    ],
  },
  canadian: {
    title: 'Canadian Wette Systemwetten Quotenrechner - Was ist eine Canadian Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Canadian Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/canadian/',
    },
    h1: 'Canadian Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Canadian Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Canadian Wette?',
        a: 'Eine Canadian Wette, auch Super Yankee genannt, ist eine Wette mit fünf Auswahlen auf verschiedene Ereignisse, die aus zehn 2er Kombiwetten, zehn 3er Kombiwetten, fünf 4er Kombiwetten und einer 5er Kombiwette besteht.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Canadian Wette?',
        a: 'Eine Canadian Wette besteht insgesamt aus 26 Kombiwetten.',
      },
      {
        q: 'Wie funktioniert eine Canadian Wette?',
        a: 'Mit den fünf Auswahlen werden zehn Kombiwetten mit zwei Auswahlen, zehn Kombiwetten mit drei Auswahlen, fünf Kombiwetten mit vier Auswahlen sowie eine Kombiwette mit allen fünf Auswahlen gebildet.',
      },
      {
        q: 'Wie berechnet man eine Canadian Wette?',
        a: 'Bei einer Canadian Wette werden die Gewinne der 2er, 3er, 4er und 5er Kombiwetten summiert. Mindestens zwei Ihrer Auswahlen müssen gewinnen, damit Sie eine Auszahlung erhalten. Der Vorteil einer Canadian Wette liegt darin, dass Sie auch dann eine Auszahlung erhalten, wenn drei Auswahlen verlieren.',
      },
    ],
  },
  heinz: {
    title: 'Heinz Wette Systemwetten Quotenrechner - Was ist eine Heinz Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Heinz Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/heinz/',
    },
    h1: 'Heinz Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Heinz Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Heinz Wette?',
        a: 'Eine Heinz Wette ist eine Wette mit sechs Auswahlen auf verschiedene Ereignisse, die aus 15 2er Kombiwetten, 20 3er Kombiwetten, 15 4er Kombiwetten, fünf 5er Kombiwetten und einer 6er Kombiwette besteht.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Heinz Wette?',
        a: 'Eine Heinz Wette besteht insgesamt aus 57 Kombiwetten.',
      },
      {
        q: 'Wie funktioniert eine Heinz Wette?',
        a: 'Mit den sechs Auswahlen werden 15 Kombiwetten mit zwei Auswahlen, 20 Kombiwetten mit drei Auswahlen, 15 Kombiwetten mit vier Auswahlen, fünf Kombiwetten mit fünf Auswahlen sowie eine Kombiwette mit allen sechs Auswahlen gebildet.',
      },
      {
        q: 'Wie berechnet man eine Heinz Wette?',
        a: 'Bei einer Heinz Wette werden die Gewinne der 2er, 3er, 4er, 5er und der 6er Kombiwetten summiert. Mindestens zwei Ihrer Auswahlen müssen gewinnen, damit Sie eine Auszahlung erhalten. Der Vorteil einer Canadian Wette liegt darin, dass Sie auch dann eine Auszahlung erhalten, wenn vier Auswahlen verlieren.',
      },
    ],
  },
  'super-heinz': {
    title: 'Super Heinz Wette Systemwetten Quotenrechner - Was ist eine Super Heinz Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Super Heinz Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/super-heinz/',
    },
    h1: 'Super Heinz Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Super Heinz Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Super Heinz Wette?',
        a: 'Eine Super Heinz Wette ist eine Wette mit sieben Auswahlen auf verschiedene Ereignisse, die aus 21 2er Kombiwetten, 35 3er Kombiwetten, 35 4er Kombiwetten, 21 5er Kombiwetten, sieben 6er Kombiwetten und einer 7er Kombiwette besteht.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Super Heinz Wette?',
        a: 'Eine Super Heinz Wette besteht insgesamt aus 120 Kombinationswetten.',
      },
      {
        q: 'Wie funktioniert eine Super Heinz Wette?',
        a: 'Mit den sieben Auswahlen werden 21 Kombiwetten mit zwei Auswahlen, 35 Kombiwetten mit drei Auswahlen, 35 Kombiwetten mit vier Auswahlen, 21 Kombiwetten mit fünf Auswahlen, sieben Kombiwetten mit sechs Auswahlen sowie eine Kombiwette mit allen sieben Auswahlen gebildet.',
      },
      {
        q: 'Wie berechnet man eine Super Heinz Wette?',
        a: 'Bei einer Super Heinz Wette werden die Gewinne der 2er, 3er, 4er, 5er, 6er und der 7er Kombiwetten summiert. Mindestens zwei Ihrer Auswahlen müssen gewinnen, damit Sie eine Auszahlung erhalten. Der Vorteil einer Canadian Wette liegt darin, dass Sie auch dann eine Auszahlung erhalten, wenn fünf Auswahlen verlieren.',
      },
    ],
  },
  goliath: {
    title: 'Goliath Wette Systemwetten Quotenrechner - Was ist eine Goliath Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Goliath Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/goliath/',
    },
    h1: 'Goliath Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Goliath Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Goliath Wette?',
        a: 'Eine Goliath Wette ist eine Wette mit acht Auswahlen auf verschiedene Ereignisse, die aus 28 2er Kombiwetten, 56 3er Kombiwetten, 70 4er Kombiwetten, 56 5er Kombiwetten, 28 6er Kombiwetten, acht 7er Kombiwetten und einer 8er Kombiwette besteht.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Goliath Wette?',
        a: 'Eine Super Heinz Wette besteht insgesamt aus 247 Kombiwetten.',
      },
      {
        q: 'Wie funktioniert eine Goliath Wette?',
        a: 'Mit den acht Auswahlen werden 28 Kombiwetten mit zwei Auswahlen, 56 Kombiwetten mit drei Auswahlen, 70 Kombiwetten mit vier Auswahlen, 56 Kombiwetten mit fünf Auswahlen, 28 Kombiwetten mit sechs Auswahlen, acht Kombiwetten mit sieben Auswahlen sowie eine Kombiwette mit allen acht Auswahlen gebildet.',
      },
      {
        q: 'Wie berechnet man eine Goliath Wette?',
        a: 'Bei einer Goliath Wette werden die Gewinne der 2er, 3er, 4er, 5er, 6er, 7er und der 8er Kombiwetten summiert. Mindestens zwei Ihrer Auswahlen müssen gewinnen, damit Sie eine Auszahlung erhalten. Der Vorteil einer Canadian Wette liegt darin, dass Sie auch dann eine Auszahlung erhalten, wenn sechs Auswahlen verlieren.',
      },
    ],
  },
  'lucky-15': {
    title: 'Lucky 15 Wette Systemwetten Quotenrechner - Was ist eine Lucky 15 Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Lucky 15 Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/lucky-15/',
    },
    h1: 'Lucky 15 Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Lucky 15 Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Lucky 15 Wette?',
        a: 'Eine Lucky 15 Wette ist eine Wette mit vier Auswahlen auf verschiedene Ereignisse, die aus vier Einzelwetten, sechs 2er Kombiwetten, vier 4er Kombiwetten und einer 4er Kombiwette besteht. Eine Lucky 15 Wette ist eine Yankee Wette mit zusätzlich vier Einzelwetten auf die vier Auswahlen. BetVictor bietet keine erhöhten Quoten.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Lucky 15 Wette?',
        a: 'Eine Lucky 15 Wette besteht, wie der Name schon sagt, insgesamt aus 15 Wetten.',
      },
      {
        q: 'Wie funktioniert eine Lucky 15 Wette?',
        a: 'Mit den vier Auswahlen werden vier Einzelwetten, sechs Kombiwetten mit zwei Auswahlen, vier Kombiwetten mit drei Auswahlen sowie eine Kombiwette mit allen vier Auswahlen gebildet.',
      },
      {
        q: 'Wie berechnet man eine Lucky 15 Wette?',
        a: 'Bei einer Lucky 15 Wette werden die Gewinne der Einzelwetten, 2er, 3er und der 4er Kombiwette summiert. Mindestens eine Ihrer Auswahlen muss gewinnen, damit Sie eine Auszahlung erhalten. Der Vorteil einer Lucky 15 Wette liegt darin, dass Sie auch dann eine Auszahlung erhalten, wenn drei Auswahlen verlieren.',
      },
    ],
  },
  'lucky-31': {
    title: 'Lucky 31 Wette Systemwetten Quotenrechner - Was ist eine Lucky 31 Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Lucky 31 Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/lucky-31/',
    },
    h1: 'Lucky 31 Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Lucky 31 Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Lucky 31 Wette?',
        a: 'Eine Lucky 31 Wette ist eine Wette mit fünf Auswahlen auf verschiedene Ereignisse, die aus fünf Einzelwetten, zehn 2er Kombiwetten, zehn 3er Kombiwetten, fünf 4er Kombiwetten und einer 5er Kombiwette besteht. Eine Lucky 31 Wette ist eine Canadian bzw. Super Yankee Wette mit zusätzlich fünf Einzelwetten auf die fünf Auswahlen.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Lucky 31 Wette?',
        a: 'Eine Lucky 31 Wette besteht, wie der Name schon sagt, insgesamt aus 31 Wetten.',
      },
      {
        q: 'Wie funktioniert eine Lucky 31 Wette?',
        a: 'Mit den fünf Auswahlen werden fünf Einzelwetten, zehn Kombiwetten mit zwei Auswahlen, zehn Kombiwetten mit drei Auswahlen, fünf Kombiwetten mit vier Auswahlen sowie eine Kombiwette mit allen fünf Auswahlen gebildet.',
      },
      {
        q: 'Wie berechnet man eine Lucky 31Wette?',
        a: 'Bei einer Lucky 31 Wette werden die Gewinne der Einzelwetten, 2er, 3er, 4er und der 5er Kombiwetten summiert. Mindestens eine Ihrer Auswahlen muss gewinnen, damit Sie eine Auszahlung erhalten. Der Vorteil einer Lucky 31 Wette liegt darin, dass Sie auch dann eine Auszahlung erhalten, wenn vier Auswahlen verlieren.',
      },
    ],
  },
  'lucky-63': {
    title: 'Lucky 63 Wette Systemwetten Quotenrechner - Was ist eine Lucky 63 Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Lucky 63 Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/lucky-63/',
    },
    h1: 'Lucky 63 Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Lucky 63 Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Lucky 63 Wette?',
        a: 'Eine Lucky 63 Wette ist eine Wette mit sechs Auswahlen auf verschiedene Ereignisse, die aus sechs Einzelwetten, 15 2er Kombiwetten, 20 3er Kombiwetten, 15 4er Kombiwetten, sechs 5er Kombiwetten sowie einer 6er Kombiwette besteht. Eine Lucky 63 Wette ist eine Heinz Wette mit zusätzlich sechs Einzelwetten auf die sechs Auswahlen.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Lucky 63 Wette?',
        a: 'Eine Lucky 63 Wette besteht, wie der Name schon sagt, insgesamt aus 63 Wetten.',
      },
      {
        q: 'Wie funktioniert eine Lucky 63 Wette?',
        a: 'Mit den sechs Auswahlen werden sechs Einzelwetten, 15 Kombiwetten mit zwei Auswahlen, 20 Kombiwetten mit drei Auswahlen, 15 Kombiwetten mit vier Auswahlen, sechs Kombiwetten mit fünf Auswahlen sowie eine Kombiwette mit sechs Auswahlen gebildet.',
      },
      {
        q: 'Wie berechnet man eine Lucky 63 Wette?',
        a: 'Bei einer Lucky 63 Wette werden die Gewinne der Einzelwetten, 2er, 3er, 4er, 5er und der 6er Kombiwette summiert. Mindestens eine Ihrer Auswahlen muss gewinnen, damit Sie eine Auszahlung erhalten. Der Vorteil einer Lucky 63 Wette liegt darin, dass Sie auch dann eine Auszahlung erhalten, wenn fünf Auswahlen verlieren.',
      },
    ],
  },
  alphabet: {
    title: 'Alphabet Wette Systemwetten Quotenrechner – Was ist eine Alphabet Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Alphabet Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/alphabet/',
    },
    h1: 'Alphabet Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Alphabet Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Alphabet Wette?',
        a: 'Eine Alphabet Wette ist eine Wette mit sechs Auswahlen auf verschiedene Ereignisse, die aus zwei Patent Wetten (14 Wetten), einer Yankee Wette (11 Wetten) und einer 6er Kombiwette (eine Wette) besteht und somit aus insgesamt 26 Wetten - in Anlehnung an die 26 Buchstaben des Alphabets - besteht. Die Alpahbet Wette wird mit vier Auswahlen gebildet, wobei die Auswahlen 1 – 3 die erste Patent Wette (sieben Wetten) und die Auswahlen 4 – 6 die zweite Patent Wette (sieben Wetten) ausmachen. Die Yankee Wette wird mit den Auswahlen 2, 3, 4, & 5 gebildet (11 Wetten). Der letzte Abschnitt der Alphabet Wette ist eine 6er Kombiwette (eine Wette), die die 26 Wetten des Alphabets vervollständigt. Gewinnt nur eine einzige Auswahl haben Sie bereits eine Auszahlung.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Alphabet Wette?',
        a: 'Eine Alphabet Wette besteht aus 26 Wetten. Der Name dieser Wettart bezieht sich auf die 26 Buchstaben des Alphabets.',
      },
      {
        q: 'Wir funktioniert eine Alphabet Wette?',
        a: 'Eine Alphabet Wette wird aus der Kombination von drei verschiedenen Wettarten gebildet (Patent, Yankee und Kombiwette), dies führt zu 26 verschiedenen Wettkombinationen.',
      },
      {
        q: 'Wie berechnet man eine Alphabet Wette?',
        a: 'Bei einer Alphabet Wette werden die Gewinne der Einzel- und Kombiwetten summiert. Gewinnt eine Ihrer Auswahlen, erhaltenSie bereits eine Auszahlung.',
      },
    ],
  },
  'round-robin': {
    title: 'Round Robin Wette Systemwetten Quotenrechner – Was ist eine Round Robin Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Round Robin Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/round-robin/',
    },
    h1: 'Round Robin Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Round Robin Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Round Robin Wette?',
        a: 'Eine Round Robin Wette ist eine Wette mit drei Auswahlen auf verschiedene Ereignisse, die aus drei 2er und einer 3er Kombiwette besteht, sowie aus drei „Single Stakes About“ Wetten, das sind jeweils zwei Wetten. Somit besteht eine Round Robin Wette aus insgesamt 10 Wetten. Gewinnt eine einzige Auswahl, erhalten Sie eine Auszahlung. Die Wette „Single Stakes About“ besteht aus zwei Auswahlen verschiedener Ereignisse, die so kombiniert werden, dass sie zwei Einzelwetten mit zwei Auswahlen bilden. Der Gewinn jeder Einzelwette bis zur Höhe des ursprünglichen Einsatzes wird als Einsatz der Einzelwette der anderen Auswahl verwendet. Diese Wettart ist auch als „Up And Down“ bekannt. Die Kombination der Auswahlen ABC ist AB, AC und CB.',
      },
      {
        q: 'Aus wie vielen Wetten besteht eine Round Robin Wette?',
        a: 'Eine Round Robin Wette besteht aus 10 Wetten.',
      },
      {
        q: 'Wie funktioniert eine Round Robin Wette?',
        a: 'Eine Round Robin Wette wird aus der Kombination von 2er und einer 3er Kombiwette, sowie einer Serie von Up And Down Single Stake About Wetten, bei denen bei jedem Wettpaar der ursprüngliche Einsatz auf die nächste Wette übertragen wird, gebildet.',
      },
      {
        q: 'Wie berechnet man eine Round Robin Wette?',
        a: 'Bei einer Round Robin Wette werden die Gewinne der Einzel- und Kombiwetten summiert.',
      },
    ],
  },
  flag: {
    title: 'Flag Wette Systemwetten Quotenrechner – Was ist eine Flag Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Flag Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/flag/',
    },
    h1: 'Flag Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Flag Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Flag Wette?',
        a: 'Eine Flag Wette ist eine Wette mit vier Auswahlen auf verschiedene Ereignisse, die aus sechs 2er, vier 3er und einer 4er Kombiwette besteht, sowie aus sechs „Single Stakes About“ Wetten, mit je einem Wettpaar. Somit besteht eine Flag Wette aus insgesamt 23 Wetten. Eine Flag Wette ist ähnlich einer Yankee Wette, allerdings mit den sechs Paaren der Single Stakes About Wetten zusätzlich.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Flag Wette?',
        a: 'Eine Flag Wette besteht aus 23 Wetten.',
      },
      {
        q: 'Wie funktioniert eine Flag Wette?',
        a: 'Eine Flag Wette kombiniert die maximale Anzahl von 2er, 3er und einer 4er Kombiwette mit den Single Stakes About Wetten der vier Auswahlen.',
      },
      {
        q: 'Wie berechnet man eine Flag Wette?',
        a: 'Bei einer Flag Wette werden die Gewinne der Einzel- und Kombiwetten summiert. Gewinnt eine Ihrer Auswahlen, erhalten Sie bereits eine Auszahlung.',
      },
    ],
  },
  'super-flag': {
    title: 'Super Flag Wette Systemwetten Quotenrechner - Was ist eine Super Flag Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Super Flag Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/super-flag/',
    },
    h1: 'Super Flag Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Systemwetten Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Super Flag Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Super Flag Wette?',
        a: 'Eine Super Flag Wette ist eine Wette mit fünf Auswahlen auf verschiedene Ereignisse, die aus 10 2er, 10 3er, fünf 4er und einer 5er Kombiwette besteht, sowie aus 10 „Single Stakes About“ Wetten, mit je einem Wettpaar. Somit besteht eine Super Flag Wette aus insgesamt 46 Wetten. Eine Super Flag Wette ist ähnlich einer Candian Wette, allerdings mit den 10 Paaren der Single Stakes About Wetten zusätzlich.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Super Flag Wette?',
        a: 'Eine Flag Wette besteht aus 46 Wetten',
      },
      {
        q: 'Wie funktioniert eine Super Flag Wette?',
        a: 'Eine Super Flag Wette kombiniert die maximale Anzahl von 2er, 3er, 4er und einer 5er Kombiwette mit den Single Stakes About Wetten der fünf Auswahlen.',
      },
      {
        q: 'Wie berechnet man eine Super Flag Wette?',
        a: 'Bei einer Flag Wette werden die Gewinne der Einzel- und Kombiwetten summiert. Gewinnt eine Ihrer Auswahlen, erhalten Sie bereits eine Auszahlung.',
      },
    ],
  },
  'super-yankee': {
    title: 'Super Yankee Wette Systemwetten Quotenrechner – Was ist eine Super Yankee Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Super Yankee Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/super-yankee/',
    },
    h1: 'Super Yankee Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Super Yankee Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Super Yankee Wette?',
        a: 'Eine Super Yankee Wette, auch Canadian Wette genannt, ist eine Wette mit fünf Auswahlen auf verschiedene Ereignisse, die aus zehn 2er Kombiwetten, zehn 3er Kombiwetten, fünf 4er Kombiwetten und einer 5er Kombiwette besteht.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Super Yankee Wette?',
        a: 'Eine Super Yankee Wette besteht aus 26 Wetten.',
      },
      {
        q: 'Wie funktioniert eine Super Yankee Wette?',
        a: 'Eine Super Yankee Wette kombiniert die maximale Anzahl von 2er, 3er, 4er und einer 5er Kombiwette der fünf Auswahlen.',
      },
      {
        q: 'Wie berechnet man eine Super Yankee Wette?',
        a: 'Bei einer Super Yankee Wette werden die Gewinne der Kombiwetten summiert. Zwei Ihrer Auswahlen müssen gewinnen, damit Sie eine Auszahlung erhalten.',
      },
    ],
  },
  'double-stakes-about': {
    title: 'Double Stakes About Wette Systemwetten Quotenrechner? - Was ist eine Double Stakes About Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Double Stakes About Wette Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/double-stakes-about/',
    },
    h1: 'Double Stakes About Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Double Stakes About Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Double Stakes About Wette?',
        a: 'Eine Double Stakes About Wette besteht aus zwei Auswahlen verschiedener Ereignisse, die so kombiniert werden, dass sie zwei Einzelwetten mit zwei Auswahlen bilden. Der Gewinn jeder Einzelwette bis zur Höhe des doppelten des ursprünglichen Einsatzes wird als Einsatz der Einzelwette der anderen Auswahl verwendet.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Double Stakes About Wette?',
        a: 'Eine Double Stakes About Wette besteht aus zwei Wetten.',
      },
      {
        q: 'Wie funktioniert eine Double Stakes About Wette?',
        a: 'Gewinnt die erste Wette wird das Doppelte des ursprünglichen Einsatzes auf die zweite Wette übertragen.',
      },
      {
        q: 'Wie berechnet man eine Double Stakes About Wette?',
        a: 'Als Beispiel für die Berechnung einer Double Stakes About Wette nehmen wir an, dass Sie 10€ gesetzt haben. Ihre erste Wette ist eine Einzelwette mit der Quote von 4,0. Das macht bei einem Einsatz von 10€ einen Gewinn von 40€ aus Ihre zweite Wette ist eine Einzelwette mit der Quote von 3,0. Es wird der doppelte Einsatz des Gewinns von der ersten Wette als Einsatz (10€x2=20€) genommen. Der Gewinn der zweiten Wette beträgt 60€. Die Gesamtauszahlung der Wette beträgt 80€. Das bedeutet, Sie erhalten 20€ von der ersten Wette (40€ Gewinn abzüglich 20€ Einsatz, der auf die zweite Wette übertragen wurde) und 60€ Gewinn von der zweiten Wette.',
      },
    ],
  },
  'single-stakes-about': {
    title: 'Single Stakes About Wette Systemwetten Quotenrechner - Was ist eine Single Stakes About Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Single Stakes About Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/single-stakes-about/',
    },
    h1: 'Single Stakes About Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Single Stakes About Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Single Stakes About Wette?',
        a: 'Eine Single Stakes About Wette besteht aus zwei Auswahlen verschiedener Ereignisse, die so kombiniert werden, dass sie zwei Einzelwetten mit zwei Auswahlen bilden. Der Gewinn jeder Einzelwette bis zur Höhe des ursprünglichen Einsatzes wird als Einsatz der Einzelwette der anderen Auswahl verwendet. Diese Wettart ist auch als „Up And Down“ bekannt.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Single Stakes About Wette?',
        a: 'Eine Single Stakes About Wette besteht aus zwei Wetten.',
      },
      {
        q: 'Wie funktioniert eine Single Stakes About Wette?',
        a: 'Gewinnt die erste Wette wird der usprüngliche Einsatz auf die zweite Wette übertragen.',
      },
      {
        q: 'Wie berechnet man eine Single Stakes About Wette?',
        a: 'Als Beispiel für die Berechnung einer Single Stakes About Wette nehmen wir an, dass Sie 10€ gesetzt haben. Ihre erste Wette ist eine Einzelwette mit der Quote von 4,0. Das macht bei einem Einsatz von 10€ einen Gewinn von 40€ aus Ihre zweite Wette ist eine Einzelwette mit der Quote von 3,0. Es wird der Einsatz des Gewinns von der ersten Wette genommen. Der Gewinn der zweiten Wette beträgt 30€. Die Gesamtauszahlung der Wette beträgt 60€. Das bedeutet, Sie erhalten 30€ von der ersten Wette (40€ Gewinn abzüglich 10€ Einsatz, der auf die zweite Wette übertragen wurde) und 30€ Gewinn von der zweiten Wette.',
      },
    ],
  },
  'heinz-flag': {
    title: 'Heinz Flag Wette Systemwetten Quotenrechner – Was ist eine Heinz Flag Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Heinz Flag Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/heinz-flag/',
    },
    h1: 'Heinz Flag Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Heinz Flag Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Heinz Flag Wette?',
        a: 'Eine Heinz Flag Wette ist eine Wette mit sechs Auswahlen auf verschiedene Ereignisse, die aus 15 2er, 20 3er, 15 4er, sechs 5er und einer 6er Kombiwette besteht, sowie aus 15 „Single Stakes About“ Wetten, mit je einem Wettpaar.. Somit besteht eine Heinz Flag Wette aus insgesamt 87 Wetten. Eine Heinz Flag Wette ist ähnlich einer Heinz Wette, allerdings mit den 15 Paaren der Single Stakes About Wetten zusätzlich.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Heinz Flag Wette?',
        a: 'Eine Heinz Flag Wette besteht aus 87 Wetten.',
      },
      {
        q: 'Wie funktioniert eine Heinz Flag Wette?',
        a: 'Eine Heinz Flag Wette kombiniert die maximale Anzahl von 2er, 3er, 4er, 5er und einer 6er Kombiwette mit den Single Stakes About Wetten der sechs Auswahlen.',
      },
      {
        q: 'Wie berechnet man eine Heinz Flag Wette?',
        a: 'Bei einer Heinz Flag Wette werden die Gewinne der Einzel- und Kombiwetten summiert. Gewinnt eine Ihrer Auswahlen, erhaltenSie bereits eine Auszahlung.',
      },
    ],
  },
  'super-heinz-flag': {
    title: 'Super Heinz Flag Wette Systemwetten Quotenrechner - Was ist eine Super Heinz Flag Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Super Heinz Flag Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/super-heinz-flag/',
    },
    h1: 'Super Heinz Flag Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Super Heinz Flag Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Super Heinz Flag Wette?',
        a: 'Eine Super Heinz Flag Wette ist eine Wette mit sieben Auswahlen auf verschiedene Ereignisse, die aus 21 2er, 35 3er, 35 4er, 21 5er, sieben 6er und einer 7er Kombiwette besteht, sowie aus 21 „Single Stakes About“ Wetten, mit je einem Wettpaar. Somit besteht eine Super Heinz Flag Wette aus insgesamt 162 Wetten.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Super Heinz Flag Wette?',
        a: 'Eine Super Heinz Flag Wette besteht aus 162 Wetten.',
      },
      {
        q: 'Wie funktioniert eine Super Heinz Flag Wette?',
        a: 'Eine Super Heinz Flag Wette kombiniert die maximale Anzahl von 2er, 3er, 4er, 5er, 6er und einer 7er Kombiwette mit den Single Stakes About Wetten der sieben Auswahlen.',
      },
      {
        q: 'Wie berechnet man eine Super Heinz Flag Wette?',
        a: 'Bei einer Super Heinz Flag Wette werden die Gewinne der Einzel- und Kombiwetten summiert. Gewinnt eine Ihrer Auswahlen, erhalten Sie bereits eine Auszahlung.',
      },
    ],
  },
  'goliath-flag': {
    title: 'Goliath Flag Wette Systemwetten Quotenrechner – Was ist eine Goliath Flag Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Goliath Flag Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/goliath-flag/',
    },
    h1: 'Goliath Flag Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Super Heinz Flag Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Goliath Flag Wette?',
        a: 'Eine Goliath Flag Wette ist eine Wette mit acht Auswahlen auf verschiedene Ereignisse, die aus 28 2er, 56 3er, 70 4er, 56 5er, 28 6er, acht 7er und einer 8er Kombiwette besteht, sowie aus 20 „Single Stakes About“ Wetten, mit je einem Wettpaar. Somit besteht eine Goliath Flag Wette aus insgesamt 303 Wetten.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Goliath Flag Wette?',
        a: 'Eine Goliath Flag Wette besteht aus 303 Wetten.',
      },
      {
        q: 'Wie funktioniert eine Goliath Flag Wette?',
        a: 'Eine Goliath Flag Wette kombiniert die maximale Anzahl von 2er, 3er, 4er, 5er, 6er, 7er und einer 8er Kombiwette mit den Single Stakes About Wetten der acht Auswahlen.',
      },
      {
        q: 'Wie berechnet man eine Goliath Flag Wette?',
        a: 'Bei einer Goliath Flag Wette werden die Gewinne der Einzel- und Kombiwetten summiert. Gewinnt eine Ihrer Auswahlen, erhalten Sie bereits eine Auszahlung.',
      },
    ],
  },
  'union-jack-trebles': {
    title: 'Union Jack Trebles Wette Systemwetten Quotenrechner - Was ist eine Union Jack Trebles Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Union Jack Trebles Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/union-jack-trebles/',
    },
    h1: 'Union Jack Trebles Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Union Jack Trebles Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Union Jack Trebles Wette?',
        a: '<div class="bc-bg-flags bc-bg-flags__a">Eine Union Jack Treble Wette ist eine Wette mit neun Auswahlen auf verschiedene Ereignisse, die aus acht 3er Kombiwetten besteht. Diese 3er Kombiwetten sind in drei Reihen mit je drei Auswahlen angeordnet. Die einzelnen Wetten ergeben sich aus der Kombination der Auswahlen auf den horizontalen, vertikalen und diagonalen Linien. Jede Kombination von drei Auswahlen bildet eine 3er Kombiwette. Insgesamt besteht eine Union Jack Treble Wette aus acht Wetten.</div>',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Union Jack Treble Wette?',
        a: 'Eine Union Jack Treble Wette besteht aus acht Wettkombinationen.',
      },
      {
        q: 'Wie funktioniert eine Union Jack Treble Wette?',
        a: '<div class="bc-bg-flags bc-bg-flags__overlay-a">Eine Union Jack Treble Wette kombiniert die maximale Anzahl von 3er Kombiwetten der neun Auswahlen.</div>',
      },
      {
        q: 'Wie berechnet man eine Union Jack Treble Wette?',
        a: 'Bei einer Union Jack Treble Wette werden die Gewinne der Kombiwetten summiert.',
      },
    ],
  },
  'union-jack-trixie': {
    title: 'Union Jack Trixie Wette Systemwetten Quotenrechner – Was ist eine Union Jack Trixie Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Union Jack Trixie Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/union-jack-trixie/',
    },
    h1: 'Union Jack Trixie Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Union Jack Trixie Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Union Jack Trixie Wette?',
        a: '<div class="bc-bg-flags bc-bg-flags__a">Eine Union Jack Trixie Wette ist eine Wette mit neun Auswahlen auf verschiedene Ereignisse, die aus acht Trixie Wetten (24 2er Kombiwetten bilden acht 3er Kombiwetten) besteht. Diese 3er Kombiwetten sind in drei Reihen mit je drei Auswahlen angeordnet. Die einzelnen Wetten ergeben sich aus der Kombination der Auswahlen auf den horizontalen, vertikalen und diagonalen Linien. Jede Kombination der Trixie Wetten bildet 32 individuelle Wetten.</div>',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Union Jack Trixie Wette?',
        a: 'Eine Union Jack Trixie Wette besteht aus 32 Wettkombinationen.',
      },
      {
        q: 'Wie funktioniert eine Union Jack Trixie Wette?',
        a: '<div class="bc-bg-flags bc-bg-flags__overlay-a">Eine Union Jack Trixie Wette kombiniert die maximale Anzahl von Trixie Wetten der neun Auswahlen.</div>',
      },
      {
        q: 'Wie berechnet man eine Union Jack Trixie Wette?',
        a: 'Bei einer Union Jack Trixie Wette werden die Gewinne der Einzel- und Kombiwetten summiert.',
      },
    ],
  },
  'union-jack-patent': {
    title: 'Union Jack Patent Wette Systemwetten Quotenrechner – Was ist eine Union Jack Patent Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Union Jack Patent Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/union-jack-patent/',
    },
    h1: 'Union Jack Patent Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Union Jack Patent Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Union Jack Patent Wette?',
        a: '<div class="bc-bg-flags bc-bg-flags__a">Eine Union Jack Patent Wette ist eine Wette mit neun Auswahlen auf verschiedene Ereignisse, die aus acht Trixie Wetten und 24 Einzelwetten besteht. Die 3er Kombiwetten sind in drei Reihen mit je drei Auswahlen angeordnet. Die einzelnen Wetten ergeben sich aus der Kombination der Auswahlen auf den horizontalen, vertikalen und diagonalen Linien. Jede Kombination bildet eine Patent Wette, insgesamt sind es 56 Wetten.</div>',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Union Jack Patent Wette?',
        a: 'Eine Union Jack Patent Wette besteht aus 56 Wettkombinationen.',
      },
      {
        q: 'Wie funktioniert eine Union Jack Patent Wette?',
        a: '<div class="bc-bg-flags bc-bg-flags__overlay-a">Eine Union Jack Patent Wette kombiniert die maximale Anzahl von Trixie Wetten mit 24 Einzelwetten aller neun Auswahlen.</div>',
      },
      {
        q: 'Wie berechnet man eine Union Jack Patent Wette?',
        a: 'Bei einer Union Jack Patent Wette werden die Gewinne der Einzel- und Kombiwetten summiert.',
      },
    ],
  },
  'union-jack-round-robin': {
    title: 'Union Jack Round Robin Wette Systemwetten Quotenrechner  - Was ist eine Union Jack Round Robin Wette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Union Jack Round Robin Wetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/union-jack-round-robin/',
    },
    h1: 'Union Jack Round Robin Wette Systemwetten Quotenrechner',
    opening: 'Mit unserem einfach zu bedienenden Quotenrechner können Sie nicht nur die mögliche Auszahlung Ihrer Union Jack Round Robin Wette berechnen, sondern Sie erhalten auch eine detaillierte Übersicht Ihrer Gewinne.',
    questions: [
      {
        q: 'Was ist eine Union Jack Round Robin Wette?',
        a: '<div class="bc-bg-flags bc-bg-flags__a">Eine Union Jack Round Robin Wette ist eine Wette mit neun Auswahlen auf verschiedene Ereignisse, die aus acht Trixie Wetten und 24 Up And Down Single Stake Wetten besteht. Die 3er Kombiwetten sind in drei Reihen mit je drei Auswahlen angeordnet. Die einzelnen Wetten ergeben sich aus der Kombination der Auswahlen auf den horizontalen, vertikalen und diagonalen Linien. Jede Kombination bildet eine Round Robin Wette, insgesamt sind es 80 Wetten.</div>',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Union Jack Round Robin Wette?',
        a: 'Eine Union Jack Round Robin Wette besteht aus 80 Wettkombinationen.',
      },
      {
        q: 'Wie funktioniert eine Union Jack Round Robin Wette?',
        a: '<div class="bc-bg-flags bc-bg-flags__overlay-a">Eine Union Jack Round Robin Wette kombiniert die maximale Anzahl von Trixie Wetten mit 24 Einzelwetten aller neun Auswahlen.</div>',
      },
      {
        q: 'Wie berechnet man eine Union Jack Round Robin Wette?',
        a: 'Bei einer Union Jack Patent Wette werden die Gewinne der Einzel- und Kombiwetten summiert.',
      },
    ],
  },
  forecast: {
    title: 'Zweierwette und Kombinierte Zweierwette Systemwetten Quotenrechner – Was ist eine kombinierte Zweierwette? - BetVictor',
    meta: 'Unser gratis Online Quotenrechner für Ihre Kombinierte Zweierwetten. Nutzen Sie unseren Quotenrechner, um die Gewinne Ihrer Kombinations- und Systemwetten schnell und einfach zu berechnen. Präsentiert von BetVictor.',
    hreflang: {
      en: '/bet-calculator/forecast/',
    },
    h1: 'Kombinierte Zweierwette Systemwetten Quotenrechner',
    opening: 'Unser einfach zu bedienenden Quotenrechner zeigt Ihnen nicht nur, wie Sie eine kombinierte Zweierwette setzen können, sondern auch wie diese Wettart funktioniert.',
    questions: [
      {
        q: 'Wieviele Arten von Zweierwetten gibt es?',
        a: 'Es gibt eine Zweierwette und eine kombinierte Zweierwette. Diese können einfach, oder in Form eine 2er bzw. eine 3er Kombiwette Zweierwette kombiniert sein.',
      },
      {
        q: 'Was ist eine Zweierwette?',
        a: 'Bei einer Zweierwette wählen Sie zwei Teilnehmer des gleichen Ereignisses und tippen darauf, dass diese beide Erster und Zweiter in exakter Reihenfolge werden. Um die Ergebnisse vergangener Rennen ausfinding zu machen, möchten wir Sie auf den <a href="http://betvictor.goalsfeed.com/greyhounds/index.php" target="_blank">BetVictor Results Feed</a> verweisen.',
      },
      {
        q: 'Was ist eine Zweierwette?',
        a: 'Bei einer Zweierwette wählen Sie zwei Teilnehmer des gleichen Ereignisses und tippen darauf, dass diese beide Erster und Zweiter in beliebiger Reihenfolge werden.Diese Wette ist nichts anderes, als zwei Zweierwetten, die beide Optionen für den 1. und 2. Platz abdecken.',
      },
      {
        q: 'Aus wievielen Wetten besteht eine Zweierwette bzw. eine kombinierte Zweierwette?',
        a: 'Eine Zweierwette ist eine Wette, eine kombinierte Zweierwette besteht aus zwei Wetten.',
      },
      {
        q: 'Wie funktioniert eine Zweierwette?',
        a: 'Nachdem das Rennen beendet ist, wird eine Quote für die Zweierwette bekanntgegeben (für Ergebnisse vergangener Rennen möchten wir Sie auf den BetVictor Results Feed verweisen). Um den Gewinn zu berechnen, multiplizieren Sie Ihren Einsatz mit dieser angegebenen Quote, wie in dem Beispiel unten angegeben.',
      },
      {
        q: 'Wie funktioniert eine kombinierte Zweierwette?',
        a: 'Mit einer kombinierten Zweierwette tippen Sie auf den Erst- und Zweitplatzierten eines Ereignisses in beliebiger Reihenfolge. Diese Wettart besteht aus zwei Zweierwetten, daher wird der Einsatz verdoppelt, wie in dem Beispiel unten angeführt.',
      },
      {
        q: 'Wie funktioniert eine 2er oder 3er Kombi Zweierwette kombiniert?',
        a: 'Bei einer 2er Kombi Zweierwette kombiniert wird der Gewinn der ersten Zweierwette für den Einsatz der zweiten Zweierwette herangezogen. Bei einer 3er Kombi Zweierwette kombiniert wird der Gewinn der zweiten Zweierwette für den Einsatz der dritten Zweierwette genommen. Bitte beachten Sie das angeführte Beispiel.',
      },
    ],
  },
};
