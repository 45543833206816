import { memo } from 'react';
import PropTypes from 'prop-types';

import NumericInput from './numeric_input';
import { FormStateShape } from '../shapes';

const propTypes = {
  format: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  formState: PropTypes.shape(FormStateShape).isRequired,
};

const OddsInput = memo(({
  format,
  formState,
  onChange,
  value,
}) => {
  const onChangeFraction = (fraction) => (eventValue) => {
    onChange({
      ...value,
      [fraction]: eventValue,
    });
  };

  const className = `bc-${format}`;

  return (
    <div className={className}>
      {format === 'decimal' && (
        <NumericInput
          value={value}
          onChange={onChange}
          formState={formState}
        />
      )}

      {format === 'fractions' && (
        <>
          <NumericInput
            onChange={onChangeFraction('numerator')}
            value={value.numerator}
            formState={formState}
          />
          <span> / </span>
          <NumericInput
            onChange={onChangeFraction('denominator')}
            value={value.denominator}
            formState={formState}
          />
        </>
      )}
    </div>
  );
});

OddsInput.propTypes = propTypes;

export default OddsInput;
