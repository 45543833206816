export default {
  main: {
    questions: [
      {
        q: 'What is a Bet Calculator?',
        a: 'A bet calculator helps you determine your potential winnings from a bet. By entering the odds for your bet and the amount you want to stake, it calculates how much you could win, making it an essential tool for any bettor.',
      },
      {
        q: 'How to Use the Bet Calculator?',
        a: "Using the BetVictor Betting Odds Calculator is simple and straightforward. Here's how to get started:</br></br><b>1. Choose Your Bet Type:</b> First, decide on the type of bet you want to place. The calculator supports a wide range of options, from singles and accumulators to more complex bets like Goliaths, Lucky 15s, 31s, and 63s.</br><b>2. Select Your Bet Type:</b> Once you’ve chosen your bet, select the corresponding bet type on the calculator. You can easily navigate through the options with the links provided.</br><b>3. Display Odds:</b> Decide whether you want the odds to be shown in fractional or decimal format. This choice depends on your personal preference and familiarity.</br><b>4. Enter Your Stake:</b> Input the amount of money you wish to bet. If you're placing an each-way bet, make sure to select that option.</br><b>5. Additional Options:</b> You can also add any applicable Rule 4 deductions, choose to view selection notes, or adjust fold options. We’ve included helpful tooltips for these options to guide you if they’re unfamiliar.</br><b>6. Input the Odds and Outcome:</b> Enter the odds for your bet and select the outcome—Winner, Lost, Void, or Dead Heat. If you’re making multiple selections, enter the odds for each one.</br><b>7. Calculate:</b> After entering all the necessary information, click the ‘Calculate’ button. The calculator will then display your potential returns and profit.</br></br>If you already know the type of bet you want to evaluate, use the navigational links below to jump straight to that section. This tool is designed to make your betting experience easier and more informed, whether you’re a novice or a seasoned bettor.",
      },
    ],
  },
};
