import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Parameters from './parameters';
import ParametersForecast from './parameters_forecast';
import ParametersFullCover from './parameters_full_cover';

import { getParameters, getBetTypeId } from '../../selectors';

import { getBetTypeById, createHandler } from '../../helpers';
import { ParametersShape } from '../../shapes';

import {
  onAccumulatorRowAction,
  onChangeForecastType,
  onChangeOutcomeParameter,
  onChangeParameter,
  onChangeParameterSelections,
  toggleModal,
} from '../../duck';

const ParametersComponents = {
  ParametersForecast,
  ParametersFullCover,
};

const propTypes = {
  betTypeId: PropTypes.string.isRequired,
  parameters: PropTypes.shape(ParametersShape).isRequired,
  onAccumulatorRowAction: PropTypes.func.isRequired,
  onChangeForecastType: PropTypes.func.isRequired,
  onChangeOutcomeParameter: PropTypes.func.isRequired,
  onChangeParameter: PropTypes.func.isRequired,
  onChangeParameterSelections: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

const ParametersContainer = ({ betTypeId, ...props }) => {
  const betType = useMemo(
    () => getBetTypeById(betTypeId),
    [betTypeId],
  );

  const ParametersComponent = useMemo(() => {
    const betTypeView = betType.view || 'FullCover';
    return ParametersComponents[`Parameters${betTypeView}`];
  }, [betType.view]);

  return (
    <Parameters
      ParametersComponent={ParametersComponent}
      betType={betType}
      {...props}
    />
  );
};

ParametersContainer.propTypes = propTypes;

const mapStateToProps = (state) => ({
  betTypeId: getBetTypeId(state),
  parameters: getParameters(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAccumulatorRowAction: createHandler(dispatch, onAccumulatorRowAction),
  onChangeForecastType: createHandler(dispatch, onChangeForecastType),
  onChangeOutcomeParameter: createHandler(dispatch, onChangeOutcomeParameter),
  onChangeParameter: createHandler(dispatch, onChangeParameter),
  onChangeParameterSelections: compose(dispatch, onChangeParameterSelections),
  toggleModal: createHandler(dispatch, toggleModal),
});

export default connect(mapStateToProps, mapDispatchToProps)(ParametersContainer);
