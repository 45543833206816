import { memo } from 'react';
import PropTypes from 'prop-types';

import { useTranslations } from '../hooks';

const propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labels: PropTypes.instanceOf(Array),
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  isFull: PropTypes.bool,
};

const defaultProps = {
  checked: false,
  isFull: false,
  label: null,
  labels: null,
};

const Switch = memo(
  ({
    id, label, labels, checked, onChange, isFull,
  }) => {
    const t = useTranslations();

    return (
      <label htmlFor={id} className={`switch ${isFull ? 'is-full' : ''}`}>
        {label && <div className="switch__label">{label}</div>}
        <div className="switch__inner">
          <input
            id={id}
            type="checkbox"
            onChange={(event) => onChange(event.target.checked)}
            checked={checked}
          />
          <div className="switch-toggle-wrapper">
            <span className="switch__txt switch__txt-yes">{labels ? labels[0] : t('Parameters.switch.yes')}</span>
            <div className="switch__toggle" />
            <span className="switch__txt switch__txt-no">{labels ? labels[1] : t('Parameters.switch.no')}</span>
          </div>
        </div>
      </label>
    );
  },
);

Switch.propTypes = propTypes;
Switch.defaultProps = defaultProps;

export default Switch;
