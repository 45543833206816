export default {
  main: {
    questions: [
      {
        q: 'What is a bet calculator?',
        a: "A bet calculator is an invaluable tool designed to calculate the potential winnings of your bet based on the odds and stake you've chosen.By inputting the odds and your desired stake, the calculator provides you with an estimate of the potential amount you could win.",
      },
      {
        q: 'How to Use the Bet Calculator?',
        a: "Using the Betano Bet Calculator is a straightforward process. Begin by selecting the type of bet you wish to place, whether it's a single bet, accumulator, or more complex options like goliaths or Lucky 15s, 31s, and 63s. Once you've made your selection, the calculator, conveniently organized with easy-to-follow links, allows you to choose between viewing odds in fractional or decimal formats. <br />Next, input the amount you wish to stake – your bet amount. Indicate if your bet is each way, account for any rule 4 deductions, and specify if you want to include selection notes or fold options. We've provided explanations for these terms to aid in your understanding if needed. <br />Finally, input the odds and the outcome of your selection (Winner, Lost, Void, or Dead Heat). For multiple selections, enter the odds for each one. Click 'Calculate,' and the calculator will promptly display both your potential returns and profit. <br />For quick access to specific bet types, refer to our user-friendly navigational links below. Bet with confidence, knowing Betano's Bet Calculator has you covered every step of the way.",
      },
    ],
  },
};
