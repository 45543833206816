const types = [
  {
    id: 'single',
    label: 'Single',
    quantity: 1,
    default: true,
  },
  {
    id: 'double',
    label: 'Double',
    quantity: 2,
  },
  {
    id: 'treble',
    label: 'Treble',
    quantity: 3,
  },
];

export default types;
