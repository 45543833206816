import PropTypes from 'prop-types';
import FoldOptionContainer from './fold_option_container';
import { useTranslations } from '../../hooks';

const FoldOptions = ({ outcomes }) => {
  const t = useTranslations();

  return (
    <table className="bet-calculator__table is-fold-option">
      <thead>
        <tr>
          <th>{t('Global.fold')}</th>
          <th>{t('Global.stake')}</th>
          <th>{t('Global.outlay')}</th>
          <th>{t('Global.return')}</th>
          <th>{t('Global.profit')}</th>
        </tr>
      </thead>
      <tbody>
        {
          outcomes.map((outcome, index) => (
            <FoldOptionContainer
              foldNumber={index + 1}
            />
          ))
        }
      </tbody>
    </table>
  );
};

FoldOptions.propTypes = {
  outcomes: PropTypes.instanceOf(Array).isRequired,
};

export default FoldOptions;
