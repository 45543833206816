import PropTypes from 'prop-types';

import {
  CalculatorShape, ParametersShape, BetTypeShape, FormStateShape,
} from '../../shapes';

import CalculatorParameters from './calculator_parameters';
import CalculatorResult from './calculator_result';
import CalculatorShare from './calculator_share';

const propTypes = {
  betType: PropTypes.shape(BetTypeShape).isRequired,
  calculator: PropTypes.shape(CalculatorShape).isRequired,
  formState: PropTypes.shape(FormStateShape).isRequired,
  parameters: PropTypes.shape(ParametersShape).isRequired,
  onChangeCalculatorParameter: PropTypes.func.isRequired,
  onClickCalculate: PropTypes.func.isRequired,
};

const Calculator = ({
  betType,
  calculator,
  formState,
  parameters,
  onChangeCalculatorParameter,
  onClickCalculate,
  ...props
}) => (
  <div className="bc-calculator">
    <CalculatorParameters
      calculator={calculator}
      parameters={parameters}
      onChangeCalculatorParameter={onChangeCalculatorParameter}
      onClickCalculate={onClickCalculate}
      formState={formState}
      {...props}
    />

    <CalculatorResult
      calculator={calculator}
      formState={formState}
    />

    <CalculatorShare
      betType={betType}
      calculator={calculator}
      onChangeCalculatorParameter={onChangeCalculatorParameter}
    />
  </div>
);

Calculator.propTypes = propTypes;

export default Calculator;
