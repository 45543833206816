import PropTypes from 'prop-types';

import CalculatorShape from './calculator';
import ParametersShape from './parameters';

export default {
  betType: PropTypes.string.isRequired,
  calculator: PropTypes.shape(CalculatorShape).isRequired,
  parameters: PropTypes.shape(ParametersShape).isRequired,
};
