import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { FormStateShape } from '../shapes';

const propTypes = {
  formState: PropTypes.shape(FormStateShape).isRequired,
  maxLength: PropTypes.number,
  step: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
};

const defaultProps = {
  maxLength: 11,
  step: '0.01',
};

const NumericInput = memo(({
  formState,
  maxLength,
  step,
  value,
  onChange,
  ...props
}) => {
  const [dirty, setDirty] = useState(false);

  useEffect(
    () => {
      setDirty(false);
    }, [formState],
  );

  const error = (
    (formState.isSubmitted && formState.hasError && !dirty)
    && (typeof value === 'undefined' || !value.length || Number.isNaN(value))
  );

  const inputClass = classnames('bvs-form-control', error && 'error');
  const decimalPart = step.split('.')[1];
  const maxDecimals = decimalPart && decimalPart.length;

  const onChangeHandler = (event) => {
    const stringifiedEventValue = String(event.target.value).replace(',', '.').replace('e', '');

    const stringifiedValue = maxDecimals
      ? stringifiedEventValue
        .split('.')
        .map((v, index) => (index === 0 ? v : v.substr(0, 2)))
        .slice(0, 2).join('.')
      : stringifiedEventValue.replace('.', '');

    if (stringifiedValue.length > maxLength) return;

    const numericValue = Number(stringifiedValue);

    if (!Number.isNaN(numericValue)) {
      onChange(stringifiedValue);
      setDirty(true);
    }
  };

  return (
    <input
      type="text"
      pattern="\d*(\.\d*)?"
      maxLength={maxLength}
      value={value}
      onChange={onChangeHandler}
      className={inputClass}
      {...props}
    />
  );
});

NumericInput.propTypes = propTypes;
NumericInput.defaultProps = defaultProps;

export default NumericInput;
