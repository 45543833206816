import PropTypes from 'prop-types';
import { Button, Icon } from 'bv-components';

import { getBetTypeById } from '../../helpers';
import { useTranslations } from '../../hooks';

import { SavedBetShape } from '../../shapes';

const getFormattedDate = (savedBet) => {
  const parsedDate = new Date(Date.parse(savedBet.date));

  const date = parsedDate.getDate();
  const month = parsedDate.getMonth() + 1;
  const year = String(parsedDate.getFullYear()).substr(2);
  const hour = parsedDate.getHours();
  const minute = String(parsedDate.getMinutes()).padStart(2, '0');
  const second = String(parsedDate.getSeconds()).padStart(2, '0');

  return `${date}/${month}/${year} ${hour}:${minute}:${second}`;
};

const getBetTypeName = (savedBet) => {
  const betType = getBetTypeById(savedBet.betType);
  return betType.name;
};

const propTypes = {
  savedBet: PropTypes.shape(SavedBetShape).isRequired,
  onClickDeleteSavedBet: PropTypes.func.isRequired,
  onClickRestoreBetState: PropTypes.func.isRequired,
};

const SavedBetsListItem = ({ savedBet, onClickDeleteSavedBet, onClickRestoreBetState }) => {
  const t = useTranslations();

  const formattedDate = getFormattedDate(savedBet);
  const betTypeName = t(getBetTypeName(savedBet));

  return (
    <tr>
      <td className="bt-calculator__date-cell">{formattedDate}</td>
      <td className="bt-calculator__name-cell">{betTypeName}</td>
      <td className="bt-calculator__action-cell">
        <div
          className="bvs-label is-info bvs-link"
          onClick={onClickRestoreBetState(savedBet)}
        >
          {t('SavedBet.view')}
        </div>

        <Button
          className="is-link"
          inverse
          secondary
          onClick={onClickDeleteSavedBet(savedBet)}
        >
          <Icon id="trash" alternative />
        </Button>
      </td>
    </tr>
  );
};

SavedBetsListItem.propTypes = propTypes;

export default SavedBetsListItem;
