import {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { embeddedPath } from 'bv-services';

import SavedBets from './saved_bets';

import { deleteSavedBet } from '../../duck';
import { createHandler, encode } from '../../helpers';
import { getSavedBets, getCalculator } from '../../selectors';
import { SavedBetShape } from '../../shapes';

const propTypes = {
  savedBets: PropTypes.arrayOf(
    PropTypes.shape(SavedBetShape),
  ).isRequired,
  restoreBetState: PropTypes.func.isRequired,
};

const SavedBetsContainer = ({
  savedBets,
  ...props
}) => {
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const onClickRestoreBetStateHandler = (savedBet) => () => {
    const encodedBet = encode(savedBet);
    history.push(embeddedPath(`/bet-calculator/${savedBet.betType}?c=${encodedBet}`));
  };

  useEffect(
    () => {
      localStorage
        .setItem('betCalculator.savedBets', JSON.stringify(
          savedBets.map(encode),
        ));
    }, [savedBets],
  );

  const savedBetsOrdered = useMemo(
    () => savedBets.sort((a, b) => Date.parse(b.date) - Date.parse(a.date)),
    [savedBets],
  );

  return (
    <SavedBets
      collapsed={collapsed}
      savedBets={savedBetsOrdered}
      onClickRestoreBetState={onClickRestoreBetStateHandler}
      toggleCollapsed={toggleCollapsed}
      {...props}
    />
  );
};

SavedBetsContainer.propTypes = propTypes;

const mapStateToProps = (state) => ({
  calculator: getCalculator(state),
  savedBets: getSavedBets(state),
});

const mapDispatchToProps = (dispatch) => ({
  onClickDeleteSavedBet: createHandler(dispatch, deleteSavedBet),
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedBetsContainer);
