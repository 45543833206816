import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'bv-components';
import { range } from 'underscore';

import Arthur from '../../lib/Arthur';

import ParametersOutcomeItem from './parameters_outcome_item';

import AllowedBets from '../allowed_bets';
import BookmakerBonus from '../bookmaker_bonus';
import EachWayLabel from '../each_way_label';
import RuleFourLabel from '../rule_four_label';
import FoldOptions from '../fold_options/fold_options';
import Select from '../select';
import Switch from '../switch';

import { BetTypeShape, FormStateShape, ParametersShape } from '../../shapes';

import { outcomeTypes, betTypes, betTypesCollection } from '../../data';
import { useTranslations } from '../../hooks';

const propTypes = {
  betType: PropTypes.shape(BetTypeShape).isRequired,
  onAccumulatorRowAction: PropTypes.func.isRequired,
  onChangeOutcomeParameter: PropTypes.func.isRequired,
  onChangeParameter: PropTypes.func.isRequired,
  onChangeParameterSelections: PropTypes.func.isRequired,
  parameters: PropTypes.shape(ParametersShape).isRequired,
  toggleModal: PropTypes.func.isRequired,
  formState: PropTypes.shape(FormStateShape).isRequired,
};

const ParametersFullCover = memo(
  ({
    betType,
    formState,
    parameters,
    onAccumulatorRowAction,
    onChangeParameter,
    onChangeParameterSelections,
    onChangeOutcomeParameter,
    toggleModal,
  }) => {
    const t = useTranslations();

    const oddsFormatOptions = [
      {
        id: 'decimal',
        label: t('Global.decimal'),
      },
      {
        id: 'fractions',
        label: t('Global.fractions'),
      },
    ];

    const eachWayOptions = [
      {
        id: Arthur.WIN_TO_WIN_PLACE_TO_PLACE,
        label: t('Parameters.eachWayWinToWin'),
      },
      {
        id: Arthur.EACH_WAY_ALL_EACH_WAY,
        label: t('Parameters.eachWayAllEachWay'),
      },
    ];

    const shouldDisplayBookmakerBonus = useMemo(
      () => (parameters.betType || betType.id || '').startsWith('lucky-'),
      [betType],
    );

    const isAccumulator = useMemo(
      () => (parameters.betType || betType.id) === 'accumulator',
      [parameters, betType],
    );

    const isMain = useMemo(
      () => [betTypes.main, betTypes.default].indexOf(betType) > -1,
      [betType],
    );

    const allowedBets = useMemo(
      () => {
        if (!isMain) return false;

        const accumulatorBetType = betTypes.accumulator;

        const betTypesAllowed = betTypesCollection
          .filter((entry) => (
            entry.breakdown && (
              entry.breakdown.selections === parameters.outcomes.length
            )
          )).map((betTypeItem) => ({
            ...betTypeItem,
            name: t(betTypeItem.name),
          }));

        return betTypesAllowed || [accumulatorBetType];
      },
      [isMain, parameters.outcomes.length],
    );

    const selectionsRange = useMemo(
      () => (isAccumulator || isMain) && range(1, 13),
      [isAccumulator, isMain],
    );

    const outcomeOptions = useMemo(
      () => (
        outcomeTypes
          .filter((entry) => (
            !entry.onlyIf || (
              entry.onlyIf
                .some((onlyIf) => parameters[onlyIf])
            )
          ))
          .map((entry) => ({ ...entry, label: t(entry.label) }))
      ), [parameters],
    );

    const betListClassName = classNames('bc-bet-list', {
      'bc-bet-list-4-col': (parameters.eachWay || parameters.ruleFour) && !(parameters.eachWay && parameters.ruleFour),
      'bc-bet-list-5-col': parameters.eachWay && parameters.ruleFour,
    });

    const colClassName = classNames('bc-form__col', { 'is-small': isAccumulator, 'is-full': !isAccumulator });

    return (
      <section>
        <div className="bc-form__row">
          {isAccumulator && (
            <div className="bc-form__col is-small">
              <div className="bc-form__group">
                <label htmlFor="bet-calculator___parameters___selections">
                  {t('Parameters.selections')}

                </label>
                <Select
                  id="bet-calculator___parameters___selections"
                  options={selectionsRange}
                  defaultValue={4}
                  value={parameters.outcomes.length}
                  onChange={onChangeParameterSelections}
                />
              </div>
            </div>
          )}
          <div className={colClassName}>
            <div className="bc-form__group">
              <label htmlFor="bet-calculator___parameters___odds-format">
                {t('Parameters.oddsFormat')}
              </label>
              <Select
                id="bet-calculator___parameters___odds-format"
                options={oddsFormatOptions}
                labelKey="label"
                valueKey="id"
                defaultValue={parameters.oddsFormat}
                value={parameters.oddsFormat}
                onChange={onChangeParameter('oddsFormat')}
              />
            </div>
          </div>
        </div>

        <div className="bc-form__row bc-form__row-switcher">
          <div className="bc-form__col">
            <Switch
              id="input-switch-each-way"
              label={<EachWayLabel toggleModal={toggleModal} />}
              checked={parameters.eachWay}
              onChange={onChangeParameter('eachWay')}
            />
          </div>
          <div className="bc-form__col">
            <Switch
              id="input-switch-selection-note"
              label={t('Parameters.selectionNote')}
              checked={parameters.note}
              onChange={onChangeParameter('note')}
            />
          </div>
        </div>

        {parameters.eachWay && (
          <div className="bc-form__row">
            <div className="bc-form__col is-full">
              <Select
                options={eachWayOptions}
                labelKey="label"
                valueKey="id"
                value={parameters.eachWayValue}
                onChange={onChangeParameter('eachWayValue')}
              />
            </div>
          </div>
        )}

        <div className="bc-form__row bc-form__row-switcher">
          {
            parameters.foldOptionsAvailable && (
              <div className="bc-form__col">
                <Switch
                  id="input-switch-fold-options"
                  label={t('Global.fold-options')}
                  checked={parameters.foldOptions}
                  onChange={onChangeParameter('foldOptions')}
                />
              </div>
            )
          }

          <div className="bc-form__col">
            <Switch
              id="input-switch-rule-four"
              label={<RuleFourLabel toggleModal={toggleModal} />}
              checked={parameters.ruleFour}
              onChange={onChangeParameter('ruleFour')}
            />
          </div>
        </div>

        <div className={betListClassName}>
          <header className="bc-bet-list__wrapper">
            <div className="bc-bet-list__number">#</div>
            <div className="bc-bet-list__outcome">{t('Parameters.Outcome.outcome')}</div>
            <div className="bc-bet-list__odds">{t('Parameters.Outcome.odds')}</div>
            {parameters.eachWay && <div className="bc-bet-list__place">{t('Parameters.Outcome.place')}</div>}
            {parameters.ruleFour && <div className="bc-bet-list__rule-four">{t('Parameters.Outcome.ruleFour')}</div>}
          </header>
          <ul className="bc-bet-list_body">
            {parameters.outcomes.map((outcome, index) => (
              <ParametersOutcomeItem
                key={outcome.id}
                index={index + 1}
                outcome={outcome}
                parameters={parameters}
                onChangeOutcomeParameter={onChangeOutcomeParameter}
                formState={formState}
                outcomeOptions={outcomeOptions}
              />
            ))}
          </ul>
          {(isAccumulator || isMain) && (
            <div className="bc-bet-list__btns">
              <Button
                onClick={onAccumulatorRowAction('add')}
                className="bc-bet-list__btn-add"
                type="button"
              >
                {t('Parameters.Outcome.addRow')}
                {' '}
                <span className="is-plus-icon" />
              </Button>

              {betType.props.minimumSelections < parameters.outcomes.length && (
                <Button
                  onClick={onAccumulatorRowAction('remove')}
                  className="bc-bet-list__btn-remove"
                  type="button"
                >
                  {t('Parameters.Outcome.removeRow')}
                  {' '}
                  <span className="is-minus-icon" />
                </Button>
              )}
            </div>
          )}
        </div>

        {
          parameters.foldOptions && (
          <FoldOptions
            outcomes={parameters.outcomes}
          />
          )
        }

        {shouldDisplayBookmakerBonus && (
          <BookmakerBonus
            formState={formState}
            parameters={parameters}
            onChangeParameter={onChangeParameter}
          />
        )}

        {isMain && (
          <AllowedBets
            allowedBets={allowedBets}
            parameters={parameters}
            onClickBetType={onChangeParameter('betType')}
          />
        )}
      </section>
    );
  },
);

ParametersFullCover.propTypes = propTypes;

export default ParametersFullCover;
