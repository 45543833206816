import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { range } from 'underscore';

import Arthur from '../../lib/Arthur';

import OddsInput from '../odds_input';
import Select from '../select';

import { FormStateShape, ParametersShape } from '../../shapes';

import { outcomeTypes } from '../../data';

import { useTranslations } from '../../hooks';

const propTypes = {
  index: PropTypes.number.isRequired,
  formState: PropTypes.shape(FormStateShape).isRequired,
  outcome: PropTypes.instanceOf(Object).isRequired,
  outcomeOptions: PropTypes.instanceOf(Object).isRequired,
  parameters: PropTypes.shape(ParametersShape).isRequired,
  onChangeOutcomeParameter: PropTypes.func.isRequired,
};

const ruleFourOptionsRange = range(0, 91, 5);
const deadHeatOptions = range(2, 10);

const positionOptions = range(1, 10);
const eachWayOptions = range(1, 9).map((index) => `1/${index}`);

const OutcomeItem = memo(
  ({
    index,
    formState,
    outcome,
    outcomeOptions,
    parameters,
    onChangeOutcomeParameter,
  }) => {
    const t = useTranslations();

    const outcomeItem = useMemo(
      () => (
        outcome.type
          ? outcomeTypes.find((entry) => entry.id === outcome.type)
          : outcomeTypes.find((entry) => entry.default)
      ), [outcome.type],
    );

    const onChangeOutcomeParameterParseInt = (
      (key) => (event) => onChangeOutcomeParameter(key)(parseInt(event.target.value, 10))
    );

    return (
      <li>
        {parameters.note && (
          <div className="bc-bet-list__note bc-bet-list__wrapper">
            <input
              className="bvs-form-control"
              type="text"
              value={outcome.note}
              onChange={onChangeOutcomeParameter({ outcomeId: outcome.id, parameter: 'note' })}
            />
          </div>
        )}
        <div className="bc-bet-list__wrapper">
          <div className="bc-bet-list__number">{index}</div>
          <div className="bc-bet-list__outcome">
            <Select
              options={outcomeOptions}
              valueKey="id"
              labelKey="label"
              value={outcome.type}
              onChange={onChangeOutcomeParameter({ outcomeId: outcome.id, parameter: 'type' })}
            />
          </div>
          <div className="bc-bet-list__odds">
            {!outcomeItem.hideOddsInput && (
              <OddsInput
                format={parameters.oddsFormat}
                onChange={onChangeOutcomeParameter(
                  {
                    outcomeId: outcome.id,
                    parameter: `odds.${parameters.oddsFormat}`,
                  },
                )}
                value={outcome[`odds.${parameters.oddsFormat}`]}
                formState={formState}
              />
            )}
          </div>

          {parameters.eachWay && (
            <div className="bc-bet-list__place">
              <Select
                options={eachWayOptions}
                defaultValue="1/5"
                value={outcome.place}
                onChange={onChangeOutcomeParameter({ outcomeId: outcome.id, parameter: 'place' })}
              />
            </div>
          )}
          {parameters.ruleFour && (
            <div className="bc-bet-list__rule-four">
              <Select
                options={ruleFourOptionsRange}
                value={outcome.ruleFour}
                onChange={onChangeOutcomeParameter({ outcomeId: outcome.id, parameter: 'ruleFour' })}
              />
            </div>
          )}
        </div>
        {outcome.type === Arthur.DEAD_HEAT && (
          <div className="bc-bet-list__dead-heat">
            <label htmlFor="bet_calculator___outcome___input_select___dead_heat">
              {t('Parameters.Outcome.runnersInDeadHeat')}
            </label>

            <Select
              id="bet_calculator___outcome___input_select___dead_heat"
              options={deadHeatOptions}
              value={outcome.deadHeatRunners}
              onChange={onChangeOutcomeParameterParseInt({ outcomeId: outcome.id, parameter: 'deadHeatRunners' })}
            />
          </div>
        )}
        {parameters.eachWay && outcome.type === Arthur.DEAD_HEAT && (
          <div>
            <div>
              <label htmlFor="bet_calculator___outcome___input_select___tied_position">
                {t('Parameters.Outcome.tiedPosition')}
              </label>

              <Select
                id="bet_calculator___outcome___input_select___tied_position"
                options={positionOptions}
                value={outcome.tiedPosition}
                onChange={onChangeOutcomeParameterParseInt({ outcomeId: outcome.id, parameter: 'tiedPosition' })}
              />
            </div>

            <div>
              <label htmlFor="bet_calculator___outcome___input_select___places_offered">
                {t('Parameters.Outcome.placesOffered')}
              </label>

              <Select
                id="bet_calculator___outcome___input_select___places_offered"
                options={positionOptions}
                value={outcome.placesOffered}
                onChange={onChangeOutcomeParameterParseInt({ outcomeId: outcome.id, parameter: 'placesOffered' })}
              />
            </div>
          </div>
        )}
      </li>
    );
  },
);

OutcomeItem.propTypes = propTypes;

export default OutcomeItem;
