export default {
  main: {
    questions: [
      {
        q: 'What is a Parimatch bet calculator?',
        a: 'The Parimatch bet calculator helps you determine your potential winnings. Just input the odds for your bet and your chosen stake, and it will show you the potential payout.',
      },
      {
        q: 'How to Use the Parimatch Bet Calculator:',
        a: "Using the Parimatch Bet Calculator is straightforward. <br />1. Choose the type of bet you want from our wide range of options, including singles, accumulators, goliaths, Lucky 15s, 31s, and 63s. <br />2. Select your desired bet type on the calculator, and choose between fractional or decimal odds formats. <br />3. Enter your stake – the amount you wish to bet. You can also indicate if your bet is each way, apply any rule 4 deductions, and opt to view selection notes or fold options. We've provided explanations for unfamiliar terms. <br />4. Input the odds and the outcome for each selection – Winner, Lost, Void, or Dead Heat. If you have multiple selections, enter the odds for each one. <br />5. Click 'Calculate' to see your potential returns and profits displayed instantly.",
      },
    ],
  },
};
