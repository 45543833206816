import { memo } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  defaultOptionValue: PropTypes.string,
  defaultOptionLabel: PropTypes.string,
};

const defaultProps = {
  valueKey: '',
  labelKey: '',
  defaultOptionValue: '',
  defaultOptionLabel: '',
};

const Select = memo(
  ({
    options,
    valueKey,
    labelKey,
    defaultOptionValue,
    defaultOptionLabel,
    ...selectProps
  }) => (
    <select
      {...selectProps}
      className="bvs-form-control"
    >
      {defaultOptionLabel && (
        <option
          value={defaultOptionValue}
        >
          {defaultOptionLabel}
        </option>
      )}
      {options.map((option) => {
        const key = valueKey ? option[valueKey] : option;
        const label = labelKey ? option[labelKey] : option;

        return (
          <option
            key={key}
            value={key}
          >
            {label}
          </option>
        );
      })}
    </select>
  ),
);

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
