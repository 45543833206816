import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { locale as getLocale } from 'bv-i18n';
import { v as bvVar } from 'bv';

import BetInformation from './bet_information';
import BetInformationForecast from './bet_information_forecast';

import { shortLocaleFromParams } from '../../helpers';
import { getBetTypeId } from '../../selectors';

import * as betInformationByLanguage from '../../data/bet_info';

const brandId = bvVar('brandId');

const propTypes = {
  betTypeId: PropTypes.string.isRequired,
};

const BetInformationContainer = ({ betTypeId }) => {
  const BetInformationComponent = useMemo(
    () => (
      betTypeId === 'forecast'
        ? BetInformationForecast
        : BetInformation
    ),
    [betTypeId],
  );

  const betType = betTypeId === 'default' ? 'main' : betTypeId;
  const loc = getLocale();
  const lang = shortLocaleFromParams({ locale: loc });

  const defaultBetInformation = betInformationByLanguage[lang].default[betType]; // BV
  const currentBrandBetInformation = betInformationByLanguage[lang][loc][brandId]?.[betType] || {};

  const betInformation = { ...defaultBetInformation, ...currentBrandBetInformation };

  return (
    <BetInformationComponent
      betInformation={betInformation}
    />
  );
};

BetInformationContainer.propTypes = propTypes;

const mapStateToProps = (state) => ({
  betTypeId: getBetTypeId(state),
});

export default connect(mapStateToProps)(BetInformationContainer);
