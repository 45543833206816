import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'bv-components';

import SavedBetsListItem from './saved_bets_list_item';
import SavedBetsEmptyList from './saved_bets_empty_list';

import { SavedBetShape } from '../../shapes';
import { useTranslations } from '../../hooks';

const propTypes = {
  collapsed: PropTypes.bool.isRequired,
  savedBets: PropTypes.arrayOf(
    PropTypes.shape(SavedBetShape),
  ).isRequired,
  toggleCollapsed: PropTypes.func.isRequired,
};

const SavedBetsList = ({
  collapsed, savedBets, toggleCollapsed, ...props
}) => {
  const t = useTranslations();

  const savedBetsItems = useMemo(
    () => savedBets.map((savedBet) => (
      <SavedBetsListItem
        key={savedBet.calculator.id}
        savedBet={savedBet}
        {...props}
      />
    )).slice(0, !collapsed ? 3 : savedBets.length),
    [savedBets, collapsed],
  );

  if (!savedBets.length) return <SavedBetsEmptyList />;

  return (
    <>
      <table className="bet-calculator__table is-saved-bets">
        <thead>
          <tr>
            <th>{t('Global.date')}</th>
            <th colSpan="2">{t('Global.betType.singular')}</th>
          </tr>
        </thead>
        <tbody>{savedBetsItems}</tbody>
      </table>
      <Button className="is-link bet-calculator__more-btn" onClick={toggleCollapsed}>
        <span className="bet-calculator__more-btn-label">{t(`SavedBets.${collapsed ? 'less' : 'more'}`)}</span>
        <Icon id={collapsed ? 'arrow-up' : 'arrow-down'} />
      </Button>
    </>
  );
};

SavedBetsList.propTypes = propTypes;

export default SavedBetsList;
