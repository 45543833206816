import { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'bv-components';

import NumericInput from '../numeric_input';
import Switch from '../switch';

import { FormStateShape, ParametersShape } from '../../shapes';

import { forecastTypes } from '../../data';
import { useTranslations } from '../../hooks';

const propTypes = {
  parameters: PropTypes.shape(ParametersShape),
  onChangeParameter: PropTypes.func.isRequired,
  formState: PropTypes.shape(FormStateShape).isRequired,
  onChangeForecastType: PropTypes.func.isRequired,
  onChangeOutcomeParameter: PropTypes.func.isRequired,
};

const defaultProps = {
  parameters: {},
};

const onClickType = (type, onChangeForecastType) => (event) => {
  event.preventDefault();
  onChangeForecastType(type)();
};

const ParametersForecast = memo(({
  formState,
  parameters,
  onChangeForecastType,
  onChangeOutcomeParameter,
  onChangeParameter,
}) => {
  const t = useTranslations();

  return (
    <div>
      <div className="forecast-parameters">
        <Switch
          id="input-switch-reverse"
          label={t('Parameters.Outcome.forecastType')}
          checked={parameters.reverse}
          onChange={onChangeParameter('reverse')}
          labels={[t('Parameters.Forecast.straight'), t('Parameters.Forecast.reverse')]}
        />

        <Switch
          id="input-switch-selection-note"
          label={t('Parameters.Outcome.selectionNote')}
          checked={parameters.note}
          onChange={onChangeParameter('note')}
        />
      </div>
      <div className="forecast-type-selector">
        {forecastTypes.map((type) => (
          <Button
            className="bvs-button-chip"
            noClass
            label={type.label}
            onClick={onClickType(type, onChangeForecastType)}
            active={parameters.type ? parameters.type === type : type.default}
          />
        ))}
      </div>
      <div className="inputs">
        {parameters.outcomes.map((outcome, index) => (
          <div>
            <h2 className="forecast-title">
              {t('Parameters.Forecast.race')}
              {' '}
              {index + 1}
            </h2>
            {parameters.note && (
              <input
                type="text"
                value={outcome.note}
                onChange={onChangeOutcomeParameter({ outcomeId: outcome.id, parameter: 'note' })}
              />
            )}
            <div className="bvs-form-group">
              <label htmlFor={`bet_calculator___parameters___race-${index}`}>
                {t(`Parameters.Forecast.${parameters.reverse ? 'reverse' : 'straight'}`)}
              </label>
              <NumericInput
                id={`bet_calculator___parameters___race-${index}`}
                onChange={onChangeOutcomeParameter({ outcomeId: outcome.id, parameter: 'value' })}
                value={outcome.value}
                formState={formState}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

ParametersForecast.propTypes = propTypes;
ParametersForecast.defaultProps = defaultProps;

export default ParametersForecast;
