import Arthur from '../lib/Arthur';

import mapNextOutcomesByDiff from '../helpers/map_next_outcomes_by_diff';

import { betTypes, forecastTypes } from '.';

export const betTypeTemplate = Object.values(betTypes).find((betType) => betType.default);
export const defaultForecastType = forecastTypes.find((betType) => betType.default);

export const outcomeTemplate = {
  type: Arthur.WINNER,
  'odds.decimal': '',
  'odds.fractions': {
    numerator: '',
    denominator: '',
  },
  deadHeatRunners: 2,
  note: '',
  place: '1/5',
  placesOffered: 2,
  rule4: 0,
  tiedPosition: 2,
};

export const generateCalculationTemplate = () => ({
  id: null,
  error: false,
  result: {
    outlay: '0.00',
    returns: '0.00',
    profit: '0.00',
    bets: {},
  },
});

export const parametersTemplate = {
  oddsFormat: 'fractions',
  eachWay: false,
  note: false,
  ruleFour: false,
  foldOptionsAvailable: true,
  outcomes: mapNextOutcomesByDiff(
    [],
    betTypeTemplate.props.selectionCount || betTypeTemplate.props.minimumSelections,
    outcomeTemplate,
  ),
  'bookmakerBonus.allWinnersBonus': '',
  'bookmakerBonus.oneWinnerConsolation': Arthur.OWC_NONE,
};

export const defaultFormState = {
  hasError: false,
  isSubmitted: false,
};

export const calculatorTemplate = {
  parameters: {
    isShareCollapsed: true,
    isTotal: false,
    isUsedBonusFunds: false,
    stake: '1',
    shouldSaveBet: true,
  },
  ...generateCalculationTemplate(),
  link: {},
};
