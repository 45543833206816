import { connect } from 'react-redux';
import _, { partial, pick } from 'underscore';
import FoldOption from './fold_option';
import { getParameters } from '../../selectors';

const foldOptionProps = partial(
  pick,
  _,
  'oddsFormat',
  'outcomes',
  'eachWay',
  'eachWayValue',
  'ruleFour',
);

const mapStateToProps = (state) => ({
  ...foldOptionProps(getParameters(state)),
});

export default connect(mapStateToProps)(FoldOption);
