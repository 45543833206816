import { useTranslations } from '../../hooks';

const SavedBetsEmptyList = () => {
  const t = useTranslations();

  return (
    <div>
      <p>{t('SavedBets.noSavedBets')}</p>
    </div>
  );
};

export default SavedBetsEmptyList;
