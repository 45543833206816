import Arthur from '../lib/Arthur';

export default [
  {
    id: Arthur.WIN,
    label: 'Parameters.Outcome.Type.winner',
    default: true,
  },
  {
    id: Arthur.PLACED,
    label: 'Parameters.Outcome.Type.placed',
    onlyIf: ['eachWay'],
  },
  {
    id: Arthur.LOST,
    label: 'Parameters.Outcome.Type.lost',
    hideOddsInput: true,
  },
  {
    id: Arthur.VOID,
    label: 'Parameters.Outcome.Type.void',
    hideOddsInput: true,
  },
  {
    id: Arthur.DEAD_HEAT,
    label: 'Parameters.Outcome.Type.deadHeat',
  },
];
