import { memo } from 'react';
import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { Modal } from 'bv-components';
import { t } from 'bv-i18n';
import { useTranslations } from '../hooks';

const propTypes = {
  toggleModal: PropTypes.func.isRequired,
};

const EachWayModal = memo(({ toggleModal }) => {
  const bcTranslations = useTranslations();

  return (
    <Modal
      info
      onCloseClick={toggleModal()}
      actions={[
        {
          label: t('close'), secondary: true, inverse: true, onClick: toggleModal(),
        },
      ]}
    >
      <div dangerouslySetInnerHTML={{ __html: sanitize(bcTranslations('EachWayModal.content')) }} />
    </Modal>
  );
});

EachWayModal.propTypes = propTypes;

export default EachWayModal;
