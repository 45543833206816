import PropTypes from 'prop-types';
import { RenderInBody } from 'bv-components';

import { v as bvVar } from 'bv';
import { locale } from 'bv-i18n';
import BetInformationContainer from '../bet_information/bet_information_container';
import BetTypesContainer from '../bet_types/bet_types_container';
import BreakdownContainer from '../breakdown/breakdown_container';
import FormContainer from '../form/form_container';
import SavedBetsContainer from '../saved_bets/saved_bets_container';

import Banner from '../banner';

import BetTypeLinks from '../bet_type_links';
import { getBetCalculatorBannersHrefs } from '../../data/bet_calculator_banners_hrefs';

const propTypes = {
  DisplayedModal: PropTypes.instanceOf(Object),
  toggleModal: PropTypes.func.isRequired,
};

const defaultProps = {
  DisplayedModal: null,
};

const betCalculatorBannersBrandOptions = bvVar('betCalculatorBannersBrandOptions') || {};
const betCalculatorBannersHrefs = getBetCalculatorBannersHrefs(bvVar('brandId'), locale());

const App = ({ DisplayedModal, toggleModal }) => (
  <div className="bet-calculator">

    <div className="bet-calculator__row">
      <div className="bet-calculator__col">
        {DisplayedModal && (
          <RenderInBody>
            <DisplayedModal
              toggleModal={toggleModal}
            />
          </RenderInBody>
        )}
        <BetTypesContainer />
        <Banner
          bannerUrl={betCalculatorBannersBrandOptions.accountBanner}
          bannerHref={betCalculatorBannersHrefs.accountBanner}
        />
        <FormContainer />
      </div>

      <div className="bet-calculator__col">
        <SavedBetsContainer />
        <Banner
          bannerUrl={betCalculatorBannersBrandOptions.offerBanner}
          bannerHref={betCalculatorBannersHrefs.offerBanner}
        />
        <BreakdownContainer />
        <BetInformationContainer />
        <BetTypeLinks />
      </div>
    </div>
  </div>
);

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
