import { memo } from 'react';
import PropTypes from 'prop-types';
import { Heading, Button } from 'bv-components';
import { useTranslations } from '../../hooks';

const propTypes = {
  ParametersComponent: PropTypes.instanceOf(Object).isRequired,
};

const Parameters = memo(({ ParametersComponent, ...props }) => {
  const t = useTranslations();

  return (
    <>
      <header className="bet-calculator__params">
        <Heading weight="5" tag="h2">{t('Parameters.title')}</Heading>
        <Button
          className="hidden is-link"
          label={t('Parameters.savedBets')}
          inverse
          secondary
        />
      </header>

      <ParametersComponent
        {...props}
      />
    </>
  );
});

Parameters.propTypes = propTypes;

export default Parameters;
