import PropTypes from 'prop-types';

const OutcomeShape = {
  type: PropTypes.string.isRequired,
  'odds.decimal': PropTypes.string.isRequired,
  'odds.fractions': PropTypes.shape({
    numerator: PropTypes.string.isRequired,
    denominator: PropTypes.string.isRequired,
  }).isRequired,
};

export default {
  oddsFormat: PropTypes.string.isRequired,
  eachWay: PropTypes.bool.isRequired,
  note: PropTypes.bool.isRequired,
  ruleFour: PropTypes.bool.isRequired,
  outcomes: PropTypes.arrayOf(PropTypes.shape(OutcomeShape)).isRequired,
};
