import { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'bv-components';

import NumericInput from '../numeric_input';
import Switch from '../switch';

import { CalculatorShape, FormStateShape, ParametersShape } from '../../shapes';
import { useTranslations } from '../../hooks';

const propTypes = {
  calculator: PropTypes.shape(CalculatorShape).isRequired,
  formState: PropTypes.shape(FormStateShape).isRequired,
  parameters: PropTypes.shape(ParametersShape).isRequired,
  onChangeCalculatorParameter: PropTypes.func.isRequired,
};

const CalculatorParameters = memo(
  ({
    calculator: { parameters, id: calculatorId },
    formState,
    onChangeCalculatorParameter,
  }) => {
    const t = useTranslations();

    return (
      <div className="bc-parameters">
        <div className="bc-form__row">
          <Switch
            id="bet_calculator___calculator___checkbox__isTotal"
            labels={[t('Calculator.Parameters.totalStake'), t('Calculator.Parameters.stakePerBet')]}
            checked={parameters.isTotal}
            onChange={onChangeCalculatorParameter('isTotal')}
            isFull
          />
        </div>
        <div className="bc-parameters__stake">
          <label htmlFor="bet_calculator___calculator___stake">
            {t('Calculator.Parameters.enterStake')}
          </label>
          <div className="bc-form__row">
            <div className="bc-form__col">
              <NumericInput
                id="bet_calculator___calculator___stake"
                value={parameters.stake}
                onChange={onChangeCalculatorParameter('stake')}
                formState={formState}
              />
            </div>
            <div className="bc-form__col">
              <Button
                type="submit"
                label={t(`Calculator.Parameters.${calculatorId ? 'calculate' : 'recalculate'}`)}
                secondary
              />
            </div>
          </div>
        </div>
        <div className="bc-form__row bc-form__row--center">
          <label className="bc-form__checkbox" htmlFor="bet_calculator___calculator___input_checkbox___isUsedBonusFunds">
            <input
              id="bet_calculator___calculator___input_checkbox___isUsedBonusFunds"
              type="checkbox"
              checked={parameters.isUsedBonusFunds}
              onChange={onChangeCalculatorParameter('isUsedBonusFunds')}
            />
            <span>{t('Calculator.Parameters.usedBonusFunds')}</span>
          </label>
          <label className="bc-form__checkbox" htmlFor="bet_calculator___calculator___input_checkbox___shouldSaveBet">
            <input
              id="bet_calculator___calculator___input_checkbox___shouldSaveBet"
              type="checkbox"
              checked={parameters.shouldSaveBet}
              onChange={onChangeCalculatorParameter('shouldSaveBet')}
            />
            <span>{t('Calculator.Parameters.saveBet')}</span>
          </label>
        </div>
      </div>
    );
  },
);

CalculatorParameters.propTypes = propTypes;

export default CalculatorParameters;
