import { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'bv-components';
import { useTranslations } from '../hooks';

const propTypes = {
  toggleModal: PropTypes.func.isRequired,
};

const EachWayLabel = memo(({ toggleModal }) => {
  const t = useTranslations();

  return (
    <>
      <span>{t('Parameters.eachWayLabel')}</span>
      <Button
        className="is-link each-way-link"
        onClick={toggleModal('EachWayModal')}
        label={t('Parameters.eachWayPlaceTerms')}
        inverse
      />
    </>
  );
});

EachWayLabel.propTypes = propTypes;

export default EachWayLabel;
