import enGb1 from './1/en-gb'; // bvcore
import enCa1 from './1/en-ca'; // bvcore

import enGb14 from './14/en-gb'; // pmuk
import enGb17 from './17/en-gb'; // talksport
import enGb21 from './21/en-gb'; // betano

export default {
  default: enGb1,
  'en-gb': {
    1: {},
    14: enGb14,
    17: enGb17,
    21: enGb21,
  },
  'en-ca': {
    1: enCa1,
  },
};
