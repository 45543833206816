import { Route } from 'react-router-dom';
import { ContentLayout } from 'bv-components';
import { embeddedPath, isInComponentsIframe } from 'bv-services';
import calculatorReducer from './duck';
import AppContainer from './components/app/app_container';

const { addReducers } = window.reduxState;

addReducers({
  betCalculator: calculatorReducer,
});

const App = () => (
  <ContentLayout showTabs={!isInComponentsIframe()}>
    <Route
      path={embeddedPath('/bet-calculator/:betType?')}
    >
      <AppContainer />
    </Route>
  </ContentLayout>
);

export default App;
