import { Card, Heading } from 'bv-components';

import SavedBetsList from './saved_bets_list';

import { useTranslations } from '../../hooks';

const SavedBets = (props) => {
  const t = useTranslations();

  return (
    <Card>
      <Heading weight="4" tag="h2">{t('SavedBets.title')}</Heading>
      <SavedBetsList
        {...props}
      />
    </Card>
  );
};

export default SavedBets;
