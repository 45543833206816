import { memo } from 'react';
import PropTypes from 'prop-types';
import { mapObject } from 'underscore';
import { Button } from 'bv-components';
import { handleCta } from 'bv-services';
import { useLoggedIn } from 'bv-hooks';

import { currencyPrefix } from 'bv';
import { locale } from 'bv-i18n';
import { getCurrencyCode } from 'bv-helpers/price';
import { CalculatorShape, FormStateShape } from '../../shapes';
import { useTranslations } from '../../hooks';

const gotoBreakdown = () => {
  const element = document.querySelector('#bet_calculator___breakdown');
  window.scrollTo({ top: element.offsetTop - 50 });
};

const propTypes = {
  calculator: PropTypes.shape(CalculatorShape).isRequired,
  formState: PropTypes.shape(FormStateShape).isRequired,
};

const CalculatorResult = memo(
  ({
    formState,
    calculator: { result },
  }) => {
    const systemCurrencySign = new Intl.NumberFormat(locale(), { style: 'currency', currency: getCurrencyCode() })
      .formatToParts(1)
      .find((x) => x.type === 'currency')
      .value || '£';

    const currencyPrefixValue = currencyPrefix();
    const currencySign = currencyPrefixValue !== 'null' ? currencyPrefixValue : systemCurrencySign;
    const values = mapObject(
      result,
      (value, key) => (key === 'error' ? value : `${currencySign}${value}`),
    );

    const t = useTranslations();
    const loggedIn = useLoggedIn();

    // TODO: We should not be using CTAs for this, we should have route helpers
    // CTA handlers should use this route helpers (map from cta definition to route)
    const onClickPlaceYourBet = () => {
      if (!loggedIn) {
        handleCta({
          action: 'ACCOUNTSECTION',
          accountSection: 'CREATEACCOUNT',
        });
      } else {
        handleCta({
          action: 'PRODUCTPAGE',
          productPage: 'SPORTS',
        });
      }
    };

    return (
      <div className="bc-result">
        {formState.hasError && (
          <p className="bvs-text-error">
            {t('Calculator.Result.unexpectedError')}
          </p>
        )}
        <div className="bc-result__list">
          <div>
            <strong>{t('Calculator.Result.total')}</strong>
            <span>{values.outlay}</span>
          </div>

          <div>
            <strong>{t('Calculator.Result.return')}</strong>
            <span>{values.returns}</span>
          </div>

          <div>
            <strong>{t('Calculator.Result.profit')}</strong>
            <span>{values.profit}</span>
          </div>
        </div>
        <Button
          type="button"
          onClick={onClickPlaceYourBet}
          label={t('Calculator.Result.placeYourBet')}
          primary
        />
        <Button
          type="button"
          onClick={gotoBreakdown}
          label={t('Calculator.Result.betBreakdown')}
          secondary
          inverse
        />
      </div>
    );
  },
);

CalculatorResult.propTypes = propTypes;

export default CalculatorResult;
