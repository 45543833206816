import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Calculator from './calculator';

import { getBetTypeById, createHandler } from '../../helpers';
import {
  getCalculator, getParameters, getEncodedBetState, getFormState,
} from '../../selectors';

import { CalculatorShape, FormStateShape, ParametersShape } from '../../shapes';

import { onChangeCalculatorParameter, onCreateBitlyLinkThunk } from '../../duck';

const propTypes = {
  betType: PropTypes.string.isRequired,
  calculator: PropTypes.shape(CalculatorShape).isRequired,
  encodedBetState: PropTypes.string,
  formState: PropTypes.shape(FormStateShape).isRequired,
  parameters: PropTypes.shape(ParametersShape).isRequired,
  onChangeCalculatorParameter: PropTypes.func.isRequired,
  onCreateBitlyLinkThunkDispatcher: PropTypes.func.isRequired,
};

const defaultProps = {
  encodedBetState: null,
};

const CalculatorContainer = memo(
  ({ encodedBetState, onCreateBitlyLinkThunkDispatcher, ...props }) => {
    useEffect(
      () => {
        if (props.calculator.parameters.isShareCollapsed || !encodedBetState) return;

        const { betType } = props;
        onCreateBitlyLinkThunkDispatcher({ betType, encodedBetState });
      }, [encodedBetState, props.calculator.parameters.isShareCollapsed],
    );

    return (
      <Calculator
        {...props}
      />
    );
  },
);

CalculatorContainer.propTypes = propTypes;
CalculatorContainer.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  betType: getBetTypeById(state.betCalculator.betType),
  calculator: getCalculator(state),
  encodedBetState: getEncodedBetState(state),
  formState: getFormState(state),
  parameters: getParameters(state),
});

const mapDispatchToProps = (dispatch) => ({
  onChangeCalculatorParameter: createHandler(dispatch, onChangeCalculatorParameter),
  onCreateBitlyLinkThunkDispatcher: compose(dispatch, onCreateBitlyLinkThunk),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorContainer);
