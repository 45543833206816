import PropTypes from 'prop-types';
import { Heading, Button } from 'bv-components';
import { BetTypeShape, ParametersShape } from '../shapes';
import { useTranslations } from '../hooks';

const propTypes = {
  allowedBets: PropTypes.arrayOf(BetTypeShape).isRequired,
  parameters: PropTypes.shape(ParametersShape).isRequired,
  onClickBetType: PropTypes.func.isRequired,
};

const AllowedBets = ({ allowedBets, parameters, onClickBetType }) => {
  const t = useTranslations();

  return (
    <div className="bc-allowed-bets">
      <Heading weight="4" tag="h2">{t('BetTypes.allowedBets.title')}</Heading>
      <p>{t('BetTypes.allowedBets.subtitle')}</p>
      <ul className="bc-allowed-bets__list">
        {allowedBets.map((bet) => (
          <li>
            <Button
              className="bvs-button-chip"
              noClass
              onClick={() => onClickBetType(bet.id)}
              label={bet.name}
              active={parameters.betType && parameters.betType === bet.id}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

AllowedBets.propTypes = propTypes;

export default AllowedBets;
