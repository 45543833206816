import { v4 as uuid } from 'uuid';

import Arthur from '../../lib/Arthur';
import { getBetTypeById } from '../../helpers';

import mapNextOutcomesByDiff from '../../helpers/map_next_outcomes_by_diff';
import {
  defaultFormState, parametersTemplate, calculatorTemplate, outcomeTemplate,
} from '../../data/templates';

import { betTypes } from '../../data';

export default (state, action) => {
  const { betType: betTypeId } = action.payload;
  const betType = getBetTypeById(betTypeId);

  if (state.betType === betTypeId) {
    return {
      ...state,
      parameters: {
        ...state.parameters,
        betType: ['main', 'default'].indexOf(betTypeId) > -1 && 'accumulator',
      },
    };
  }

  const { minimumSelections, eachWay, foldOptionsAvailable } = betType.props;

  // if first load (!state.betType.view) or both are 'FullCover'
  const currentOutcomes = !state.betType || [state.betType.view, betType.view].every((view) => view === 'FullCover')
    ? state.parameters.outcomes
    : [];

  let nextOutcomes;
  if (betType === betTypes.forecast) {
    nextOutcomes = [{ id: uuid(), value: '' }];
  } else if (betType.props.dynamicOutcomes) {
    const diff = minimumSelections - currentOutcomes.length;

    nextOutcomes = (diff <= 0)
      ? currentOutcomes
      : mapNextOutcomesByDiff(currentOutcomes, diff, outcomeTemplate);
  } else if (minimumSelections !== currentOutcomes.length) {
    const diff = minimumSelections - currentOutcomes.length;

    nextOutcomes = mapNextOutcomesByDiff(currentOutcomes, diff, outcomeTemplate);
  } else {
    nextOutcomes = mapNextOutcomesByDiff([], minimumSelections, outcomeTemplate);
  }

  return {
    ...state,
    formState: defaultFormState,
    betType: betTypeId,
    calculator: calculatorTemplate,
    parameters: {

      ...parametersTemplate,
      // if prev and next betType are FullCover, keep same selected parameters
      ...[betType, state.betType].every((type) => type && type.view === 'FullCover') && (
        Object.keys(parametersTemplate)
          .reduce((acc, key) => ({
            ...acc,
            [key]: state.parameters[key],
          }), {})
      ),
      eachWay,
      foldOptionsAvailable,
      oddsFormat: state.parameters.oddsFormat,
      betType: betTypeId === 'main' && 'accumulator',
      outcomes: nextOutcomes,
      'bookmakerBonus.allWinnersBonus': '',
      'bookmakerBonus.oneWinnerConsolation': Arthur.OWC_NONE,
    },
  };
};
