import { useState } from 'react';
import PropTypes from 'prop-types';
import foldOptionValues from '../../services/fold_option_values';
import NumericInput from '../numeric_input';

const FoldOption = ({
  foldNumber,
  oddsFormat,
  outcomes,
  eachWay,
  eachWayValue,
}) => {
  const [stake, changeStake] = useState(undefined);

  const { outlay, returns, profit } = foldOptionValues({
    foldNumber,
    stake,
    oddsFormat,
    outcomes,
    eachWay,
    eachWayValue,
  });

  return (
    <tr>
      <td>{foldNumber}</td>
      <td>
        <NumericInput
          value={stake}
          onChange={changeStake}
          formState={{}}
        />
      </td>
      <td>{outlay}</td>
      <td>{returns}</td>
      <td>{profit}</td>
    </tr>
  );
};

FoldOption.propTypes = {
  foldNumber: PropTypes.number.isRequired,
  oddsFormat: PropTypes.string.isRequired,
  outcomes: PropTypes.instanceOf(Array).isRequired,
  eachWay: PropTypes.bool.isRequired,
  eachWayValue: PropTypes.number.isRequired,
};

export default FoldOption;
