import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { Card, Heading } from 'bv-components';

import Select from '../select';

import { betTypesCollection } from '../../data';

import { useTranslations } from '../../hooks';

import { BetTypeShape } from '../../shapes';

const propTypes = {
  betType: PropTypes.shape(BetTypeShape).isRequired,
  betInfo: PropTypes.instanceOf(Object).isRequired,
  boxDescriptor: PropTypes.shape({
    class: PropTypes.string,
    message: PropTypes.string,
    title: PropTypes.string,
  }),
  onChangeBetType: PropTypes.func.isRequired,
};

const defaultProps = {
  boxDescriptor: null,
};

const BetTypes = ({
  betType, betInfo, boxDescriptor, onChangeBetType,
}) => {
  const t = useTranslations();
  const translatedBetTypesCollection = betTypesCollection.map((betTypeItem) => ({
    ...betTypeItem,
    name: t(betTypeItem.name),
  }));

  const heading = betType.id === 'default'
    ? t('BetTypes.Type.main')
    : t(betType.name);

  return (
    <Card>
      <Select
        options={translatedBetTypesCollection}
        valueKey="id"
        labelKey="name"
        value={betType.id}
        onChange={(event) => onChangeBetType(event.target.value)}
      />
      <Heading weight="3" tag="h1">{heading || t('BetInformation.main.h1') }</Heading>
      <div dangerouslySetInnerHTML={{ __html: sanitize(betInfo.opening) }} />
      <br />

      {boxDescriptor && (
        <div className={boxDescriptor.class}>
          <span className="title">{ boxDescriptor.title }</span>
          <p dangerouslySetInnerHTML={{ __html: sanitize(boxDescriptor.message) }} />
        </div>
      )}
    </Card>
  );
};

BetTypes.propTypes = propTypes;
BetTypes.defaultProps = defaultProps;

export default BetTypes;
