import PropTypes from 'prop-types';
import { sanitize } from 'dompurify';
import { Card, Heading } from 'bv-components';

const propTypes = {
  betInformation: PropTypes.instanceOf(Object).isRequired,
};

const BetInformation = ({ betInformation }) => (
  <Card>
    {betInformation.questions.map((question) => (
      <div>
        <Heading
          weight="5"
          tag="h2"
          itemScope
          itemType="http://schema.org/Question"
        >
          {question.q}
        </Heading>
        <p
          dangerouslySetInnerHTML={{ __html: sanitize(question.a) }}
          itemScope
          itemType="http://schema.org/Answer"
          itemProp="suggestedAnswer"
        />
      </div>
    ))}
  </Card>
);

BetInformation.propTypes = propTypes;

export default BetInformation;
