/* eslint import/prefer-default-export: off */
const getBetTypeId = (state) => state.betCalculator.betType;
const getCalculator = (state) => state.betCalculator.calculator;
const getDisplayedModal = (state) => state.betCalculator.displayedModal;
const getEncodedBetState = (state) => state.betCalculator.encodedBetState;
const getFormState = (state) => state.betCalculator.formState;
const getParameters = (state) => state.betCalculator.parameters;
const getSavedBets = (state) => state.betCalculator.savedBets;

export {
  getBetTypeId,
  getCalculator,
  getDisplayedModal,
  getEncodedBetState,
  getFormState,
  getParameters,
  getSavedBets,
};
