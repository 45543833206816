import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, Icon, Spinner } from 'bv-components';

import { CalculatorShape, BetTypeShape } from '../../shapes';
import { useTranslations } from '../../hooks';

const createUrlFacebook = (link, returns, betType) => {
  const amount = `£${returns.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  const betTypeName = betType.name.replace(' ', '+');
  return `https://www.facebook.com/sharer/sharer.php?u=${link}&title=BetVictor+Bet+Calculator&caption=Check+out+my+${amount}+${betTypeName}`;
};

const propTypes = {
  betType: PropTypes.shape(BetTypeShape).isRequired,
  calculator: PropTypes.shape(CalculatorShape).isRequired,
  onChangeCalculatorParameter: PropTypes.func.isRequired,
};

const CalculatorShare = memo(
  ({
    betType,
    calculator,
    calculator: { link: { encodedLink, link }, result: { returns } },
    onChangeCalculatorParameter,
  }) => {
    const t = useTranslations();
    const [embedVisible, setEmbedVisible] = useState(false);

    return (
      <div className="bc-share">
        <Button
          onClick={() => onChangeCalculatorParameter('isShareCollapsed')(!calculator.parameters.isShareCollapsed)}
          className="bc-share__toggle"
          secondary
        >
          {t('Calculator.Share.title')}
          <Icon id={calculator.parameters.isShareCollapsed ? 'arrow-down' : 'arrow-up'} />
        </Button>
        <div
          className={classnames('bc-share__btns', {
            'is-collapsed': calculator.parameters.isShareCollapsed,
            'is-loading': !calculator.parameters.isShareCollapsed && !link,
          })}
        >
          {!calculator.parameters.isShareCollapsed && !link && <Spinner />}
          <a
            className="bc-share__btn is-whatsapp"
            href={`whatsapp://send?text=${encodedLink}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            whatsapp
          </a>
          <a
            className="bc-share__btn is-facebook"
            href={createUrlFacebook(encodedLink, returns, betType)}
            target="_blank"
            rel="noopener noreferrer"
          >
            facebook
          </a>
          <a
            className="bc-share__btn is-twitter"
            href={`https://twitter.com/home?status=${encodedLink}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            twitter
          </a>
          <a
            className="bc-share__btn is-mail"
            href={`mailto:?body=${encodedLink}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            e-mail
          </a>
          <button
            type="button"
            className="bc-share__btn is-embed"
            onClick={() => {
              setEmbedVisible(!embedVisible);
            }}
          >
            Embed
          </button>

          <div>
            <input className="bvs-form-control" readOnly value={link} />
            {embedVisible && <input className="bvs-form-control" readOnly value={`<iframe src="${link}"></iframe>`} /> }
          </div>
        </div>
      </div>
    );
  },
);

CalculatorShare.propTypes = propTypes;

export default CalculatorShare;
