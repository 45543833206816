import { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Heading, Button } from 'bv-components';
import { embeddedPath } from 'bv-services';

import { getBetTypeId } from '../selectors';
import { betTypesCollection } from '../data';
import { useTranslations } from '../hooks';

const notDefaultBetType = (betType) => !['default'].includes(betType.id);

const propTypes = {
  betTypeId: PropTypes.string.isRequired,
};

const BetTypeLinks = memo(({ betTypeId }) => {
  const t = useTranslations();

  const generateLink = (betType) => {
    const link = betType.id === 'main' ? '/bet-calculator' : `/bet-calculator/${betType.id}`;
    return embeddedPath(link);
  };

  return (
    <Card>
      <Heading weight="4" tag="h2">{t('BetTypeLinks.title')}</Heading>
      <div className="bet-calculator__type-links">
        {betTypesCollection.filter(notDefaultBetType).map((betType) => (
          <Button
            to={generateLink(betType)}
            noClass
            className="bvs-button-chip is-small"
            active={betTypeId === betType.id || (betTypeId === 'default' && betType.id === 'main')}
          >
            {t(`BetTypes.Type.${betType.id}`)}
          </Button>
        ))}
      </div>
    </Card>
  );
});

BetTypeLinks.propTypes = propTypes;

const mapStateToProps = (state) => ({
  betTypeId: getBetTypeId(state),
});

export default connect(mapStateToProps)(BetTypeLinks);
