import { v4 as uuid } from 'uuid';
import { range } from 'underscore';

export default (outcomes, diff, outcomeTemplate) => (
  diff < 0
    ? outcomes.slice(0, outcomes.length + diff)
    : outcomes.concat(
      range(0, diff).map((v, index) => ({
        ...outcomeTemplate,
        id: uuid(),
        index: index + outcomes.length,
      })),
    )
);
