export default {
  main: {
    title: 'Bet Calculator | Free Online Betting Calculator | BetVictor',
    h1: 'Bet Calculator',
    opening: 'Want to know how much you can win? Try our bet calculator and we’ll work out your potential returns for you.  From popular Lucky 15s to specialist Round Robin bets, our bet calculator offers a quick and easy way to work out your winnings.',
    questions: [
      {
        q: 'What is a bet calculator?',
        a: 'A bet calculator works out your potential winnings. Simply enter the odds available on your bet and your choice of stake, and it will tell you how much you could win.',
      },
      {
        q: 'How to Use the Bet Calculator?',
        a: 'It’s really easy to use the BetVictor Betting Odds Calculator. <br />First of all, you need to decide which type of bet you want, of which we have many, from singles accumulators to goliaths and Lucky 15s, 31s and 63s. <br />Once you’ve chosen, select your bet type on the calculator (which we’ve broken out with easy navigational links below) and decide if you want the odds to be displayed in a fractional or decimal format.<br />After that, you’ll need to input your stake – how much you want to bet. You’ll then want to decide if that bet is each way, add any rule 4s, and whether you want to see the selection notes or fold options. We’ve put a  over some of these, to give you some explanation if they’re unfamiliar. <br />Finally, enter the odds and the outcome: Winner, Lost, Void and Dead Heat. If there are multiple selections you would like to make, enter the odds for each one. Simply click the ‘Calculate’ option and both your potential returns and profit will be displayed for you.<br />If you already know which bet you’d like to check, please see our easy navigational links below.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/',
    },
  },

  single: {
    title: 'Single Bet Calculator | What Is A Win Single Bet? | BetVictor',
    h1: 'Single Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of your Single Bet with our easy to use bet calculator and we’ll also show you a clear breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Single Bet?',
        a: 'A Single Bet is a stake placed on a single selection outcome, and only produces a return if it wins.',
      },
      {
        q: 'How to work out a Single Bet?',
        a: 'Should your bet win at odds of 7/2 with a £10 stake, simply multiply your initial stake of £10 by 9 (the 9 comes from adding the figures from 7/2) then divide by the denominator 2. So that’s £10x9=90 then 90÷2=£45, now you have calculated your returns for a win Single.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/einzelwetten/',
    },
  },

  'each-way': {
    title: 'Each Way Bet Calculator | What Is A Each Way Bet? | BetVictor',
    h1: 'Each Way Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of an Each Way Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is an Each Way Bet?',
        a: 'An Each Way Bet is made up of two separate bets, a Single and a Place Bet.',
      },
      {
        q: 'How does Each Way betting work?',
        a: 'Each Way betting is simply two bets on a single selection. One section of the bet is for a win, the other section is for the bet to be placed; placed is to come between 2nd, 3rd or 4th. So if your selection wins, you’ll be paid for both parts of the bet, but if it’s placed, then you’ll only be paid for the place bet. Each-Way terms vary but quite often they are ¼ or 1/5 the odds.',
      },
      {
        q: 'How do you calculate an Each Way Bet?',
        a: 'To put it into practice, a £5 Each Way Bet totals to £10. If your selection wins at odds of 8/1 your paid out £8 for every pound you spend plus your initial stake of £5 for the win part of the bet. So that’s £45 just for winning. The second part of the bet also has some winnings. If the each way odds are ¼ that means you’ll be paid ¼ of 8/1 for the place part of the bet which is 2/1  for your £5 stake for place that returns another £15. Totalling £60.\n\n\nIf you selection was placed, you’d only be entitled to £15 in total just for the place part of your bet as the win section had lost.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/sieg-platz/',
    },
  },

  double: {
    title: 'Double Bet Calculator | What Is A Double Bet? | BetVictor',
    h1: 'Double Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Double Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Double bet?',
        a: 'A Double bet is a single bet across two selections that requires both to win in order to generate a return.',
      },
      {
        q: 'How many bets are in a Double Bet?',
        a: 'There is 1 bet across 2 selections in a Double',
      },
      {
        q: 'How does a Double Bet work?',
        a: 'If the first bet were to win its returns are rolled over as a stake for the second selection, both selections must be separate events for a Double Bet to be valid.',
      },
      {
        q: 'How do you calculate a Double Bet?',
        a: 'To calculate a Double Bet assume the stake is £10 across the bet. Bet 1 is a 3/1 Single Bet. A win equals £40 return (£3 return for every 1 pound staked plus the stake returned).\nBet 2 is a 2/1 Single Bet using the return of bet 1 as the stake. A win equals a £120 return (£2 return for every £1 staked = £80 plus the original £40 stake) ',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/zweierkombi/',
    },
  },

  treble: {
    title: 'Treble Bet Calculator | What Is A Treble Bet? | BetVictor',
    h1: 'Treble Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Treble Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Treble Bet?',
        a: 'A Treble Bet is a bet on 3 selections all of which need to win in order to generate a return.',
      },
      {
        q: 'How many bets are in a Treble Bet?',
        a: 'There is 1 bet across 3 selections in a Treble',
      },
      {
        q: 'How does a Treble Bet work?',
        a: 'If the first bet in a three-part selection win the return is rolled in to the second bet as the stake. Should the second bet win, the return rolls on to the third bet as the stake.',
      },
      {
        q: 'How do you calculate a Treble Bet?',
        a: 'To calculate a Treble Bet assume the stake is £10 across the bet. Bet 1 is a 3/1 Single Bet. A win equals £40 return (£3 return for every 1 pound staked plus the stake returned).\nBet 2 is a 2/1 Single Bet using the return of bet 1 as the stake. A win equals a £120 return (£2 return for every £1 staked = £80 profit plus the original £40 stake)\nBet 3 is a 3/1 Single Bet using the return of bet 2 as the stake. A win equals £480 return (£3 returned for every £1 staked = £360 profit plus the original £120 stake)',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/dreierkombi/',
    },
  },

  accumulator: {
    title: 'Accumulator Bet Calculator | What Is An Accumulator Bet? | BetVictor',
    h1: 'Accumulator Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of an Accumulator Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings across multiple fold bets.',
    questions: [
      {
        q: 'What is an Accumulator Bet?',
        a: 'An Accumulator bet consists of a bet on 4 or more selections taking part in different events all of which need to win in order to generate a return. It is often named after the number of selections that have been chosen in a bet e.g. Four-Fold, Five-Fold, Six-Fold etc.…',
      },
      {
        q: 'How many bets are in an Accumulator Bet?',
        a: 'There is one bet across 4 or more selections in an Accumulator',
      },
      {
        q: 'How does an Accumulator Bet work?',
        a: 'Following the first bet win the return is rolled on as the stake for the next bet in the selection. Each resultant win continues the rollover of the return as a stake. All bets across the selection must win to see a return from the bet',
      },
      {
        q: 'How do you calculate an Accumulator Bet?',
        a: 'To calculate an Accumulator Bet assume the stake is £10 across a 4 fold (selection) Accumulator Bet.\nBet 1 is a 3/1 Single Bet. A win equals £40 return (£3 return for every 1 pound staked plus the stake returned).\nBet 2 is a 2/1 Single Bet using the return of bet 1 as the stake. A win equals a £120 return (£2 return for every £1 staked = £80 profit plus the £40 stake)\nBet 3 is a 3/1 Single Bet using the return of bet 2 as the stake. A win equals £480 return (£3 returned for every £1 staked = £360 profit plus the £120 stake)\nBet 4 is a 3/1 Single Bet using the return of bet 3 as the stake. A win equals a £1,920 return (£3 returned for every £1 staked = £1,440 plus the £480 stake)',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/kombiwetten/',
    },
  },

  trixie: {
    title: 'Trixie Bet Calculator | What Is A Trixie Bet? | BetVictor',
    h1: 'Trixie Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Trixie Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Trixie Bet?',
        a: 'A Trixie Bet is a bet on 3 selections taking part in different events consisting of 3 Doubles and one Treble totalling to 4 bets.',
      },
      {
        q: 'How many bets are in a Trixie Bet?',
        a: 'There is a combination of 4 bets in a Trixie',
      },
      {
        q: 'How does a Trixie Bet work?',
        a: 'All Double and Treble combinations are placed across 3 selections.',
      },
      {
        q: 'How do you calculate a Trixie Bet?',
        a: 'Add together the return of each winning Double or Treble Bet. You have to get a minimum of 2 winners in order to generate any returns. Benefits of a Trixie is that even if one lets you down, you’re still entitled to a return.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/trixie/',
    },
  },
  patent: {
    title: 'Patent Bet Calculator | What Is A Patent Bet? | BetVictor',
    h1: 'Patent Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Patent Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Patent Bet?',
        a: 'A Patent Bet is a bet across 3 selections taking part in different events. The bet is made up of 3 Singles, 3 Doubles and one Treble totalling 7 bets. Very similar to a Trixie but with the added 3 Singles.',
      },
      {
        q: 'How many bets are in a Patent Bet?',
        a: 'There is a combination of 7 bets in a Patent',
      },
      {
        q: 'How does a Patent Bet work?',
        a: 'The Patent covers all combination of Single, Double and Treble Bets across 3 selections.',
      },
      {
        q: 'How do you calculate a Patent Bet?',
        a: 'Add together the return of each winning Single, Double or Treble Bet. Benefits of a Patent is that a single winning selections ensures some winnings',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/patent/',
    },
  },
  yankee: {
    title: 'Yankee Bet Calculator | What Is A Yankee Bet? | BetVictor',
    h1: 'Yankee Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Yankee Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Yankee Bet?',
        a: 'A Yankee Bet is a bet across 4 selections taking part in different events consisting of 6 Doubles, 4 Trebles and one Four-Fold totalling to 11 bets.',
      },
      {
        q: 'How many bets are in a Yankee Bet?',
        a: 'There is a combination of 11 bets in a Yankee',
      },
      {
        q: 'How does a Yankee Bet work?',
        a: 'The Yankee covers all combinations of Double, Treble and Four-Fold Accumulator Bets across 4 selections.',
      },
      {
        q: 'How do you calculate a Yankee Bet?',
        a: 'Add together the return of each winning Double, Treble or Four-Fold bet. In order to ensure some winnings, two selections have to win in a Yankee bet.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/yankee/',
    },
  },
  canadian: {
    title: 'Canadian Bet Calculator | What Is A Canadian Bet? | BetVictor',
    h1: 'Canadian Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Canadian Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Canadian Bet?',
        a: 'A Canadian Bet, also known as a Super Yankee Bet, is a 5 selection bet taking part in different events consisting of 10 Doubles, 10 Trebles, 5 Four-Folds and a Five-Fold Accumulator totalling 26 bets. 2 selections require a win to gain any returns.',
      },
      {
        q: 'How many bets in a Canadian?',
        a: 'There is a combination of 26 bets in a Canadian Bet.',
      },
      {
        q: 'How does a Canadian Bet work?',
        a: 'The Canadian covers all combination of Double, Treble, Four and Five-Fold bets across 5 selections.',
      },
      {
        q: 'How Do you calculate a Canadian Bet?',
        a: 'Add together the return of each winning Single, Double, Treble, Four-Fold or Five-Fold bet. In order to ensure some winnings, two selections have to win in a Canadian Bet.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/canadian/',
    },
  },
  heinz: {
    title: 'Heinz Bet Calculator | What Is A Heinz Bet? | BetVictor',
    h1: 'Heinz Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Heinz Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Heinz Bet?',
        a: 'A Heinz Bet is a bet on 6 selections taking part in different events consisting of 15 Doubles, 20 Trebles, 15 Four-Folds, 6 Five-Folds and a Six-Fold Accumulator totalling 57 bets.',
      },
      {
        q: 'How many bets in Heinz?',
        a: 'There is a combination of 57 bets in a Heinz Bet.',
      },
      {
        q: 'How does a Heinz Bet work?',
        a: 'The Heinz covers all combination of Double, Treble, Four, Five and Six-Fold Bets across 6 selections.',
      },
      {
        q: 'How Do you calculate a Heinz Bet?',
        a: 'Add together the return of each winning Double, Treble, Four, Five or Six-fold Accumulator Bet. In order to ensure some winnings, two selections have to win in a Heinz Bet.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/heinz/',
    },
  },
  'super-heinz': {
    title: 'Super Heinz Bet Calculator | What Is A Super Heinz Bet? | BetVictor',
    h1: 'Super Heinz Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Super Heinz Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Super Heinz Bet?',
        a: 'A Super Heinz Bet is a bet on 7 selections taking part in different events consisting of 21 Doubles, 35 Trebles, 35 Four-Folds, 21 Five-Folds, 7 Six-Folds, and a Seven-Fold Accumulator totalling 120 bets. 2 selections must win to ensure any returns.',
      },
      {
        q: 'How many bets in a Super Heinz?',
        a: 'There is a combination of 120 bets in a Super Heinz',
      },
      {
        q: 'How does a Super Heinz Bet work?',
        a: 'The Super Heinz covers all combination of Single, Double, Treble, Four, Five and Six-Fold bets across 7 selections',
      },
      {
        q: 'How Do you calculate a Super Heinz Bet?',
        a: 'Add together the return of each winning Double, Treble, Four, Five, Six or Seven-fold Accumulator Bet. In order to ensure some winnings, two selections have to win in a Super Heinz Bet.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/super-heinz/',
    },
  },
  goliath: {
    title: 'Goliath Bet Calculator | What Is A Goliath Bet? | BetVictor',
    h1: 'Goliath Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Goliath Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Goliath Bet?',
        a: 'A Goliath Bet is a bet on 8 selections taking part in different events consisting of 28 Doubles, 56 Trebles, 70 Four-Folds, 56 Five-Folds, 28 Six-Folds, 8 Seven-Folds and an 8-fold Accumulator totalling 247 bets. 2 selections must win to ensure any returns.',
      },
      {
        q: 'How many bets in a Goliath?',
        a: 'There is a combination of 247 bets in a Goliath Bet',
      },
      {
        q: 'How does a Goliath Bet work?',
        a: 'The Goliath covers all combination of Double, Treble, Four, Five, Six, Seven and Eight-Fold Accumulator Bets across 8 selections',
      },
      {
        q: 'How Do you calculate a Goliath Bet?',
        a: 'Add together the return of each winning Double, Treble, Four, Five, Six, Seven and Eight-fold Bet. In order to ensure some winnings, two selections have to win in a Goliath Bet.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/goliath/',
    },
  },
  'lucky-15': {
    title: 'Lucky 15 Bet Calculator | What Is A Lucky 15 Bet? | BetVictor',
    h1: 'Lucky 15 Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Lucky 15 Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Lucky 15 Bet?',
        a: 'A Lucky 15 Bet is a bet on 4 selections taking part in different events consisting of 4 Singles, 6 Doubles, 4 Trebles and a Four-Fold Accumulator totalling 15 bets. It is similar to a Yankee bet but includes the 4 Single Bets. The “Lucky” part of the name of this bet comes from the practice of offering enhanced odds for a single winner. Betvictor does not offer enhanced odds.',
      },
      {
        q: 'How many bets in a Lucky 15?',
        a: 'There is a combination of 15 bets in a Lucky 15',
      },
      {
        q: 'How does a Lucky 15 Bet work?',
        a: 'The Lucky 15 covers all combinations of Single, Double, Treble and a Four-Fold Accumulator Bets across 4 selections',
      },
      {
        q: 'How Do you calculate a Lucky 15 Bet?',
        a: 'Add together the return of each winning Single, Double, Treble or Accumulator Bet. A single win from any selection ensures a return.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/lucky-15/',
    },
  },
  'lucky-31': {
    title: 'Lucky 31 Bet Calculator | What Is A Lucky 31 Bet? | BetVictor',
    h1: 'Lucky 31 Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Lucky 31 Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Lucky 31?',
        a: 'A Lucky 31 Bet is a bet on 5 selections taking part in different events consisting of 5 Singles, 10 Doubles, 10 Trebles, 5 Four-Folds and a Five-Fold Accumulator totalling 31 bets. A single win from any selection ensures a return. It is similar to a Super Yankee bet but includes 5 Singles bets.',
      },
      {
        q: 'How many bets in a Lucky 31?',
        a: 'There is a combination of 31 bets in a Lucky 31',
      },
      {
        q: 'How does a Lucky 31 Bet work?',
        a: 'The Lucky 31 covers all combinations of Single, Double, Treble, Four and Five-Fold Accumulator Bets across 5 selections',
      },
      {
        q: 'How Do you calculate a Lucky 31 Bet?',
        a: 'Add together the return of each winning Single, Double, Treble, Four or Five-fold Accumulator Bet. A single win from any selection ensures a return.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/lucky-31/',
    },
  },
  'lucky-63': {
    title: 'Lucky 63 Bet Calculator | What Is A Lucky 63 Bet? | BetVictor',
    h1: 'Lucky 63 Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Lucky 63 Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Lucky 63 Bet?',
        a: 'A Lucky 63 Bet is a bet on 6 selections taking part in different events consisting of 6 Singles, 15 Doubles, 20 Trebles, 15 Four-Folds, 6 Five-Folds and a Six-Fold Accumulator totalling 63 bets. ',
      },
      {
        q: 'How many bets in a Lucky 63?',
        a: 'There is a combination of 63 bets in a Lucky 63',
      },
      {
        q: 'How does a Lucky 63 Bet work?',
        a: 'The Lucky 63 covers all combinations of Single, Double, Treble, Four, Five and Six-Fold Accumulator Bets across 6 selections',
      },
      {
        q: 'How Do you calculate a Lucky 63 Bet?',
        a: 'Add together the return of each winning Single, Double, Treble, Four, Five and Six-fold Accumulator Bet. A single win from any selection ensures a return. It is similar to Heinz Bet but includes the Six Single Bets.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/lucky-63/',
    },
  },
  alphabet: {
    title: 'Alphabet Bet Calculator - What is an Alphabet Bet? - BetVictor',
    h1: 'Alphabet Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of an Alphabet Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is an Alphabet Bet?',
        a: 'An Alphabet Bet is a bet on 6 selections taking part in different events consisting of 2 Patents (14 bets) 1 Yankee (11 bets) and one Six-Fold Accumulator (1 bet) totalling 26 bets.\nThe Alphabet is composed of Four sections; selections 1-3 make up the first Patent (7 bets). Selections 4-6 make up the second Patent (7bets). The Yankee is made of selections 2, 3, 4 & 5 (11 bets). The final section is the Six-Fold Accumulator which is only 1 bet and in total, completes the 26 bets. A single win from selection ensures a return.',
      },
      {
        q: 'How many bets in an Alphabet Bet?',
        a: 'There is a combination of 26 bets in an Alphabet Bet',
      },
      {
        q: 'How does an Alphabet Bet work?',
        a: 'An Alphabet Bet utilises a combination of three bet types (the Patent, the Yankee and an Accumulator) which contain multiple combinations across 26 different bet combinations',
      },
      {
        q: 'How Do you calculate an Alphabet Bet?',
        a: 'Add together the return of each winning Single, Double, Treble or Accumulator Bet. A single win from any selection guarantees a return',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/alphabet/',
    },
  },
  'round-robin': {
    title: 'Round Robin Bet Calculator | What Is A Round Robin Bet? | BetVictor',
    h1: 'Round Robin Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Round Robin Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Round Robin Bet?',
        a: 'A Round Robin Bet is a bet on 3 selections taking part in different events consisting of 3 Doubles, 1 Treble and 3 Single Stakes About that are 2 bets each, totalling 10 bets. A single win from any selection ensures a return. The Single Stakes About consists of 2 selections taking part in different events, which are combined to produce 2 bets derived from 2 Singles. Any return on each Single, up to the original stake amount, is used to fund a further Single on the other selection. This bet is also known as an Up And Down, The combination from selections ABC would be AB, AC and CB.',
      },
      {
        q: 'How many bets in a Round Robin Bet?',
        a: 'There is a combination of 10 bets in a Round Robin Bet',
      },
      {
        q: 'How does a Round Robin Bet work?',
        a: 'A Round Robin Bet combines Doubles and a Treble Bet with a series of up and down Single Stake About Bets (where only the original stake is rolled on to the next bet in each pair of bets)',
      },
      {
        q: 'How Do you calculate a Round Robin Bet?',
        a: 'Add together the return of each winning up and down Single, Double and Treble Bet.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/round-robin/',
    },
  },
  flag: {
    title: 'Flag Bet Calculator | What Is A Flag Bet? | BetVictor',
    h1: 'Flag Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Flag Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Flag Bet?',
        a: 'A Flag Bet is a bet on 4 selections taking part in different events consisting of 6 Doubles, 4 Trebles, 1 Four-Fold and 6 Single Stakes About Bets in pairs totalling 23 bets. It is similar to a Yankee bet with the added 6 pairs of Single Stakes About Bets.',
      },
      {
        q: 'How many bets in a flag?',
        a: 'There is a combination of 23 bets in a Flag Bet',
      },
      {
        q: 'How does a Flag Bet work?',
        a: 'A Flag Bet combines the maximum variation of Doubles, Trebles and a Four-Fold Accumulator with Single Stakes About Bets across 4 selections.',
      },
      {
        q: 'How Do you calculate a Flag Bet?',
        a: 'Add together the return of each winning Double, Treble Four-fold Accumulator or SSA Bet. A single win from any selection ensures a return.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/flag/',
    },
  },
  'super-flag': {
    title: 'Super Flag Bet Calculator | What Is A Super Flag Bet? | BetVictor',
    h1: 'Super Flag Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Super Flag Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Super Flag Bet?',
        a: 'A Super Flag Bet is a bet on 5 selections taking part in different events consisting of 10 Doubles, 10 Trebles, 5 Four-Folds, 1 Five-Fold and 10 Single Stakes About Bets in pairs totalling 46 bets. A single win from any selection ensures a return. It is similar to a Canadian Bet with the added 10 pairs of Single Stakes About Bets.',
      },
      {
        q: 'How many bets in a Super Flag?',
        a: 'There is a combination of 46 bets in a Super Flag Bet',
      },
      {
        q: 'How does a Super Flag Bet work?',
        a: 'A Super Flag Bet combines the maximum variation of Double, Treble, Four and Five-Fold Accumulator Bets with Single Stakes About Bets across 4 selections.',
      },
      {
        q: 'How Do you calculate a Super Flag Bet?',
        a: 'Add together the return of each winning Double, Treble, Four, Five-fold Accumulator or SSA Bet. A single win from any selection ensures a return.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/super-flag/',
    },
  },
  'super-yankee': {
    title: 'Super Yankee Bet Calculator | What Is A Super Yankee Bet? | BetVictor',
    h1: 'Super Yankee Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Super Yankee Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Super Yankee?',
        a: 'A Super Yankee bet also known as a Canadian Bet, is a 5 selection bet taking part in different events consisting of 10 Doubles, 10 Trebles, 5 Four-Folds and a Five-Fold Accumulator totalling 26 bets.',
      },
      {
        q: 'How many bets in a Super Yankee?',
        a: 'There is a combination of 26 bets in a Super Yankee',
      },
      {
        q: 'How does a Super Yankee Bet work?',
        a: 'A Super Yankee Bet combines the maximum variation of Double, Treble, Four and Five-Fold Accumulator Bets across 5 selections.',
      },
      {
        q: 'How Do you calculate a Super Yankee Bet?',
        a: 'Add together the return of each winning Double, Treble, Four or Five-fold Accumulator Bet. 2 selections require a win to gain any returns.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/super-yankee/',
    },
  },
  'double-stakes-about': {
    title: 'Super Yankee Bet Calculator - What is a Super Yankee Bet? - BetVictor',
    h1: 'Double Stakes About Bet calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Double Stakes About Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Double Stakes About Bet?',
        a: 'A Double Stakes About is an any to come bet consisting of 2 selections taking part in different events, which are combined to produce 2 bets derived from 2 Singles. Any return on each Single, up to Double the original stake amount, is used to fund a further Single on the other selection.',
      },
      {
        q: 'How many bets in a Double Stakes About?',
        a: 'There are 2 bets in a Double Stakes About Bet',
      },
      {
        q: 'How does a Double Stakes About Bet work?',
        a: 'A Double Stakes About Bet is a single two part bet across two selections that uses the return of bet 1, up to Double the original stake, to be laid as the stake on bet 2.',
      },
      {
        q: 'How Do you calculate a Double Stakes About Bet?',
        a: 'To calculate a Double Stakes About Bet assume the stake is £10 across the bet. \nBet 1 is a 3/1 Single Bet. A win equals £40 return (£3 return for every 1 pound staked plus the stake returned).\nBet 2 is a 2/1 Single Bet using double the stake of bet 1 (£10x2 =£20) taken from the win return of bet 1. A bet 2 win equals a £60 (£2 return for every £1 staked = £40 plus the doubled original stake of £20)\nThe total return is therefore £80. This breaks down as £20 from bet 1 (£40 return minus Double the original stake rolled on to bet 2) and £60 return from bet 2.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/double-stakes-about/',
    },
  },
  'single-stakes-about': {
    title: 'Single Stakes About Bet Calculator - BetVictor',
    h1: 'Single Stakes About Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Single Stakes About Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Single Stakes About Bet?',
        a: 'Single Stakes About is bet consisting of 2 selections taking part in different events, which are combined to produce two bets derived from 2 Singles. Any return on each Single up to the original stake, is used to fund a further Single on the other selection. This is also known as an up and down, cross bet.',
      },
      {
        q: 'How many bets in a Single Stakes About Bet?',
        a: 'There are 2 bets in a Single Stakes About Bet',
      },
      {
        q: 'How does a Single Stakes About Bet work?',
        a: 'A successful win on bet 1 allows the original stake to be rolled on to bet 2.',
      },
      {
        q: 'How Do you calculate a Single Stakes About Bet?',
        a: 'To calculate a Single Stakes About Bet assume the stake is £10 across the bet. \nBet 1 is a 3/1 Single Bet. A win equals £40 return (£3 return for every 1 pound staked plus the stake returned).\nBet 2 is a 2/1 Single Bet using the stake of bet 1 (£10) from the return from the win of bet 1. A bet 2 win equals a £30 (£2 return for every £1 staked = £20 plus the original stake of £10)\nThe total return is therefore £60. This breaks down as £30 from bet 1 (£40 return minus the original stake rolled on to bet 2) and £30 return from bet 2.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/single-stakes-about/',
    },
  },
  'heinz-flag': {
    title: 'Heinz Flag Bet Calculator | What Is A Heinz Flag Bet? | BetVictor',
    h1: 'Heinz Flag Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Heinz Flag Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Heinz Flag Bet?',
        a: 'A Heinz Flag Bet is a bet on 6 selections taking part in different events consisting of 15 Doubles, 20 Trebles, 15 Four-Folds, 6 Five-Folds, 1 Six-Fold Accumulator and 15 Single Stake About Bets in pairs totalling 87 bets. A single win from any selection ensures a return. It is similar to a Heinz Bet with the added 15 pairs of Single Stake About Bets.',
      },
      {
        q: 'How many bets in a Heinz Flag?',
        a: 'There are 87 bet combinations in a Heinz Flag Bet',
      },
      {
        q: 'How does a Heinz Flag Bet work?',
        a: 'A Heinz Flag Bet combines the maximum variation of Double, Treble, Four, Five and Six-Fold Accumulator with Single Stakes About Bets across 6 selections.',
      },
      {
        q: 'How Do you calculate a Heinz Flag Bet?',
        a: 'Add together the return of each winning Double, Treble, Four, Five, Six-fold Accumulator or SSA Bet. A single win from any selection ensures a return.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/heinz-flag/',
    },
  },
  'super-heinz-flag': {
    title: 'Super Heinz Flag Bet Calculator | What Is A Super Heinz Flag? | BetVictor',
    h1: 'Super Heinz Flag Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Super Heinz Flag Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Super Heinz Flag Bet?',
        a: 'A Super Heinz Flag Bet is a bet on 7 selections taking part in different events consisting of 21 Doubles, 35 Trebles, 35 Four-Folds, 21 Five-Folds, 7 Six-Folds and 21 Single Stake About pairs totalling 162 bets. A single win from any selections ensures a return.',
      },
      {
        q: 'How many bets in a Super Heinz flag?',
        a: 'There are 162 bet combinations on a Super Heinz Flag Bet',
      },
      {
        q: 'How does a Super Heinz Flag Bet work?',
        a: 'A Super Heinz Flag Bet combines the maximum variation of Doubles, Trebles, Four, Five and Six-Fold Accumulator with Single Stakes About Bets across 7 selections.',
      },
      {
        q: 'How Do you calculate a Super Heinz Flag Bet?',
        a: 'Add together the return of each winning Double, Treble, Four, Five, Six Accumulator and SSA Bet. A single win from any selection ensures a return.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/super-heinz-flag/',
    },
  },
  'goliath-flag': {
    title: 'Goliath Flag Bet Calculator | What Is A Goliath Flag Bet? | BetVictor',
    h1: 'Goliath Flag Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Goliath Flag Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Goliath Flag Bet?',
        a: 'A Goliath Flag Bet is a bet on 8 selections taking part in different events consisting of 28 Doubles, 56 Trebles, 70 Four-Folds, 56 Five-Folds, 28 Six-Folds, 8 Seven-Folds, 1 Eight-Fold Accumulator and 20 Single Stakes About pairs totalling 303 bets. A single win from any selections ensures a return.',
      },
      {
        q: 'How many bets in a Goliath Flag?',
        a: 'There are 303 bet combinations in a Goliath Flag Bet',
      },
      {
        q: 'How does a Goliath Flag Bet work?',
        a: 'A Goliath Flag Bet combines the maximum variation of Doubles, Trebles, Four, Five, Six and Seven-Fold Accumulator with Single Stakes About Bets across 8 selections.',
      },
      {
        q: 'How Do you calculate a Goliath Flag Bet?',
        a: 'Add together the return of each winning Double, Treble, Four, Five, Six and Seven-fold Accumulator and SSA Bet. A single win from any selection ensures a return.',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/goliath-flag/',
    },
  },
  'union-jack-trebles': {
    title: 'Union Jack Trebles Bet Calculator | BetVictor',
    h1: 'Union Jack Trebles Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Union Jack Treble Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Union Jack Trebles bet?',
        a: '<div class="bc-bg-flags bc-bg-flags__a">A Union Jack Treble is a bet on 9 selections taking part in different events consisting of 8 Trebles. The Trebles are arranged in 3 rows of 3 selections and bets are formed by combining the selections on each of the horizontal, vertical and diagonal lines. Each combination of three selections forms a Treble, totalling 8 bets. </div>',
      },
      {
        q: 'How many bets in a Union Jack Treble?',
        a: 'There are 8 bet combinations in a Union Jack Treble',
      },
      {
        q: 'How does a Union Jack Treble Bet work?',
        a: '<div class="bc-bg-flags bc-bg-flags__overlay-a">A Union Jack Treble Bet combines the maximum number of Treble Bet variations across 9 selections.</div>',
      },
      {
        q: 'How Do you calculate a Union Jack Treble Bet?',
        a: 'Add together the return of each winning Treble',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/union-jack-trebles/',
    },
  },
  'union-jack-trixie': {
    title: 'Union Jack Trixie Bet Calculator | What Is A Union Jack Trixie? | BetVictor',
    h1: 'Union Jack Trixie Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Union Jack Trixie Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Union Jack Trixie bet?',
        a: '<div class="bc-bg-flags bc-bg-flags__a">A Union Jack Trixie is a bet on 9 selections taking part in different events consisting of 8 Trixies (24 Doubles forming 8 Trebles). The Trebles are arranged in 3 rows of 3 selections and bets are formed by combining the selections on each of the horizontal, vertical and diagonal lines. Each combination of Trixie totals 32 individual bets.</div>',
      },
      {
        q: 'How many bets in a Union Jack Trixie?',
        a: 'There are 32 bet combinations on a Union Jack Trixie',
      },
      {
        q: 'How does a Union Jack Trixie Bet work?',
        a: '<div class="bc-bg-flags bc-bg-flags__overlay-a">A Union Jack Trixie Bet combines the maximum number of Trixie Bet variations across 9 selections.</div>',
      },
      {
        q: 'How Do you calculate a Union Jack Trixie Bet?',
        a: 'Add together the return of each winning Double and Treble',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/union-jack-trixie/',
    },
  },
  'union-jack-patent': {
    title: 'Union Jack Patent Bet Calculator | What Is A Union Jack Patent? | BetVictor',
    h1: 'Union Jack Patent Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Union Jack Patent Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Union Jack Patent Bet?',
        a: '<div class="bc-bg-flags bc-bg-flags__a">A Union Jack Patent is a bet on 9 selections taking part in different events consisting of 8 Trixies and 24 Single Bets. The Trebles are arranged in 3 rows of 3 selections and bets are formed by combining the selections on each of the horizontal, vertical and diagonal lines. Each combination of three selections forms a Patent, totalling 56 bets.</div>',
      },
      {
        q: 'How many bets in a Union Jack Patent?',
        a: 'There are 56 bet combinations in a Union Jack Patent Bet',
      },
      {
        q: 'How does a Union Jack Patent Bet work?',
        a: '<div class="bc-bg-flags bc-bg-flags__overlay-a">A Union Jack Patent Bet combines the maximum number of Trixie Bet variations with 24 Single Bets across 9 selections.</div>',
      },
      {
        q: 'How Do you calculate a Union Jack Patent Bet?',
        a: 'Add together the return of each winning Single, Double and Treble',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/union-jack-patent/',
    },
  },
  'union-jack-round-robin': {
    title: 'Union Jack Round Robin Bet Calculator | BetVictor',
    h1: 'Union Jack Round Robin Bet Calculator',
    opening: 'Want to know how much you can win? Work out your potential returns of a Union Jack Round Robin Bet with our simple bet calculator and we’ll also show you a simple breakdown of your winnings.',
    questions: [
      {
        q: 'What is a Union Jack Round Robin Bet?',
        a: '<div class="bc-bg-flags bc-bg-flags__a">A Union Jack Round Robin is a bet on 9 selections taking part in different events consisting of 8 Trixies and 24 up and down Single Stake About Bets. The Trebles are arranged in 3 rows of 3 selections and bets are formed by combining the selections on each of the horizontal, vertical and diagonal lines. Each combination of three selections forms a Round Robin, totalling 80 bets.</div>',
      },
      {
        q: 'How many bets in a Union Jack Round Robin?',
        a: 'There are 80 bet combinations in a Union Jack Round Robin Bet',
      },
      {
        q: 'How does a Union Jack Round Robin Bet work?',
        a: '<div class="bc-bg-flags bc-bg-flags__overlay-a">A Union Jack Round Robin Bet combines the maximum number of Trixie Bet variations with 24 Single Stake About Bets across 9 selections.</div>',
      },
      {
        q: 'How Do you calculate a Union Jack Round Robin Bet?',
        a: 'Add together the return of each winning Single, Double and Treble',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/union-jack-round-robin/',
    },
  },
  forecast: {
    title: 'Straight and Reverse Forecast Bet Calculator | BetVictor',
    h1: 'Straight & Reverse Forecast Calculator',
    opening: 'Find our simple guide to how to place a Reverse Forecast bet and how it works.',
    questions: [
      {
        q: 'How many types of forecast betting are there?',
        a: 'There can be a straight or a reverse forecast. These can be single, double or treble bets.',
      },
      {
        q: 'What is a Straight Forecast?',
        a: 'A Straight Forecast bet allows you to select two runners where you are predicting which will finish 1st and which will finish 2nd.\n\nThe Straight Forecast result is also the name of the value paid out per pound staked following the results of a race. To find out the Straight Forecast result of past races please check the BetVictor Results Feed',
      },
      {
        q: 'What is a Reverse Forecast?',
        a: 'A Reverse Forecast bet allows you to select two runners from the same event to finish 1st and 2nd in any order to generate a return.',
      },
      {
        q: 'How many bets in a straight and reverse forecast?',
        a: 'There is 1 Single Bet in a straight forecast and there are 2 Single Bets in a Reverse Forecast',
      },
      {
        q: 'How Does a Straight Forecast work?',
        a: 'Upon the completion of a race, a straight forecast dividend will be provided (see the BetVictor Results Feed for past races). Multiply the straight forecast dividend by the stake to provide the return as illustrated below.',
      },
      {
        q: 'How does a Reverse Forecast bet work?',
        a: 'The Reverse Forecast is a bet on two runners finishing 1st and 2nd in one half and the reverse in the second half of the bet. It is effectively two straight forecasts with opposing predicted results meaning the stake is double compared to a Straight Forecast. This is illustrated below.',
      },
      {
        q: 'How Does a Double or Treble Reverse Forecast work?',
        a: 'A Double Reverse Forecast is utilising a 2 single Reverse Forecasts where the return of race 1 are used as the stake for race 2. A Treble Reverse Forecast simply uses the return of race 2 as the stake for race 3. We have illustrated a Double Reverse forecast below',
      },
    ],
    hreflang: {
      de: '/sportwetten-quotenrechner/zweierwette-kombiniert/',
    },
  },
};
