import { Combination } from 'js-combinatorics';
import Big from 'big.js';
import _, { partial, every } from 'underscore';
import Arthur from '../lib/Arthur';

const defaultRespopnse = {
  outlay: 'XX.XX',
  returns: 'XX.XX',
  profit: 'XX.XX',
};

// eslint-disable-next-line no-undef
const allOutcomesValid = partial(every, _, (outcome) => ['void', 'lost'].includes(outcome.type) || outcome.odds !== '');

// eslint-disable-next-line no-undef
const allOutcomesDontRequireOdds = partial(every, _, (outcome) => ['void', 'lost'].includes(outcome.type));

const validFractionOutcome = (outcome) => outcome['odds.fractions'].numerator !== ''
  && outcome['odds.fractions'].denominator !== '' && outcome['odds.fractions'].denominator !== '0';

const fractionOutcomeAsText = (outcome) => `${outcome['odds.fractions'].numerator}/${outcome['odds.fractions'].denominator}`;

const outcomeOdds = (outcome, oddsFormat) => (oddsFormat === 'fractions'
  ? (validFractionOutcome(outcome) && fractionOutcomeAsText(outcome)) || ''
  : outcome['odds.decimal']
);

const parseOutcomes = (outcomes, oddsFormat) => outcomes.map((outcome) => ({
  ...outcome,
  odds: outcomeOdds(outcome, oddsFormat),
  outcome: outcome.type,
  rule4: parseInt(outcome.ruleFour || 0, 10),
}));

const eachWayOptions = (eachWay, eachWayValue) => {
  if (!eachWay) return {};

  return {
    eachWay,
    eachWayType: eachWayValue || Arthur.WIN_TO_WIN_PLACE_TO_PLACE,
  };
};

export default ({
  oddsFormat,
  outcomes,
  stake,
  foldNumber,
  eachWay,
  eachWayValue,
} = {}) => {
  const parsedOutcomes = parseOutcomes(outcomes, oddsFormat);

  if (
    !stake
    || !allOutcomesValid(parsedOutcomes, oddsFormat)
    || allOutcomesDontRequireOdds(parsedOutcomes)
  ) {
    return defaultRespopnse;
  }

  const foldOptionsResult = new Combination(
    parsedOutcomes,
    foldNumber,
  ).toArray().reduce((acc, selections) => {
    const slip = new Arthur.Slip({
      betType: 'accumulator',
      stake: parseFloat(stake),
      selections,
      ...eachWayOptions(eachWay, eachWayValue),
    });

    const result = Arthur.calculate(slip, true);

    return {
      outlay: acc.outlay.plus(result.outlay),
      returns: acc.returns.plus(result.returns),
      profit: acc.profit.plus(result.profit),
    };
  }, { outlay: new Big(0), returns: new Big(0), profit: new Big(0) });

  return {
    outlay: foldOptionsResult.outlay.round(10).toFixed(2),
    returns: foldOptionsResult.returns.round(10).toFixed(2),
    profit: foldOptionsResult.profit.round(10).toFixed(2),
  };
};
