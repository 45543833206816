import { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '../hooks';

const propTypes = {
  toggleModal: PropTypes.func.isRequired,
};

const RuleFourLabel = memo(({ toggleModal }) => {
  const t = useTranslations();

  return (
    <>
      {t('Parameters.ruleFour')}
      <button
        type="button"
        onClick={toggleModal('RuleFourModal')}
        className="info-toggle"
      >
        ?
      </button>
    </>
  );
});

RuleFourLabel.propTypes = propTypes;

export default RuleFourLabel;
