import PropTypes from 'prop-types';

const CalculatorParametersShape = {
  isTotal: PropTypes.bool.isRequired,
  isUsedBonusFunds: PropTypes.bool.isRequired,
  stake: PropTypes.string.isRequired,
  shouldSaveBet: PropTypes.bool.isRequired,
};

const CalculatorResultShape = {
  outlay: PropTypes.string.isRequired,
  returns: PropTypes.string.isRequired,
  profit: PropTypes.string.isRequired,
};

export default {
  id: PropTypes.string,
  error: PropTypes.bool.isRequired,
  parameters: PropTypes.shape(CalculatorParametersShape).isRequired,
  result: PropTypes.shape(CalculatorResultShape).isRequired,
};
