import PropTypes from 'prop-types';
import { Card } from 'bv-components';

import CalculatorContainer from '../calculator/calculator_container';
import ParametersContainer from '../parameters/parameters_container';

const propTypes = {
  onClickCalculate: PropTypes.func.isRequired,
};

const Form = ({ onClickCalculate, ...props }) => {
  const onSubmit = (event) => {
    event.preventDefault();
    onClickCalculate();
  };

  return (
    <Card className="bet-calculator-details">
      <form onSubmit={onSubmit}>
        <ParametersContainer
          {...props}
        />
        <CalculatorContainer
          {...props}
        />
      </form>
    </Card>
  );
};

Form.propTypes = propTypes;

export default Form;
