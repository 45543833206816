import { connect } from 'react-redux';

import Breakdown from './breakdown';

import { getCalculator } from '../../selectors';

const BreakdownContainer = (props) => (
  <Breakdown
    {...props}
  />
);

const mapStateToProps = (state) => ({
  calculator: getCalculator(state),
});

export default connect(mapStateToProps)(BreakdownContainer);
