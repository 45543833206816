import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Form from './form';

import { onClickCalculate } from '../../duck';
import { getFormState } from '../../selectors';

const propTypes = {
  handlers: PropTypes.shape({
    onClickCalculate: PropTypes.func.isRequired,
  }).isRequired,
};

const FormContainer = ({ handlers, ...props }) => (
  <Form {...props} {...handlers} />
);

FormContainer.propTypes = propTypes;

const mapStateToProps = (state) => ({
  formState: getFormState(state),
});

const mapDispatchToProps = (dispatch) => ({
  handlers: {
    onClickCalculate: compose(dispatch, onClickCalculate),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
