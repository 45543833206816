import PropTypes from 'prop-types';

const Banner = ({ bannerUrl, bannerHref }) => (
  <a className="bet-calculator__banner" href={bannerHref} target="_blank" data-react rel="noopener noreferrer">
    <img src={bannerUrl} alt="Create an account banner" />
  </a>
);

Banner.propTypes = {
  bannerUrl: PropTypes.string.isRequired,
  bannerHref: PropTypes.string.isRequired,
};

export default Banner;
