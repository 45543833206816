/* eslint-disable */
!(function(e, t) {
  "object" == typeof exports && "object" == typeof module
    ? (module.exports = t())
    : "function" == typeof define && define.amd
    ? define([], t)
    : "object" == typeof exports
    ? (exports.Arthur = t())
    : (e.Arthur = t());
})(this, function() {
  return (function(e) {
    function t(a) {
      if (r[a]) return r[a].exports;
      var s = (r[a] = { i: a, l: !1, exports: {} });
      return e[a].call(s.exports, s, s.exports, t), (s.l = !0), s.exports;
    }
    var r = {};
    return (
      (t.m = e),
      (t.c = r),
      (t.i = function(e) {
        return e;
      }),
      (t.d = function(e, r, a) {
        t.o(e, r) ||
          Object.defineProperty(e, r, {
            configurable: !1,
            enumerable: !0,
            get: a
          });
      }),
      (t.n = function(e) {
        var r =
          e && e.__esModule
            ? function() {
                return e.default;
              }
            : function() {
                return e;
              };
        return t.d(r, "a", r), r;
      }),
      (t.o = function(e, t) {
        return Object.prototype.hasOwnProperty.call(e, t);
      }),
      (t.p = ""),
      t((t.s = 75))
    );
  })([
    function(e, t) {
      e.exports = {
        WIN_TO_WIN_PLACE_TO_PLACE: "wwpp",
        EACH_WAY_ALL_EACH_WAY: "ewew",
        FRACTIONS: "fractions",
        DECIMAL: "decimal",
        WINNER: "winner",
        WIN: "winner",
        PLACED: "placed",
        PLACE: "placed",
        LOST: "lost",
        LOSE: "lost",
        VOID: "void",
        DEAD_HEAT: "deadHeat",
        TOTAL_COMBINED_STAKE: "totalCombined",
        STAKE_PER_BET: "perBet",
        OWC_NONE: "owcNone",
        OWC_DOUBLE: "owcDouble",
        OWC_TREBLE: "owcTreble"
      };
    },
    function(e, t, r) {
      var a;
      !(function(s) {
        "use strict";
        function n() {
          function e(t) {
            var r = this;
            if (!(r instanceof e)) return void 0 === t ? n() : new e(t);
            t instanceof e
              ? ((r.s = t.s), (r.e = t.e), (r.c = t.c.slice()))
              : o(r, t),
              (r.constructor = e);
          }
          return (
            (e.prototype = m),
            (e.DP = h),
            (e.RM = f),
            (e.E_NEG = p),
            (e.E_POS = d),
            e
          );
        }
        function i(e, t, r) {
          var a = e.constructor,
            s = t - (e = new a(e)).e,
            n = e.c;
          for (
            n.length > ++t && u(e, s, a.RM),
              n[0] ? (r ? (s = t) : ((n = e.c), (s = e.e + s + 1))) : ++s;
            n.length < s;
            n.push(0)
          );
          return (
            (s = e.e),
            1 === r || (r && (t <= s || s <= a.E_NEG))
              ? (e.s < 0 && n[0] ? "-" : "") +
                (n.length > 1 ? n[0] + "." + n.join("").slice(1) : n[0]) +
                (s < 0 ? "e" : "e+") +
                s
              : e.toString()
          );
        }
        function o(e, t) {
          var r, a, s;
          for (
            0 === t && 1 / t < 0 ? (t = "-0") : v.test((t += "")) || l(NaN),
              e.s = "-" == t.charAt(0) ? ((t = t.slice(1)), -1) : 1,
              (r = t.indexOf(".")) > -1 && (t = t.replace(".", "")),
              (a = t.search(/e/i)) > 0
                ? (r < 0 && (r = a),
                  (r += +t.slice(a + 1)),
                  (t = t.substring(0, a)))
                : r < 0 && (r = t.length),
              a = 0;
            "0" == t.charAt(a);
            a++
          );
          if (a == (s = t.length)) e.c = [(e.e = 0)];
          else {
            for (; "0" == t.charAt(--s); );
            for (
              e.e = r - a - 1, e.c = [], r = 0;
              a <= s;
              e.c[r++] = +t.charAt(a++)
            );
          }
          return e;
        }
        function u(e, t, r, a) {
          var s,
            n = e.c,
            i = e.e + t + 1;
          if (
            (1 === r
              ? (a = n[i] >= 5)
              : 2 === r
              ? (a =
                  n[i] > 5 ||
                  (5 == n[i] && (a || i < 0 || n[i + 1] !== s || 1 & n[i - 1])))
              : 3 === r
              ? (a = a || n[i] !== s || i < 0)
              : ((a = !1), 0 !== r && l("!Big.RM!")),
            i < 1 || !n[0])
          )
            a ? ((e.e = -t), (e.c = [1])) : (e.c = [(e.e = 0)]);
          else {
            if (((n.length = i--), a))
              for (; ++n[i] > 9; ) (n[i] = 0), i-- || (++e.e, n.unshift(1));
            for (i = n.length; !n[--i]; n.pop());
          }
          return e;
        }
        function l(e) {
          var t = new Error(e);
          throw ((t.name = "BigError"), t);
        }
        var c,
          h = 20,
          f = 1,
          p = -7,
          d = 21,
          m = {},
          v = /^-?(\d+(\.\d*)?|\.\d+)(e[+-]?\d+)?$/i;
        (m.abs = function() {
          var e = new this.constructor(this);
          return (e.s = 1), e;
        }),
          (m.cmp = function(e) {
            var t,
              r = this,
              a = r.c,
              s = (e = new r.constructor(e)).c,
              n = r.s,
              i = e.s,
              o = r.e,
              u = e.e;
            if (!a[0] || !s[0]) return a[0] ? n : s[0] ? -i : 0;
            if (n != i) return n;
            if (((t = n < 0), o != u)) return (o > u) ^ t ? 1 : -1;
            for (n = -1, i = (o = a.length) < (u = s.length) ? o : u; ++n < i; )
              if (a[n] != s[n]) return (a[n] > s[n]) ^ t ? 1 : -1;
            return o == u ? 0 : (o > u) ^ t ? 1 : -1;
          }),
          (m.div = function(e) {
            var t = this,
              r = t.constructor,
              a = t.c,
              s = (e = new r(e)).c,
              n = t.s == e.s ? 1 : -1,
              i = r.DP;
            if (
              ((i !== ~~i || i < 0 || i > 1e6) && l("!Big.DP!"), !a[0] || !s[0])
            )
              return a[0] == s[0] && l(NaN), s[0] || l(n / 0), new r(0 * n);
            var o,
              c,
              h,
              f,
              p,
              d,
              m = s.slice(),
              v = (o = s.length),
              g = a.length,
              y = a.slice(0, o),
              P = y.length,
              E = e,
              w = (E.c = []),
              b = 0,
              S = i + (E.e = t.e - e.e) + 1;
            for (E.s = n, n = S < 0 ? 0 : S, m.unshift(0); P++ < o; y.push(0));
            do {
              for (h = 0; h < 10; h++) {
                if (o != (P = y.length)) f = o > P ? 1 : -1;
                else
                  for (p = -1, f = 0; ++p < o; )
                    if (s[p] != y[p]) {
                      f = s[p] > y[p] ? 1 : -1;
                      break;
                    }
                if (!(f < 0)) break;
                for (c = P == o ? s : m; P; ) {
                  if (y[--P] < c[P]) {
                    for (p = P; p && !y[--p]; y[p] = 9);
                    --y[p], (y[P] += 10);
                  }
                  y[P] -= c[P];
                }
                for (; !y[0]; y.shift());
              }
              (w[b++] = f ? h : ++h),
                y[0] && f ? (y[P] = a[v] || 0) : (y = [a[v]]);
            } while ((v++ < g || y[0] !== d) && n--);
            return (
              w[0] || 1 == b || (w.shift(), E.e--),
              b > S && u(E, i, r.RM, y[0] !== d),
              E
            );
          }),
          (m.eq = function(e) {
            return !this.cmp(e);
          }),
          (m.gt = function(e) {
            return this.cmp(e) > 0;
          }),
          (m.gte = function(e) {
            return this.cmp(e) > -1;
          }),
          (m.lt = function(e) {
            return this.cmp(e) < 0;
          }),
          (m.lte = function(e) {
            return this.cmp(e) < 1;
          }),
          (m.sub = m.minus = function(e) {
            var t,
              r,
              a,
              s,
              n = this,
              i = n.constructor,
              o = n.s,
              u = (e = new i(e)).s;
            if (o != u) return (e.s = -u), n.plus(e);
            var l = n.c.slice(),
              c = n.e,
              h = e.c,
              f = e.e;
            if (!l[0] || !h[0])
              return h[0] ? ((e.s = -u), e) : new i(l[0] ? n : 0);
            if ((o = c - f)) {
              for (
                (s = o < 0) ? ((o = -o), (a = l)) : ((f = c), (a = h)),
                  a.reverse(),
                  u = o;
                u--;
                a.push(0)
              );
              a.reverse();
            } else
              for (
                r = ((s = l.length < h.length) ? l : h).length, o = u = 0;
                u < r;
                u++
              )
                if (l[u] != h[u]) {
                  s = l[u] < h[u];
                  break;
                }
            if (
              (s && ((a = l), (l = h), (h = a), (e.s = -e.s)),
              (u = (r = h.length) - (t = l.length)) > 0)
            )
              for (; u--; l[t++] = 0);
            for (u = t; r > o; ) {
              if (l[--r] < h[r]) {
                for (t = r; t && !l[--t]; l[t] = 9);
                --l[t], (l[r] += 10);
              }
              l[r] -= h[r];
            }
            for (; 0 === l[--u]; l.pop());
            for (; 0 === l[0]; ) l.shift(), --f;
            return (
              l[0] || ((e.s = 1), (l = [(f = 0)])), (e.c = l), (e.e = f), e
            );
          }),
          (m.mod = function(e) {
            var t,
              r = this,
              a = r.constructor,
              s = r.s,
              n = (e = new a(e)).s;
            return (
              e.c[0] || l(NaN),
              (r.s = e.s = 1),
              (t = 1 == e.cmp(r)),
              (r.s = s),
              (e.s = n),
              t
                ? new a(r)
                : ((s = a.DP),
                  (n = a.RM),
                  (a.DP = a.RM = 0),
                  (r = r.div(e)),
                  (a.DP = s),
                  (a.RM = n),
                  this.minus(r.times(e)))
            );
          }),
          (m.add = m.plus = function(e) {
            var t,
              r = this,
              a = r.constructor,
              s = r.s,
              n = (e = new a(e)).s;
            if (s != n) return (e.s = -n), r.minus(e);
            var i = r.e,
              o = r.c,
              u = e.e,
              l = e.c;
            if (!o[0] || !l[0]) return l[0] ? e : new a(o[0] ? r : 0 * s);
            if (((o = o.slice()), (s = i - u))) {
              for (
                s > 0 ? ((u = i), (t = l)) : ((s = -s), (t = o)), t.reverse();
                s--;
                t.push(0)
              );
              t.reverse();
            }
            for (
              o.length - l.length < 0 && ((t = l), (l = o), (o = t)),
                s = l.length,
                n = 0;
              s;

            )
              (n = ((o[--s] = o[s] + l[s] + n) / 10) | 0), (o[s] %= 10);
            for (n && (o.unshift(n), ++u), s = o.length; 0 === o[--s]; o.pop());
            return (e.c = o), (e.e = u), e;
          }),
          (m.pow = function(e) {
            var t = this,
              r = new t.constructor(1),
              a = r,
              s = e < 0;
            for (
              (e !== ~~e || e < -1e6 || e > 1e6) && l("!pow!"), e = s ? -e : e;
              1 & e && (a = a.times(t)), (e >>= 1);

            )
              t = t.times(t);
            return s ? r.div(a) : a;
          }),
          (m.round = function(e, t) {
            var r = this,
              a = r.constructor;
            return (
              null == e
                ? (e = 0)
                : (e !== ~~e || e < 0 || e > 1e6) && l("!round!"),
              u((r = new a(r)), e, null == t ? a.RM : t),
              r
            );
          }),
          (m.sqrt = function() {
            var e,
              t,
              r,
              a = this,
              s = a.constructor,
              n = a.c,
              i = a.s,
              o = a.e,
              c = new s("0.5");
            if (!n[0]) return new s(a);
            i < 0 && l(NaN),
              (i = Math.sqrt(a.toString())),
              0 === i || i === 1 / 0
                ? ((e = n.join("")),
                  (e.length + o) & 1 || (e += "0"),
                  (t = new s(Math.sqrt(e).toString())),
                  (t.e = (((o + 1) / 2) | 0) - (o < 0 || 1 & o)))
                : (t = new s(i.toString())),
              (i = t.e + (s.DP += 4));
            do {
              (r = t), (t = c.times(r.plus(a.div(r))));
            } while (r.c.slice(0, i).join("") !== t.c.slice(0, i).join(""));
            return u(t, (s.DP -= 4), s.RM), t;
          }),
          (m.mul = m.times = function(e) {
            var t,
              r = this,
              a = r.constructor,
              s = r.c,
              n = (e = new a(e)).c,
              i = s.length,
              o = n.length,
              u = r.e,
              l = e.e;
            if (((e.s = r.s == e.s ? 1 : -1), !s[0] || !n[0]))
              return new a(0 * e.s);
            for (
              e.e = u + l,
                i < o && ((t = s), (s = n), (n = t), (l = i), (i = o), (o = l)),
                t = new Array((l = i + o));
              l--;
              t[l] = 0
            );
            for (u = o; u--; ) {
              for (o = 0, l = i + u; l > u; )
                (o = t[l] + n[u] * s[l - u - 1] + o),
                  (t[l--] = o % 10),
                  (o = (o / 10) | 0);
              t[l] = (t[l] + o) % 10;
            }
            for (o && ++e.e, t[0] || t.shift(), u = t.length; !t[--u]; t.pop());
            return (e.c = t), e;
          }),
          (m.toString = m.valueOf = m.toJSON = function() {
            var e = this,
              t = e.constructor,
              r = e.e,
              a = e.c.join(""),
              s = a.length;
            if (r <= t.E_NEG || r >= t.E_POS)
              a =
                a.charAt(0) +
                (s > 1 ? "." + a.slice(1) : "") +
                (r < 0 ? "e" : "e+") +
                r;
            else if (r < 0) {
              for (; ++r; a = "0" + a);
              a = "0." + a;
            } else if (r > 0)
              if (++r > s) for (r -= s; r--; a += "0");
              else r < s && (a = a.slice(0, r) + "." + a.slice(r));
            else s > 1 && (a = a.charAt(0) + "." + a.slice(1));
            return e.s < 0 && e.c[0] ? "-" + a : a;
          }),
          (m.toExponential = function(e) {
            return (
              null == e
                ? (e = this.c.length - 1)
                : (e !== ~~e || e < 0 || e > 1e6) && l("!toExp!"),
              i(this, e, 1)
            );
          }),
          (m.toFixed = function(e) {
            var t,
              r = this,
              a = r.constructor,
              s = a.E_NEG,
              n = a.E_POS;
            return (
              (a.E_NEG = -(a.E_POS = 1 / 0)),
              null == e
                ? (t = r.toString())
                : e === ~~e &&
                  e >= 0 &&
                  e <= 1e6 &&
                  ((t = i(r, r.e + e)),
                  r.s < 0 && r.c[0] && t.indexOf("-") < 0 && (t = "-" + t)),
              (a.E_NEG = s),
              (a.E_POS = n),
              t || l("!toFix!"),
              t
            );
          }),
          (m.toPrecision = function(e) {
            return null == e
              ? this.toString()
              : ((e !== ~~e || e < 1 || e > 1e6) && l("!toPre!"),
                i(this, e - 1, 2));
          }),
          (c = n()),
          void 0 !==
            (a = function() {
              return c;
            }.call(t, r, t, e)) && (e.exports = a);
      })();
    },
    function(e, t, r) {
      "use strict";
      function a(e, t) {
        if (!(e instanceof t))
          throw new TypeError("Cannot call a class as a function");
      }
      var s = r(54),
        n = function e(t) {
          a(this, e);
          var n = r.i(s.a)();
          if (!n(t)) {
            var i = n.errors[0];
            throw "Slip invalid: " + i.dataPath + " " + i.message;
          }
          for (var o in t) t.hasOwnProperty(o) && (this[o] = t[o]);
        };
      (n.prototype.getSlipValidator = s.a), (t.a = n);
    },
    function(e, t, r) {
      "use strict";
      var a = r(76),
        s = r(77),
        n = r(78),
        i = r(79),
        o = r(81),
        u = (r(80), r(82)),
        l = r(83),
        c = r(84),
        h = r(85),
        f = r(86);
      r.d(t, "j", function() {
        return a.a;
      }),
        r.d(t, "i", function() {
          return s.a;
        }),
        r.d(t, "d", function() {
          return n.a;
        }),
        r.d(t, "b", function() {
          return i.a;
        }),
        r.d(t, "f", function() {
          return o.a;
        }),
        r.d(t, "g", function() {
          return u.a;
        }),
        r.d(t, "a", function() {
          return l.a;
        }),
        r.d(t, "e", function() {
          return c.a;
        }),
        r.d(t, "h", function() {
          return h.a;
        }),
        r.d(t, "c", function() {
          return f.a;
        });
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        function t(e, t) {
          1 === e && (e = "Single"),
            2 === e && (e = "Double"),
            3 === e && (e = "Treble"),
            e > 3 && (e += "-fold"),
            e in a || (a[e] = { win: 0, place: 0, lost: 0 }),
            t === u.WINNER
              ? a[e].win++
              : t === u.PLACED
              ? a[e].place++
              : t === u.LOSE && a[e].lost++;
        }
        var a = {},
          s = !1,
          n = !1,
          i = new o.a(e.stake);
        e.stakeType === u.TOTAL_COMBINED_STAKE && (i = new o.a(e.stake).div(2));
        var h = e.selections.reduce(function(e, t, a) {
          if (t.outcome === u.LOST) return (n = !0), !1;
          var h = new o.a(0),
            f = r.i(l.d)(t.odds, t.rule4),
            p = new o.a(0);
          p = 0 === a ? new o.a(i) : new o.a(e);
          var d = f
            .minus(1)
            .times(
              r
                .i(l.b)(t.place)
                .minus(1)
            )
            .plus(1);
          if (
            (t.outcome === u.PLACED && ((s = !0), (f = new o.a(0))),
            t.outcome === u.DEAD_HEAT)
          ) {
            var m = r.i(c.a)(t, f.plus(1), d, p);
            r
              .i(c.b)(t, !0)
              .tiedPosition.eq(1)
              ? 0 === a
                ? ((p = m), (p = p.plus(i.times(d))))
                : ((p = p.div(2).times(d)), (p = p.plus(m.div(2))))
              : (p = 0 === a ? m.times(d) : m.div(2).times(d));
          }
          if (t.outcome === u.WINNER || t.outcome === u.PLACED) {
            var v = f;
            return 0 === a
              ? ((h = p.times(v).plus(p.times(d))), e.plus(h))
              : ((h = p.div(2).times(v)), (h = h.plus(p.div(2).times(d))));
          }
          return t.outcome === u.VOID && 0 === a ? p.plus(i) : p;
        }, new o.a(0));
        return n
          ? (t(e.selections.length, u.LOSE), { returns: !1, bets: a })
          : (s
              ? t(e.selections.length, u.PLACED)
              : t(e.selections.length, u.WINNER),
            { returns: h, bets: a });
      }
      function s(e) {
        function t(e, t) {
          1 === e && (e = "Single"),
            2 === e && (e = "Double"),
            3 === e && (e = "Treble"),
            e > 3 && (e += "-fold"),
            e in s || (s[e] = { win: 0, place: 0, lost: 0 }),
            t === u.WINNER
              ? s[e].win++
              : t === u.PLACED
              ? s[e].place++
              : t === u.LOSE && s[e].lost++;
        }
        var a = new o.a(e.stake);
        e.stakeType === u.TOTAL_COMBINED_STAKE && (a = new o.a(e.stake).div(2));
        var s = {},
          n = !1,
          i = !1,
          h = (new o.a(0), e.selections),
          f = new o.a(a),
          p = new o.a(a);
        return (
          h.forEach(function(t) {
            if (t.outcome === u.LOSE) return (n = !0), !1;
            if (t.outcome === u.VOID) return !1;
            var a = r.i(l.d)(t.odds, t.rule4),
              s = r.i(l.d)(t.odds, 0),
              h = void 0;
            if (t.rule4 > 0) {
              var d = new o.a(t.rule4),
                m = parseInt(t.place.split("/")[1]);
              h = s
                .minus(1)
                .div(m)
                .times(new o.a(100).minus(d).div(100))
                .plus(1);
            } else
              h = a
                .minus(1)
                .times(
                  r
                    .i(l.b)(t.place)
                    .minus(1)
                )
                .plus(1);
            if (
              (console.log("place stake: " + p.toString()),
              t.outcome === u.DEAD_HEAT)
            ) {
              var v = (r.i(c.a)(t, a.plus(1), h, f),
                r.i(c.a)(t, a.minus(1), h, p)),
                g = r.i(c.b)(t, !0);
              g.tiedPosition.gt(g.placesOffered)
                ? ((f = new o.a(0)),
                  (p = new o.a(0)),
                  console.log("WIN STAKE: " + f.toString()),
                  console.log("PLACE STAKE: " + p.toString()))
                : g.tiedPosition.eq(1)
                ? g.deadHeatRunners.gt(g.placesOffered)
                  ? ((f = f.times(g.tiedPosition.div(g.deadHeatRunners))),
                    (f = f.times(s)),
                    (p = p.times(g.placesOffered.div(g.deadHeatRunners))),
                    (p = p.times(h)),
                    console.log("place odds: " + h.toString()))
                  : g.finishers.gt(g.placesOffered)
                  ? ((f = f.times(g.tiedPosition.div(g.deadHeatRunners))),
                    (f = f.times(s)),
                    (p = p.times(g.placesOffered.div(g.deadHeatRunners))),
                    (p = p.times(h)))
                  : ((f = f.times(g.tiedPosition.div(g.deadHeatRunners))),
                    (f = f.times(s)),
                    (p = p.times(h)))
                : ((f = new o.a(0)),
                  g.finishers.gt(g.placesOffered)
                    ? ((p = p.times(g.remainingPlaces.div(g.deadHeatRunners))),
                      (p = v.times(h)))
                    : (p = v.times(h)));
            }
            if (
              (t.outcome === u.PLACED && ((i = !0), (p = p.times(h))),
              t.outcome === u.WINNER)
            ) {
              var y = r.i(l.i)(e, t, a);
              (f = f.times(y)), (p = p.times(h));
            }
          }),
          n
            ? (t(e.selections.length, u.LOSE),
              (f = new o.a(0)),
              (p = new o.a(0)))
            : i
            ? t(e.selections.length, u.PLACED)
            : t(e.selections.length, u.WINNER),
          i && (f = new o.a(0)),
          (f = r.i(l.j)(e, f)),
          { returns: f.plus(p), bets: s }
        );
      }
      function n(e) {
        function t(e, t) {
          1 === e && (e = "Single"),
            2 === e && (e = "Double"),
            3 === e && (e = "Treble"),
            e > 3 && (e += "-fold"),
            e in n || (n[e] = { win: 0, place: 0, lost: 0 }),
            t === u.WINNER
              ? n[e].win++
              : t === u.PLACED
              ? n[e].place++
              : (t !== u.LOSE && t !== u.VOID) || n[e].lost++;
        }
        var n = {},
          i = !1,
          c = new o.a(0),
          h = e.selections,
          f = new o.a(e.stake);
        new o.a(e.stake);
        if (
          (e.stakeType === u.TOTAL_COMBINED_STAKE && new o.a(e.stake).div(2),
          e.selections.forEach(function(e) {
            e.outcome === u.VOID && t(1, u.VOID);
          }),
          !0 === e.eachWay && e.eachWayType === u.EACH_WAY_ALL_EACH_WAY)
        ) {
          var p = a(e);
          p.returns
            ? ((c = p.returns), (n = p.bets))
            : ((i = !0), (n = p.bets));
        } else if (
          !0 === e.eachWay &&
          e.eachWayType === u.WIN_TO_WIN_PLACE_TO_PLACE
        ) {
          var d = s(e);
          d.returns
            ? ((c = d.returns), (n = d.bets))
            : ((i = !0), (n = d.bets));
        } else
          (c = h.reduce(function(t, a) {
            var s = r.i(l.d)(a.odds, a.rule4);
            if (a.outcome === u.LOSE) return (i = !0), new o.a(0);
            if (a.outcome === u.VOID) return t;
            if (a.outcome === u.WINNER) {
              var n = r.i(l.i)(e, a, s);
              return t.times(n);
            }
            if (a.outcome === u.DEAD_HEAT) {
              if (!("deadHeatRunners" in a))
                throw new Error(
                  'Cannot calculate Dead Heat selection without "deadHeatRunners" prop'
                );
              var c = a.deadHeatRunners;
              t = t.div(c).times(s);
            }
            return t;
          }, f)),
            i
              ? ((c = new o.a(0)), t(e.selections.length, u.LOSE))
              : t(e.selections.length, u.WINNER);
        var m = new o.a(0);
        return (
          e.eachWay
            ? ((m = new o.a(e.stake).times(2)),
              e.stakeType === u.TOTAL_COMBINED_STAKE && (m = new o.a(e.stake)))
            : (m = new o.a(e.stake)),
          e.eachWay || (c = r.i(l.j)(e, c)),
          { returns: c, outlay: m, profit: c.minus(m), bets: n }
        );
      }
      var i = r(1),
        o = r.n(i),
        u = r(0),
        l = (r.n(u), r(3)),
        c = r(74);
      t.a = n;
    },
    function(e, t, r) {
      "use strict";
      function a(e, t) {
        t = t || {};
        for (var r in e) t[r] = e[r];
        return t;
      }
      function s(e, t, r) {
        var a = r ? " !== " : " === ",
          s = r ? " || " : " && ",
          n = r ? "!" : "",
          i = r ? "" : "!";
        switch (e) {
          case "null":
            return t + a + "null";
          case "array":
            return n + "Array.isArray(" + t + ")";
          case "object":
            return (
              "(" +
              n +
              t +
              s +
              "typeof " +
              t +
              a +
              '"object"' +
              s +
              i +
              "Array.isArray(" +
              t +
              "))"
            );
          case "integer":
            return (
              "(typeof " +
              t +
              a +
              '"number"' +
              s +
              i +
              "(" +
              t +
              " % 1)" +
              s +
              t +
              a +
              t +
              ")"
            );
          default:
            return "typeof " + t + a + '"' + e + '"';
        }
      }
      function n(e, t) {
        switch (e.length) {
          case 1:
            return s(e[0], t, !0);
          default:
            var r = "",
              a = o(e);
            a.array &&
              a.object &&
              ((r = a.null ? "(" : "(!" + t + " || "),
              (r += "typeof " + t + ' !== "object")'),
              delete a.null,
              delete a.array,
              delete a.object),
              a.number && delete a.integer;
            for (var n in a) r += (r ? " && " : "") + s(n, t, !0);
            return r;
        }
      }
      function i(e, t) {
        if (Array.isArray(t)) {
          for (var r = [], a = 0; a < t.length; a++) {
            var s = t[a];
            _[s]
              ? (r[r.length] = s)
              : "array" === e && "array" === s && (r[r.length] = s);
          }
          if (r.length) return r;
        } else {
          if (_[t]) return [t];
          if ("array" === e && "array" === t) return ["array"];
        }
      }
      function o(e) {
        for (var t = {}, r = 0; r < e.length; r++) t[e[r]] = !0;
        return t;
      }
      function u(e) {
        return "number" == typeof e
          ? "[" + e + "]"
          : x.test(e)
          ? "." + e
          : "['" + l(e) + "']";
      }
      function l(e) {
        return e
          .replace(R, "\\$&")
          .replace(/\n/g, "\\n")
          .replace(/\r/g, "\\r")
          .replace(/\f/g, "\\f")
          .replace(/\t/g, "\\t");
      }
      function c(e, t) {
        t += "[^0-9]";
        var r = e.match(new RegExp(t, "g"));
        return r ? r.length : 0;
      }
      function h(e, t, r) {
        return (
          (t += "([^0-9])"),
          (r = r.replace(/\$/g, "$$$$")),
          e.replace(new RegExp(t, "g"), r + "$1")
        );
      }
      function f(e) {
        return e
          .replace(A, "")
          .replace(D, "")
          .replace(k, "if (!($1))");
      }
      function p(e, t) {
        var r = e.match(T);
        return (
          r &&
            2 == r.length &&
            (e = t
              ? e.replace(j, "").replace(W, N)
              : e.replace(F, "").replace(I, $)),
          (r = e.match(C)),
          r && 3 === r.length ? e.replace(L, "") : e
        );
      }
      function d(e, t) {
        if ("boolean" == typeof e) return !e;
        for (var r in e) if (t[r]) return !0;
      }
      function m(e, t, r) {
        if ("boolean" == typeof e) return !e && "not" != r;
        for (var a in e) if (a != r && t[a]) return !0;
      }
      function v(e) {
        return "'" + l(e) + "'";
      }
      function g(e, t, r, a) {
        return E(
          e,
          r
            ? "'/' + " +
                t +
                (a ? "" : ".replace(/~/g, '~0').replace(/\\//g, '~1')")
            : a
            ? "'[' + " + t + " + ']'"
            : "'[\\'' + " + t + " + '\\']'"
        );
      }
      function y(e, t, r) {
        return E(e, v(r ? "/" + S(t) : u(t)));
      }
      function P(e, t, r) {
        var a, s, n, i;
        if ("" === e) return "rootData";
        if ("/" == e[0]) {
          if (!z.test(e)) throw new Error("Invalid JSON-pointer: " + e);
          (s = e), (n = "rootData");
        } else {
          if (!(i = e.match(M))) throw new Error("Invalid JSON-pointer: " + e);
          if (((a = +i[1]), "#" == (s = i[2]))) {
            if (a >= t)
              throw new Error(
                "Cannot access property/index " +
                  a +
                  " levels up, current level is " +
                  t
              );
            return r[t - a];
          }
          if (a > t)
            throw new Error(
              "Cannot access data " + a + " levels up, current level is " + t
            );
          if (((n = "data" + (t - a || "")), !s)) return n;
        }
        for (var o = n, l = s.split("/"), c = 0; c < l.length; c++) {
          var h = l[c];
          h && ((n += u(O(h))), (o += " && " + n));
        }
        return o;
      }
      function E(e, t) {
        return '""' == e ? t : (e + " + " + t).replace(/' \+ '/g, "");
      }
      function w(e) {
        return O(decodeURIComponent(e));
      }
      function b(e) {
        return encodeURIComponent(S(e));
      }
      function S(e) {
        return e.replace(/~/g, "~0").replace(/\//g, "~1");
      }
      function O(e) {
        return e.replace(/~1/g, "/").replace(/~0/g, "~");
      }
      e.exports = {
        copy: a,
        checkDataType: s,
        checkDataTypes: n,
        coerceToTypes: i,
        toHash: o,
        getProperty: u,
        escapeQuotes: l,
        equal: r(9),
        ucs2length: r(31),
        varOccurences: c,
        varReplace: h,
        cleanUpCode: f,
        finalCleanUpCode: p,
        schemaHasRules: d,
        schemaHasRulesExcept: m,
        toQuotedString: v,
        getPathExpr: g,
        getPath: y,
        getData: P,
        unescapeFragment: w,
        escapeFragment: b,
        escapeJsonPointer: S
      };
      var _ = o(["string", "number", "integer", "boolean", "null"]),
        x = /^[a-z$_][a-z$_0-9]*$/i,
        R = /'|\\/g,
        A = /else\s*{\s*}/g,
        D = /if\s*\([^)]+\)\s*\{\s*\}(?!\s*else)/g,
        k = /if\s*\(([^)]+)\)\s*\{\s*\}\s*else(?!\s*if)/g,
        T = /[^v\.]errors/g,
        F = /var errors = 0;|var vErrors = null;|validate.errors = vErrors;/g,
        j = /var errors = 0;|var vErrors = null;/g,
        I = "return errors === 0;",
        $ = "validate.errors = null; return true;",
        W = /if \(errors === 0\) return data;\s*else throw new ValidationError\(vErrors\);/,
        N = "return data;",
        C = /[^A-Za-z_$]rootData[^A-Za-z0-9_$]/g,
        L = /if \(rootData === undefined\) rootData = data;/,
        z = /^\/(?:[^~]|~0|~1)*$/,
        M = /^([0-9]+)(#|\/(?:[^~]|~0|~1)*)?$/;
    },
    function(e, t, r) {
      "use strict";
      var a = r(71),
        s = r(72),
        n = r(73);
      r.d(t, "a", function() {
        return a.a;
      }),
        r.d(t, "b", function() {
          return s.a;
        }),
        r.d(t, "c", function() {
          return n.a;
        });
    },
    function(e, t) {
      function r(e, t, r) {
        return void 0 === r || 0 == +r
          ? Math[e](t)
          : ((t = +t),
            (r = +r),
            isNaN(t) || "number" != typeof r || r % 1 != 0
              ? NaN
              : ((t = t.toString().split("e")),
                (t = Math[e](+(t[0] + "e" + (t[1] ? +t[1] - r : -r)))),
                (t = t.toString().split("e")),
                +(t[0] + "e" + (t[1] ? +t[1] + r : r))));
      }
      e.exports = r;
    },
    function(e, t, r) {
      var a, s, n;
      !(function(r, i) {
        (s = []),
          (a = i),
          void 0 !== (n = "function" == typeof a ? a.apply(t, s) : a) &&
            (e.exports = n);
      })(0, function() {
        "use strict";
        var e = function(e, t) {
            for (var r = 1; t--; ) r *= e--;
            return r;
          },
          t = function(t, r) {
            return r > t ? 0 : e(t, r) / e(r, r);
          },
          r = function(t) {
            return e(t, t);
          },
          a = function(e, t) {
            var a = 1;
            if (t) a = r(t);
            else {
              for (t = 1; a < e; a *= ++t);
              a > e && (a /= t--);
            }
            for (var s = [0]; t; a /= t--) (s[t] = Math.floor(e / a)), (e %= a);
            return s;
          },
          s = function(e, t) {
            Object.keys(t).forEach(function(r) {
              Object.defineProperty(e, r, {
                value: t[r],
                configurable: "next" == r
              });
            });
          },
          n = function(e, t) {
            Object.defineProperty(e, t, { writable: !0 });
          },
          i = function(e) {
            var t,
              r = [];
            for (this.init(); (t = this.next()); ) r.push(e ? e(t) : t);
            return this.init(), r;
          },
          o = {
            toArray: i,
            map: i,
            forEach: function(e) {
              var t;
              for (this.init(); (t = this.next()); ) e(t);
              this.init();
            },
            filter: function(e) {
              var t,
                r = [];
              for (this.init(); (t = this.next()); ) e(t) && r.push(t);
              return this.init(), r;
            },
            lazyMap: function(e) {
              return (this._lazyMap = e), this;
            },
            lazyFilter: function(e) {
              if (
                (Object.defineProperty(this, "next", { writable: !0 }),
                "function" != typeof e)
              )
                this.next = this._next;
              else {
                "function" != typeof this._next && (this._next = this.next);
                var t = this._next.bind(this);
                this.next = function() {
                  for (var r; (r = t()); ) if (e(r)) return r;
                  return r;
                }.bind(this);
              }
              return (
                Object.defineProperty(this, "next", { writable: !1 }), this
              );
            }
          },
          u = function(e, t) {
            var r = 1 << e.length,
              a = function() {
                return r;
              },
              i = Object.create(e.slice(), { length: { get: a } });
            return (
              n(i, "index"),
              s(i, {
                valueOf: a,
                init: function() {
                  i.index = 0;
                },
                nth: function(e) {
                  if (!(e >= r)) {
                    for (var t = 0, a = []; e; e >>>= 1, t++)
                      1 & e && a.push(this[t]);
                    return "function" == typeof i._lazyMap ? i._lazyMap(a) : a;
                  }
                },
                next: function() {
                  return this.nth(this.index++);
                }
              }),
              s(i, o),
              i.init(),
              "function" == typeof t ? i.map(t) : i
            );
          },
          l = function(e) {
            var t = e & -e,
              r = e + t;
            return r | ((((r & -r) / t) >> 1) - 1);
          },
          c = function(e, r, a) {
            if ((r || (r = e.length), r < 1)) throw new RangeError();
            if (r > e.length) throw new RangeError();
            var i = (1 << r) - 1,
              u = t(e.length, r),
              c = 1 << e.length,
              h = function() {
                return u;
              },
              f = Object.create(e.slice(), { length: { get: h } });
            return (
              n(f, "index"),
              s(f, {
                valueOf: h,
                init: function() {
                  this.index = i;
                },
                next: function() {
                  if (!(this.index >= c)) {
                    for (var e = 0, t = this.index, r = []; t; t >>>= 1, e++)
                      1 & t && (r[r.length] = this[e]);
                    return (
                      (this.index = l(this.index)),
                      "function" == typeof f._lazyMap ? f._lazyMap(r) : r
                    );
                  }
                }
              }),
              s(f, o),
              f.init(),
              "function" == typeof a ? f.map(a) : f
            );
          },
          h = function(e, t) {
            var r = e,
              a = t,
              s = 0;
            for (s = r.length - 1; s >= 0 && 1 == r[s]; s--) a--;
            if (0 == a) {
              r[r.length] = 1;
              for (var n = r.length - 2; n >= 0; n--) r[n] = n < t - 1 ? 1 : 0;
            } else {
              for (var i = -1, o = -1, s = 0; s < r.length; s++)
                if (
                  (0 == r[s] && -1 != i && (o = s),
                  1 == r[s] && (i = s),
                  -1 != o && -1 != i)
                ) {
                  (r[o] = 1), (r[i] = 0);
                  break;
                }
              a = t;
              for (var s = r.length - 1; s >= i; s--) 1 == r[s] && a--;
              for (var s = 0; s < i; s++) r[s] = s < a ? 1 : 0;
            }
            return r;
          },
          f = function(e) {
            for (var t = [], r = 0; r < e; r++) t[r] = 1;
            return (t[0] = 1), t;
          },
          p = function(e, r, a) {
            if ((r || (r = e.length), r < 1)) throw new RangeError();
            if (r > e.length) throw new RangeError();
            var i = f(r),
              u = t(e.length, r),
              l = e.length,
              c = function() {
                return u;
              },
              p = Object.create(e.slice(), { length: { get: c } });
            return (
              n(p, "index"),
              s(p, {
                valueOf: c,
                init: function() {
                  this.index = i.concat();
                },
                next: function() {
                  if (!(this.index.length > l)) {
                    for (
                      var e = 0, t = this.index, a = [], s = 0;
                      s < t.length;
                      s++, e++
                    )
                      t[s] && (a[a.length] = this[e]);
                    return (
                      h(this.index, r),
                      "function" == typeof p._lazyMap ? p._lazyMap(a) : a
                    );
                  }
                }
              }),
              s(p, o),
              p.init(),
              "function" == typeof a ? p.map(a) : p
            );
          },
          d = function(e) {
            var t = e.slice(),
              s = r(t.length);
            return (
              (t.index = 0),
              (t.next = function() {
                if (!(this.index >= s)) {
                  for (
                    var e = this.slice(),
                      r = a(this.index, this.length),
                      n = [],
                      i = this.length - 1;
                    i >= 0;
                    --i
                  )
                    n.push(e.splice(r[i], 1)[0]);
                  return (
                    this.index++,
                    "function" == typeof t._lazyMap ? t._lazyMap(n) : n
                  );
                }
              }),
              t
            );
          },
          m = function(t, r, a) {
            if ((r || (r = t.length), r < 1)) throw new RangeError();
            if (r > t.length) throw new RangeError();
            var i = e(t.length, r),
              u = function() {
                return i;
              },
              l = Object.create(t.slice(), { length: { get: u } });
            return (
              n(l, "cmb"),
              n(l, "per"),
              s(l, {
                valueOf: function() {
                  return i;
                },
                init: function() {
                  (this.cmb = c(t, r)), (this.per = d(this.cmb.next()));
                },
                next: function() {
                  var e = this.per.next();
                  if (!e) {
                    var t = this.cmb.next();
                    if (!t) return;
                    return (this.per = d(t)), this.next();
                  }
                  return "function" == typeof l._lazyMap ? l._lazyMap(e) : e;
                }
              }),
              s(l, o),
              l.init(),
              "function" == typeof a ? l.map(a) : l
            );
          },
          v = function(t) {
            for (var r = 0, a = 1; a <= t; a++) {
              r += e(t, a);
            }
            return r;
          },
          g = function(e, t) {
            var r = v(e.length),
              a = function() {
                return r;
              },
              i = Object.create(e.slice(), { length: { get: a } });
            return (
              n(i, "cmb"),
              n(i, "per"),
              n(i, "nelem"),
              s(i, {
                valueOf: function() {
                  return r;
                },
                init: function() {
                  (this.nelem = 1),
                    (this.cmb = c(e, this.nelem)),
                    (this.per = d(this.cmb.next()));
                },
                next: function() {
                  var t = this.per.next();
                  if (!t) {
                    var r = this.cmb.next();
                    if (!r) {
                      if (++this.nelem > e.length) return;
                      if (
                        ((this.cmb = c(e, this.nelem)), !(r = this.cmb.next()))
                      )
                        return;
                    }
                    return (this.per = d(r)), this.next();
                  }
                  return "function" == typeof i._lazyMap ? i._lazyMap(t) : t;
                }
              }),
              s(i, o),
              i.init(),
              "function" == typeof t ? i.map(t) : i
            );
          },
          y = Array.prototype.slice,
          P = function() {
            if (!arguments.length) throw new RangeError();
            var e = y.call(arguments),
              t = e.reduce(function(e, t) {
                return e * t.length;
              }, 1),
              r = function() {
                return t;
              },
              a = e.length,
              i = Object.create(e, { length: { get: r } });
            if (!t) throw new RangeError();
            return (
              n(i, "index"),
              s(i, {
                valueOf: r,
                dim: a,
                init: function() {
                  this.index = 0;
                },
                get: function() {
                  if (arguments.length === this.length) {
                    for (var e = [], t = 0; t < a; t++) {
                      var r = arguments[t];
                      if (r >= this[t].length) return;
                      e.push(this[t][r]);
                    }
                    return "function" == typeof i._lazyMap ? i._lazyMap(e) : e;
                  }
                },
                nth: function(e) {
                  for (var t = [], r = 0; r < a; r++) {
                    var s = this[r].length,
                      n = e % s;
                    t.push(this[r][n]), (e -= n), (e /= s);
                  }
                  return "function" == typeof i._lazyMap ? i._lazyMap(t) : t;
                },
                next: function() {
                  if (!(this.index >= t)) {
                    var e = this.nth(this.index);
                    return this.index++, e;
                  }
                }
              }),
              s(i, o),
              i.init(),
              i
            );
          },
          E = function(e, t, r) {
            if ((t || (t = e.length), t < 1)) throw new RangeError();
            var a = e.length,
              i = Math.pow(a, t),
              u = function() {
                return i;
              },
              l = Object.create(e.slice(), { length: { get: u } });
            return (
              n(l, "index"),
              s(l, {
                valueOf: u,
                init: function() {
                  l.index = 0;
                },
                nth: function(r) {
                  if (!(r >= i)) {
                    for (var s = [], n = 0; n < t; n++) {
                      var o = r % a;
                      s.push(e[o]), (r -= o), (r /= a);
                    }
                    return "function" == typeof l._lazyMap ? l._lazyMap(s) : s;
                  }
                },
                next: function() {
                  return this.nth(this.index++);
                }
              }),
              s(l, o),
              l.init(),
              "function" == typeof r ? l.map(r) : l
            );
          },
          w = Object.create(null);
        return (
          s(w, {
            C: t,
            P: e,
            factorial: r,
            factoradic: a,
            cartesianProduct: P,
            combination: c,
            bigCombination: p,
            permutation: m,
            permutationCombination: g,
            power: u,
            baseN: E,
            VERSION: "0.5.2"
          }),
          w
        );
      });
    },
    function(e, t, r) {
      "use strict";
      e.exports = function e(t, r) {
        if (t === r) return !0;
        var a,
          s = Array.isArray(t),
          n = Array.isArray(r);
        if (s && n) {
          if (t.length != r.length) return !1;
          for (a = 0; a < t.length; a++) if (!e(t[a], r[a])) return !1;
          return !0;
        }
        if (s != n) return !1;
        if (t && r && "object" == typeof t && "object" == typeof r) {
          var i = Object.keys(t);
          if (i.length !== Object.keys(r).length) return !1;
          var o = t instanceof Date,
            u = r instanceof Date;
          if (o && u) return t.getTime() == r.getTime();
          if (o != u) return !1;
          var l = t instanceof RegExp,
            c = r instanceof RegExp;
          if (l && c) return t.toString() == r.toString();
          if (l != c) return !1;
          for (a = 0; a < i.length; a++)
            if (!Object.prototype.hasOwnProperty.call(r, i[a])) return !1;
          for (a = 0; a < i.length; a++) if (!e(t[i[a]], r[i[a]])) return !1;
          return !0;
        }
        return !1;
      };
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        (this.message = "validation failed"),
          (this.errors = e),
          (this.ajv = this.validation = !0);
      }
      function s(e, t, r) {
        (this.message = r || s.message(e, t)),
          (this.missingRef = i.url(e, t)),
          (this.missingSchema = i.normalizeId(i.fullPath(this.missingRef)));
      }
      function n(e) {
        return (
          (e.prototype = Object.create(Error.prototype)),
          (e.prototype.constructor = e),
          e
        );
      }
      var i = r(11);
      (e.exports = { Validation: n(a), MissingRef: n(s) }),
        (s.message = function(e, t) {
          return "can't resolve reference " + t + " from id " + e;
        });
    },
    function(e, t, r) {
      "use strict";
      function a(e, t, r) {
        var n = this._refs[r];
        if ("string" == typeof n) {
          if (!this._refs[n]) return a.call(this, e, t, n);
          n = this._refs[n];
        }
        if ((n = n || this._schemas[r]) instanceof y)
          return o(n.schema, this._opts.inlineRefs)
            ? n.schema
            : n.validate || this._compile(n);
        var i,
          u,
          l,
          c = s.call(this, t, r);
        return (
          c && ((i = c.schema), (t = c.root), (l = c.baseId)),
          i instanceof y
            ? (u = i.validate || e.call(this, i.schema, t, void 0, l))
            : void 0 !== i &&
              (u = o(i, this._opts.inlineRefs)
                ? i
                : e.call(this, i, t, void 0, l)),
          u
        );
      }
      function s(e, t) {
        var r = m.parse(t, !1, !0),
          a = h(r),
          s = c(this._getId(e.schema));
        if (a !== s) {
          var o = f(a),
            u = this._refs[o];
          if ("string" == typeof u) return n.call(this, e, u, r);
          if (u instanceof y) u.validate || this._compile(u), (e = u);
          else {
            if (!((u = this._schemas[o]) instanceof y)) return;
            if ((u.validate || this._compile(u), o == f(t)))
              return { schema: u, root: e, baseId: s };
            e = u;
          }
          if (!e.schema) return;
          s = c(this._getId(e.schema));
        }
        return i.call(this, r, s, e.schema, e);
      }
      function n(e, t, r) {
        var a = s.call(this, e, t);
        if (a) {
          var n = a.schema,
            o = a.baseId;
          e = a.root;
          var u = this._getId(n);
          return u && (o = p(o, u)), i.call(this, r, o, n, e);
        }
      }
      function i(e, t, r, a) {
        if (((e.hash = e.hash || ""), "#/" == e.hash.slice(0, 2))) {
          for (var n = e.hash.split("/"), i = 1; i < n.length; i++) {
            var o = n[i];
            if (o) {
              if (((o = g.unescapeFragment(o)), void 0 === (r = r[o]))) break;
              var u;
              if (!P[o] && ((u = this._getId(r)), u && (t = p(t, u)), r.$ref)) {
                var l = p(t, r.$ref),
                  c = s.call(this, a, l);
                c && ((r = c.schema), (a = c.root), (t = c.baseId));
              }
            }
          }
          return void 0 !== r && r !== a.schema
            ? { schema: r, root: a, baseId: t }
            : void 0;
        }
      }
      function o(e, t) {
        return (
          !1 !== t && (void 0 === t || !0 === t ? u(e) : t ? l(e) <= t : void 0)
        );
      }
      function u(e) {
        var t;
        if (Array.isArray(e)) {
          for (var r = 0; r < e.length; r++)
            if ("object" == typeof (t = e[r]) && !u(t)) return !1;
        } else
          for (var a in e) {
            if ("$ref" == a) return !1;
            if ("object" == typeof (t = e[a]) && !u(t)) return !1;
          }
        return !0;
      }
      function l(e) {
        var t,
          r = 0;
        if (Array.isArray(e)) {
          for (var a = 0; a < e.length; a++)
            if (((t = e[a]), "object" == typeof t && (r += l(t)), r == 1 / 0))
              return 1 / 0;
        } else
          for (var s in e) {
            if ("$ref" == s) return 1 / 0;
            if (E[s]) r++;
            else if (
              ((t = e[s]), "object" == typeof t && (r += l(t) + 1), r == 1 / 0)
            )
              return 1 / 0;
          }
        return r;
      }
      function c(e, t) {
        return !1 !== t && (e = f(e)), h(m.parse(e, !1, !0));
      }
      function h(e) {
        var t = e.protocol || "//" == e.href.slice(0, 2) ? "//" : "";
        return (e.protocol || "") + t + (e.host || "") + (e.path || "") + "#";
      }
      function f(e) {
        return e ? e.replace(w, "") : "";
      }
      function p(e, t) {
        return (t = f(t)), m.resolve(e, t);
      }
      function d(e) {
        function t(e, r, s) {
          if (Array.isArray(e))
            for (var n = 0; n < e.length; n++)
              t.call(this, e[n], r + "/" + n, s);
          else if (e && "object" == typeof e) {
            var i = this._getId(e);
            if ("string" == typeof i) {
              i = s = f(s ? m.resolve(s, i) : i);
              var o = this._refs[i];
              if (
                ("string" == typeof o && (o = this._refs[o]), o && o.schema)
              ) {
                if (!v(e, o.schema))
                  throw new Error(
                    'id "' + i + '" resolves to more than one schema'
                  );
              } else if (i != f(r))
                if ("#" == i[0]) {
                  if (a[i] && !v(e, a[i]))
                    throw new Error(
                      'id "' + i + '" resolves to more than one schema'
                    );
                  a[i] = e;
                } else this._refs[i] = r;
            }
            for (var u in e)
              t.call(this, e[u], r + "/" + g.escapeFragment(u), s);
          }
        }
        var r = f(this._getId(e)),
          a = {};
        return t.call(this, e, c(r, !1), r), a;
      }
      var m = r(95),
        v = r(9),
        g = r(5),
        y = r(13);
      (e.exports = a),
        (a.normalizeId = f),
        (a.fullPath = c),
        (a.url = p),
        (a.ids = d),
        (a.inlineRef = o),
        (a.schema = s);
      var P = g.toHash([
          "properties",
          "patternProperties",
          "enum",
          "dependencies",
          "definitions"
        ]),
        E = g.toHash([
          "type",
          "format",
          "pattern",
          "maxLength",
          "minLength",
          "maxProperties",
          "minProperties",
          "maxItems",
          "minItems",
          "maximum",
          "minimum",
          "uniqueItems",
          "multipleOf",
          "required",
          "enum"
        ]),
        w = /#\/?$/;
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        function t(e, t) {
          1 === e && (e = "Single"),
            2 === e && (e = "Double"),
            3 === e && (e = "Treble"),
            e > 3 && (e += "-fold"),
            e in n || (n[e] = { win: 0, place: 0, lost: 0 }),
            t === c.WINNER
              ? n[e].win++
              : t === c.PLACED
              ? n[e].place++
              : t === c.LOSE && n[e].lost++;
        }
        var a = e.selections,
          s = void 0,
          n = {};
        a.forEach(function(e) {
          t("SSA", e.outcome);
        });
        var u = e.stake;
        e.stakeType === c.TOTAL_COMBINED_STAKE &&
          (u = parseFloat(
            new l.a(e.stake).div(new l.a(e.selections.length)).toString()
          ));
        var p = a.filter(h.f);
        s =
          p.length < 1
            ? new l.a(0)
            : e.eachWay
            ? i(e)
            : 1 === p.length
            ? p.map(function(e) {
                return r.i(
                  o.a
                )(new f.a({ betType: "accumulator", eachWay: !1, stake: u, selections: [e] })).profit;
              })[0]
            : p
                .map(function(e, t) {
                  var a = r.i(o.a)(
                    new f.a({
                      betType: "accumulator",
                      eachWay: !1,
                      stake: u,
                      selections: [e]
                    })
                  ).returns;
                  0 === t && e.outcome !== c.VOID && (a = a.minus(u));
                  var s = {
                    outcome: e.outcome,
                    place: e.place,
                    rule4: e.rule4
                  };
                  (s.odds =
                    1 === t
                      ? r
                          .i(h.d)(e.odds)
                          .minus(1)
                          .toString()
                      : r
                          .i(h.d)(e.odds)
                          .toString()),
                    e.outcome === c.DEAD_HEAT &&
                      ("placesOffered" in e &&
                        (s.placesOffered = e.placesOffered),
                      "tiedPosition" in e && (s.tiedPosition = e.tiedPosition),
                      "deadHeatRunners" in e &&
                        (s.deadHeatRunners = e.deadHeatRunners));
                  var n = r.i(o.a)(
                    new f.a({
                      betType: "accumulator",
                      eachWay: !1,
                      stake: u,
                      selections: [s]
                    })
                  ).returns;
                  return a.plus(n);
                })
                .reduce(function(e, t) {
                  return t.plus(e);
                });
        var d = new l.a(0);
        return (
          e.stakeType === c.TOTAL_COMBINED_STAKE
            ? (d = new l.a(e.stake))
            : e.stakeType === c.STAKE_PER_BET &&
              (d = e.eachWay
                ? new l.a(e.stake).times(2).times(2)
                : new l.a(e.stake).times(2)),
          { returns: s, profit: s.minus(d), outlay: d, bets: n }
        );
      }
      function s(e, t) {
        if (e.outcome === c.DEAD_HEAT) {
          if (t) {
            if (!("placesOffered" in e))
              throw new Error(
                'Cannot calculate Dead Heat Each Way selection without "placesOffered" prop'
              );
            if (!("tiedPosition" in e))
              throw new Error(
                'Cannot calculate Dead Heat Each Way selection without "tiedPosition" prop'
              );
          }
          if (!("deadHeatRunners" in e))
            throw new Error(
              'Cannot calculate Dead Heat Each Way selection without "deadHeatRunners" prop'
            );
          var r = new l.a(0),
            a = new l.a(0),
            s = new l.a(0),
            n = new l.a(0),
            i = new l.a(0);
          return (
            (s = new l.a(e.deadHeatRunners)),
            t &&
              ((r = new l.a(e.placesOffered)),
              (a = new l.a(e.tiedPosition)),
              (n = r.minus(a).plus(1)),
              (i = a.plus(s).minus(1))),
            {
              placesOffered: r,
              tiedPosition: a,
              deadHeatRunners: s,
              remainingPlaces: n,
              finishers: i
            }
          );
        }
        return !1;
      }
      function n(e, t, r, a) {
        var n = s(e, !0),
          i = new l.a(1),
          o = new l.a(a);
        if (((t = t.minus(1)), (r = r.minus(1)), n.tiedPosition.eq(1)))
          if (n.deadHeatRunners.lte(n.placesOffered))
            o = o.div(n.deadHeatRunners);
          else {
            i = n.remainingPlaces.div(n.deadHeatRunners);
            var u = i.times(o);
            o = u.times(t);
          }
        else
          n.finishers.gte(n.placesOffered) &&
            ((i = n.remainingPlaces.div(n.deadHeatRunners)), (o = i.times(o)));
        return o;
      }
      function i(e) {
        var t = new l.a(e.stake);
        e.stakeType === c.TOTAL_COMBINED_STAKE &&
          (t = t.div(new l.a(e.selections.length).times(2)));
        var a = t,
          i = a.times(2),
          o = new l.a(0),
          u = new l.a(0),
          f = new l.a(0),
          p = e.selections[0],
          d = r.i(h.d)(p.odds),
          m = r.i(h.d)(p.place),
          v = void 0,
          g = void 0;
        p.outcome === c.WINNER &&
          ((o = d.minus(1).times(t)),
          (u = m
            .minus(1)
            .times(d.minus(1))
            .times(t)));
        var y = void 0;
        if (p.outcome === c.DEAD_HEAT) {
          y = n(p, d, m, t);
          var P = s(p, !0);
          P.tiedPosition.eq(1)
            ? P.deadHeatRunners.gt(P.placesOffered)
              ? ((v = t.times(P.tiedPosition.div(P.deadHeatRunners))),
                (g = t.times(P.placesOffered.div(P.deadHeatRunners))),
                (o = d.minus(1).times(v)),
                (u = m
                  .minus(1)
                  .times(d.minus(1))
                  .times(g)))
              : ((o = d.minus(1).times(y)),
                (u = m
                  .minus(1)
                  .times(d.minus(1))
                  .times(t)))
            : (u = m
                .minus(1)
                .times(d.minus(1))
                .times(y));
        }
        p.outcome === c.PLACED &&
          (u = m
            .minus(1)
            .times(d.minus(1))
            .times(t)),
          p.outcome === c.LOSE && (t = new l.a(0)),
          (f = u.add(o)),
          p.outcome === c.VOID && (f = new l.a(0));
        var E = p.outcome;
        if (p.outcome === c.DEAD_HEAT) {
          var w = s(p, !0);
          w.tiedPosition.gt(w.placesOffered)
            ? ((t = new l.a(0)), (f = new l.a(0)))
            : w.tiedPosition.eq(1)
            ? w.deadHeatRunners.gt(w.placesOffered)
              ? w.finishers.gt(w.placesOffered)
                ? f.plus(v.plus(g)).gt(i)
                  ? (f = f.plus(v.plus(g)).minus(i))
                  : ((t = f.plus(v.plus(g)).div(2)), (f = new l.a(0)))
                : f
                    .plus(y)
                    .plus(t)
                    .gt(i)
                ? (f = f
                    .plus(y)
                    .plus(t)
                    .minus(i))
                : ((t = f
                    .plus(y)
                    .plus(t)
                    .div(2)),
                  (f = new l.a(0)))
              : w.finishers.gt(w.placesOffered)
              ? f.plus(y.times(2)).gt(i)
                ? (f = f.plus(y.times(2)).minus(i))
                : ((t = f.plus(y.times(2)).div(2)), (f = new l.a(0)))
              : f
                  .plus(y)
                  .plus(t)
                  .gt(i)
              ? (f = f
                  .plus(y)
                  .plus(t)
                  .minus(i))
              : ((t = f
                  .plus(y)
                  .plus(t)
                  .div(2)),
                (f = new l.a(0)))
            : w.finishers.gt(w.placesOffered)
            ? f.plus(y).gt(i)
              ? (f = f.plus(y).minus(i))
              : ((t = f.plus(y).div(2)), (f = new l.a(0)))
            : f.plus(t).gt(i)
            ? (f = f.plus(t).minus(i))
            : ((t = f.plus(t).div(2)), (f = new l.a(0)));
        }
        console.log("total 1: " + f.toString()),
          E === c.PLACED
            ? f.plus(t).gte(i)
              ? ((f = f.plus(t).minus(i)), (t = a))
              : ((t = f.plus(t).div(2)), (f = new l.a(0)))
            : E === c.WINNER && (t = a);
        var b = new l.a(0),
          S = new l.a(0),
          O = new l.a(0),
          _ = e.selections[1],
          x = r.i(h.d)(_.odds),
          R = r.i(h.d)(_.place),
          A = void 0,
          D = void 0,
          k = void 0;
        if (
          (_.outcome === c.WINNER &&
            ((b = x.minus(1).times(t)),
            (S = R.minus(1)
              .times(x.minus(1))
              .times(t))),
          _.outcome === c.DEAD_HEAT)
        ) {
          A = n(_, x, R, t);
          var T = s(_, !0);
          T.tiedPosition.eq(1)
            ? T.deadHeatRunners.gt(T.placesOffered)
              ? ((D = t.times(T.tiedPosition.div(T.deadHeatRunners))),
                (k = t.times(T.placesOffered.div(T.deadHeatRunners))),
                (b = x.minus(1).times(D)),
                (S = R.minus(1)
                  .times(x.minus(1))
                  .times(k)))
              : ((b = x.minus(1).times(A)),
                (S = R.minus(1)
                  .times(x.minus(1))
                  .times(t)))
            : (S = R.minus(1)
                .times(x.minus(1))
                .times(A));
        }
        if (
          (_.outcome === c.PLACED &&
            (S = R.minus(1)
              .times(x.minus(1))
              .times(t)),
          (O = S.add(b)),
          _.outcome === c.WINNER)
        )
          O = O.plus(t.times(2));
        else if (_.outcome === c.PLACED) O = O.plus(t);
        else if (_.outcome === c.DEAD_HEAT) {
          var F = s(_, !0);
          F.tiedPosition.gt(F.placesOffered)
            ? ((t = new l.a(0)), (O = new l.a(0)))
            : (O = F.tiedPosition.eq(1)
                ? F.deadHeatRunners.gt(F.placesOffered)
                  ? F.finishers.gt(F.placesOffered)
                    ? (O.plus(D.plus(k)).gt(i), O.plus(D.plus(k)))
                    : (O.plus(A)
                        .plus(t)
                        .gt(i),
                      O.plus(A).plus(t))
                  : F.finishers.gt(F.placesOffered)
                  ? (O.plus(A.times(2)).gt(i), O.plus(A.times(2)))
                  : (O.plus(A)
                      .plus(t)
                      .gt(i),
                    O.plus(A).plus(t))
                : F.finishers.gt(F.placesOffered)
                ? (O.plus(A).gt(i), O.plus(A))
                : (O.plus(t).gt(i), O.plus(t)));
        }
        _.outcome === c.VOID && (O = t.times(2)),
          _.outcome === c.LOSE && (O = new l.a(0)),
          console.log("total 2: " + O.toString()),
          (t = a);
        var j = new l.a(0),
          I = new l.a(0),
          $ = new l.a(0),
          W = e.selections[1],
          N = r.i(h.d)(W.odds),
          C = r.i(h.d)(W.place),
          L = void 0,
          z = void 0;
        W.outcome === c.WINNER &&
          ((j = N.minus(1).times(t)),
          (I = C.minus(1)
            .times(N.minus(1))
            .times(t)));
        var M = void 0;
        if (W.outcome === c.DEAD_HEAT) {
          M = n(W, N, C, t);
          var H = s(W, !0);
          H.tiedPosition.eq(1)
            ? H.deadHeatRunners.gt(H.placesOffered)
              ? ((L = t.times(H.tiedPosition.div(H.deadHeatRunners))),
                (z = t.times(H.placesOffered.div(H.deadHeatRunners))),
                (j = N.minus(1).times(L)),
                (I = C.minus(1)
                  .times(N.minus(1))
                  .times(z)))
              : ((j = N.minus(1).times(M)),
                (I = C.minus(1)
                  .times(N.minus(1))
                  .times(t)))
            : (I = C.minus(1)
                .times(N.minus(1))
                .times(M));
        }
        W.outcome === c.PLACED &&
          (I = C.minus(1)
            .times(N.minus(1))
            .times(t)),
          W.outcome === c.LOSE && (t = new l.a(0)),
          ($ = I.add(j)),
          W.outcome === c.VOID && ($ = new l.a(0));
        var q = W.outcome;
        if (_.outcome === c.DEAD_HEAT) {
          var V = s(_, !0);
          V.tiedPosition.gt(V.placesOffered)
            ? ((t = new l.a(0)), ($ = new l.a(0)))
            : V.tiedPosition.eq(1)
            ? V.deadHeatRunners.gt(V.placesOffered)
              ? V.finishers.gt(V.placesOffered)
                ? $.plus(L.plus(z)).gt(i)
                  ? ($ = $.plus(L.plus(z)).minus(i))
                  : ((t = $.plus(L.plus(z)).div(2)), ($ = new l.a(0)))
                : $.plus(M)
                    .plus(t)
                    .gt(i)
                ? ($ = $.plus(M)
                    .plus(t)
                    .minus(i))
                : ((t = $.plus(M)
                    .plus(t)
                    .div(2)),
                  ($ = new l.a(0)))
              : V.finishers.gt(V.placesOffered)
              ? $.plus(M.times(2)).gt(i)
                ? ($ = $.plus(M.times(2)).minus(i))
                : ((t = $.plus(M.times(2)).div(2)), ($ = new l.a(0)))
              : $.plus(M)
                  .plus(t)
                  .gt(i)
              ? ($ = $.plus(M)
                  .plus(t)
                  .minus(i))
              : ((t = $.plus(M)
                  .plus(t)
                  .div(2)),
                ($ = new l.a(0)))
            : V.finishers.gt(V.placesOffered)
            ? $.plus(M).gt(i)
              ? ($ = $.plus(M).minus(i))
              : ((t = $.plus(M).div(2)), ($ = new l.a(0)))
            : $.plus(t).gt(i)
            ? ($ = $.plus(t).minus(i))
            : ((t = $.plus(t).div(2)), ($ = new l.a(0)));
        }
        q === c.PLACED
          ? $.plus(t).gte(i)
            ? (($ = $.plus(t).minus(i)), (t = a))
            : ((t = $.plus(t).div(2)), ($ = new l.a(0)))
          : q === c.WINNER && (t = a),
          console.log("up total 2: " + $.toString());
        var B = new l.a(0),
          Q = new l.a(0),
          U = new l.a(0),
          K = e.selections[0],
          J = r.i(h.d)(K.odds),
          G = r.i(h.d)(K.place),
          Y = void 0,
          Z = void 0;
        K.outcome === c.WINNER &&
          ((B = J.minus(1).times(t)),
          (Q = G.minus(1)
            .times(J.minus(1))
            .times(t)));
        var X = void 0;
        if (K.outcome === c.DEAD_HEAT) {
          X = n(K, J, G, t);
          var ee = s(K, !0);
          ee.tiedPosition.eq(1)
            ? ee.deadHeatRunners.gt(ee.placesOffered)
              ? ((Y = t.times(ee.tiedPosition.div(ee.deadHeatRunners))),
                (Z = t.times(ee.placesOffered.div(ee.deadHeatRunners))),
                (B = J.minus(1).times(Y)),
                (Q = G.minus(1)
                  .times(J.minus(1))
                  .times(Z)))
              : ((B = J.minus(1).times(X)),
                (Q = G.minus(1)
                  .times(J.minus(1))
                  .times(t)))
            : (Q = G.minus(1)
                .times(J.minus(1))
                .times(X));
        }
        if (
          (K.outcome === c.PLACED &&
            (Q = G.minus(1)
              .times(J.minus(1))
              .times(t)),
          (U = Q.add(B)),
          K.outcome === c.WINNER)
        )
          U = U.plus(t.times(2));
        else if (K.outcome === c.PLACED) U = U.plus(t);
        else if (K.outcome === c.DEAD_HEAT) {
          var te = s(K, !0);
          te.tiedPosition.gt(te.placesOffered)
            ? ((t = new l.a(0)), (U = new l.a(0)))
            : (U = te.tiedPosition.eq(1)
                ? te.deadHeatRunners.gt(te.placesOffered)
                  ? te.finishers.gt(te.placesOffered)
                    ? (U.plus(Y.plus(Z)).gt(i), U.plus(Y.plus(Z)))
                    : (U.plus(X)
                        .plus(t)
                        .gt(i),
                      U.plus(X).plus(t))
                  : te.finishers.gt(te.placesOffered)
                  ? (U.plus(ddeadHeatStakeUp1.times(2)).gt(i),
                    f.plus(X.times(2)))
                  : (U.plus(X)
                      .plus(t)
                      .gt(i),
                    U.plus(X).plus(t))
                : te.finishers.gt(te.placesOffered)
                ? (U.plus(X).gt(i), U.plus(X))
                : (U.plus(t).gt(i), U.plus(t)));
        }
        console.log("up total 1: " + U.toString()),
          K.outcome === c.VOID && (U = t.times(2)),
          K.outcome === c.LOSE && (U = new l.a(0));
        var re = U.plus(f),
          ae = $.plus(O);
        return re.plus(ae);
      }
      var o = r(4),
        u = r(1),
        l = r.n(u),
        c = r(0),
        h = (r.n(c), r(3)),
        f = r(2);
      t.a = a;
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        s.copy(e, this);
      }
      var s = r(5);
      e.exports = a;
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a,
          s,
          n = " ",
          i = e.level,
          o = e.dataLevel,
          u = e.schema[t],
          l = e.schemaPath + e.util.getProperty(t),
          c = e.errSchemaPath + "/" + t,
          h = !e.opts.allErrors,
          f = "data" + (o || ""),
          p = e.opts.$data && u && u.$data;
        p
          ? ((n +=
              " var schema" +
              i +
              " = " +
              e.util.getData(u.$data, o, e.dataPathArr) +
              "; "),
            (s = "schema" + i))
          : (s = u);
        var d = "maximum" == t,
          m = d ? "exclusiveMaximum" : "exclusiveMinimum",
          v = e.schema[m],
          g = e.opts.$data && v && v.$data,
          y = d ? "<" : ">",
          P = d ? ">" : "<",
          a = void 0;
        if (g) {
          var E = e.util.getData(v.$data, o, e.dataPathArr),
            w = "exclusive" + i,
            b = "exclType" + i,
            S = "exclIsNumber" + i,
            O = "op" + i,
            _ = "' + " + O + " + '";
          (n += " var schemaExcl" + i + " = " + E + "; "),
            (E = "schemaExcl" + i),
            (n +=
              " var " +
              w +
              "; var " +
              b +
              " = typeof " +
              E +
              "; if (" +
              b +
              " != 'boolean' && " +
              b +
              " != 'undefined' && " +
              b +
              " != 'number') { ");
          var a = m,
            x = x || [];
          x.push(n),
            (n = ""),
            !1 !== e.createErrors
              ? ((n +=
                  " { keyword: '" +
                  (a || "_exclusiveLimit") +
                  "' , dataPath: (dataPath || '') + " +
                  e.errorPath +
                  " , schemaPath: " +
                  e.util.toQuotedString(c) +
                  " , params: {} "),
                !1 !== e.opts.messages &&
                  (n += " , message: '" + m + " should be boolean' "),
                e.opts.verbose &&
                  (n +=
                    " , schema: validate.schema" +
                    l +
                    " , parentSchema: validate.schema" +
                    e.schemaPath +
                    " , data: " +
                    f +
                    " "),
                (n += " } "))
              : (n += " {} ");
          var R = n;
          (n = x.pop()),
            !e.compositeRule && h
              ? e.async
                ? (n += " throw new ValidationError([" + R + "]); ")
                : (n += " validate.errors = [" + R + "]; return false; ")
              : (n +=
                  " var err = " +
                  R +
                  ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
            (n += " } else if ( "),
            p &&
              (n +=
                " (" +
                s +
                " !== undefined && typeof " +
                s +
                " != 'number') || "),
            (n +=
              " " +
              b +
              " == 'number' ? ( (" +
              w +
              " = " +
              s +
              " === undefined || " +
              E +
              " " +
              y +
              "= " +
              s +
              ") ? " +
              f +
              " " +
              P +
              "= " +
              E +
              " : " +
              f +
              " " +
              P +
              " " +
              s +
              " ) : ( (" +
              w +
              " = " +
              E +
              " === true) ? " +
              f +
              " " +
              P +
              "= " +
              s +
              " : " +
              f +
              " " +
              P +
              " " +
              s +
              " ) || " +
              f +
              " !== " +
              f +
              ") { var op" +
              i +
              " = " +
              w +
              " ? '" +
              y +
              "' : '" +
              y +
              "=';");
        } else {
          var S = "number" == typeof v,
            _ = y;
          if (S && p) {
            var O = "'" + _ + "'";
            (n += " if ( "),
              p &&
                (n +=
                  " (" +
                  s +
                  " !== undefined && typeof " +
                  s +
                  " != 'number') || "),
              (n +=
                " ( " +
                s +
                " === undefined || " +
                v +
                " " +
                y +
                "= " +
                s +
                " ? " +
                f +
                " " +
                P +
                "= " +
                v +
                " : " +
                f +
                " " +
                P +
                " " +
                s +
                " ) || " +
                f +
                " !== " +
                f +
                ") { ");
          } else {
            S && void 0 === u
              ? ((w = !0),
                (a = m),
                (c = e.errSchemaPath + "/" + m),
                (s = v),
                (P += "="))
              : (S && (s = Math[d ? "min" : "max"](v, u)),
                v === (!S || s)
                  ? ((w = !0),
                    (a = m),
                    (c = e.errSchemaPath + "/" + m),
                    (P += "="))
                  : ((w = !1), (_ += "=")));
            var O = "'" + _ + "'";
            (n += " if ( "),
              p &&
                (n +=
                  " (" +
                  s +
                  " !== undefined && typeof " +
                  s +
                  " != 'number') || "),
              (n +=
                " " +
                f +
                " " +
                P +
                " " +
                s +
                " || " +
                f +
                " !== " +
                f +
                ") { ");
          }
        }
        a = a || t;
        var x = x || [];
        x.push(n),
          (n = ""),
          !1 !== e.createErrors
            ? ((n +=
                " { keyword: '" +
                (a || "_limit") +
                "' , dataPath: (dataPath || '') + " +
                e.errorPath +
                " , schemaPath: " +
                e.util.toQuotedString(c) +
                " , params: { comparison: " +
                O +
                ", limit: " +
                s +
                ", exclusive: " +
                w +
                " } "),
              !1 !== e.opts.messages &&
                ((n += " , message: 'should be " + _ + " "),
                (n += p ? "' + " + s : s + "'")),
              e.opts.verbose &&
                ((n += " , schema:  "),
                (n += p ? "validate.schema" + l : "" + u),
                (n +=
                  "         , parentSchema: validate.schema" +
                  e.schemaPath +
                  " , data: " +
                  f +
                  " ")),
              (n += " } "))
            : (n += " {} ");
        var R = n;
        return (
          (n = x.pop()),
          !e.compositeRule && h
            ? e.async
              ? (n += " throw new ValidationError([" + R + "]); ")
              : (n += " validate.errors = [" + R + "]; return false; ")
            : (n +=
                " var err = " +
                R +
                ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
          (n += " } "),
          h && (n += " else { "),
          n
        );
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a,
          s,
          n = " ",
          i = e.level,
          o = e.dataLevel,
          u = e.schema[t],
          l = e.schemaPath + e.util.getProperty(t),
          c = e.errSchemaPath + "/" + t,
          h = !e.opts.allErrors,
          f = "data" + (o || ""),
          p = e.opts.$data && u && u.$data;
        p
          ? ((n +=
              " var schema" +
              i +
              " = " +
              e.util.getData(u.$data, o, e.dataPathArr) +
              "; "),
            (s = "schema" + i))
          : (s = u);
        var d = "maxItems" == t ? ">" : "<";
        (n += "if ( "),
          p &&
            (n +=
              " (" + s + " !== undefined && typeof " + s + " != 'number') || "),
          (n += " " + f + ".length " + d + " " + s + ") { ");
        var a = t,
          m = m || [];
        m.push(n),
          (n = ""),
          !1 !== e.createErrors
            ? ((n +=
                " { keyword: '" +
                (a || "_limitItems") +
                "' , dataPath: (dataPath || '') + " +
                e.errorPath +
                " , schemaPath: " +
                e.util.toQuotedString(c) +
                " , params: { limit: " +
                s +
                " } "),
              !1 !== e.opts.messages &&
                ((n += " , message: 'should NOT have "),
                (n += "maxItems" == t ? "more" : "less"),
                (n += " than "),
                (n += p ? "' + " + s + " + '" : "" + u),
                (n += " items' ")),
              e.opts.verbose &&
                ((n += " , schema:  "),
                (n += p ? "validate.schema" + l : "" + u),
                (n +=
                  "         , parentSchema: validate.schema" +
                  e.schemaPath +
                  " , data: " +
                  f +
                  " ")),
              (n += " } "))
            : (n += " {} ");
        var v = n;
        return (
          (n = m.pop()),
          !e.compositeRule && h
            ? e.async
              ? (n += " throw new ValidationError([" + v + "]); ")
              : (n += " validate.errors = [" + v + "]; return false; ")
            : (n +=
                " var err = " +
                v +
                ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
          (n += "} "),
          h && (n += " else { "),
          n
        );
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a,
          s,
          n = " ",
          i = e.level,
          o = e.dataLevel,
          u = e.schema[t],
          l = e.schemaPath + e.util.getProperty(t),
          c = e.errSchemaPath + "/" + t,
          h = !e.opts.allErrors,
          f = "data" + (o || ""),
          p = e.opts.$data && u && u.$data;
        p
          ? ((n +=
              " var schema" +
              i +
              " = " +
              e.util.getData(u.$data, o, e.dataPathArr) +
              "; "),
            (s = "schema" + i))
          : (s = u);
        var d = "maxLength" == t ? ">" : "<";
        (n += "if ( "),
          p &&
            (n +=
              " (" + s + " !== undefined && typeof " + s + " != 'number') || "),
          !1 === e.opts.unicode
            ? (n += " " + f + ".length ")
            : (n += " ucs2length(" + f + ") "),
          (n += " " + d + " " + s + ") { ");
        var a = t,
          m = m || [];
        m.push(n),
          (n = ""),
          !1 !== e.createErrors
            ? ((n +=
                " { keyword: '" +
                (a || "_limitLength") +
                "' , dataPath: (dataPath || '') + " +
                e.errorPath +
                " , schemaPath: " +
                e.util.toQuotedString(c) +
                " , params: { limit: " +
                s +
                " } "),
              !1 !== e.opts.messages &&
                ((n += " , message: 'should NOT be "),
                (n += "maxLength" == t ? "longer" : "shorter"),
                (n += " than "),
                (n += p ? "' + " + s + " + '" : "" + u),
                (n += " characters' ")),
              e.opts.verbose &&
                ((n += " , schema:  "),
                (n += p ? "validate.schema" + l : "" + u),
                (n +=
                  "         , parentSchema: validate.schema" +
                  e.schemaPath +
                  " , data: " +
                  f +
                  " ")),
              (n += " } "))
            : (n += " {} ");
        var v = n;
        return (
          (n = m.pop()),
          !e.compositeRule && h
            ? e.async
              ? (n += " throw new ValidationError([" + v + "]); ")
              : (n += " validate.errors = [" + v + "]; return false; ")
            : (n +=
                " var err = " +
                v +
                ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
          (n += "} "),
          h && (n += " else { "),
          n
        );
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a,
          s,
          n = " ",
          i = e.level,
          o = e.dataLevel,
          u = e.schema[t],
          l = e.schemaPath + e.util.getProperty(t),
          c = e.errSchemaPath + "/" + t,
          h = !e.opts.allErrors,
          f = "data" + (o || ""),
          p = e.opts.$data && u && u.$data;
        p
          ? ((n +=
              " var schema" +
              i +
              " = " +
              e.util.getData(u.$data, o, e.dataPathArr) +
              "; "),
            (s = "schema" + i))
          : (s = u);
        var d = "maxProperties" == t ? ">" : "<";
        (n += "if ( "),
          p &&
            (n +=
              " (" + s + " !== undefined && typeof " + s + " != 'number') || "),
          (n += " Object.keys(" + f + ").length " + d + " " + s + ") { ");
        var a = t,
          m = m || [];
        m.push(n),
          (n = ""),
          !1 !== e.createErrors
            ? ((n +=
                " { keyword: '" +
                (a || "_limitProperties") +
                "' , dataPath: (dataPath || '') + " +
                e.errorPath +
                " , schemaPath: " +
                e.util.toQuotedString(c) +
                " , params: { limit: " +
                s +
                " } "),
              !1 !== e.opts.messages &&
                ((n += " , message: 'should NOT have "),
                (n += "maxProperties" == t ? "more" : "less"),
                (n += " than "),
                (n += p ? "' + " + s + " + '" : "" + u),
                (n += " properties' ")),
              e.opts.verbose &&
                ((n += " , schema:  "),
                (n += p ? "validate.schema" + l : "" + u),
                (n +=
                  "         , parentSchema: validate.schema" +
                  e.schemaPath +
                  " , data: " +
                  f +
                  " ")),
              (n += " } "))
            : (n += " {} ");
        var v = n;
        return (
          (n = m.pop()),
          !e.compositeRule && h
            ? e.async
              ? (n += " throw new ValidationError([" + v + "]); ")
              : (n += " validate.errors = [" + v + "]; return false; ")
            : (n +=
                " var err = " +
                v +
                ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
          (n += "} "),
          h && (n += " else { "),
          n
        );
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        function a(e) {
          for (var t = e.rules, r = 0; r < t.length; r++)
            if (s(t[r])) return !0;
        }
        function s(t) {
          return void 0 !== e.schema[t.keyword] || (t.implements && n(t));
        }
        function n(t) {
          for (var r = t.implements, a = 0; a < r.length; a++)
            if (void 0 !== e.schema[r[a]]) return !0;
        }
        var i = "",
          o = !0 === e.schema.$async,
          u = e.util.schemaHasRulesExcept(e.schema, e.RULES.all, "$ref"),
          l = e.self._getId(e.schema);
        if (e.isTop) {
          if (o) {
            e.async = !0;
            var c = "es7" == e.opts.async;
            e.yieldAwait = c ? "await" : "yield";
          }
          (i += " var validate = "),
            o
              ? c
                ? (i += " (async function ")
                : ("*" != e.opts.async && (i += "co.wrap"),
                  (i += "(function* "))
              : (i += " (function "),
            (i +=
              " (data, dataPath, parentData, parentDataProperty, rootData) { 'use strict'; "),
            l &&
              (e.opts.sourceCode || e.opts.processCode) &&
              (i += " /*# sourceURL=" + l + " */ ");
        }
        if ("boolean" == typeof e.schema || (!u && !e.schema.$ref)) {
          var h,
            f = e.level,
            p = e.dataLevel,
            d = e.schema["false schema"],
            m = e.schemaPath + e.util.getProperty("false schema"),
            v = e.errSchemaPath + "/false schema",
            g = !e.opts.allErrors,
            y = "data" + (p || ""),
            P = "valid" + f;
          if (!1 === e.schema) {
            e.isTop ? (g = !0) : (i += " var " + P + " = false; ");
            var E = E || [];
            E.push(i),
              (i = ""),
              !1 !== e.createErrors
                ? ((i +=
                    " { keyword: '" +
                    (h || "false schema") +
                    "' , dataPath: (dataPath || '') + " +
                    e.errorPath +
                    " , schemaPath: " +
                    e.util.toQuotedString(v) +
                    " , params: {} "),
                  !1 !== e.opts.messages &&
                    (i += " , message: 'boolean schema is false' "),
                  e.opts.verbose &&
                    (i +=
                      " , schema: false , parentSchema: validate.schema" +
                      e.schemaPath +
                      " , data: " +
                      y +
                      " "),
                  (i += " } "))
                : (i += " {} ");
            var w = i;
            (i = E.pop()),
              !e.compositeRule && g
                ? e.async
                  ? (i += " throw new ValidationError([" + w + "]); ")
                  : (i += " validate.errors = [" + w + "]; return false; ")
                : (i +=
                    " var err = " +
                    w +
                    ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; ");
          } else
            e.isTop
              ? (i += o
                  ? " return data; "
                  : " validate.errors = null; return true; ")
              : (i += " var " + P + " = true; ");
          return e.isTop && (i += " }); return validate; "), i;
        }
        if (e.isTop) {
          var b = e.isTop,
            f = (e.level = 0),
            p = (e.dataLevel = 0),
            y = "data";
          (e.rootId = e.resolve.fullPath(e.self._getId(e.root.schema))),
            (e.baseId = e.baseId || e.rootId),
            delete e.isTop,
            (e.dataPathArr = [void 0]),
            (i += " var vErrors = null; "),
            (i += " var errors = 0;     "),
            (i += " if (rootData === undefined) rootData = data; ");
        } else {
          var f = e.level,
            p = e.dataLevel,
            y = "data" + (p || "");
          if ((l && (e.baseId = e.resolve.url(e.baseId, l)), o && !e.async))
            throw new Error("async schema in sync schema");
          i += " var errs_" + f + " = errors;";
        }
        var h,
          P = "valid" + f,
          g = !e.opts.allErrors,
          S = "",
          O = "",
          _ = e.schema.type,
          x = Array.isArray(_);
        if (
          (x && 1 == _.length && ((_ = _[0]), (x = !1)), e.schema.$ref && u)
        ) {
          if ("fail" == e.opts.extendRefs)
            throw new Error(
              '$ref: validation keywords used in schema at path "' +
                e.errSchemaPath +
                '" (see option extendRefs)'
            );
          !0 !== e.opts.extendRefs &&
            ((u = !1),
            console.warn(
              '$ref: keywords ignored in schema at path "' +
                e.errSchemaPath +
                '"'
            ));
        }
        if (_) {
          if (e.opts.coerceTypes)
            var R = e.util.coerceToTypes(e.opts.coerceTypes, _);
          var A = e.RULES.types[_];
          if (R || x || !0 === A || (A && !a(A))) {
            var m = e.schemaPath + ".type",
              v = e.errSchemaPath + "/type",
              m = e.schemaPath + ".type",
              v = e.errSchemaPath + "/type",
              D = x ? "checkDataTypes" : "checkDataType";
            if (((i += " if (" + e.util[D](_, y, !0) + ") { "), R)) {
              var k = "dataType" + f,
                T = "coerced" + f;
              (i += " var " + k + " = typeof " + y + "; "),
                "array" == e.opts.coerceTypes &&
                  (i +=
                    " if (" +
                    k +
                    " == 'object' && Array.isArray(" +
                    y +
                    ")) " +
                    k +
                    " = 'array'; "),
                (i += " var " + T + " = undefined; ");
              var F = "",
                j = R;
              if (j)
                for (var I, $ = -1, W = j.length - 1; $ < W; )
                  (I = j[($ += 1)]),
                    $ &&
                      ((i += " if (" + T + " === undefined) { "), (F += "}")),
                    "array" == e.opts.coerceTypes &&
                      "array" != I &&
                      (i +=
                        " if (" +
                        k +
                        " == 'array' && " +
                        y +
                        ".length == 1) { " +
                        T +
                        " = " +
                        y +
                        " = " +
                        y +
                        "[0]; " +
                        k +
                        " = typeof " +
                        y +
                        ";  } "),
                    "string" == I
                      ? (i +=
                          " if (" +
                          k +
                          " == 'number' || " +
                          k +
                          " == 'boolean') " +
                          T +
                          " = '' + " +
                          y +
                          "; else if (" +
                          y +
                          " === null) " +
                          T +
                          " = ''; ")
                      : "number" == I || "integer" == I
                      ? ((i +=
                          " if (" +
                          k +
                          " == 'boolean' || " +
                          y +
                          " === null || (" +
                          k +
                          " == 'string' && " +
                          y +
                          " && " +
                          y +
                          " == +" +
                          y +
                          " "),
                        "integer" == I && (i += " && !(" + y + " % 1)"),
                        (i += ")) " + T + " = +" + y + "; "))
                      : "boolean" == I
                      ? (i +=
                          " if (" +
                          y +
                          " === 'false' || " +
                          y +
                          " === 0 || " +
                          y +
                          " === null) " +
                          T +
                          " = false; else if (" +
                          y +
                          " === 'true' || " +
                          y +
                          " === 1) " +
                          T +
                          " = true; ")
                      : "null" == I
                      ? (i +=
                          " if (" +
                          y +
                          " === '' || " +
                          y +
                          " === 0 || " +
                          y +
                          " === false) " +
                          T +
                          " = null; ")
                      : "array" == e.opts.coerceTypes &&
                        "array" == I &&
                        (i +=
                          " if (" +
                          k +
                          " == 'string' || " +
                          k +
                          " == 'number' || " +
                          k +
                          " == 'boolean' || " +
                          y +
                          " == null) " +
                          T +
                          " = [" +
                          y +
                          "]; ");
              i += " " + F + " if (" + T + " === undefined) {   ";
              var E = E || [];
              E.push(i),
                (i = ""),
                !1 !== e.createErrors
                  ? ((i +=
                      " { keyword: '" +
                      (h || "type") +
                      "' , dataPath: (dataPath || '') + " +
                      e.errorPath +
                      " , schemaPath: " +
                      e.util.toQuotedString(v) +
                      " , params: { type: '"),
                    (i += x ? "" + _.join(",") : "" + _),
                    (i += "' } "),
                    !1 !== e.opts.messages &&
                      ((i += " , message: 'should be "),
                      (i += x ? "" + _.join(",") : "" + _),
                      (i += "' ")),
                    e.opts.verbose &&
                      (i +=
                        " , schema: validate.schema" +
                        m +
                        " , parentSchema: validate.schema" +
                        e.schemaPath +
                        " , data: " +
                        y +
                        " "),
                    (i += " } "))
                  : (i += " {} ");
              var w = i;
              (i = E.pop()),
                !e.compositeRule && g
                  ? e.async
                    ? (i += " throw new ValidationError([" + w + "]); ")
                    : (i += " validate.errors = [" + w + "]; return false; ")
                  : (i +=
                      " var err = " +
                      w +
                      ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
                (i += " } else {  ");
              var N = p ? "data" + (p - 1 || "") : "parentData",
                C = p ? e.dataPathArr[p] : "parentDataProperty";
              (i += " " + y + " = " + T + "; "),
                p || (i += "if (" + N + " !== undefined)"),
                (i += " " + N + "[" + C + "] = " + T + "; } ");
            } else {
              var E = E || [];
              E.push(i),
                (i = ""),
                !1 !== e.createErrors
                  ? ((i +=
                      " { keyword: '" +
                      (h || "type") +
                      "' , dataPath: (dataPath || '') + " +
                      e.errorPath +
                      " , schemaPath: " +
                      e.util.toQuotedString(v) +
                      " , params: { type: '"),
                    (i += x ? "" + _.join(",") : "" + _),
                    (i += "' } "),
                    !1 !== e.opts.messages &&
                      ((i += " , message: 'should be "),
                      (i += x ? "" + _.join(",") : "" + _),
                      (i += "' ")),
                    e.opts.verbose &&
                      (i +=
                        " , schema: validate.schema" +
                        m +
                        " , parentSchema: validate.schema" +
                        e.schemaPath +
                        " , data: " +
                        y +
                        " "),
                    (i += " } "))
                  : (i += " {} ");
              var w = i;
              (i = E.pop()),
                !e.compositeRule && g
                  ? e.async
                    ? (i += " throw new ValidationError([" + w + "]); ")
                    : (i += " validate.errors = [" + w + "]; return false; ")
                  : (i +=
                      " var err = " +
                      w +
                      ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; ");
            }
            i += " } ";
          }
        }
        if (e.schema.$ref && !u)
          (i += " " + e.RULES.all.$ref.code(e, "$ref") + " "),
            g &&
              ((i += " } if (errors === "),
              (i += b ? "0" : "errs_" + f),
              (i += ") { "),
              (O += "}"));
        else {
          e.opts.v5 &&
            e.schema.patternGroups &&
            console.warn(
              'keyword "patternGroups" is deprecated and disabled. Use option patternGroups: true to enable.'
            );
          var L = e.RULES;
          if (L)
            for (var A, z = -1, M = L.length - 1; z < M; )
              if (((A = L[(z += 1)]), a(A))) {
                if (
                  (A.type &&
                    (i += " if (" + e.util.checkDataType(A.type, y) + ") { "),
                  e.opts.useDefaults && !e.compositeRule)
                )
                  if ("object" == A.type && e.schema.properties) {
                    var d = e.schema.properties,
                      H = Object.keys(d),
                      q = H;
                    if (q)
                      for (var V, B = -1, Q = q.length - 1; B < Q; ) {
                        V = q[(B += 1)];
                        var U = d[V];
                        if (void 0 !== U.default) {
                          var K = y + e.util.getProperty(V);
                          (i += "  if (" + K + " === undefined) " + K + " = "),
                            "shared" == e.opts.useDefaults
                              ? (i += " " + e.useDefault(U.default) + " ")
                              : (i += " " + JSON.stringify(U.default) + " "),
                            (i += "; ");
                        }
                      }
                  } else if (
                    "array" == A.type &&
                    Array.isArray(e.schema.items)
                  ) {
                    var J = e.schema.items;
                    if (J)
                      for (var U, $ = -1, G = J.length - 1; $ < G; )
                        if (((U = J[($ += 1)]), void 0 !== U.default)) {
                          var K = y + "[" + $ + "]";
                          (i += "  if (" + K + " === undefined) " + K + " = "),
                            "shared" == e.opts.useDefaults
                              ? (i += " " + e.useDefault(U.default) + " ")
                              : (i += " " + JSON.stringify(U.default) + " "),
                            (i += "; ");
                        }
                  }
                var Y = A.rules;
                if (Y)
                  for (var Z, X = -1, ee = Y.length - 1; X < ee; )
                    if (((Z = Y[(X += 1)]), s(Z))) {
                      var te = Z.code(e, Z.keyword, A.type);
                      te && ((i += " " + te + " "), g && (S += "}"));
                    }
                if (
                  (g && ((i += " " + S + " "), (S = "")),
                  A.type && ((i += " } "), _ && _ === A.type && !R))
                ) {
                  i += " else { ";
                  var m = e.schemaPath + ".type",
                    v = e.errSchemaPath + "/type",
                    E = E || [];
                  E.push(i),
                    (i = ""),
                    !1 !== e.createErrors
                      ? ((i +=
                          " { keyword: '" +
                          (h || "type") +
                          "' , dataPath: (dataPath || '') + " +
                          e.errorPath +
                          " , schemaPath: " +
                          e.util.toQuotedString(v) +
                          " , params: { type: '"),
                        (i += x ? "" + _.join(",") : "" + _),
                        (i += "' } "),
                        !1 !== e.opts.messages &&
                          ((i += " , message: 'should be "),
                          (i += x ? "" + _.join(",") : "" + _),
                          (i += "' ")),
                        e.opts.verbose &&
                          (i +=
                            " , schema: validate.schema" +
                            m +
                            " , parentSchema: validate.schema" +
                            e.schemaPath +
                            " , data: " +
                            y +
                            " "),
                        (i += " } "))
                      : (i += " {} ");
                  var w = i;
                  (i = E.pop()),
                    !e.compositeRule && g
                      ? e.async
                        ? (i += " throw new ValidationError([" + w + "]); ")
                        : (i +=
                            " validate.errors = [" + w + "]; return false; ")
                      : (i +=
                          " var err = " +
                          w +
                          ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
                    (i += " } ");
                }
                g &&
                  ((i += " if (errors === "),
                  (i += b ? "0" : "errs_" + f),
                  (i += ") { "),
                  (O += "}"));
              }
        }
        return (
          g && (i += " " + O + " "),
          b
            ? (o
                ? ((i += " if (errors === 0) return data;           "),
                  (i += " else throw new ValidationError(vErrors); "))
                : ((i += " validate.errors = vErrors; "),
                  (i += " return errors === 0;       ")),
              (i += " }); return validate;"))
            : (i += " var " + P + " = errors === errs_" + f + ";"),
          (i = e.util.cleanUpCode(i)),
          b && (i = e.util.finalCleanUpCode(i, o)),
          i
        );
      };
    },
    function(e, t, r) {
      "use strict";
      function a(e, t) {
        function a(e, t) {
          1 === e && (e = "Single"),
            2 === e && (e = "Double"),
            3 === e && (e = "Treble"),
            e > 3 && (e += "-fold"),
            e in m || (m[e] = { win: 0, place: 0, lost: 0 }),
            t === o.WINNER
              ? m[e].win++
              : t === o.PLACED
              ? m[e].place++
              : (t !== o.LOSE && t !== o.VOID) || m[e].lost++;
        }
        var n = t.minWinningSelections,
          l = e.selections,
          p = new i.a(0),
          d = e.stake,
          m = {},
          v = new i.a(
            r
              .i(c.e)(n, l.length)
              .map(function(e, t) {
                return r
                  .i(u.combination)(r.i(c.e)(1, l.length), t)
                  .toArray().length;
              })
              .reduce(function(e, t) {
                return e + t;
              })
          );
        if (
          (e.eachWay && (v = v.times(2)),
          e.stakeType === o.TOTAL_COMBINED_STAKE &&
            (d = parseFloat(new i.a(e.stake).div(v).toString())),
          f.indexOf(e.betType) && "bookMakersBonus" in e)
        ) {
          var g = e.selections.filter(c.c),
            y = e.selections.filter(c.h);
          if (
            1 === g.length &&
            "oneWinnerConsolation" in e.bookMakersBonus &&
            y.length < 1
          ) {
            var P = e.selections.indexOf(g[0]);
            e.bookMakersBonus.oneWinnerConsolation === o.OWC_DOUBLE
              ? (e.selections[P].odds = (
                  2 * r.i(c.b)(e.selections[P].odds) -
                  1
                ).toString())
              : e.bookMakersBonus.oneWinnerConsolation === o.OWC_TREBLE &&
                (e.selections[P].odds = (
                  3 * r.i(c.b)(e.selections[P].odds) -
                  2
                ).toString());
          }
        }
        var E = l.filter(c.f),
          w = new i.a(0);
        if (
          ((p =
            E.length < n
              ? new i.a(0)
              : r
                  .i(c.e)(n, l.length)
                  .map(function(t) {
                    return r
                      .i(u.combination)(l, t)
                      .toArray()
                      .map(function(t) {
                        var n = t.filter(c.a),
                          u = t.filter(c.g),
                          l = t.filter(c.h),
                          f = o.WINNER;
                        return (
                          n.length > 0 && (f = o.PLACED),
                          u.length > 0 && (f = o.LOSE),
                          l.length > 0 && (f = o.VOID),
                          a(t.length, f),
                          u.length > 0
                            ? new i.a(0)
                            : r.i(s.a)(
                                new h.a({
                                  betType: "accumulator",
                                  eachWay: e.eachWay,
                                  eachWayType: e.eachWayType,
                                  selections: t,
                                  stake: d
                                })
                              ).returns
                        );
                      })
                      .reduce(function(e, t) {
                        return e.plus(t);
                      });
                  })
                  .reduce(function(e, t) {
                    return e.plus(t);
                  })),
          f.indexOf(e.betType) && "bookMakersBonus" in e)
        ) {
          for (
            var b = e.selections.filter(c.c), S = !0, O = 0;
            O < e.selections.length;
            O++
          )
            "winner" !== e.selections[O].outcome && (S = !1);
          if (
            b.length === e.selections.length &&
            "allWinnersBonus" in e.bookMakersBonus &&
            S
          )
            if (e.eachWay) {
              (w = r
                .i(c.e)(n, l.length)
                .map(function(t) {
                  return r
                    .i(u.combination)(l, t)
                    .toArray()
                    .map(function(t) {
                      var n = t.filter(c.a),
                        u = t.filter(c.g),
                        l = t.filter(c.h),
                        f = o.WINNER;
                      return (
                        n.length > 0 && (f = o.PLACED),
                        u.length > 0 && (f = o.LOSE),
                        l.length > 0 && (f = o.VOID),
                        a(t.length, f),
                        u.length > 0
                          ? new i.a(0)
                          : r.i(s.a)(
                              new h.a({
                                betType: "accumulator",
                                eachWay: !1,
                                eachWayType: e.eachWayType,
                                selections: t,
                                stake: d
                              })
                            ).returns
                      );
                    })
                    .reduce(function(e, t) {
                      return e.plus(t);
                    });
                })
                .reduce(function(e, t) {
                  return e.plus(t);
                })),
                console.log("win returns: " + w.toString());
              var _ = e.bookMakersBonus.allWinnersBonus / 100 + 1;
              console.log(_),
                (p = p.minus(w).plus(w.times(_))),
                console.log(
                  "awb: " + e.bookMakersBonus.allWinnersBonus.toString()
                );
            } else
              p = p.plus(p.div(100).times(e.bookMakersBonus.allWinnersBonus));
        }
        var x = v.times(new i.a(d));
        return { returns: p, profit: p.minus(x), outlay: x, bets: m };
      }
      var s = r(4),
        n = r(1),
        i = r.n(n),
        o = r(0),
        u = (r.n(o), r(8)),
        l = (r.n(u), r(7)),
        c = (r.n(l), r(3)),
        h = r(2),
        f = ["lucky-15", "lucky-31", "lucky-63"];
      t.a = a;
    },
    function(e, t) {
      function r(e) {
        var t = this,
          r = h.call(arguments, 1);
        return new Promise(function(s, n) {
          function i(t) {
            var r;
            try {
              r = e.next(t);
            } catch (e) {
              return n(e);
            }
            l(r);
          }
          function u(t) {
            var r;
            try {
              r = e.throw(t);
            } catch (e) {
              return n(e);
            }
            l(r);
          }
          function l(e) {
            if (e.done) return s(e.value);
            var r = a.call(t, e.value);
            return r && o(r)
              ? r.then(i, u)
              : u(
                  new TypeError(
                    'You may only yield a function, promise, generator, array, or object, but the following object was passed: "' +
                      String(e.value) +
                      '"'
                  )
                );
          }
          if (
            ("function" == typeof e && (e = e.apply(t, r)),
            !e || "function" != typeof e.next)
          )
            return s(e);
          i();
        });
      }
      function a(e) {
        return e
          ? o(e)
            ? e
            : l(e) || u(e)
            ? r.call(this, e)
            : "function" == typeof e
            ? s.call(this, e)
            : Array.isArray(e)
            ? n.call(this, e)
            : c(e)
            ? i.call(this, e)
            : e
          : e;
      }
      function s(e) {
        var t = this;
        return new Promise(function(r, a) {
          e.call(t, function(e, t) {
            if (e) return a(e);
            arguments.length > 2 && (t = h.call(arguments, 1)), r(t);
          });
        });
      }
      function n(e) {
        return Promise.all(e.map(a, this));
      }
      function i(e) {
        for (
          var t = new e.constructor(), r = Object.keys(e), s = [], n = 0;
          n < r.length;
          n++
        ) {
          var i = r[n],
            u = a.call(this, e[i]);
          u && o(u)
            ? (function(e, r) {
                (t[r] = void 0),
                  s.push(
                    e.then(function(e) {
                      t[r] = e;
                    })
                  );
              })(u, i)
            : (t[i] = e[i]);
        }
        return Promise.all(s).then(function() {
          return t;
        });
      }
      function o(e) {
        return "function" == typeof e.then;
      }
      function u(e) {
        return "function" == typeof e.next && "function" == typeof e.throw;
      }
      function l(e) {
        var t = e.constructor;
        return (
          !!t &&
          ("GeneratorFunction" === t.name ||
            "GeneratorFunction" === t.displayName ||
            u(t.prototype))
        );
      }
      function c(e) {
        return Object == e.constructor;
      }
      var h = Array.prototype.slice;
      (e.exports = r.default = r.co = r),
        (r.wrap = function(e) {
          function t() {
            return r.call(this, e.apply(this, arguments));
          }
          return (t.__generatorFunction__ = e), t;
        });
    },
    function(e, t, r) {
      var a = "undefined" != typeof JSON ? JSON : r(87);
      e.exports = function(e, t) {
        t || (t = {}), "function" == typeof t && (t = { cmp: t });
        var r = t.space || "";
        "number" == typeof r && (r = Array(r + 1).join(" "));
        var i = "boolean" == typeof t.cycles && t.cycles,
          o =
            t.replacer ||
            function(e, t) {
              return t;
            },
          u =
            t.cmp &&
            (function(e) {
              return function(t) {
                return function(r, a) {
                  var s = { key: r, value: t[r] },
                    n = { key: a, value: t[a] };
                  return e(s, n);
                };
              };
            })(t.cmp),
          l = [];
        return (function e(t, c, h, f) {
          var p = r ? "\n" + new Array(f + 1).join(r) : "",
            d = r ? ": " : ":";
          if (
            (h && h.toJSON && "function" == typeof h.toJSON && (h = h.toJSON()),
            void 0 !== (h = o.call(t, c, h)))
          ) {
            if ("object" != typeof h || null === h) return a.stringify(h);
            if (s(h)) {
              for (var m = [], v = 0; v < h.length; v++) {
                var g = e(h, v, h[v], f + 1) || a.stringify(null);
                m.push(p + r + g);
              }
              return "[" + m.join(",") + p + "]";
            }
            if (-1 !== l.indexOf(h)) {
              if (i) return a.stringify("__cycle__");
              throw new TypeError("Converting circular structure to JSON");
            }
            l.push(h);
            for (
              var y = n(h).sort(u && u(h)), m = [], v = 0;
              v < y.length;
              v++
            ) {
              var c = y[v],
                P = e(h, c, h[c], f + 1);
              if (P) {
                var E = a.stringify(c) + d + P;
                m.push(p + r + E);
              }
            }
            return l.splice(l.indexOf(h), 1), "{" + m.join(",") + p + "}";
          }
        })({ "": e }, "", e, 0);
      };
      var s =
          Array.isArray ||
          function(e) {
            return "[object Array]" === {}.toString.call(e);
          },
        n =
          Object.keys ||
          function(e) {
            var t =
                Object.prototype.hasOwnProperty ||
                function() {
                  return !0;
                },
              r = [];
            for (var a in e) t.call(e, a) && r.push(a);
            return r;
          };
    },
    function(e, t, r) {
      "use strict";
      Object.defineProperty(t, "__esModule", { value: !0 });
      var a = r(0),
        s = (r.n(a), r(2)),
        n = r(60),
        i = r(70),
        o = { Slip: s.a, calculate: i.a };
      r.i(n.a)(o);
      for (var u in a)
        if (a.hasOwnProperty(u)) {
          var l = a[u];
          o[u] = l;
        }
      t.default = o;
    },
    function(e, t, r) {
      "use strict";
      var a = [
        "multipleOf",
        "maximum",
        "exclusiveMaximum",
        "minimum",
        "exclusiveMinimum",
        "maxLength",
        "minLength",
        "pattern",
        "additionalItems",
        "maxItems",
        "minItems",
        "uniqueItems",
        "maxProperties",
        "minProperties",
        "required",
        "additionalProperties",
        "enum",
        "format",
        "const"
      ];
      e.exports = function(e, t) {
        for (var r = 0; r < t.length; r++) {
          e = JSON.parse(JSON.stringify(e));
          var s,
            n = t[r].split("/"),
            i = e;
          for (s = 1; s < n.length; s++) i = i[n[s]];
          for (s = 0; s < a.length; s++) {
            var o = a[s],
              u = i[o];
            u &&
              (i[o] = {
                anyOf: [
                  u,
                  {
                    $ref:
                      "https://raw.githubusercontent.com/epoberezkin/ajv/master/lib/refs/$data.json#"
                  }
                ]
              });
          }
        }
        return e;
      };
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        if (!(this instanceof a)) return new a(e);
        (e = this._opts = N.copy(e) || {}),
          (this._schemas = {}),
          (this._refs = {}),
          (this._fragments = {}),
          (this._formats = j(e.format));
        var t = (this._schemaUriFormat = this._formats["uri-reference"]);
        (this._schemaUriFormatFunc = function(e) {
          return t.test(e);
        }),
          (this._cache = e.cache || new k()),
          (this._loadingSchemas = {}),
          (this._compilations = []),
          (this.RULES = I()),
          (this._getId = g(e)),
          (e.loopRequired = e.loopRequired || 1 / 0),
          "property" == e.errorDataPath && (e._errorDataPathProperty = !0),
          void 0 === e.serialize && (e.serialize = F),
          (this._metaOpts = R(this)),
          e.formats && _(this),
          S(this),
          "object" == typeof e.meta && this.addMetaSchema(e.meta),
          O(this),
          e.patternGroups && W(this);
      }
      function s(e, t) {
        var r;
        if ("string" == typeof e) {
          if (!(r = this.getSchema(e)))
            throw new Error('no schema with key or ref "' + e + '"');
        } else {
          var a = this._addSchema(e);
          r = a.validate || this._compile(a);
        }
        var s = r(t);
        return !0 === r.$async
          ? "*" == this._opts.async
            ? C(s)
            : s
          : ((this.errors = r.errors), s);
      }
      function n(e, t) {
        var r = this._addSchema(e, void 0, t);
        return r.validate || this._compile(r);
      }
      function i(e, t, r, a) {
        if (Array.isArray(e))
          for (var s = 0; s < e.length; s++) this.addSchema(e[s], void 0, r, a);
        else {
          var n = this._getId(e);
          if (void 0 !== n && "string" != typeof n)
            throw new Error("schema id must be string");
          (t = D.normalizeId(t || n)),
            x(this, t),
            (this._schemas[t] = this._addSchema(e, r, a, !0));
        }
      }
      function o(e, t, r) {
        this.addSchema(e, t, r, !0);
      }
      function u(e, t) {
        var r = e.$schema;
        if (void 0 !== r && "string" != typeof r)
          throw new Error("$schema must be a string");
        if (!(r = r || this._opts.defaultMeta || l(this)))
          return (
            console.warn("meta-schema not available"), (this.errors = null), !0
          );
        var a = this._formats.uri;
        this._formats.uri =
          "function" == typeof a
            ? this._schemaUriFormatFunc
            : this._schemaUriFormat;
        var s;
        try {
          s = this.validate(r, e);
        } finally {
          this._formats.uri = a;
        }
        if (!s && t) {
          var n = "schema is invalid: " + this.errorsText();
          if ("log" != this._opts.validateSchema) throw new Error(n);
          console.error(n);
        }
        return s;
      }
      function l(e) {
        var t = e._opts.meta;
        return (
          (e._opts.defaultMeta =
            "object" == typeof t
              ? e._getId(t) || t
              : e.getSchema(M)
              ? M
              : void 0),
          e._opts.defaultMeta
        );
      }
      function c(e) {
        var t = f(this, e);
        switch (typeof t) {
          case "object":
            return t.validate || this._compile(t);
          case "string":
            return this.getSchema(t);
          case "undefined":
            return h(this, e);
        }
      }
      function h(e, t) {
        var r = D.schema.call(e, { schema: {} }, t);
        if (r) {
          var a = r.schema,
            s = r.root,
            n = r.baseId,
            i = A.call(e, a, s, void 0, n);
          return (
            (e._fragments[t] = new T({
              ref: t,
              fragment: !0,
              schema: a,
              root: s,
              baseId: n,
              validate: i
            })),
            i
          );
        }
      }
      function f(e, t) {
        return (
          (t = D.normalizeId(t)), e._schemas[t] || e._refs[t] || e._fragments[t]
        );
      }
      function p(e) {
        if (e instanceof RegExp)
          return d(this, this._schemas, e), void d(this, this._refs, e);
        switch (typeof e) {
          case "undefined":
            return (
              d(this, this._schemas),
              d(this, this._refs),
              void this._cache.clear()
            );
          case "string":
            var t = f(this, e);
            return (
              t && this._cache.del(t.cacheKey),
              delete this._schemas[e],
              void delete this._refs[e]
            );
          case "object":
            var r = this._opts.serialize,
              a = r ? r(e) : e;
            this._cache.del(a);
            var s = this._getId(e);
            s &&
              ((s = D.normalizeId(s)),
              delete this._schemas[s],
              delete this._refs[s]);
        }
      }
      function d(e, t, r) {
        for (var a in t) {
          var s = t[a];
          s.meta ||
            (r && !r.test(a)) ||
            (e._cache.del(s.cacheKey), delete t[a]);
        }
      }
      function m(e, t, r, a) {
        if ("object" != typeof e && "boolean" != typeof e)
          throw new Error("schema should be object or boolean");
        var s = this._opts.serialize,
          n = s ? s(e) : e,
          i = this._cache.get(n);
        if (i) return i;
        a = a || !1 !== this._opts.addUsedSchema;
        var o = D.normalizeId(this._getId(e));
        o && a && x(this, o);
        var u,
          l = !1 !== this._opts.validateSchema && !t;
        l &&
          !(u = o && o == D.normalizeId(e.$schema)) &&
          this.validateSchema(e, !0);
        var c = D.ids.call(this, e),
          h = new T({ id: o, schema: e, localRefs: c, cacheKey: n, meta: r });
        return (
          "#" != o[0] && a && (this._refs[o] = h),
          this._cache.put(n, h),
          l && u && this.validateSchema(e, !0),
          h
        );
      }
      function v(e, t) {
        function r() {
          var t = e.validate,
            a = t.apply(null, arguments);
          return (r.errors = t.errors), a;
        }
        if (e.compiling)
          return (
            (e.validate = r),
            (r.schema = e.schema),
            (r.errors = null),
            (r.root = t || r),
            !0 === e.schema.$async && (r.$async = !0),
            r
          );
        e.compiling = !0;
        var a;
        e.meta && ((a = this._opts), (this._opts = this._metaOpts));
        var s;
        try {
          s = A.call(this, e.schema, t, e.localRefs);
        } finally {
          (e.compiling = !1), e.meta && (this._opts = a);
        }
        return (
          (e.validate = s),
          (e.refs = s.refs),
          (e.refVal = s.refVal),
          (e.root = s.root),
          s
        );
      }
      function g(e) {
        switch (e.schemaId) {
          case "$id":
            return P;
          case "id":
            return y;
          default:
            return E;
        }
      }
      function y(e) {
        return e.$id && console.warn("schema $id ignored", e.$id), e.id;
      }
      function P(e) {
        return e.id && console.warn("schema id ignored", e.id), e.$id;
      }
      function E(e) {
        if (e.$id && e.id && e.$id != e.id)
          throw new Error("schema $id is different from id");
        return e.$id || e.id;
      }
      function w(e, t) {
        if (!(e = e || this.errors)) return "No errors";
        t = t || {};
        for (
          var r = void 0 === t.separator ? ", " : t.separator,
            a = void 0 === t.dataVar ? "data" : t.dataVar,
            s = "",
            n = 0;
          n < e.length;
          n++
        ) {
          var i = e[n];
          i && (s += a + i.dataPath + " " + i.message + r);
        }
        return s.slice(0, -r.length);
      }
      function b(e, t) {
        "string" == typeof t && (t = new RegExp(t)), (this._formats[e] = t);
      }
      function S(e) {
        var t;
        if (
          (e._opts.$data && ((t = r(52)), e.addMetaSchema(t, t.$id, !0)),
          !1 !== e._opts.meta)
        ) {
          var a = r(53);
          e._opts.$data && (a = $(a, q)),
            e.addMetaSchema(a, M, !0),
            (e._refs["http://json-schema.org/schema"] = M);
        }
      }
      function O(e) {
        var t = e._opts.schemas;
        if (t)
          if (Array.isArray(t)) e.addSchema(t);
          else for (var r in t) e.addSchema(t[r], r);
      }
      function _(e) {
        for (var t in e._opts.formats) {
          var r = e._opts.formats[t];
          e.addFormat(t, r);
        }
      }
      function x(e, t) {
        if (e._schemas[t] || e._refs[t])
          throw new Error('schema with key or id "' + t + '" already exists');
      }
      function R(e) {
        for (var t = N.copy(e._opts), r = 0; r < H.length; r++) delete t[H[r]];
        return t;
      }
      var A = r(29),
        D = r(11),
        k = r(25),
        T = r(13),
        F = r(21),
        j = r(28),
        I = r(30),
        $ = r(23),
        W = r(51),
        N = r(5),
        C = r(20);
      (e.exports = a),
        (a.prototype.validate = s),
        (a.prototype.compile = n),
        (a.prototype.addSchema = i),
        (a.prototype.addMetaSchema = o),
        (a.prototype.validateSchema = u),
        (a.prototype.getSchema = c),
        (a.prototype.removeSchema = p),
        (a.prototype.addFormat = b),
        (a.prototype.errorsText = w),
        (a.prototype._addSchema = m),
        (a.prototype._compile = v),
        (a.prototype.compileAsync = r(27));
      var L = r(50);
      (a.prototype.addKeyword = L.add),
        (a.prototype.getKeyword = L.get),
        (a.prototype.removeKeyword = L.remove);
      var z = r(10);
      (a.ValidationError = z.Validation),
        (a.MissingRefError = z.MissingRef),
        (a.$dataMetaSchema = $);
      var M = "http://json-schema.org/draft-06/schema",
        H = ["removeAdditional", "useDefaults", "coerceTypes"],
        q = ["/properties"];
    },
    function(e, t, r) {
      "use strict";
      var a = (e.exports = function() {
        this._cache = {};
      });
      (a.prototype.put = function(e, t) {
        this._cache[e] = t;
      }),
        (a.prototype.get = function(e) {
          return this._cache[e];
        }),
        (a.prototype.del = function(e) {
          delete this._cache[e];
        }),
        (a.prototype.clear = function() {
          this._cache = {};
        });
    },
    function(e, t, r) {
      "use strict";
      e.exports = {
        $ref: r(47),
        allOf: r(32),
        anyOf: r(33),
        const: r(34),
        contains: r(35),
        dependencies: r(37),
        enum: r(38),
        format: r(39),
        items: r(40),
        maximum: r(14),
        minimum: r(14),
        maxItems: r(15),
        minItems: r(15),
        maxLength: r(16),
        minLength: r(16),
        maxProperties: r(17),
        minProperties: r(17),
        multipleOf: r(41),
        not: r(42),
        oneOf: r(43),
        pattern: r(44),
        properties: r(45),
        propertyNames: r(46),
        required: r(48),
        uniqueItems: r(49),
        validate: r(18)
      };
    },
    function(e, t, r) {
      "use strict";
      function a(e, t, r) {
        function n(e) {
          var t = e.$schema;
          return t && !o.getSchema(t)
            ? a.call(o, { $ref: t }, !0)
            : Promise.resolve();
        }
        function i(e) {
          try {
            return o._compile(e);
          } catch (r) {
            if (r instanceof s)
              return (function(r) {
                function a() {
                  delete o._loadingSchemas[u];
                }
                function s(e) {
                  return o._refs[e] || o._schemas[e];
                }
                var u = r.missingSchema;
                if (s(u))
                  throw new Error(
                    "Schema " +
                      u +
                      " is loaded but " +
                      r.missingRef +
                      " cannot be resolved"
                  );
                var l = o._loadingSchemas[u];
                return (
                  l ||
                    ((l = o._loadingSchemas[u] = o._opts.loadSchema(u)),
                    l.then(a, a)),
                  l
                    .then(function(e) {
                      if (!s(u))
                        return n(e).then(function() {
                          s(u) || o.addSchema(e, u, void 0, t);
                        });
                    })
                    .then(function() {
                      return i(e);
                    })
                );
              })(r);
            throw r;
          }
        }
        var o = this;
        if ("function" != typeof this._opts.loadSchema)
          throw new Error("options.loadSchema should be a function");
        "function" == typeof t && ((r = t), (t = void 0));
        var u = n(e).then(function() {
          var r = o._addSchema(e, void 0, t);
          return r.validate || i(r);
        });
        return (
          r &&
            u.then(function(e) {
              r(null, e);
            }, r),
          u
        );
      }
      var s = r(10).MissingRef;
      e.exports = a;
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        return (e = "full" == e ? "full" : "fast"), c.copy(a[e]);
      }
      function s(e) {
        var t = e.match(h);
        if (!t) return !1;
        var r = +t[1],
          a = +t[2];
        return r >= 1 && r <= 12 && a >= 1 && a <= f[r];
      }
      function n(e, t) {
        var r = e.match(p);
        if (!r) return !1;
        var a = r[1],
          s = r[2],
          n = r[3],
          i = r[5];
        return a <= 23 && s <= 59 && n <= 59 && (!t || i);
      }
      function i(e) {
        var t = e.split(b);
        return 2 == t.length && s(t[0]) && n(t[1], !0);
      }
      function o(e) {
        return e.length <= 255 && d.test(e);
      }
      function u(e) {
        return S.test(e) && m.test(e);
      }
      function l(e) {
        if (O.test(e)) return !1;
        try {
          return new RegExp(e), !0;
        } catch (e) {
          return !1;
        }
      }
      var c = r(5),
        h = /^\d\d\d\d-(\d\d)-(\d\d)$/,
        f = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
        p = /^(\d\d):(\d\d):(\d\d)(\.\d+)?(z|[+-]\d\d:\d\d)?$/i,
        d = /^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[-0-9a-z]{0,61}[0-9a-z])?)*$/i,
        m = /^(?:[a-z][a-z0-9+\-.]*:)(?:\/?\/(?:(?:[a-z0-9\-._~!$&'()*+,;=:]|%[0-9a-f]{2})*@)?(?:\[(?:(?:(?:(?:[0-9a-f]{1,4}:){6}|::(?:[0-9a-f]{1,4}:){5}|(?:[0-9a-f]{1,4})?::(?:[0-9a-f]{1,4}:){4}|(?:(?:[0-9a-f]{1,4}:){0,1}[0-9a-f]{1,4})?::(?:[0-9a-f]{1,4}:){3}|(?:(?:[0-9a-f]{1,4}:){0,2}[0-9a-f]{1,4})?::(?:[0-9a-f]{1,4}:){2}|(?:(?:[0-9a-f]{1,4}:){0,3}[0-9a-f]{1,4})?::[0-9a-f]{1,4}:|(?:(?:[0-9a-f]{1,4}:){0,4}[0-9a-f]{1,4})?::)(?:[0-9a-f]{1,4}:[0-9a-f]{1,4}|(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?))|(?:(?:[0-9a-f]{1,4}:){0,5}[0-9a-f]{1,4})?::[0-9a-f]{1,4}|(?:(?:[0-9a-f]{1,4}:){0,6}[0-9a-f]{1,4})?::)|[Vv][0-9a-f]+\.[a-z0-9\-._~!$&'()*+,;=:]+)\]|(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?)|(?:[a-z0-9\-._~!$&'()*+,;=]|%[0-9a-f]{2})*)(?::\d*)?(?:\/(?:[a-z0-9\-._~!$&'()*+,;=:@]|%[0-9a-f]{2})*)*|\/(?:(?:[a-z0-9\-._~!$&'()*+,;=:@]|%[0-9a-f]{2})+(?:\/(?:[a-z0-9\-._~!$&'()*+,;=:@]|%[0-9a-f]{2})*)*)?|(?:[a-z0-9\-._~!$&'()*+,;=:@]|%[0-9a-f]{2})+(?:\/(?:[a-z0-9\-._~!$&'()*+,;=:@]|%[0-9a-f]{2})*)*)(?:\?(?:[a-z0-9\-._~!$&'()*+,;=:@\/?]|%[0-9a-f]{2})*)?(?:\#(?:[a-z0-9\-._~!$&'()*+,;=:@\/?]|%[0-9a-f]{2})*)?$/i,
        v = /^(?:[a-z][a-z0-9+\-.]*:)?(?:\/?\/(?:(?:[a-z0-9\-._~!$&'()*+,;=:]|%[0-9a-f]{2})*@)?(?:\[(?:(?:(?:(?:[0-9a-f]{1,4}:){6}|::(?:[0-9a-f]{1,4}:){5}|(?:[0-9a-f]{1,4})?::(?:[0-9a-f]{1,4}:){4}|(?:(?:[0-9a-f]{1,4}:){0,1}[0-9a-f]{1,4})?::(?:[0-9a-f]{1,4}:){3}|(?:(?:[0-9a-f]{1,4}:){0,2}[0-9a-f]{1,4})?::(?:[0-9a-f]{1,4}:){2}|(?:(?:[0-9a-f]{1,4}:){0,3}[0-9a-f]{1,4})?::[0-9a-f]{1,4}:|(?:(?:[0-9a-f]{1,4}:){0,4}[0-9a-f]{1,4})?::)(?:[0-9a-f]{1,4}:[0-9a-f]{1,4}|(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?))|(?:(?:[0-9a-f]{1,4}:){0,5}[0-9a-f]{1,4})?::[0-9a-f]{1,4}|(?:(?:[0-9a-f]{1,4}:){0,6}[0-9a-f]{1,4})?::)|[Vv][0-9a-f]+\.[a-z0-9\-._~!$&'()*+,;=:]+)\]|(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?)|(?:[a-z0-9\-._~!$&'"()*+,;=]|%[0-9a-f]{2})*)(?::\d*)?(?:\/(?:[a-z0-9\-._~!$&'"()*+,;=:@]|%[0-9a-f]{2})*)*|\/(?:(?:[a-z0-9\-._~!$&'"()*+,;=:@]|%[0-9a-f]{2})+(?:\/(?:[a-z0-9\-._~!$&'"()*+,;=:@]|%[0-9a-f]{2})*)*)?|(?:[a-z0-9\-._~!$&'"()*+,;=:@]|%[0-9a-f]{2})+(?:\/(?:[a-z0-9\-._~!$&'"()*+,;=:@]|%[0-9a-f]{2})*)*)?(?:\?(?:[a-z0-9\-._~!$&'"()*+,;=:@\/?]|%[0-9a-f]{2})*)?(?:\#(?:[a-z0-9\-._~!$&'"()*+,;=:@\/?]|%[0-9a-f]{2})*)?$/i,
        g = /^(?:(?:[^\x00-\x20"'<>%\\^`{|}]|%[0-9a-f]{2})|\{[+#.\/;?&=,!@|]?(?:[a-z0-9_]|%[0-9a-f]{2})+(?:\:[1-9][0-9]{0,3}|\*)?(?:,(?:[a-z0-9_]|%[0-9a-f]{2})+(?:\:[1-9][0-9]{0,3}|\*)?)*\})*$/i,
        y = /^(?:(?:http[s\u017F]?|ftp):\/\/)(?:(?:[\0-\x08\x0E-\x1F!-\x9F\xA1-\u167F\u1681-\u1FFF\u200B-\u2027\u202A-\u202E\u2030-\u205E\u2060-\u2FFF\u3001-\uD7FF\uE000-\uFEFE\uFF00-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])+(?::(?:[\0-\x08\x0E-\x1F!-\x9F\xA1-\u167F\u1681-\u1FFF\u200B-\u2027\u202A-\u202E\u2030-\u205E\u2060-\u2FFF\u3001-\uD7FF\uE000-\uFEFE\uFF00-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])*)?@)?(?:(?!10(?:\.[0-9]{1,3}){3})(?!127(?:\.[0-9]{1,3}){3})(?!169\.254(?:\.[0-9]{1,3}){2})(?!192\.168(?:\.[0-9]{1,3}){2})(?!172\.(?:1[6-9]|2[0-9]|3[01])(?:\.[0-9]{1,3}){2})(?:[1-9][0-9]?|1[0-9][0-9]|2[01][0-9]|22[0-3])(?:\.(?:1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])){2}(?:\.(?:[1-9][0-9]?|1[0-9][0-9]|2[0-4][0-9]|25[0-4]))|(?:(?:(?:[0-9KSa-z\xA1-\uD7FF\uE000-\uFFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])+\-?)*(?:[0-9KSa-z\xA1-\uD7FF\uE000-\uFFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])+)(?:\.(?:(?:[0-9KSa-z\xA1-\uD7FF\uE000-\uFFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])+\-?)*(?:[0-9KSa-z\xA1-\uD7FF\uE000-\uFFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])+)*(?:\.(?:(?:[KSa-z\xA1-\uD7FF\uE000-\uFFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF]){2,})))(?::[0-9]{2,5})?(?:\/(?:[\0-\x08\x0E-\x1F!-\x9F\xA1-\u167F\u1681-\u1FFF\u200B-\u2027\u202A-\u202E\u2030-\u205E\u2060-\u2FFF\u3001-\uD7FF\uE000-\uFEFE\uFF00-\uFFFF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF])*)?$/i,
        P = /^(?:urn\:uuid\:)?[0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12}$/i,
        E = /^(?:\/(?:[^~\/]|~0|~1)*)*$|^\#(?:\/(?:[a-z0-9_\-\.!$&'()*+,;:=@]|%[0-9a-f]{2}|~0|~1)*)*$/i,
        w = /^(?:0|[1-9][0-9]*)(?:\#|(?:\/(?:[^~\/]|~0|~1)*)*)$/;
      (e.exports = a),
        (a.fast = {
          date: /^\d\d\d\d-[0-1]\d-[0-3]\d$/,
          time: /^[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?(?:z|[+-]\d\d:\d\d)?$/i,
          "date-time": /^\d\d\d\d-[0-1]\d-[0-3]\d[t\s][0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)?(?:z|[+-]\d\d:\d\d)$/i,
          uri: /^(?:[a-z][a-z0-9+-.]*)(?:\:|\/)\/?[^\s]*$/i,
          "uri-reference": /^(?:(?:[a-z][a-z0-9+-.]*:)?\/\/)?[^\s]*$/i,
          "uri-template": g,
          url: y,
          email: /^[a-z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)*$/i,
          hostname: d,
          ipv4: /^(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?)$/,
          ipv6: /^\s*(?:(?:(?:[0-9a-f]{1,4}:){7}(?:[0-9a-f]{1,4}|:))|(?:(?:[0-9a-f]{1,4}:){6}(?::[0-9a-f]{1,4}|(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(?:(?:[0-9a-f]{1,4}:){5}(?:(?:(?::[0-9a-f]{1,4}){1,2})|:(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(?:(?:[0-9a-f]{1,4}:){4}(?:(?:(?::[0-9a-f]{1,4}){1,3})|(?:(?::[0-9a-f]{1,4})?:(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(?:(?:[0-9a-f]{1,4}:){3}(?:(?:(?::[0-9a-f]{1,4}){1,4})|(?:(?::[0-9a-f]{1,4}){0,2}:(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(?:(?:[0-9a-f]{1,4}:){2}(?:(?:(?::[0-9a-f]{1,4}){1,5})|(?:(?::[0-9a-f]{1,4}){0,3}:(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(?:(?:[0-9a-f]{1,4}:){1}(?:(?:(?::[0-9a-f]{1,4}){1,6})|(?:(?::[0-9a-f]{1,4}){0,4}:(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(?::(?:(?:(?::[0-9a-f]{1,4}){1,7})|(?:(?::[0-9a-f]{1,4}){0,5}:(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(?:%.+)?\s*$/i,
          regex: l,
          uuid: P,
          "json-pointer": E,
          "relative-json-pointer": w
        }),
        (a.full = {
          date: s,
          time: n,
          "date-time": i,
          uri: u,
          "uri-reference": v,
          "uri-template": g,
          url: y,
          email: /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&''*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
          hostname: o,
          ipv4: /^(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?)$/,
          ipv6: /^\s*(?:(?:(?:[0-9a-f]{1,4}:){7}(?:[0-9a-f]{1,4}|:))|(?:(?:[0-9a-f]{1,4}:){6}(?::[0-9a-f]{1,4}|(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(?:(?:[0-9a-f]{1,4}:){5}(?:(?:(?::[0-9a-f]{1,4}){1,2})|:(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(?:(?:[0-9a-f]{1,4}:){4}(?:(?:(?::[0-9a-f]{1,4}){1,3})|(?:(?::[0-9a-f]{1,4})?:(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(?:(?:[0-9a-f]{1,4}:){3}(?:(?:(?::[0-9a-f]{1,4}){1,4})|(?:(?::[0-9a-f]{1,4}){0,2}:(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(?:(?:[0-9a-f]{1,4}:){2}(?:(?:(?::[0-9a-f]{1,4}){1,5})|(?:(?::[0-9a-f]{1,4}){0,3}:(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(?:(?:[0-9a-f]{1,4}:){1}(?:(?:(?::[0-9a-f]{1,4}){1,6})|(?:(?::[0-9a-f]{1,4}){0,4}:(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(?::(?:(?:(?::[0-9a-f]{1,4}){1,7})|(?:(?::[0-9a-f]{1,4}){0,5}:(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(?:%.+)?\s*$/i,
          regex: l,
          uuid: P,
          "json-pointer": E,
          "relative-json-pointer": w
        });
      var b = /t|\s/i,
        S = /\/|\:/,
        O = /[^\\]\\Z/;
    },
    function(e, t, r) {
      "use strict";
      function a(e, t, r, i) {
        function w() {
          var e = z.validate,
            t = e.apply(null, arguments);
          return (w.errors = e.errors), t;
        }
        function b(e, r, s, n) {
          var i = !r || (r && r.schema == e);
          if (r.schema != t.schema) return a.call(k, e, r, s, n);
          var m = !0 === e.$async,
            w = v({
              isTop: !0,
              schema: e,
              isRoot: i,
              baseId: n,
              root: r,
              schemaPath: "",
              errSchemaPath: "#",
              errorPath: '""',
              MissingRefError: d.MissingRef,
              RULES: H,
              validate: v,
              util: p,
              resolve: f,
              resolveRef: S,
              usePattern: R,
              useDefault: A,
              useCustomRule: D,
              opts: T,
              formats: M,
              self: k
            });
          (w = h(F, l) + h(I, o) + h(W, u) + h(C, c) + w),
            T.processCode && (w = T.processCode(w));
          var b;
          try {
            (b = new Function(
              "self",
              "RULES",
              "formats",
              "root",
              "refVal",
              "defaults",
              "customRules",
              "co",
              "equal",
              "ucs2length",
              "ValidationError",
              w
            )(k, H, M, t, F, W, C, g, P, y, E)),
              (F[0] = b);
          } catch (e) {
            throw (console.error("Error compiling schema, function code:", w),
            e);
          }
          return (
            (b.schema = e),
            (b.errors = null),
            (b.refs = j),
            (b.refVal = F),
            (b.root = i ? b : r),
            m && (b.$async = !0),
            !0 === T.sourceCode &&
              (b.source = { code: w, patterns: I, defaults: W }),
            b
          );
        }
        function S(e, s, n) {
          s = f.url(e, s);
          var i,
            o,
            u = j[s];
          if (void 0 !== u)
            return (i = F[u]), (o = "refVal[" + u + "]"), x(i, o);
          if (!n && t.refs) {
            var l = t.refs[s];
            if (void 0 !== l) return (i = t.refVal[l]), (o = O(s, i)), x(i, o);
          }
          o = O(s);
          var c = f.call(k, b, t, s);
          if (void 0 === c) {
            var h = r && r[s];
            h && (c = f.inlineRef(h, T.inlineRefs) ? h : a.call(k, h, t, r, e));
          }
          return void 0 !== c ? (_(s, c), x(c, o)) : void 0;
        }
        function O(e, t) {
          var r = F.length;
          return (F[r] = t), (j[e] = r), "refVal" + r;
        }
        function _(e, t) {
          var r = j[e];
          F[r] = t;
        }
        function x(e, t) {
          return "object" == typeof e || "boolean" == typeof e
            ? { code: t, schema: e, inline: !0 }
            : { code: t, $async: e && e.$async };
        }
        function R(e) {
          var t = $[e];
          return (
            void 0 === t && ((t = $[e] = I.length), (I[t] = e)), "pattern" + t
          );
        }
        function A(e) {
          switch (typeof e) {
            case "boolean":
            case "number":
              return "" + e;
            case "string":
              return p.toQuotedString(e);
            case "object":
              if (null === e) return "null";
              var t = m(e),
                r = N[t];
              return (
                void 0 === r && ((r = N[t] = W.length), (W[r] = e)),
                "default" + r
              );
          }
        }
        function D(e, t, r, a) {
          var s = e.definition.validateSchema;
          if (s && !1 !== k._opts.validateSchema) {
            if (!s(t)) {
              var n = "keyword schema is invalid: " + k.errorsText(s.errors);
              if ("log" != k._opts.validateSchema) throw new Error(n);
              console.error(n);
            }
          }
          var i,
            o = e.definition.compile,
            u = e.definition.inline,
            l = e.definition.macro;
          if (o) i = o.call(k, t, r, a);
          else if (l)
            (i = l.call(k, t, r, a)),
              !1 !== T.validateSchema && k.validateSchema(i, !0);
          else if (u) i = u.call(k, a, e.keyword, t, r);
          else if (!(i = e.definition.validate)) return;
          if (void 0 === i)
            throw new Error(
              'custom keyword "' + e.keyword + '"failed to compile'
            );
          var c = C.length;
          return (C[c] = i), { code: "customRule" + c, validate: i };
        }
        var k = this,
          T = this._opts,
          F = [void 0],
          j = {},
          I = [],
          $ = {},
          W = [],
          N = {},
          C = [];
        t = t || { schema: e, refVal: F, refs: j };
        var L = s.call(this, e, t, i),
          z = this._compilations[L.index];
        if (L.compiling) return (z.callValidate = w);
        var M = this._formats,
          H = this.RULES;
        try {
          var q = b(e, t, r, i);
          z.validate = q;
          var V = z.callValidate;
          return (
            V &&
              ((V.schema = q.schema),
              (V.errors = null),
              (V.refs = q.refs),
              (V.refVal = q.refVal),
              (V.root = q.root),
              (V.$async = q.$async),
              T.sourceCode && (V.source = q.source)),
            q
          );
        } finally {
          n.call(this, e, t, i);
        }
      }
      function s(e, t, r) {
        var a = i.call(this, e, t, r);
        return a >= 0
          ? { index: a, compiling: !0 }
          : ((a = this._compilations.length),
            (this._compilations[a] = { schema: e, root: t, baseId: r }),
            { index: a, compiling: !1 });
      }
      function n(e, t, r) {
        var a = i.call(this, e, t, r);
        a >= 0 && this._compilations.splice(a, 1);
      }
      function i(e, t, r) {
        for (var a = 0; a < this._compilations.length; a++) {
          var s = this._compilations[a];
          if (s.schema == e && s.root == t && s.baseId == r) return a;
        }
        return -1;
      }
      function o(e, t) {
        return (
          "var pattern" + e + " = new RegExp(" + p.toQuotedString(t[e]) + ");"
        );
      }
      function u(e) {
        return "var default" + e + " = defaults[" + e + "];";
      }
      function l(e, t) {
        return void 0 === t[e]
          ? ""
          : "var refVal" + e + " = refVal[" + e + "];";
      }
      function c(e) {
        return "var customRule" + e + " = customRules[" + e + "];";
      }
      function h(e, t) {
        if (!e.length) return "";
        for (var r = "", a = 0; a < e.length; a++) r += t(a, e);
        return r;
      }
      var f = r(11),
        p = r(5),
        d = r(10),
        m = r(21),
        v = r(18),
        g = r(20),
        y = p.ucs2length,
        P = r(9),
        E = d.Validation;
      e.exports = a;
    },
    function(e, t, r) {
      "use strict";
      var a = r(26),
        s = r(5).toHash;
      e.exports = function() {
        var e = [
            {
              type: "number",
              rules: [
                { maximum: ["exclusiveMaximum"] },
                { minimum: ["exclusiveMinimum"] },
                "multipleOf",
                "format"
              ]
            },
            {
              type: "string",
              rules: ["maxLength", "minLength", "pattern", "format"]
            },
            {
              type: "array",
              rules: [
                "maxItems",
                "minItems",
                "uniqueItems",
                "contains",
                "items"
              ]
            },
            {
              type: "object",
              rules: [
                "maxProperties",
                "minProperties",
                "required",
                "dependencies",
                "propertyNames",
                { properties: ["additionalProperties", "patternProperties"] }
              ]
            },
            {
              rules: ["$ref", "const", "enum", "not", "anyOf", "oneOf", "allOf"]
            }
          ],
          t = ["type"],
          r = [
            "additionalItems",
            "$schema",
            "id",
            "title",
            "description",
            "default",
            "definitions"
          ],
          n = [
            "number",
            "integer",
            "string",
            "array",
            "object",
            "boolean",
            "null"
          ];
        return (
          (e.all = s(t)),
          (e.types = s(n)),
          e.forEach(function(r) {
            (r.rules = r.rules.map(function(r) {
              var s;
              if ("object" == typeof r) {
                var n = Object.keys(r)[0];
                (s = r[n]),
                  (r = n),
                  s.forEach(function(r) {
                    t.push(r), (e.all[r] = !0);
                  });
              }
              return (
                t.push(r),
                (e.all[r] = { keyword: r, code: a[r], implements: s })
              );
            })),
              r.type && (e.types[r.type] = r);
          }),
          (e.keywords = s(t.concat(r))),
          (e.custom = {}),
          e
        );
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e) {
        for (var t, r = 0, a = e.length, s = 0; s < a; )
          r++,
            (t = e.charCodeAt(s++)) >= 55296 &&
              t <= 56319 &&
              s < a &&
              56320 == (64512 & (t = e.charCodeAt(s))) &&
              s++;
        return r;
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a = " ",
          s = e.schema[t],
          n = e.schemaPath + e.util.getProperty(t),
          i = e.errSchemaPath + "/" + t,
          o = !e.opts.allErrors,
          u = e.util.copy(e),
          l = "";
        u.level++;
        var c = "valid" + u.level,
          h = u.baseId,
          f = !0,
          p = s;
        if (p)
          for (var d, m = -1, v = p.length - 1; m < v; )
            (d = p[(m += 1)]),
              e.util.schemaHasRules(d, e.RULES.all) &&
                ((f = !1),
                (u.schema = d),
                (u.schemaPath = n + "[" + m + "]"),
                (u.errSchemaPath = i + "/" + m),
                (a += "  " + e.validate(u) + " "),
                (u.baseId = h),
                o && ((a += " if (" + c + ") { "), (l += "}")));
        return (
          o && (a += f ? " if (true) { " : " " + l.slice(0, -1) + " "),
          (a = e.util.cleanUpCode(a))
        );
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a = " ",
          s = e.level,
          n = e.dataLevel,
          i = e.schema[t],
          o = e.schemaPath + e.util.getProperty(t),
          u = e.errSchemaPath + "/" + t,
          l = !e.opts.allErrors,
          c = "data" + (n || ""),
          h = "valid" + s,
          f = "errs__" + s,
          p = e.util.copy(e),
          d = "";
        p.level++;
        var m = "valid" + p.level;
        if (
          i.every(function(t) {
            return e.util.schemaHasRules(t, e.RULES.all);
          })
        ) {
          var v = p.baseId;
          a += " var " + f + " = errors; var " + h + " = false;  ";
          var g = e.compositeRule;
          e.compositeRule = p.compositeRule = !0;
          var y = i;
          if (y)
            for (var P, E = -1, w = y.length - 1; E < w; )
              (P = y[(E += 1)]),
                (p.schema = P),
                (p.schemaPath = o + "[" + E + "]"),
                (p.errSchemaPath = u + "/" + E),
                (a += "  " + e.validate(p) + " "),
                (p.baseId = v),
                (a +=
                  " " + h + " = " + h + " || " + m + "; if (!" + h + ") { "),
                (d += "}");
          (e.compositeRule = p.compositeRule = g),
            (a += " " + d + " if (!" + h + ") {   var err =   "),
            !1 !== e.createErrors
              ? ((a +=
                  " { keyword: 'anyOf' , dataPath: (dataPath || '') + " +
                  e.errorPath +
                  " , schemaPath: " +
                  e.util.toQuotedString(u) +
                  " , params: {} "),
                !1 !== e.opts.messages &&
                  (a += " , message: 'should match some schema in anyOf' "),
                e.opts.verbose &&
                  (a +=
                    " , schema: validate.schema" +
                    o +
                    " , parentSchema: validate.schema" +
                    e.schemaPath +
                    " , data: " +
                    c +
                    " "),
                (a += " } "))
              : (a += " {} "),
            (a +=
              ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
            !e.compositeRule &&
              l &&
              (e.async
                ? (a += " throw new ValidationError(vErrors); ")
                : (a += " validate.errors = vErrors; return false; ")),
            (a +=
              " } else {  errors = " +
              f +
              "; if (vErrors !== null) { if (" +
              f +
              ") vErrors.length = " +
              f +
              "; else vErrors = null; } "),
            e.opts.allErrors && (a += " } "),
            (a = e.util.cleanUpCode(a));
        } else l && (a += " if (true) { ");
        return a;
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a = " ",
          s = e.level,
          n = e.dataLevel,
          i = e.schema[t],
          o = e.schemaPath + e.util.getProperty(t),
          u = e.errSchemaPath + "/" + t,
          l = !e.opts.allErrors,
          c = "data" + (n || ""),
          h = "valid" + s,
          f = e.opts.$data && i && i.$data;
        f &&
          (a +=
            " var schema" +
            s +
            " = " +
            e.util.getData(i.$data, n, e.dataPathArr) +
            "; "),
          f || (a += " var schema" + s + " = validate.schema" + o + ";"),
          (a +=
            "var " +
            h +
            " = equal(" +
            c +
            ", schema" +
            s +
            "); if (!" +
            h +
            ") {   ");
        var p = p || [];
        p.push(a),
          (a = ""),
          !1 !== e.createErrors
            ? ((a +=
                " { keyword: 'const' , dataPath: (dataPath || '') + " +
                e.errorPath +
                " , schemaPath: " +
                e.util.toQuotedString(u) +
                " , params: {} "),
              !1 !== e.opts.messages &&
                (a += " , message: 'should be equal to constant' "),
              e.opts.verbose &&
                (a +=
                  " , schema: validate.schema" +
                  o +
                  " , parentSchema: validate.schema" +
                  e.schemaPath +
                  " , data: " +
                  c +
                  " "),
              (a += " } "))
            : (a += " {} ");
        var d = a;
        return (
          (a = p.pop()),
          !e.compositeRule && l
            ? e.async
              ? (a += " throw new ValidationError([" + d + "]); ")
              : (a += " validate.errors = [" + d + "]; return false; ")
            : (a +=
                " var err = " +
                d +
                ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
          (a += " }"),
          l && (a += " else { "),
          a
        );
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a = " ",
          s = e.level,
          n = e.dataLevel,
          i = e.schema[t],
          o = e.schemaPath + e.util.getProperty(t),
          u = e.errSchemaPath + "/" + t,
          l = !e.opts.allErrors,
          c = "data" + (n || ""),
          h = "valid" + s,
          f = "errs__" + s,
          p = e.util.copy(e);
        p.level++;
        var d = "valid" + p.level,
          m = "i" + s,
          v = (p.dataLevel = e.dataLevel + 1),
          g = "data" + v,
          y = e.baseId,
          P = e.util.schemaHasRules(i, e.RULES.all);
        if (((a += "var " + f + " = errors;var " + h + ";"), P)) {
          var E = e.compositeRule;
          (e.compositeRule = p.compositeRule = !0),
            (p.schema = i),
            (p.schemaPath = o),
            (p.errSchemaPath = u),
            (a +=
              " var " +
              d +
              " = false; for (var " +
              m +
              " = 0; " +
              m +
              " < " +
              c +
              ".length; " +
              m +
              "++) { "),
            (p.errorPath = e.util.getPathExpr(
              e.errorPath,
              m,
              e.opts.jsonPointers,
              !0
            ));
          var w = c + "[" + m + "]";
          p.dataPathArr[v] = m;
          var b = e.validate(p);
          (p.baseId = y),
            e.util.varOccurences(b, g) < 2
              ? (a += " " + e.util.varReplace(b, g, w) + " ")
              : (a += " var " + g + " = " + w + "; " + b + " "),
            (a += " if (" + d + ") break; }  "),
            (e.compositeRule = p.compositeRule = E),
            (a += "  if (!" + d + ") {");
        } else a += " if (" + c + ".length == 0) {";
        var S = S || [];
        S.push(a),
          (a = ""),
          !1 !== e.createErrors
            ? ((a +=
                " { keyword: 'contains' , dataPath: (dataPath || '') + " +
                e.errorPath +
                " , schemaPath: " +
                e.util.toQuotedString(u) +
                " , params: {} "),
              !1 !== e.opts.messages &&
                (a += " , message: 'should contain a valid item' "),
              e.opts.verbose &&
                (a +=
                  " , schema: validate.schema" +
                  o +
                  " , parentSchema: validate.schema" +
                  e.schemaPath +
                  " , data: " +
                  c +
                  " "),
              (a += " } "))
            : (a += " {} ");
        var O = a;
        return (
          (a = S.pop()),
          !e.compositeRule && l
            ? e.async
              ? (a += " throw new ValidationError([" + O + "]); ")
              : (a += " validate.errors = [" + O + "]; return false; ")
            : (a +=
                " var err = " +
                O +
                ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
          (a += " } else { "),
          P &&
            (a +=
              "  errors = " +
              f +
              "; if (vErrors !== null) { if (" +
              f +
              ") vErrors.length = " +
              f +
              "; else vErrors = null; } "),
          e.opts.allErrors && (a += " } "),
          (a = e.util.cleanUpCode(a))
        );
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a,
          s,
          n = " ",
          i = e.level,
          o = e.dataLevel,
          u = e.schema[t],
          l = e.schemaPath + e.util.getProperty(t),
          c = e.errSchemaPath + "/" + t,
          h = !e.opts.allErrors,
          f = "data" + (o || ""),
          p = "valid" + i,
          d = "errs__" + i,
          m = e.opts.$data && u && u.$data;
        m
          ? ((n +=
              " var schema" +
              i +
              " = " +
              e.util.getData(u.$data, o, e.dataPathArr) +
              "; "),
            (s = "schema" + i))
          : (s = u);
        var v,
          g,
          y,
          P,
          E,
          w = this,
          b = "definition" + i,
          S = w.definition,
          O = "";
        if (m && S.$data) {
          E = "keywordValidate" + i;
          var _ = S.validateSchema;
          n +=
            " var " +
            b +
            " = RULES.custom['" +
            t +
            "'].definition; var " +
            E +
            " = " +
            b +
            ".validate;";
        } else {
          if (!(P = e.useCustomRule(w, u, e.schema, e))) return;
          (s = "validate.schema" + l),
            (E = P.code),
            (v = S.compile),
            (g = S.inline),
            (y = S.macro);
        }
        var x = E + ".errors",
          R = "i" + i,
          A = "ruleErr" + i,
          D = S.async;
        if (D && !e.async) throw new Error("async keyword in sync schema");
        if (
          (g || y || (n += x + " = null;"),
          (n += "var " + d + " = errors;var " + p + ";"),
          m &&
            S.$data &&
            ((O += "}"),
            (n +=
              " if (" + s + " === undefined) { " + p + " = true; } else { "),
            _ &&
              ((O += "}"),
              (n +=
                " " +
                p +
                " = " +
                b +
                ".validateSchema(" +
                s +
                "); if (" +
                p +
                ") { "))),
          g)
        )
          S.statements
            ? (n += " " + P.validate + " ")
            : (n += " " + p + " = " + P.validate + "; ");
        else if (y) {
          var k = e.util.copy(e),
            O = "";
          k.level++;
          var T = "valid" + k.level;
          (k.schema = P.validate), (k.schemaPath = "");
          var F = e.compositeRule;
          e.compositeRule = k.compositeRule = !0;
          var j = e.validate(k).replace(/validate\.schema/g, E);
          (e.compositeRule = k.compositeRule = F), (n += " " + j);
        } else {
          var I = I || [];
          I.push(n),
            (n = ""),
            (n += "  " + E + ".call( "),
            e.opts.passContext ? (n += "this") : (n += "self"),
            v || !1 === S.schema
              ? (n += " , " + f + " ")
              : (n +=
                  " , " +
                  s +
                  " , " +
                  f +
                  " , validate.schema" +
                  e.schemaPath +
                  " "),
            (n += " , (dataPath || '')"),
            '""' != e.errorPath && (n += " + " + e.errorPath);
          var $ = o ? "data" + (o - 1 || "") : "parentData",
            W = o ? e.dataPathArr[o] : "parentDataProperty";
          n += " , " + $ + " , " + W + " , rootData )  ";
          var N = n;
          (n = I.pop()),
            !1 === S.errors
              ? ((n += " " + p + " = "),
                D && (n += "" + e.yieldAwait),
                (n += N + "; "))
              : D
              ? ((x = "customErrors" + i),
                (n +=
                  " var " +
                  x +
                  " = null; try { " +
                  p +
                  " = " +
                  e.yieldAwait +
                  N +
                  "; } catch (e) { " +
                  p +
                  " = false; if (e instanceof ValidationError) " +
                  x +
                  " = e.errors; else throw e; } "))
              : (n += " " + x + " = null; " + p + " = " + N + "; ");
        }
        if (
          (S.modifying &&
            (n += " if (" + $ + ") " + f + " = " + $ + "[" + W + "];"),
          (n += "" + O),
          S.valid)
        )
          h && (n += " if (true) { ");
        else {
          (n += " if ( "),
            void 0 === S.valid
              ? ((n += " !"), (n += y ? "" + T : "" + p))
              : (n += " " + !S.valid + " "),
            (n += ") { "),
            (a = w.keyword);
          var I = I || [];
          I.push(n), (n = "");
          var I = I || [];
          I.push(n),
            (n = ""),
            !1 !== e.createErrors
              ? ((n +=
                  " { keyword: '" +
                  (a || "custom") +
                  "' , dataPath: (dataPath || '') + " +
                  e.errorPath +
                  " , schemaPath: " +
                  e.util.toQuotedString(c) +
                  " , params: { keyword: '" +
                  w.keyword +
                  "' } "),
                !1 !== e.opts.messages &&
                  (n +=
                    " , message: 'should pass \"" +
                    w.keyword +
                    "\" keyword validation' "),
                e.opts.verbose &&
                  (n +=
                    " , schema: validate.schema" +
                    l +
                    " , parentSchema: validate.schema" +
                    e.schemaPath +
                    " , data: " +
                    f +
                    " "),
                (n += " } "))
              : (n += " {} ");
          var C = n;
          (n = I.pop()),
            !e.compositeRule && h
              ? e.async
                ? (n += " throw new ValidationError([" + C + "]); ")
                : (n += " validate.errors = [" + C + "]; return false; ")
              : (n +=
                  " var err = " +
                  C +
                  ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; ");
          var L = n;
          (n = I.pop()),
            g
              ? S.errors
                ? "full" != S.errors &&
                  ((n +=
                    "  for (var " +
                    R +
                    "=" +
                    d +
                    "; " +
                    R +
                    "<errors; " +
                    R +
                    "++) { var " +
                    A +
                    " = vErrors[" +
                    R +
                    "]; if (" +
                    A +
                    ".dataPath === undefined) " +
                    A +
                    ".dataPath = (dataPath || '') + " +
                    e.errorPath +
                    "; if (" +
                    A +
                    ".schemaPath === undefined) { " +
                    A +
                    '.schemaPath = "' +
                    c +
                    '"; } '),
                  e.opts.verbose &&
                    (n +=
                      " " +
                      A +
                      ".schema = " +
                      s +
                      "; " +
                      A +
                      ".data = " +
                      f +
                      "; "),
                  (n += " } "))
                : !1 === S.errors
                ? (n += " " + L + " ")
                : ((n +=
                    " if (" +
                    d +
                    " == errors) { " +
                    L +
                    " } else {  for (var " +
                    R +
                    "=" +
                    d +
                    "; " +
                    R +
                    "<errors; " +
                    R +
                    "++) { var " +
                    A +
                    " = vErrors[" +
                    R +
                    "]; if (" +
                    A +
                    ".dataPath === undefined) " +
                    A +
                    ".dataPath = (dataPath || '') + " +
                    e.errorPath +
                    "; if (" +
                    A +
                    ".schemaPath === undefined) { " +
                    A +
                    '.schemaPath = "' +
                    c +
                    '"; } '),
                  e.opts.verbose &&
                    (n +=
                      " " +
                      A +
                      ".schema = " +
                      s +
                      "; " +
                      A +
                      ".data = " +
                      f +
                      "; "),
                  (n += " } } "))
              : y
              ? ((n += "   var err =   "),
                !1 !== e.createErrors
                  ? ((n +=
                      " { keyword: '" +
                      (a || "custom") +
                      "' , dataPath: (dataPath || '') + " +
                      e.errorPath +
                      " , schemaPath: " +
                      e.util.toQuotedString(c) +
                      " , params: { keyword: '" +
                      w.keyword +
                      "' } "),
                    !1 !== e.opts.messages &&
                      (n +=
                        " , message: 'should pass \"" +
                        w.keyword +
                        "\" keyword validation' "),
                    e.opts.verbose &&
                      (n +=
                        " , schema: validate.schema" +
                        l +
                        " , parentSchema: validate.schema" +
                        e.schemaPath +
                        " , data: " +
                        f +
                        " "),
                    (n += " } "))
                  : (n += " {} "),
                (n +=
                  ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
                !e.compositeRule &&
                  h &&
                  (e.async
                    ? (n += " throw new ValidationError(vErrors); ")
                    : (n += " validate.errors = vErrors; return false; ")))
              : !1 === S.errors
              ? (n += " " + L + " ")
              : ((n +=
                  " if (Array.isArray(" +
                  x +
                  ")) { if (vErrors === null) vErrors = " +
                  x +
                  "; else vErrors = vErrors.concat(" +
                  x +
                  "); errors = vErrors.length;  for (var " +
                  R +
                  "=" +
                  d +
                  "; " +
                  R +
                  "<errors; " +
                  R +
                  "++) { var " +
                  A +
                  " = vErrors[" +
                  R +
                  "]; if (" +
                  A +
                  ".dataPath === undefined) " +
                  A +
                  ".dataPath = (dataPath || '') + " +
                  e.errorPath +
                  ";  " +
                  A +
                  '.schemaPath = "' +
                  c +
                  '";  '),
                e.opts.verbose &&
                  (n +=
                    " " +
                    A +
                    ".schema = " +
                    s +
                    "; " +
                    A +
                    ".data = " +
                    f +
                    "; "),
                (n += " } } else { " + L + " } ")),
            (n += " } "),
            h && (n += " else { ");
        }
        return n;
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a = " ",
          s = e.level,
          n = e.dataLevel,
          i = e.schema[t],
          o = e.schemaPath + e.util.getProperty(t),
          u = e.errSchemaPath + "/" + t,
          l = !e.opts.allErrors,
          c = "data" + (n || ""),
          h = "errs__" + s,
          f = e.util.copy(e),
          p = "";
        f.level++;
        var d = "valid" + f.level,
          m = {},
          v = {},
          g = e.opts.ownProperties;
        for (w in i) {
          var y = i[w],
            P = Array.isArray(y) ? v : m;
          P[w] = y;
        }
        a += "var " + h + " = errors;";
        var E = e.errorPath;
        a += "var missing" + s + ";";
        for (var w in v)
          if (((P = v[w]), P.length)) {
            if (
              ((a += " if ( " + c + e.util.getProperty(w) + " !== undefined "),
              g &&
                (a +=
                  " && Object.prototype.hasOwnProperty.call(" +
                  c +
                  ", '" +
                  e.util.escapeQuotes(w) +
                  "') "),
              l)
            ) {
              a += " && ( ";
              var b = P;
              if (b)
                for (var S, O = -1, _ = b.length - 1; O < _; ) {
                  (S = b[(O += 1)]), O && (a += " || ");
                  var x = e.util.getProperty(S),
                    R = c + x;
                  (a += " ( ( " + R + " === undefined "),
                    g &&
                      (a +=
                        " || ! Object.prototype.hasOwnProperty.call(" +
                        c +
                        ", '" +
                        e.util.escapeQuotes(S) +
                        "') "),
                    (a +=
                      ") && (missing" +
                      s +
                      " = " +
                      e.util.toQuotedString(e.opts.jsonPointers ? S : x) +
                      ") ) ");
                }
              a += ")) {  ";
              var A = "missing" + s,
                D = "' + " + A + " + '";
              e.opts._errorDataPathProperty &&
                (e.errorPath = e.opts.jsonPointers
                  ? e.util.getPathExpr(E, A, !0)
                  : E + " + " + A);
              var k = k || [];
              k.push(a),
                (a = ""),
                !1 !== e.createErrors
                  ? ((a +=
                      " { keyword: 'dependencies' , dataPath: (dataPath || '') + " +
                      e.errorPath +
                      " , schemaPath: " +
                      e.util.toQuotedString(u) +
                      " , params: { property: '" +
                      e.util.escapeQuotes(w) +
                      "', missingProperty: '" +
                      D +
                      "', depsCount: " +
                      P.length +
                      ", deps: '" +
                      e.util.escapeQuotes(1 == P.length ? P[0] : P.join(", ")) +
                      "' } "),
                    !1 !== e.opts.messages &&
                      ((a += " , message: 'should have "),
                      1 == P.length
                        ? (a += "property " + e.util.escapeQuotes(P[0]))
                        : (a +=
                            "properties " + e.util.escapeQuotes(P.join(", "))),
                      (a +=
                        " when property " +
                        e.util.escapeQuotes(w) +
                        " is present' ")),
                    e.opts.verbose &&
                      (a +=
                        " , schema: validate.schema" +
                        o +
                        " , parentSchema: validate.schema" +
                        e.schemaPath +
                        " , data: " +
                        c +
                        " "),
                    (a += " } "))
                  : (a += " {} ");
              var T = a;
              (a = k.pop()),
                !e.compositeRule && l
                  ? e.async
                    ? (a += " throw new ValidationError([" + T + "]); ")
                    : (a += " validate.errors = [" + T + "]; return false; ")
                  : (a +=
                      " var err = " +
                      T +
                      ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; ");
            } else {
              a += " ) { ";
              var F = P;
              if (F)
                for (var S, j = -1, I = F.length - 1; j < I; ) {
                  S = F[(j += 1)];
                  var x = e.util.getProperty(S),
                    D = e.util.escapeQuotes(S),
                    R = c + x;
                  e.opts._errorDataPathProperty &&
                    (e.errorPath = e.util.getPath(E, S, e.opts.jsonPointers)),
                    (a += " if ( " + R + " === undefined "),
                    g &&
                      (a +=
                        " || ! Object.prototype.hasOwnProperty.call(" +
                        c +
                        ", '" +
                        e.util.escapeQuotes(S) +
                        "') "),
                    (a += ") {  var err =   "),
                    !1 !== e.createErrors
                      ? ((a +=
                          " { keyword: 'dependencies' , dataPath: (dataPath || '') + " +
                          e.errorPath +
                          " , schemaPath: " +
                          e.util.toQuotedString(u) +
                          " , params: { property: '" +
                          e.util.escapeQuotes(w) +
                          "', missingProperty: '" +
                          D +
                          "', depsCount: " +
                          P.length +
                          ", deps: '" +
                          e.util.escapeQuotes(
                            1 == P.length ? P[0] : P.join(", ")
                          ) +
                          "' } "),
                        !1 !== e.opts.messages &&
                          ((a += " , message: 'should have "),
                          1 == P.length
                            ? (a += "property " + e.util.escapeQuotes(P[0]))
                            : (a +=
                                "properties " +
                                e.util.escapeQuotes(P.join(", "))),
                          (a +=
                            " when property " +
                            e.util.escapeQuotes(w) +
                            " is present' ")),
                        e.opts.verbose &&
                          (a +=
                            " , schema: validate.schema" +
                            o +
                            " , parentSchema: validate.schema" +
                            e.schemaPath +
                            " , data: " +
                            c +
                            " "),
                        (a += " } "))
                      : (a += " {} "),
                    (a +=
                      ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; } ");
                }
            }
            (a += " }   "), l && ((p += "}"), (a += " else { "));
          }
        e.errorPath = E;
        var $ = f.baseId;
        for (var w in m) {
          var y = m[w];
          e.util.schemaHasRules(y, e.RULES.all) &&
            ((a +=
              " " +
              d +
              " = true; if ( " +
              c +
              e.util.getProperty(w) +
              " !== undefined "),
            g &&
              (a +=
                " && Object.prototype.hasOwnProperty.call(" +
                c +
                ", '" +
                e.util.escapeQuotes(w) +
                "') "),
            (a += ") { "),
            (f.schema = y),
            (f.schemaPath = o + e.util.getProperty(w)),
            (f.errSchemaPath = u + "/" + e.util.escapeFragment(w)),
            (a += "  " + e.validate(f) + " "),
            (f.baseId = $),
            (a += " }  "),
            l && ((a += " if (" + d + ") { "), (p += "}")));
        }
        return (
          l && (a += "   " + p + " if (" + h + " == errors) {"),
          (a = e.util.cleanUpCode(a))
        );
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a = " ",
          s = e.level,
          n = e.dataLevel,
          i = e.schema[t],
          o = e.schemaPath + e.util.getProperty(t),
          u = e.errSchemaPath + "/" + t,
          l = !e.opts.allErrors,
          c = "data" + (n || ""),
          h = "valid" + s,
          f = e.opts.$data && i && i.$data;
        f &&
          (a +=
            " var schema" +
            s +
            " = " +
            e.util.getData(i.$data, n, e.dataPathArr) +
            "; ");
        var p = "i" + s,
          d = "schema" + s;
        f || (a += " var " + d + " = validate.schema" + o + ";"),
          (a += "var " + h + ";"),
          f &&
            (a +=
              " if (schema" +
              s +
              " === undefined) " +
              h +
              " = true; else if (!Array.isArray(schema" +
              s +
              ")) " +
              h +
              " = false; else {"),
          (a +=
            h +
            " = false;for (var " +
            p +
            "=0; " +
            p +
            "<" +
            d +
            ".length; " +
            p +
            "++) if (equal(" +
            c +
            ", " +
            d +
            "[" +
            p +
            "])) { " +
            h +
            " = true; break; }"),
          f && (a += "  }  "),
          (a += " if (!" + h + ") {   ");
        var m = m || [];
        m.push(a),
          (a = ""),
          !1 !== e.createErrors
            ? ((a +=
                " { keyword: 'enum' , dataPath: (dataPath || '') + " +
                e.errorPath +
                " , schemaPath: " +
                e.util.toQuotedString(u) +
                " , params: { allowedValues: schema" +
                s +
                " } "),
              !1 !== e.opts.messages &&
                (a +=
                  " , message: 'should be equal to one of the allowed values' "),
              e.opts.verbose &&
                (a +=
                  " , schema: validate.schema" +
                  o +
                  " , parentSchema: validate.schema" +
                  e.schemaPath +
                  " , data: " +
                  c +
                  " "),
              (a += " } "))
            : (a += " {} ");
        var v = a;
        return (
          (a = m.pop()),
          !e.compositeRule && l
            ? e.async
              ? (a += " throw new ValidationError([" + v + "]); ")
              : (a += " validate.errors = [" + v + "]; return false; ")
            : (a +=
                " var err = " +
                v +
                ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
          (a += " }"),
          l && (a += " else { "),
          a
        );
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a = " ",
          s = e.level,
          n = e.dataLevel,
          i = e.schema[t],
          o = e.schemaPath + e.util.getProperty(t),
          u = e.errSchemaPath + "/" + t,
          l = !e.opts.allErrors,
          c = "data" + (n || "");
        if (!1 === e.opts.format) return l && (a += " if (true) { "), a;
        var h,
          f = e.opts.$data && i && i.$data;
        f
          ? ((a +=
              " var schema" +
              s +
              " = " +
              e.util.getData(i.$data, n, e.dataPathArr) +
              "; "),
            (h = "schema" + s))
          : (h = i);
        var p = e.opts.unknownFormats,
          d = Array.isArray(p);
        if (f) {
          var m = "format" + s,
            v = "isObject" + s,
            g = "formatType" + s;
          (a +=
            " var " +
            m +
            " = formats[" +
            h +
            "]; var " +
            v +
            " = typeof " +
            m +
            " == 'object' && !(" +
            m +
            " instanceof RegExp) && " +
            m +
            ".validate; var " +
            g +
            " = " +
            v +
            " && " +
            m +
            ".type || 'string'; if (" +
            v +
            ") { "),
            e.async && (a += " var async" + s + " = " + m + ".async; "),
            (a += " " + m + " = " + m + ".validate; } if (  "),
            f &&
              (a +=
                " (" +
                h +
                " !== undefined && typeof " +
                h +
                " != 'string') || "),
            (a += " ("),
            "ignore" != p &&
              ((a += " (" + h + " && !" + m + " "),
              d &&
                (a +=
                  " && self._opts.unknownFormats.indexOf(" + h + ") == -1 "),
              (a += ") || ")),
            (a +=
              " (" +
              m +
              " && " +
              g +
              " == '" +
              r +
              "' && !(typeof " +
              m +
              " == 'function' ? "),
            e.async
              ? (a +=
                  " (async" +
                  s +
                  " ? " +
                  e.yieldAwait +
                  " " +
                  m +
                  "(" +
                  c +
                  ") : " +
                  m +
                  "(" +
                  c +
                  ")) ")
              : (a += " " + m + "(" + c + ") "),
            (a += " : " + m + ".test(" + c + "))))) {");
        } else {
          var m = e.formats[i];
          if (!m) {
            if ("ignore" == p)
              return (
                console.warn(
                  'unknown format "' +
                    i +
                    '" ignored in schema at path "' +
                    e.errSchemaPath +
                    '"'
                ),
                l && (a += " if (true) { "),
                a
              );
            if (d && p.indexOf(i) >= 0) return l && (a += " if (true) { "), a;
            throw new Error(
              'unknown format "' +
                i +
                '" is used in schema at path "' +
                e.errSchemaPath +
                '"'
            );
          }
          var v = "object" == typeof m && !(m instanceof RegExp) && m.validate,
            g = (v && m.type) || "string";
          if (v) {
            var y = !0 === m.async;
            m = m.validate;
          }
          if (g != r) return l && (a += " if (true) { "), a;
          if (y) {
            if (!e.async) throw new Error("async format in sync schema");
            var P = "formats" + e.util.getProperty(i) + ".validate";
            a += " if (!(" + e.yieldAwait + " " + P + "(" + c + "))) { ";
          } else {
            a += " if (! ";
            var P = "formats" + e.util.getProperty(i);
            v && (P += ".validate"),
              (a +=
                "function" == typeof m
                  ? " " + P + "(" + c + ") "
                  : " " + P + ".test(" + c + ") "),
              (a += ") { ");
          }
        }
        var E = E || [];
        E.push(a),
          (a = ""),
          !1 !== e.createErrors
            ? ((a +=
                " { keyword: 'format' , dataPath: (dataPath || '') + " +
                e.errorPath +
                " , schemaPath: " +
                e.util.toQuotedString(u) +
                " , params: { format:  "),
              (a += f ? "" + h : "" + e.util.toQuotedString(i)),
              (a += "  } "),
              !1 !== e.opts.messages &&
                ((a += " , message: 'should match format \""),
                (a += f ? "' + " + h + " + '" : "" + e.util.escapeQuotes(i)),
                (a += "\"' ")),
              e.opts.verbose &&
                ((a += " , schema:  "),
                (a += f
                  ? "validate.schema" + o
                  : "" + e.util.toQuotedString(i)),
                (a +=
                  "         , parentSchema: validate.schema" +
                  e.schemaPath +
                  " , data: " +
                  c +
                  " ")),
              (a += " } "))
            : (a += " {} ");
        var w = a;
        return (
          (a = E.pop()),
          !e.compositeRule && l
            ? e.async
              ? (a += " throw new ValidationError([" + w + "]); ")
              : (a += " validate.errors = [" + w + "]; return false; ")
            : (a +=
                " var err = " +
                w +
                ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
          (a += " } "),
          l && (a += " else { "),
          a
        );
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a = " ",
          s = e.level,
          n = e.dataLevel,
          i = e.schema[t],
          o = e.schemaPath + e.util.getProperty(t),
          u = e.errSchemaPath + "/" + t,
          l = !e.opts.allErrors,
          c = "data" + (n || ""),
          h = "valid" + s,
          f = "errs__" + s,
          p = e.util.copy(e),
          d = "";
        p.level++;
        var m = "valid" + p.level,
          v = "i" + s,
          g = (p.dataLevel = e.dataLevel + 1),
          y = "data" + g,
          P = e.baseId;
        if (
          ((a += "var " + f + " = errors;var " + h + ";"), Array.isArray(i))
        ) {
          var E = e.schema.additionalItems;
          if (!1 === E) {
            a += " " + h + " = " + c + ".length <= " + i.length + "; ";
            var w = u;
            (u = e.errSchemaPath + "/additionalItems"),
              (a += "  if (!" + h + ") {   ");
            var b = b || [];
            b.push(a),
              (a = ""),
              !1 !== e.createErrors
                ? ((a +=
                    " { keyword: 'additionalItems' , dataPath: (dataPath || '') + " +
                    e.errorPath +
                    " , schemaPath: " +
                    e.util.toQuotedString(u) +
                    " , params: { limit: " +
                    i.length +
                    " } "),
                  !1 !== e.opts.messages &&
                    (a +=
                      " , message: 'should NOT have more than " +
                      i.length +
                      " items' "),
                  e.opts.verbose &&
                    (a +=
                      " , schema: false , parentSchema: validate.schema" +
                      e.schemaPath +
                      " , data: " +
                      c +
                      " "),
                  (a += " } "))
                : (a += " {} ");
            var S = a;
            (a = b.pop()),
              !e.compositeRule && l
                ? e.async
                  ? (a += " throw new ValidationError([" + S + "]); ")
                  : (a += " validate.errors = [" + S + "]; return false; ")
                : (a +=
                    " var err = " +
                    S +
                    ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
              (a += " } "),
              (u = w),
              l && ((d += "}"), (a += " else { "));
          }
          var O = i;
          if (O)
            for (var _, x = -1, R = O.length - 1; x < R; )
              if (((_ = O[(x += 1)]), e.util.schemaHasRules(_, e.RULES.all))) {
                a += " " + m + " = true; if (" + c + ".length > " + x + ") { ";
                var A = c + "[" + x + "]";
                (p.schema = _),
                  (p.schemaPath = o + "[" + x + "]"),
                  (p.errSchemaPath = u + "/" + x),
                  (p.errorPath = e.util.getPathExpr(
                    e.errorPath,
                    x,
                    e.opts.jsonPointers,
                    !0
                  )),
                  (p.dataPathArr[g] = x);
                var D = e.validate(p);
                (p.baseId = P),
                  e.util.varOccurences(D, y) < 2
                    ? (a += " " + e.util.varReplace(D, y, A) + " ")
                    : (a += " var " + y + " = " + A + "; " + D + " "),
                  (a += " }  "),
                  l && ((a += " if (" + m + ") { "), (d += "}"));
              }
          if ("object" == typeof E && e.util.schemaHasRules(E, e.RULES.all)) {
            (p.schema = E),
              (p.schemaPath = e.schemaPath + ".additionalItems"),
              (p.errSchemaPath = e.errSchemaPath + "/additionalItems"),
              (a +=
                " " +
                m +
                " = true; if (" +
                c +
                ".length > " +
                i.length +
                ") {  for (var " +
                v +
                " = " +
                i.length +
                "; " +
                v +
                " < " +
                c +
                ".length; " +
                v +
                "++) { "),
              (p.errorPath = e.util.getPathExpr(
                e.errorPath,
                v,
                e.opts.jsonPointers,
                !0
              ));
            var A = c + "[" + v + "]";
            p.dataPathArr[g] = v;
            var D = e.validate(p);
            (p.baseId = P),
              e.util.varOccurences(D, y) < 2
                ? (a += " " + e.util.varReplace(D, y, A) + " ")
                : (a += " var " + y + " = " + A + "; " + D + " "),
              l && (a += " if (!" + m + ") break; "),
              (a += " } }  "),
              l && ((a += " if (" + m + ") { "), (d += "}"));
          }
        } else if (e.util.schemaHasRules(i, e.RULES.all)) {
          (p.schema = i),
            (p.schemaPath = o),
            (p.errSchemaPath = u),
            (a +=
              "  for (var " +
              v +
              " = 0; " +
              v +
              " < " +
              c +
              ".length; " +
              v +
              "++) { "),
            (p.errorPath = e.util.getPathExpr(
              e.errorPath,
              v,
              e.opts.jsonPointers,
              !0
            ));
          var A = c + "[" + v + "]";
          p.dataPathArr[g] = v;
          var D = e.validate(p);
          (p.baseId = P),
            e.util.varOccurences(D, y) < 2
              ? (a += " " + e.util.varReplace(D, y, A) + " ")
              : (a += " var " + y + " = " + A + "; " + D + " "),
            l && (a += " if (!" + m + ") break; "),
            (a += " }");
        }
        return (
          l && (a += " " + d + " if (" + f + " == errors) {"),
          (a = e.util.cleanUpCode(a))
        );
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a,
          s = " ",
          n = e.level,
          i = e.dataLevel,
          o = e.schema[t],
          u = e.schemaPath + e.util.getProperty(t),
          l = e.errSchemaPath + "/" + t,
          c = !e.opts.allErrors,
          h = "data" + (i || ""),
          f = e.opts.$data && o && o.$data;
        f
          ? ((s +=
              " var schema" +
              n +
              " = " +
              e.util.getData(o.$data, i, e.dataPathArr) +
              "; "),
            (a = "schema" + n))
          : (a = o),
          (s += "var division" + n + ";if ("),
          f &&
            (s +=
              " " + a + " !== undefined && ( typeof " + a + " != 'number' || "),
          (s += " (division" + n + " = " + h + " / " + a + ", "),
          e.opts.multipleOfPrecision
            ? (s +=
                " Math.abs(Math.round(division" +
                n +
                ") - division" +
                n +
                ") > 1e-" +
                e.opts.multipleOfPrecision +
                " ")
            : (s += " division" + n + " !== parseInt(division" + n + ") "),
          (s += " ) "),
          f && (s += "  )  "),
          (s += " ) {   ");
        var p = p || [];
        p.push(s),
          (s = ""),
          !1 !== e.createErrors
            ? ((s +=
                " { keyword: 'multipleOf' , dataPath: (dataPath || '') + " +
                e.errorPath +
                " , schemaPath: " +
                e.util.toQuotedString(l) +
                " , params: { multipleOf: " +
                a +
                " } "),
              !1 !== e.opts.messages &&
                ((s += " , message: 'should be multiple of "),
                (s += f ? "' + " + a : a + "'")),
              e.opts.verbose &&
                ((s += " , schema:  "),
                (s += f ? "validate.schema" + u : "" + o),
                (s +=
                  "         , parentSchema: validate.schema" +
                  e.schemaPath +
                  " , data: " +
                  h +
                  " ")),
              (s += " } "))
            : (s += " {} ");
        var d = s;
        return (
          (s = p.pop()),
          !e.compositeRule && c
            ? e.async
              ? (s += " throw new ValidationError([" + d + "]); ")
              : (s += " validate.errors = [" + d + "]; return false; ")
            : (s +=
                " var err = " +
                d +
                ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
          (s += "} "),
          c && (s += " else { "),
          s
        );
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a = " ",
          s = e.level,
          n = e.dataLevel,
          i = e.schema[t],
          o = e.schemaPath + e.util.getProperty(t),
          u = e.errSchemaPath + "/" + t,
          l = !e.opts.allErrors,
          c = "data" + (n || ""),
          h = "errs__" + s,
          f = e.util.copy(e);
        f.level++;
        var p = "valid" + f.level;
        if (e.util.schemaHasRules(i, e.RULES.all)) {
          (f.schema = i),
            (f.schemaPath = o),
            (f.errSchemaPath = u),
            (a += " var " + h + " = errors;  ");
          var d = e.compositeRule;
          (e.compositeRule = f.compositeRule = !0), (f.createErrors = !1);
          var m;
          f.opts.allErrors && ((m = f.opts.allErrors), (f.opts.allErrors = !1)),
            (a += " " + e.validate(f) + " "),
            (f.createErrors = !0),
            m && (f.opts.allErrors = m),
            (e.compositeRule = f.compositeRule = d),
            (a += " if (" + p + ") {   ");
          var v = v || [];
          v.push(a),
            (a = ""),
            !1 !== e.createErrors
              ? ((a +=
                  " { keyword: 'not' , dataPath: (dataPath || '') + " +
                  e.errorPath +
                  " , schemaPath: " +
                  e.util.toQuotedString(u) +
                  " , params: {} "),
                !1 !== e.opts.messages &&
                  (a += " , message: 'should NOT be valid' "),
                e.opts.verbose &&
                  (a +=
                    " , schema: validate.schema" +
                    o +
                    " , parentSchema: validate.schema" +
                    e.schemaPath +
                    " , data: " +
                    c +
                    " "),
                (a += " } "))
              : (a += " {} ");
          var g = a;
          (a = v.pop()),
            !e.compositeRule && l
              ? e.async
                ? (a += " throw new ValidationError([" + g + "]); ")
                : (a += " validate.errors = [" + g + "]; return false; ")
              : (a +=
                  " var err = " +
                  g +
                  ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
            (a +=
              " } else {  errors = " +
              h +
              "; if (vErrors !== null) { if (" +
              h +
              ") vErrors.length = " +
              h +
              "; else vErrors = null; } "),
            e.opts.allErrors && (a += " } ");
        } else
          (a += "  var err =   "),
            !1 !== e.createErrors
              ? ((a +=
                  " { keyword: 'not' , dataPath: (dataPath || '') + " +
                  e.errorPath +
                  " , schemaPath: " +
                  e.util.toQuotedString(u) +
                  " , params: {} "),
                !1 !== e.opts.messages &&
                  (a += " , message: 'should NOT be valid' "),
                e.opts.verbose &&
                  (a +=
                    " , schema: validate.schema" +
                    o +
                    " , parentSchema: validate.schema" +
                    e.schemaPath +
                    " , data: " +
                    c +
                    " "),
                (a += " } "))
              : (a += " {} "),
            (a +=
              ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
            l && (a += " if (false) { ");
        return a;
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a = " ",
          s = e.level,
          n = e.dataLevel,
          i = e.schema[t],
          o = e.schemaPath + e.util.getProperty(t),
          u = e.errSchemaPath + "/" + t,
          l = !e.opts.allErrors,
          c = "data" + (n || ""),
          h = "valid" + s,
          f = "errs__" + s,
          p = e.util.copy(e),
          d = "";
        p.level++;
        var m = "valid" + p.level;
        a +=
          "var " +
          f +
          " = errors;var prevValid" +
          s +
          " = false;var " +
          h +
          " = false;";
        var v = p.baseId,
          g = e.compositeRule;
        e.compositeRule = p.compositeRule = !0;
        var y = i;
        if (y)
          for (var P, E = -1, w = y.length - 1; E < w; )
            (P = y[(E += 1)]),
              e.util.schemaHasRules(P, e.RULES.all)
                ? ((p.schema = P),
                  (p.schemaPath = o + "[" + E + "]"),
                  (p.errSchemaPath = u + "/" + E),
                  (a += "  " + e.validate(p) + " "),
                  (p.baseId = v))
                : (a += " var " + m + " = true; "),
              E &&
                ((a +=
                  " if (" +
                  m +
                  " && prevValid" +
                  s +
                  ") " +
                  h +
                  " = false; else { "),
                (d += "}")),
              (a += " if (" + m + ") " + h + " = prevValid" + s + " = true;");
        return (
          (e.compositeRule = p.compositeRule = g),
          (a += d + "if (!" + h + ") {   var err =   "),
          !1 !== e.createErrors
            ? ((a +=
                " { keyword: 'oneOf' , dataPath: (dataPath || '') + " +
                e.errorPath +
                " , schemaPath: " +
                e.util.toQuotedString(u) +
                " , params: {} "),
              !1 !== e.opts.messages &&
                (a +=
                  " , message: 'should match exactly one schema in oneOf' "),
              e.opts.verbose &&
                (a +=
                  " , schema: validate.schema" +
                  o +
                  " , parentSchema: validate.schema" +
                  e.schemaPath +
                  " , data: " +
                  c +
                  " "),
              (a += " } "))
            : (a += " {} "),
          (a +=
            ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
          !e.compositeRule &&
            l &&
            (e.async
              ? (a += " throw new ValidationError(vErrors); ")
              : (a += " validate.errors = vErrors; return false; ")),
          (a +=
            "} else {  errors = " +
            f +
            "; if (vErrors !== null) { if (" +
            f +
            ") vErrors.length = " +
            f +
            "; else vErrors = null; }"),
          e.opts.allErrors && (a += " } "),
          a
        );
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a,
          s = " ",
          n = e.level,
          i = e.dataLevel,
          o = e.schema[t],
          u = e.schemaPath + e.util.getProperty(t),
          l = e.errSchemaPath + "/" + t,
          c = !e.opts.allErrors,
          h = "data" + (i || ""),
          f = e.opts.$data && o && o.$data;
        f
          ? ((s +=
              " var schema" +
              n +
              " = " +
              e.util.getData(o.$data, i, e.dataPathArr) +
              "; "),
            (a = "schema" + n))
          : (a = o);
        var p = f ? "(new RegExp(" + a + "))" : e.usePattern(o);
        (s += "if ( "),
          f &&
            (s +=
              " (" + a + " !== undefined && typeof " + a + " != 'string') || "),
          (s += " !" + p + ".test(" + h + ") ) {   ");
        var d = d || [];
        d.push(s),
          (s = ""),
          !1 !== e.createErrors
            ? ((s +=
                " { keyword: 'pattern' , dataPath: (dataPath || '') + " +
                e.errorPath +
                " , schemaPath: " +
                e.util.toQuotedString(l) +
                " , params: { pattern:  "),
              (s += f ? "" + a : "" + e.util.toQuotedString(o)),
              (s += "  } "),
              !1 !== e.opts.messages &&
                ((s += " , message: 'should match pattern \""),
                (s += f ? "' + " + a + " + '" : "" + e.util.escapeQuotes(o)),
                (s += "\"' ")),
              e.opts.verbose &&
                ((s += " , schema:  "),
                (s += f
                  ? "validate.schema" + u
                  : "" + e.util.toQuotedString(o)),
                (s +=
                  "         , parentSchema: validate.schema" +
                  e.schemaPath +
                  " , data: " +
                  h +
                  " ")),
              (s += " } "))
            : (s += " {} ");
        var m = s;
        return (
          (s = d.pop()),
          !e.compositeRule && c
            ? e.async
              ? (s += " throw new ValidationError([" + m + "]); ")
              : (s += " validate.errors = [" + m + "]; return false; ")
            : (s +=
                " var err = " +
                m +
                ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
          (s += "} "),
          c && (s += " else { "),
          s
        );
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a = " ",
          s = e.level,
          n = e.dataLevel,
          i = e.schema[t],
          o = e.schemaPath + e.util.getProperty(t),
          u = e.errSchemaPath + "/" + t,
          l = !e.opts.allErrors,
          c = "data" + (n || ""),
          h = "valid" + s,
          f = "errs__" + s,
          p = e.util.copy(e),
          d = "";
        p.level++;
        var m = "valid" + p.level,
          v = "key" + s,
          g = "idx" + s,
          y = (p.dataLevel = e.dataLevel + 1),
          P = "data" + y,
          E = "dataProperties" + s,
          w = Object.keys(i || {}),
          b = e.schema.patternProperties || {},
          S = Object.keys(b),
          O = e.schema.additionalProperties,
          _ = w.length || S.length,
          x = !1 === O,
          R = "object" == typeof O && Object.keys(O).length,
          A = e.opts.removeAdditional,
          D = x || R || A,
          k = e.opts.ownProperties,
          T = e.baseId,
          F = e.schema.required;
        if (F && (!e.opts.v5 || !F.$data) && F.length < e.opts.loopRequired)
          var j = e.util.toHash(F);
        if (e.opts.patternGroups)
          var I = e.schema.patternGroups || {},
            $ = Object.keys(I);
        if (
          ((a += "var " + f + " = errors;var " + m + " = true;"),
          k && (a += " var " + E + " = undefined;"),
          D)
        ) {
          if (
            ((a += k
              ? " " +
                E +
                " = " +
                E +
                " || Object.keys(" +
                c +
                "); for (var " +
                g +
                "=0; " +
                g +
                "<" +
                E +
                ".length; " +
                g +
                "++) { var " +
                v +
                " = " +
                E +
                "[" +
                g +
                "]; "
              : " for (var " + v + " in " + c + ") { "),
            _)
          ) {
            if (((a += " var isAdditional" + s + " = !(false "), w.length))
              if (w.length > 5) a += " || validate.schema" + o + "[" + v + "] ";
              else {
                var W = w;
                if (W)
                  for (var N, C = -1, L = W.length - 1; C < L; )
                    (N = W[(C += 1)]),
                      (a +=
                        " || " + v + " == " + e.util.toQuotedString(N) + " ");
              }
            if (S.length) {
              var z = S;
              if (z)
                for (var M, H = -1, q = z.length - 1; H < q; )
                  (M = z[(H += 1)]),
                    (a += " || " + e.usePattern(M) + ".test(" + v + ") ");
            }
            if (e.opts.patternGroups && $.length) {
              var V = $;
              if (V)
                for (var B, H = -1, Q = V.length - 1; H < Q; )
                  (B = V[(H += 1)]),
                    (a += " || " + e.usePattern(B) + ".test(" + v + ") ");
            }
            a += " ); if (isAdditional" + s + ") { ";
          }
          if ("all" == A) a += " delete " + c + "[" + v + "]; ";
          else {
            var U = e.errorPath,
              K = "' + " + v + " + '";
            if (
              (e.opts._errorDataPathProperty &&
                (e.errorPath = e.util.getPathExpr(
                  e.errorPath,
                  v,
                  e.opts.jsonPointers
                )),
              x)
            )
              if (A) a += " delete " + c + "[" + v + "]; ";
              else {
                a += " " + m + " = false; ";
                var J = u;
                u = e.errSchemaPath + "/additionalProperties";
                var G = G || [];
                G.push(a),
                  (a = ""),
                  !1 !== e.createErrors
                    ? ((a +=
                        " { keyword: 'additionalProperties' , dataPath: (dataPath || '') + " +
                        e.errorPath +
                        " , schemaPath: " +
                        e.util.toQuotedString(u) +
                        " , params: { additionalProperty: '" +
                        K +
                        "' } "),
                      !1 !== e.opts.messages &&
                        (a +=
                          " , message: 'should NOT have additional properties' "),
                      e.opts.verbose &&
                        (a +=
                          " , schema: false , parentSchema: validate.schema" +
                          e.schemaPath +
                          " , data: " +
                          c +
                          " "),
                      (a += " } "))
                    : (a += " {} ");
                var Y = a;
                (a = G.pop()),
                  !e.compositeRule && l
                    ? e.async
                      ? (a += " throw new ValidationError([" + Y + "]); ")
                      : (a += " validate.errors = [" + Y + "]; return false; ")
                    : (a +=
                        " var err = " +
                        Y +
                        ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
                  (u = J),
                  l && (a += " break; ");
              }
            else if (R)
              if ("failing" == A) {
                a += " var " + f + " = errors;  ";
                var Z = e.compositeRule;
                (e.compositeRule = p.compositeRule = !0),
                  (p.schema = O),
                  (p.schemaPath = e.schemaPath + ".additionalProperties"),
                  (p.errSchemaPath = e.errSchemaPath + "/additionalProperties"),
                  (p.errorPath = e.opts._errorDataPathProperty
                    ? e.errorPath
                    : e.util.getPathExpr(e.errorPath, v, e.opts.jsonPointers));
                var X = c + "[" + v + "]";
                p.dataPathArr[y] = v;
                var ee = e.validate(p);
                (p.baseId = T),
                  e.util.varOccurences(ee, P) < 2
                    ? (a += " " + e.util.varReplace(ee, P, X) + " ")
                    : (a += " var " + P + " = " + X + "; " + ee + " "),
                  (a +=
                    " if (!" +
                    m +
                    ") { errors = " +
                    f +
                    "; if (validate.errors !== null) { if (errors) validate.errors.length = errors; else validate.errors = null; } delete " +
                    c +
                    "[" +
                    v +
                    "]; }  "),
                  (e.compositeRule = p.compositeRule = Z);
              } else {
                (p.schema = O),
                  (p.schemaPath = e.schemaPath + ".additionalProperties"),
                  (p.errSchemaPath = e.errSchemaPath + "/additionalProperties"),
                  (p.errorPath = e.opts._errorDataPathProperty
                    ? e.errorPath
                    : e.util.getPathExpr(e.errorPath, v, e.opts.jsonPointers));
                var X = c + "[" + v + "]";
                p.dataPathArr[y] = v;
                var ee = e.validate(p);
                (p.baseId = T),
                  e.util.varOccurences(ee, P) < 2
                    ? (a += " " + e.util.varReplace(ee, P, X) + " ")
                    : (a += " var " + P + " = " + X + "; " + ee + " "),
                  l && (a += " if (!" + m + ") break; ");
              }
            e.errorPath = U;
          }
          _ && (a += " } "),
            (a += " }  "),
            l && ((a += " if (" + m + ") { "), (d += "}"));
        }
        var te = e.opts.useDefaults && !e.compositeRule;
        if (w.length) {
          var re = w;
          if (re)
            for (var N, ae = -1, se = re.length - 1; ae < se; ) {
              N = re[(ae += 1)];
              var ne = i[N];
              if (e.util.schemaHasRules(ne, e.RULES.all)) {
                var ie = e.util.getProperty(N),
                  X = c + ie,
                  oe = te && void 0 !== ne.default;
                (p.schema = ne),
                  (p.schemaPath = o + ie),
                  (p.errSchemaPath = u + "/" + e.util.escapeFragment(N)),
                  (p.errorPath = e.util.getPath(
                    e.errorPath,
                    N,
                    e.opts.jsonPointers
                  )),
                  (p.dataPathArr[y] = e.util.toQuotedString(N));
                var ee = e.validate(p);
                if (((p.baseId = T), e.util.varOccurences(ee, P) < 2)) {
                  ee = e.util.varReplace(ee, P, X);
                  var ue = X;
                } else {
                  var ue = P;
                  a += " var " + P + " = " + X + "; ";
                }
                if (oe) a += " " + ee + " ";
                else {
                  if (j && j[N]) {
                    (a += " if ( " + ue + " === undefined "),
                      k &&
                        (a +=
                          " || ! Object.prototype.hasOwnProperty.call(" +
                          c +
                          ", '" +
                          e.util.escapeQuotes(N) +
                          "') "),
                      (a += ") { " + m + " = false; ");
                    var U = e.errorPath,
                      J = u,
                      le = e.util.escapeQuotes(N);
                    e.opts._errorDataPathProperty &&
                      (e.errorPath = e.util.getPath(U, N, e.opts.jsonPointers)),
                      (u = e.errSchemaPath + "/required");
                    var G = G || [];
                    G.push(a),
                      (a = ""),
                      !1 !== e.createErrors
                        ? ((a +=
                            " { keyword: 'required' , dataPath: (dataPath || '') + " +
                            e.errorPath +
                            " , schemaPath: " +
                            e.util.toQuotedString(u) +
                            " , params: { missingProperty: '" +
                            le +
                            "' } "),
                          !1 !== e.opts.messages &&
                            ((a += " , message: '"),
                            e.opts._errorDataPathProperty
                              ? (a += "is a required property")
                              : (a +=
                                  "should have required property \\'" +
                                  le +
                                  "\\'"),
                            (a += "' ")),
                          e.opts.verbose &&
                            (a +=
                              " , schema: validate.schema" +
                              o +
                              " , parentSchema: validate.schema" +
                              e.schemaPath +
                              " , data: " +
                              c +
                              " "),
                          (a += " } "))
                        : (a += " {} ");
                    var Y = a;
                    (a = G.pop()),
                      !e.compositeRule && l
                        ? e.async
                          ? (a += " throw new ValidationError([" + Y + "]); ")
                          : (a +=
                              " validate.errors = [" + Y + "]; return false; ")
                        : (a +=
                            " var err = " +
                            Y +
                            ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
                      (u = J),
                      (e.errorPath = U),
                      (a += " } else { ");
                  } else
                    l
                      ? ((a += " if ( " + ue + " === undefined "),
                        k &&
                          (a +=
                            " || ! Object.prototype.hasOwnProperty.call(" +
                            c +
                            ", '" +
                            e.util.escapeQuotes(N) +
                            "') "),
                        (a += ") { " + m + " = true; } else { "))
                      : ((a += " if (" + ue + " !== undefined "),
                        k &&
                          (a +=
                            " &&   Object.prototype.hasOwnProperty.call(" +
                            c +
                            ", '" +
                            e.util.escapeQuotes(N) +
                            "') "),
                        (a += " ) { "));
                  a += " " + ee + " } ";
                }
              }
              l && ((a += " if (" + m + ") { "), (d += "}"));
            }
        }
        if (S.length) {
          var ce = S;
          if (ce)
            for (var M, he = -1, fe = ce.length - 1; he < fe; ) {
              M = ce[(he += 1)];
              var ne = b[M];
              if (e.util.schemaHasRules(ne, e.RULES.all)) {
                (p.schema = ne),
                  (p.schemaPath =
                    e.schemaPath +
                    ".patternProperties" +
                    e.util.getProperty(M)),
                  (p.errSchemaPath =
                    e.errSchemaPath +
                    "/patternProperties/" +
                    e.util.escapeFragment(M)),
                  (a += k
                    ? " " +
                      E +
                      " = " +
                      E +
                      " || Object.keys(" +
                      c +
                      "); for (var " +
                      g +
                      "=0; " +
                      g +
                      "<" +
                      E +
                      ".length; " +
                      g +
                      "++) { var " +
                      v +
                      " = " +
                      E +
                      "[" +
                      g +
                      "]; "
                    : " for (var " + v + " in " + c + ") { "),
                  (a += " if (" + e.usePattern(M) + ".test(" + v + ")) { "),
                  (p.errorPath = e.util.getPathExpr(
                    e.errorPath,
                    v,
                    e.opts.jsonPointers
                  ));
                var X = c + "[" + v + "]";
                p.dataPathArr[y] = v;
                var ee = e.validate(p);
                (p.baseId = T),
                  e.util.varOccurences(ee, P) < 2
                    ? (a += " " + e.util.varReplace(ee, P, X) + " ")
                    : (a += " var " + P + " = " + X + "; " + ee + " "),
                  l && (a += " if (!" + m + ") break; "),
                  (a += " } "),
                  l && (a += " else " + m + " = true; "),
                  (a += " }  "),
                  l && ((a += " if (" + m + ") { "), (d += "}"));
              }
            }
        }
        if (e.opts.patternGroups && $.length) {
          var pe = $;
          if (pe)
            for (var B, de = -1, me = pe.length - 1; de < me; ) {
              B = pe[(de += 1)];
              var ve = I[B],
                ne = ve.schema;
              if (e.util.schemaHasRules(ne, e.RULES.all)) {
                (p.schema = ne),
                  (p.schemaPath =
                    e.schemaPath +
                    ".patternGroups" +
                    e.util.getProperty(B) +
                    ".schema"),
                  (p.errSchemaPath =
                    e.errSchemaPath +
                    "/patternGroups/" +
                    e.util.escapeFragment(B) +
                    "/schema"),
                  (a += " var pgPropCount" + s + " = 0;  "),
                  (a += k
                    ? " " +
                      E +
                      " = " +
                      E +
                      " || Object.keys(" +
                      c +
                      "); for (var " +
                      g +
                      "=0; " +
                      g +
                      "<" +
                      E +
                      ".length; " +
                      g +
                      "++) { var " +
                      v +
                      " = " +
                      E +
                      "[" +
                      g +
                      "]; "
                    : " for (var " + v + " in " + c + ") { "),
                  (a +=
                    " if (" +
                    e.usePattern(B) +
                    ".test(" +
                    v +
                    ")) { pgPropCount" +
                    s +
                    "++; "),
                  (p.errorPath = e.util.getPathExpr(
                    e.errorPath,
                    v,
                    e.opts.jsonPointers
                  ));
                var X = c + "[" + v + "]";
                p.dataPathArr[y] = v;
                var ee = e.validate(p);
                (p.baseId = T),
                  e.util.varOccurences(ee, P) < 2
                    ? (a += " " + e.util.varReplace(ee, P, X) + " ")
                    : (a += " var " + P + " = " + X + "; " + ee + " "),
                  l && (a += " if (!" + m + ") break; "),
                  (a += " } "),
                  l && (a += " else " + m + " = true; "),
                  (a += " }  "),
                  l && ((a += " if (" + m + ") { "), (d += "}"));
                var ge = ve.minimum,
                  ye = ve.maximum;
                if (void 0 !== ge || void 0 !== ye) {
                  a += " var " + h + " = true; ";
                  var J = u;
                  if (void 0 !== ge) {
                    var Pe = ge,
                      Ee = "minimum",
                      we = "less";
                    (a += " " + h + " = pgPropCount" + s + " >= " + ge + "; "),
                      (u = e.errSchemaPath + "/patternGroups/minimum"),
                      (a += "  if (!" + h + ") {   ");
                    var G = G || [];
                    G.push(a),
                      (a = ""),
                      !1 !== e.createErrors
                        ? ((a +=
                            " { keyword: 'patternGroups' , dataPath: (dataPath || '') + " +
                            e.errorPath +
                            " , schemaPath: " +
                            e.util.toQuotedString(u) +
                            " , params: { reason: '" +
                            Ee +
                            "', limit: " +
                            Pe +
                            ", pattern: '" +
                            e.util.escapeQuotes(B) +
                            "' } "),
                          !1 !== e.opts.messages &&
                            (a +=
                              " , message: 'should NOT have " +
                              we +
                              " than " +
                              Pe +
                              ' properties matching pattern "' +
                              e.util.escapeQuotes(B) +
                              "\"' "),
                          e.opts.verbose &&
                            (a +=
                              " , schema: validate.schema" +
                              o +
                              " , parentSchema: validate.schema" +
                              e.schemaPath +
                              " , data: " +
                              c +
                              " "),
                          (a += " } "))
                        : (a += " {} ");
                    var Y = a;
                    (a = G.pop()),
                      !e.compositeRule && l
                        ? e.async
                          ? (a += " throw new ValidationError([" + Y + "]); ")
                          : (a +=
                              " validate.errors = [" + Y + "]; return false; ")
                        : (a +=
                            " var err = " +
                            Y +
                            ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
                      (a += " } "),
                      void 0 !== ye && (a += " else ");
                  }
                  if (void 0 !== ye) {
                    var Pe = ye,
                      Ee = "maximum",
                      we = "more";
                    (a += " " + h + " = pgPropCount" + s + " <= " + ye + "; "),
                      (u = e.errSchemaPath + "/patternGroups/maximum"),
                      (a += "  if (!" + h + ") {   ");
                    var G = G || [];
                    G.push(a),
                      (a = ""),
                      !1 !== e.createErrors
                        ? ((a +=
                            " { keyword: 'patternGroups' , dataPath: (dataPath || '') + " +
                            e.errorPath +
                            " , schemaPath: " +
                            e.util.toQuotedString(u) +
                            " , params: { reason: '" +
                            Ee +
                            "', limit: " +
                            Pe +
                            ", pattern: '" +
                            e.util.escapeQuotes(B) +
                            "' } "),
                          !1 !== e.opts.messages &&
                            (a +=
                              " , message: 'should NOT have " +
                              we +
                              " than " +
                              Pe +
                              ' properties matching pattern "' +
                              e.util.escapeQuotes(B) +
                              "\"' "),
                          e.opts.verbose &&
                            (a +=
                              " , schema: validate.schema" +
                              o +
                              " , parentSchema: validate.schema" +
                              e.schemaPath +
                              " , data: " +
                              c +
                              " "),
                          (a += " } "))
                        : (a += " {} ");
                    var Y = a;
                    (a = G.pop()),
                      !e.compositeRule && l
                        ? e.async
                          ? (a += " throw new ValidationError([" + Y + "]); ")
                          : (a +=
                              " validate.errors = [" + Y + "]; return false; ")
                        : (a +=
                            " var err = " +
                            Y +
                            ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
                      (a += " } ");
                  }
                  (u = J), l && ((a += " if (" + h + ") { "), (d += "}"));
                }
              }
            }
        }
        return (
          l && (a += " " + d + " if (" + f + " == errors) {"),
          (a = e.util.cleanUpCode(a))
        );
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a = " ",
          s = e.level,
          n = e.dataLevel,
          i = e.schema[t],
          o = e.schemaPath + e.util.getProperty(t),
          u = e.errSchemaPath + "/" + t,
          l = !e.opts.allErrors,
          c = "data" + (n || ""),
          h = "errs__" + s,
          f = e.util.copy(e);
        f.level++;
        var p = "valid" + f.level;
        if (e.util.schemaHasRules(i, e.RULES.all)) {
          (f.schema = i), (f.schemaPath = o), (f.errSchemaPath = u);
          var d = "key" + s,
            m = "idx" + s,
            v = "i" + s,
            g = "' + " + d + " + '",
            y = (f.dataLevel = e.dataLevel + 1),
            P = "data" + y,
            E = "dataProperties" + s,
            w = e.opts.ownProperties,
            b = e.baseId;
          (a += " var " + h + " = errors; "),
            w && (a += " var " + E + " = undefined; "),
            (a += w
              ? " " +
                E +
                " = " +
                E +
                " || Object.keys(" +
                c +
                "); for (var " +
                m +
                "=0; " +
                m +
                "<" +
                E +
                ".length; " +
                m +
                "++) { var " +
                d +
                " = " +
                E +
                "[" +
                m +
                "]; "
              : " for (var " + d + " in " + c + ") { "),
            (a += " var startErrs" + s + " = errors; ");
          var S = d,
            O = e.compositeRule;
          e.compositeRule = f.compositeRule = !0;
          var _ = e.validate(f);
          (f.baseId = b),
            e.util.varOccurences(_, P) < 2
              ? (a += " " + e.util.varReplace(_, P, S) + " ")
              : (a += " var " + P + " = " + S + "; " + _ + " "),
            (e.compositeRule = f.compositeRule = O),
            (a +=
              " if (!" +
              p +
              ") { for (var " +
              v +
              "=startErrs" +
              s +
              "; " +
              v +
              "<errors; " +
              v +
              "++) { vErrors[" +
              v +
              "].propertyName = " +
              d +
              "; }   var err =   "),
            !1 !== e.createErrors
              ? ((a +=
                  " { keyword: 'propertyNames' , dataPath: (dataPath || '') + " +
                  e.errorPath +
                  " , schemaPath: " +
                  e.util.toQuotedString(u) +
                  " , params: { propertyName: '" +
                  g +
                  "' } "),
                !1 !== e.opts.messages &&
                  (a +=
                    " , message: 'property name \\'" + g + "\\' is invalid' "),
                e.opts.verbose &&
                  (a +=
                    " , schema: validate.schema" +
                    o +
                    " , parentSchema: validate.schema" +
                    e.schemaPath +
                    " , data: " +
                    c +
                    " "),
                (a += " } "))
              : (a += " {} "),
            (a +=
              ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
            !e.compositeRule &&
              l &&
              (e.async
                ? (a += " throw new ValidationError(vErrors); ")
                : (a += " validate.errors = vErrors; return false; ")),
            l && (a += " break; "),
            (a += " } }");
        }
        return (
          l && (a += "  if (" + h + " == errors) {"),
          (a = e.util.cleanUpCode(a))
        );
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a,
          s,
          n = " ",
          i = e.level,
          o = e.dataLevel,
          u = e.schema[t],
          l = e.errSchemaPath + "/" + t,
          c = !e.opts.allErrors,
          h = "data" + (o || ""),
          f = "valid" + i;
        if ("#" == u || "#/" == u)
          e.isRoot
            ? ((a = e.async), (s = "validate"))
            : ((a = !0 === e.root.schema.$async), (s = "root.refVal[0]"));
        else {
          var p = e.resolveRef(e.baseId, u, e.isRoot);
          if (void 0 === p) {
            var d = e.MissingRefError.message(e.baseId, u);
            if ("fail" == e.opts.missingRefs) {
              console.error(d);
              var m = m || [];
              m.push(n),
                (n = ""),
                !1 !== e.createErrors
                  ? ((n +=
                      " { keyword: '$ref' , dataPath: (dataPath || '') + " +
                      e.errorPath +
                      " , schemaPath: " +
                      e.util.toQuotedString(l) +
                      " , params: { ref: '" +
                      e.util.escapeQuotes(u) +
                      "' } "),
                    !1 !== e.opts.messages &&
                      (n +=
                        " , message: 'can\\'t resolve reference " +
                        e.util.escapeQuotes(u) +
                        "' "),
                    e.opts.verbose &&
                      (n +=
                        " , schema: " +
                        e.util.toQuotedString(u) +
                        " , parentSchema: validate.schema" +
                        e.schemaPath +
                        " , data: " +
                        h +
                        " "),
                    (n += " } "))
                  : (n += " {} ");
              var v = n;
              (n = m.pop()),
                !e.compositeRule && c
                  ? e.async
                    ? (n += " throw new ValidationError([" + v + "]); ")
                    : (n += " validate.errors = [" + v + "]; return false; ")
                  : (n +=
                      " var err = " +
                      v +
                      ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
                c && (n += " if (false) { ");
            } else {
              if ("ignore" != e.opts.missingRefs)
                throw new e.MissingRefError(e.baseId, u, d);
              console.warn(d), c && (n += " if (true) { ");
            }
          } else if (p.inline) {
            var g = e.util.copy(e);
            g.level++;
            var y = "valid" + g.level;
            (g.schema = p.schema), (g.schemaPath = ""), (g.errSchemaPath = u);
            var P = e.validate(g).replace(/validate\.schema/g, p.code);
            (n += " " + P + " "), c && (n += " if (" + y + ") { ");
          } else (a = !0 === p.$async), (s = p.code);
        }
        if (s) {
          var m = m || [];
          m.push(n),
            (n = ""),
            e.opts.passContext
              ? (n += " " + s + ".call(this, ")
              : (n += " " + s + "( "),
            (n += " " + h + ", (dataPath || '')"),
            '""' != e.errorPath && (n += " + " + e.errorPath);
          n +=
            " , " +
            (o ? "data" + (o - 1 || "") : "parentData") +
            " , " +
            (o ? e.dataPathArr[o] : "parentDataProperty") +
            ", rootData)  ";
          var E = n;
          if (((n = m.pop()), a)) {
            if (!e.async)
              throw new Error("async schema referenced by sync schema");
            c && (n += " var " + f + "; "),
              (n += " try { " + e.yieldAwait + " " + E + "; "),
              c && (n += " " + f + " = true; "),
              (n +=
                " } catch (e) { if (!(e instanceof ValidationError)) throw e; if (vErrors === null) vErrors = e.errors; else vErrors = vErrors.concat(e.errors); errors = vErrors.length; "),
              c && (n += " " + f + " = false; "),
              (n += " } "),
              c && (n += " if (" + f + ") { ");
          } else
            (n +=
              " if (!" +
              E +
              ") { if (vErrors === null) vErrors = " +
              s +
              ".errors; else vErrors = vErrors.concat(" +
              s +
              ".errors); errors = vErrors.length; } "),
              c && (n += " else { ");
        }
        return n;
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a = " ",
          s = e.level,
          n = e.dataLevel,
          i = e.schema[t],
          o = e.schemaPath + e.util.getProperty(t),
          u = e.errSchemaPath + "/" + t,
          l = !e.opts.allErrors,
          c = "data" + (n || ""),
          h = "valid" + s,
          f = e.opts.$data && i && i.$data;
        f &&
          (a +=
            " var schema" +
            s +
            " = " +
            e.util.getData(i.$data, n, e.dataPathArr) +
            "; ");
        var p = "schema" + s;
        if (!f)
          if (
            i.length < e.opts.loopRequired &&
            e.schema.properties &&
            Object.keys(e.schema.properties).length
          ) {
            var d = [],
              m = i;
            if (m)
              for (var v, g = -1, y = m.length - 1; g < y; ) {
                v = m[(g += 1)];
                var P = e.schema.properties[v];
                (P && e.util.schemaHasRules(P, e.RULES.all)) ||
                  (d[d.length] = v);
              }
          } else var d = i;
        if (f || d.length) {
          var E = e.errorPath,
            w = f || d.length >= e.opts.loopRequired,
            b = e.opts.ownProperties;
          if (l)
            if (((a += " var missing" + s + "; "), w)) {
              f || (a += " var " + p + " = validate.schema" + o + "; ");
              var S = "i" + s,
                O = "schema" + s + "[" + S + "]",
                _ = "' + " + O + " + '";
              e.opts._errorDataPathProperty &&
                (e.errorPath = e.util.getPathExpr(E, O, e.opts.jsonPointers)),
                (a += " var " + h + " = true; "),
                f &&
                  (a +=
                    " if (schema" +
                    s +
                    " === undefined) " +
                    h +
                    " = true; else if (!Array.isArray(schema" +
                    s +
                    ")) " +
                    h +
                    " = false; else {"),
                (a +=
                  " for (var " +
                  S +
                  " = 0; " +
                  S +
                  " < " +
                  p +
                  ".length; " +
                  S +
                  "++) { " +
                  h +
                  " = " +
                  c +
                  "[" +
                  p +
                  "[" +
                  S +
                  "]] !== undefined "),
                b &&
                  (a +=
                    " &&   Object.prototype.hasOwnProperty.call(" +
                    c +
                    ", " +
                    p +
                    "[" +
                    S +
                    "]) "),
                (a += "; if (!" + h + ") break; } "),
                f && (a += "  }  "),
                (a += "  if (!" + h + ") {   ");
              var x = x || [];
              x.push(a),
                (a = ""),
                !1 !== e.createErrors
                  ? ((a +=
                      " { keyword: 'required' , dataPath: (dataPath || '') + " +
                      e.errorPath +
                      " , schemaPath: " +
                      e.util.toQuotedString(u) +
                      " , params: { missingProperty: '" +
                      _ +
                      "' } "),
                    !1 !== e.opts.messages &&
                      ((a += " , message: '"),
                      e.opts._errorDataPathProperty
                        ? (a += "is a required property")
                        : (a +=
                            "should have required property \\'" + _ + "\\'"),
                      (a += "' ")),
                    e.opts.verbose &&
                      (a +=
                        " , schema: validate.schema" +
                        o +
                        " , parentSchema: validate.schema" +
                        e.schemaPath +
                        " , data: " +
                        c +
                        " "),
                    (a += " } "))
                  : (a += " {} ");
              var R = a;
              (a = x.pop()),
                !e.compositeRule && l
                  ? e.async
                    ? (a += " throw new ValidationError([" + R + "]); ")
                    : (a += " validate.errors = [" + R + "]; return false; ")
                  : (a +=
                      " var err = " +
                      R +
                      ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
                (a += " } else { ");
            } else {
              a += " if ( ";
              var A = d;
              if (A)
                for (var D, S = -1, k = A.length - 1; S < k; ) {
                  (D = A[(S += 1)]), S && (a += " || ");
                  var T = e.util.getProperty(D),
                    F = c + T;
                  (a += " ( ( " + F + " === undefined "),
                    b &&
                      (a +=
                        " || ! Object.prototype.hasOwnProperty.call(" +
                        c +
                        ", '" +
                        e.util.escapeQuotes(D) +
                        "') "),
                    (a +=
                      ") && (missing" +
                      s +
                      " = " +
                      e.util.toQuotedString(e.opts.jsonPointers ? D : T) +
                      ") ) ");
                }
              a += ") {  ";
              var O = "missing" + s,
                _ = "' + " + O + " + '";
              e.opts._errorDataPathProperty &&
                (e.errorPath = e.opts.jsonPointers
                  ? e.util.getPathExpr(E, O, !0)
                  : E + " + " + O);
              var x = x || [];
              x.push(a),
                (a = ""),
                !1 !== e.createErrors
                  ? ((a +=
                      " { keyword: 'required' , dataPath: (dataPath || '') + " +
                      e.errorPath +
                      " , schemaPath: " +
                      e.util.toQuotedString(u) +
                      " , params: { missingProperty: '" +
                      _ +
                      "' } "),
                    !1 !== e.opts.messages &&
                      ((a += " , message: '"),
                      e.opts._errorDataPathProperty
                        ? (a += "is a required property")
                        : (a +=
                            "should have required property \\'" + _ + "\\'"),
                      (a += "' ")),
                    e.opts.verbose &&
                      (a +=
                        " , schema: validate.schema" +
                        o +
                        " , parentSchema: validate.schema" +
                        e.schemaPath +
                        " , data: " +
                        c +
                        " "),
                    (a += " } "))
                  : (a += " {} ");
              var R = a;
              (a = x.pop()),
                !e.compositeRule && l
                  ? e.async
                    ? (a += " throw new ValidationError([" + R + "]); ")
                    : (a += " validate.errors = [" + R + "]; return false; ")
                  : (a +=
                      " var err = " +
                      R +
                      ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
                (a += " } else { ");
            }
          else if (w) {
            f || (a += " var " + p + " = validate.schema" + o + "; ");
            var S = "i" + s,
              O = "schema" + s + "[" + S + "]",
              _ = "' + " + O + " + '";
            e.opts._errorDataPathProperty &&
              (e.errorPath = e.util.getPathExpr(E, O, e.opts.jsonPointers)),
              f &&
                ((a +=
                  " if (" +
                  p +
                  " && !Array.isArray(" +
                  p +
                  ")) {  var err =   "),
                !1 !== e.createErrors
                  ? ((a +=
                      " { keyword: 'required' , dataPath: (dataPath || '') + " +
                      e.errorPath +
                      " , schemaPath: " +
                      e.util.toQuotedString(u) +
                      " , params: { missingProperty: '" +
                      _ +
                      "' } "),
                    !1 !== e.opts.messages &&
                      ((a += " , message: '"),
                      e.opts._errorDataPathProperty
                        ? (a += "is a required property")
                        : (a +=
                            "should have required property \\'" + _ + "\\'"),
                      (a += "' ")),
                    e.opts.verbose &&
                      (a +=
                        " , schema: validate.schema" +
                        o +
                        " , parentSchema: validate.schema" +
                        e.schemaPath +
                        " , data: " +
                        c +
                        " "),
                    (a += " } "))
                  : (a += " {} "),
                (a +=
                  ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; } else if (" +
                  p +
                  " !== undefined) { ")),
              (a +=
                " for (var " +
                S +
                " = 0; " +
                S +
                " < " +
                p +
                ".length; " +
                S +
                "++) { if (" +
                c +
                "[" +
                p +
                "[" +
                S +
                "]] === undefined "),
              b &&
                (a +=
                  " || ! Object.prototype.hasOwnProperty.call(" +
                  c +
                  ", " +
                  p +
                  "[" +
                  S +
                  "]) "),
              (a += ") {  var err =   "),
              !1 !== e.createErrors
                ? ((a +=
                    " { keyword: 'required' , dataPath: (dataPath || '') + " +
                    e.errorPath +
                    " , schemaPath: " +
                    e.util.toQuotedString(u) +
                    " , params: { missingProperty: '" +
                    _ +
                    "' } "),
                  !1 !== e.opts.messages &&
                    ((a += " , message: '"),
                    e.opts._errorDataPathProperty
                      ? (a += "is a required property")
                      : (a += "should have required property \\'" + _ + "\\'"),
                    (a += "' ")),
                  e.opts.verbose &&
                    (a +=
                      " , schema: validate.schema" +
                      o +
                      " , parentSchema: validate.schema" +
                      e.schemaPath +
                      " , data: " +
                      c +
                      " "),
                  (a += " } "))
                : (a += " {} "),
              (a +=
                ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; } } "),
              f && (a += "  }  ");
          } else {
            var j = d;
            if (j)
              for (var D, I = -1, $ = j.length - 1; I < $; ) {
                D = j[(I += 1)];
                var T = e.util.getProperty(D),
                  _ = e.util.escapeQuotes(D),
                  F = c + T;
                e.opts._errorDataPathProperty &&
                  (e.errorPath = e.util.getPath(E, D, e.opts.jsonPointers)),
                  (a += " if ( " + F + " === undefined "),
                  b &&
                    (a +=
                      " || ! Object.prototype.hasOwnProperty.call(" +
                      c +
                      ", '" +
                      e.util.escapeQuotes(D) +
                      "') "),
                  (a += ") {  var err =   "),
                  !1 !== e.createErrors
                    ? ((a +=
                        " { keyword: 'required' , dataPath: (dataPath || '') + " +
                        e.errorPath +
                        " , schemaPath: " +
                        e.util.toQuotedString(u) +
                        " , params: { missingProperty: '" +
                        _ +
                        "' } "),
                      !1 !== e.opts.messages &&
                        ((a += " , message: '"),
                        e.opts._errorDataPathProperty
                          ? (a += "is a required property")
                          : (a +=
                              "should have required property \\'" + _ + "\\'"),
                        (a += "' ")),
                      e.opts.verbose &&
                        (a +=
                          " , schema: validate.schema" +
                          o +
                          " , parentSchema: validate.schema" +
                          e.schemaPath +
                          " , data: " +
                          c +
                          " "),
                      (a += " } "))
                    : (a += " {} "),
                  (a +=
                    ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; } ");
              }
          }
          e.errorPath = E;
        } else l && (a += " if (true) {");
        return a;
      };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e, t, r) {
        var a,
          s = " ",
          n = e.level,
          i = e.dataLevel,
          o = e.schema[t],
          u = e.schemaPath + e.util.getProperty(t),
          l = e.errSchemaPath + "/" + t,
          c = !e.opts.allErrors,
          h = "data" + (i || ""),
          f = "valid" + n,
          p = e.opts.$data && o && o.$data;
        if (
          (p
            ? ((s +=
                " var schema" +
                n +
                " = " +
                e.util.getData(o.$data, i, e.dataPathArr) +
                "; "),
              (a = "schema" + n))
            : (a = o),
          (o || p) && !1 !== e.opts.uniqueItems)
        ) {
          p &&
            (s +=
              " var " +
              f +
              "; if (" +
              a +
              " === false || " +
              a +
              " === undefined) " +
              f +
              " = true; else if (typeof " +
              a +
              " != 'boolean') " +
              f +
              " = false; else { "),
            (s +=
              " var " +
              f +
              " = true; if (" +
              h +
              ".length > 1) { var i = " +
              h +
              ".length, j; outer: for (;i--;) { for (j = i; j--;) { if (equal(" +
              h +
              "[i], " +
              h +
              "[j])) { " +
              f +
              " = false; break outer; } } } } "),
            p && (s += "  }  "),
            (s += " if (!" + f + ") {   ");
          var d = d || [];
          d.push(s),
            (s = ""),
            !1 !== e.createErrors
              ? ((s +=
                  " { keyword: 'uniqueItems' , dataPath: (dataPath || '') + " +
                  e.errorPath +
                  " , schemaPath: " +
                  e.util.toQuotedString(l) +
                  " , params: { i: i, j: j } "),
                !1 !== e.opts.messages &&
                  (s +=
                    " , message: 'should NOT have duplicate items (items ## ' + j + ' and ' + i + ' are identical)' "),
                e.opts.verbose &&
                  ((s += " , schema:  "),
                  (s += p ? "validate.schema" + u : "" + o),
                  (s +=
                    "         , parentSchema: validate.schema" +
                    e.schemaPath +
                    " , data: " +
                    h +
                    " ")),
                (s += " } "))
              : (s += " {} ");
          var m = s;
          (s = d.pop()),
            !e.compositeRule && c
              ? e.async
                ? (s += " throw new ValidationError([" + m + "]); ")
                : (s += " validate.errors = [" + m + "]; return false; ")
              : (s +=
                  " var err = " +
                  m +
                  ";  if (vErrors === null) vErrors = [err]; else vErrors.push(err); errors++; "),
            (s += " } "),
            c && (s += " else { ");
        } else c && (s += " if (true) { ");
        return s;
      };
    },
    function(e, t, r) {
      "use strict";
      function a(e, t) {
        function r(e, t, r) {
          for (var a, n = 0; n < s.length; n++) {
            var i = s[n];
            if (i.type == t) {
              a = i;
              break;
            }
          }
          a || ((a = { type: t, rules: [] }), s.push(a));
          var u = {
            keyword: e,
            definition: r,
            custom: !0,
            code: o,
            implements: r.implements
          };
          a.rules.push(u), (s.custom[e] = u);
        }
        function a(e) {
          if (!s.types[e]) throw new Error("Unknown type " + e);
        }
        var s = this.RULES;
        if (s.keywords[e])
          throw new Error("Keyword " + e + " is already defined");
        if (!i.test(e))
          throw new Error("Keyword " + e + " is not a valid identifier");
        if (t) {
          if (t.macro && void 0 !== t.valid)
            throw new Error(
              '"valid" option cannot be used with macro keywords'
            );
          var n = t.type;
          if (Array.isArray(n)) {
            var u,
              l = n.length;
            for (u = 0; u < l; u++) a(n[u]);
            for (u = 0; u < l; u++) r(e, n[u], t);
          } else n && a(n), r(e, n, t);
          var c = !0 === t.$data && this._opts.$data;
          if (c && !t.validate)
            throw new Error(
              '$data support: "validate" function is not defined'
            );
          var h = t.metaSchema;
          h &&
            (c &&
              (h = {
                anyOf: [
                  h,
                  {
                    $ref:
                      "https://raw.githubusercontent.com/epoberezkin/ajv/master/lib/refs/$data.json#"
                  }
                ]
              }),
            (t.validateSchema = this.compile(h, !0)));
        }
        s.keywords[e] = s.all[e] = !0;
      }
      function s(e) {
        var t = this.RULES.custom[e];
        return t ? t.definition : this.RULES.keywords[e] || !1;
      }
      function n(e) {
        var t = this.RULES;
        delete t.keywords[e], delete t.all[e], delete t.custom[e];
        for (var r = 0; r < t.length; r++)
          for (var a = t[r].rules, s = 0; s < a.length; s++)
            if (a[s].keyword == e) {
              a.splice(s, 1);
              break;
            }
      }
      var i = /^[a-z_$][a-z0-9_$\-]*$/i,
        o = r(36);
      e.exports = { add: a, get: s, remove: n };
    },
    function(e, t, r) {
      "use strict";
      e.exports = function(e) {
        var t = e._opts.defaultMeta,
          r =
            "string" == typeof t
              ? { $ref: t }
              : e.getSchema("http://json-schema.org/draft-06/schema")
              ? { $ref: "http://json-schema.org/draft-06/schema" }
              : {};
        e.addKeyword("patternGroups", {
          metaSchema: {
            type: "object",
            additionalProperties: {
              type: "object",
              required: ["schema"],
              properties: {
                maximum: { type: "integer", minimum: 0 },
                minimum: { type: "integer", minimum: 0 },
                schema: r
              },
              additionalProperties: !1
            }
          }
        }),
          e.RULES.all.properties.implements.push("patternGroups");
      };
    },
    function(e, t) {
      e.exports = {
        $schema: "http://json-schema.org/draft-06/schema#",
        $id:
          "https://raw.githubusercontent.com/epoberezkin/ajv/master/lib/refs/$data.json#",
        description:
          "Meta-schema for $data reference (JSON-schema extension proposal)",
        type: "object",
        required: ["$data"],
        properties: {
          $data: {
            type: "string",
            anyOf: [
              { format: "relative-json-pointer" },
              { format: "json-pointer" }
            ]
          }
        },
        additionalProperties: !1
      };
    },
    function(e, t) {
      e.exports = {
        $schema: "http://json-schema.org/draft-06/schema#",
        $id: "http://json-schema.org/draft-06/schema#",
        title: "Core schema meta-schema",
        definitions: {
          schemaArray: { type: "array", minItems: 1, items: { $ref: "#" } },
          nonNegativeInteger: { type: "integer", minimum: 0 },
          nonNegativeIntegerDefault0: {
            allOf: [
              { $ref: "#/definitions/nonNegativeInteger" },
              { default: 0 }
            ]
          },
          simpleTypes: {
            enum: [
              "array",
              "boolean",
              "integer",
              "null",
              "number",
              "object",
              "string"
            ]
          },
          stringArray: {
            type: "array",
            items: { type: "string" },
            uniqueItems: !0,
            default: []
          }
        },
        type: ["object", "boolean"],
        properties: {
          $id: { type: "string", format: "uri-reference" },
          $schema: { type: "string", format: "uri" },
          $ref: { type: "string", format: "uri-reference" },
          title: { type: "string" },
          description: { type: "string" },
          default: {},
          multipleOf: { type: "number", exclusiveMinimum: 0 },
          maximum: { type: "number" },
          exclusiveMaximum: { type: "number" },
          minimum: { type: "number" },
          exclusiveMinimum: { type: "number" },
          maxLength: { $ref: "#/definitions/nonNegativeInteger" },
          minLength: { $ref: "#/definitions/nonNegativeIntegerDefault0" },
          pattern: { type: "string", format: "regex" },
          additionalItems: { $ref: "#" },
          items: {
            anyOf: [{ $ref: "#" }, { $ref: "#/definitions/schemaArray" }],
            default: {}
          },
          maxItems: { $ref: "#/definitions/nonNegativeInteger" },
          minItems: { $ref: "#/definitions/nonNegativeIntegerDefault0" },
          uniqueItems: { type: "boolean", default: !1 },
          contains: { $ref: "#" },
          maxProperties: { $ref: "#/definitions/nonNegativeInteger" },
          minProperties: { $ref: "#/definitions/nonNegativeIntegerDefault0" },
          required: { $ref: "#/definitions/stringArray" },
          additionalProperties: { $ref: "#" },
          definitions: {
            type: "object",
            additionalProperties: { $ref: "#" },
            default: {}
          },
          properties: {
            type: "object",
            additionalProperties: { $ref: "#" },
            default: {}
          },
          patternProperties: {
            type: "object",
            additionalProperties: { $ref: "#" },
            default: {}
          },
          dependencies: {
            type: "object",
            additionalProperties: {
              anyOf: [{ $ref: "#" }, { $ref: "#/definitions/stringArray" }]
            }
          },
          propertyNames: { $ref: "#" },
          const: {},
          enum: { type: "array", minItems: 1, uniqueItems: !0 },
          type: {
            anyOf: [
              { $ref: "#/definitions/simpleTypes" },
              {
                type: "array",
                items: { $ref: "#/definitions/simpleTypes" },
                minItems: 1,
                uniqueItems: !0
              }
            ]
          },
          format: { type: "string" },
          allOf: { $ref: "#/definitions/schemaArray" },
          anyOf: { $ref: "#/definitions/schemaArray" },
          oneOf: { $ref: "#/definitions/schemaArray" },
          not: { $ref: "#" }
        },
        default: {}
      };
    },
    function(e, t, r) {
      "use strict";
      function a() {
        return u;
      }
      t.a = a;
      var s = r(24),
        n = r.n(s),
        i = r(55),
        o = new n.a({ useDefaults: !0 }),
        u = o.compile(i.a);
    },
    function(e, t, r) {
      "use strict";
      var a = r(0);
      r.n(a);
      t.a = {
        $schema: "http://json-schema.org/draft-06/schema#",
        title: "Selections",
        type: "object",
        properties: {
          betType: { type: "string" },
          bookMakersBonus: {
            type: "object",
            allWinnersBonus: { type: "number", minumum: 0 },
            oneWinnerConsolation: {
              type: "string",
              enum: [a.OWC_NONE, a.OWC_DOUBLE, a.OWC_TREBLE]
            }
          },
          eachWay: { type: "boolean", default: !1 },
          eachWayType: {
            default: a.WIN_TO_WIN_PLACE_TO_PLACE,
            enum: [a.WIN_TO_WIN_PLACE_TO_PLACE, a.EACH_WAY_ALL_EACH_WAY]
          },
          oddsFormat: { default: a.FRACTIONS, enum: [a.FRACTIONS, a.DECIMAL] },
          selections: {
            type: "array",
            items: {
              title: "Selection",
              type: "object",
              properties: {
                outcome: {
                  type: "string",
                  enum: [a.WINNER, a.PLACED, a.LOST, a.VOID, a.DEAD_HEAT]
                },
                odds: { type: "string" },
                place: { type: "string" },
                rule4: { default: 0, type: "integer", minimum: 0, maximum: 90 },
                deadHeatRunners: { type: "integer", minimum: 2, maximum: 9 }
              }
            }
          },
          stake: { type: "number", minimum: 0 },
          stakeType: {
            default: a.STAKE_PER_BET,
            enum: [a.TOTAL_COMBINED_STAKE, a.STAKE_PER_BET]
          }
        },
        required: ["betType", "selections", "stake"]
      };
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        if (6 !== e.selections.length)
          throw "Wrong number of selections for bet (6 required, got " +
            e.selections.length +
            ")";
        var t = r.i(n.a)(
            new o.a({
              betType: "accumulator",
              eachWay: e.eachWay,
              eachWayType: e.eachWayType,
              selections: e.selections.slice(0, 3),
              stake: e.stake
            }),
            i.a.patent
          ),
          a = r.i(n.a)(
            new o.a({
              betType: "accumulator",
              eachWay: e.eachWay,
              eachWayType: e.eachWayType,
              selections: e.selections.slice(3, 6),
              stake: e.stake
            }),
            i.a.patent
          ),
          u = r.i(n.a)(
            new o.a({
              betType: "accumulator",
              eachWay: e.eachWay,
              eachWayType: e.eachWayType,
              selections: e.selections.slice(1, 5),
              stake: e.stake
            }),
            i.a.yankee
          ),
          l = r.i(s.a)(
            new o.a({
              betType: "accumulator",
              eachWay: e.eachWay,
              eachWayType: e.eachWayType,
              selections: e.selections,
              stake: e.stake
            })
          );
        console.log(
          t.profit.toString(),
          a.profit.toString(),
          u.profit.toString(),
          l.profit.toString()
        );
      }
      var s = r(4),
        n = r(19),
        i = r(6),
        o = r(2);
      t.a = a;
    },
    function(e, t, r) {
      "use strict";
      var a = r(59),
        s = r(58);
      r.d(t, "b", function() {
        return a.a;
      }),
        r.d(t, "a", function() {
          return s.a;
        });
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        if (e.selections.length < 1)
          throw "Not enough selections for bet (a minimum of 1 required, got " +
            e.selections.length +
            ")";
        if (e.selections.length > 3)
          throw "Too many selections for bet (a maximum of 3 required, got " +
            e.selections.length +
            ")";
        var t = new n.a(e.stake),
          r = new n.a(0);
        e.stakeType === i.TOTAL_COMBINED_STAKE &&
          (t = t.div(2 * e.selections.length));
        for (
          var a = t.times(e.selections[0].odds), s = 1;
          s < e.selections.length;
          s++
        ) {
          var o = e.selections[s].odds;
          a = a.times(o);
        }
        r = a;
        var u = void 0,
          l = e.selections.length;
        return (
          1 === l
            ? (u = t.times(2))
            : 2 === l
            ? (u = t.times(4))
            : 3 === l && (u = t.times(8)),
          { returns: r, profit: r.minus(u), outlay: u }
        );
      }
      var s = r(1),
        n = r.n(s),
        i = r(0);
      r.n(i);
      t.a = a;
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        if (e.selections.length < 1)
          throw "Not enough selections for bet (a minimum of 1 required, got " +
            e.selections.length +
            ")";
        if (e.selections.length > 3)
          throw "Too many selections for bet (a maximum of 3 required, got " +
            e.selections.length +
            ")";
        var t = new n.a(e.stake),
          r = new n.a(0);
        e.stakeType === i.TOTAL_COMBINED_STAKE &&
          (t = t.div(e.selections.length));
        var a = t.times(e.selections[0].odds);
        r = r.add(a);
        for (var s = 1; s < e.selections.length; s++) {
          var o = e.selections[s].odds;
          (a = a.times(o)), (r = r.add(a));
        }
        var u = t;
        return { returns: r, profit: r.minus(u), outlay: u };
      }
      var s = r(1),
        n = r.n(s),
        i = r(0);
      r.n(i);
      t.a = a;
    },
    function(e, t, r) {
      "use strict";
      function a(e, t) {
        var r = JSON.parse(JSON.stringify(e));
        return r.splice(t), r;
      }
      function s(e) {
        var t = { single: 1, double: 2, treble: 3 };
        for (var s in t)
          !(function(s) {
            if (t.hasOwnProperty(s)) {
              var i = t[s];
              e[s] = function(e) {
                if (e.selections.length < i)
                  throw "Wrong number of selections for " +
                    s +
                    " (" +
                    i +
                    " required, got " +
                    e.selections.length +
                    ")";
                return (
                  e.selections.length > i &&
                    (e.selections = a(e.selections, i)),
                  r.i(n.a)(e)
                );
              };
            }
          })(s);
        for (var s in c.a)
          !(function(t) {
            if (c.a.hasOwnProperty(t)) {
              var s = c.a[t].selections,
                n = c.a[t].minWinningSelections;
              e[t] = function(e) {
                if (e.selections.length < s)
                  throw "Wrong number of selections for " +
                    t +
                    " (" +
                    s +
                    " required, got " +
                    e.selections.length +
                    ")";
                return (
                  e.selections.length > s &&
                    (e.selections = a(e.selections, s)),
                  r.i(u.a)(e, { minWinningSelections: n })
                );
              };
            }
          })(s);
        for (var s in c.b)
          !(function(t) {
            if (c.b.hasOwnProperty(t)) {
              var s = c.b[t].selections,
                n = c.b[t].minWinningSelections;
              e[t] = function(e) {
                if (e.selections.length < s)
                  throw "Wrong number of selections for " +
                    t +
                    " (" +
                    s +
                    " required, got " +
                    e.selections.length +
                    ")";
                return (
                  e.selections.length > s &&
                    (e.selections = a(e.selections, s)),
                  r.i(l.a)(e, { minWinningSelections: n })
                );
              };
            }
          })(s);
        (e.accumulator = n.a),
          (e.alphabet = i.a),
          (e.doubleStakesAbout = e.dsa = h.a),
          (e.fullCover = u.a),
          (e.reverseForecast = o.a),
          (e.singleStakesAbout = e.ssa = h.b),
          (e.straightForecast = o.b),
          (e.unionJackPatent = f.a),
          (e.unionJackRoundRobin = f.b),
          (e.unionJackTrebles = f.c),
          (e.unionJackTrixie = f.d);
      }
      t.a = s;
      var n = r(4),
        i = r(56),
        o = r(57),
        u = r(19),
        l = r(61),
        c = r(6),
        h = r(68),
        f = r(62);
    },
    function(e, t, r) {
      "use strict";
      function a(e, t) {
        function a(e, t) {
          1 === e && (e = "Single"),
            2 === e && (e = "Double"),
            3 === e && (e = "Treble"),
            e > 3 && (e += "-fold"),
            e in m || (m[e] = { win: 0, place: 0, lost: 0 }),
            t === u.WINNER
              ? m[e].win++
              : t === u.PLACED
              ? m[e].place++
              : (t !== u.LOSE && t !== u.VOID) || m[e].lost++;
        }
        var i = t.minWinningSelections,
          c = e.selections,
          p = new o.a(0),
          d = e.stake,
          m = {},
          v = r.i(h.e)(i, c.length),
          g = new o.a(
            v
              .map(function(e, t) {
                var a = r
                  .i(l.combination)(r.i(h.e)(1, c.length), t)
                  .toArray().length;
                return t === v.length - 1 ? 3 * a : a;
              })
              .reduce(function(e, t) {
                return e + t;
              })
          );
        e.eachWay && (g = g.times(2)),
          e.stakeType === u.TOTAL_COMBINED_STAKE &&
            (d = parseFloat(new o.a(e.stake).div(g).toString())),
          (p =
            c.filter(h.f).length < i
              ? new o.a(0)
              : r
                  .i(h.e)(i, c.length)
                  .map(function(t) {
                    return r
                      .i(l.combination)(c, t)
                      .toArray()
                      .map(function(t) {
                        console.log(t);
                        var i = t.filter(h.a),
                          l = t.filter(h.g),
                          c = t.filter(h.h),
                          p = u.WINNER;
                        if (
                          (i.length > 0 && (p = u.PLACED),
                          c.length > 0 && (p = u.VOID),
                          l.length > 0 && (p = u.LOSE),
                          a(t.length, p),
                          l.length > 0)
                        )
                          return new o.a(0);
                        var m = new o.a(0);
                        if (2 === t.length) {
                          a("SSA", p);
                          var v = r.i(n.a)(
                            new f.a({
                              betType: "ssa",
                              eachWay: e.eachWay,
                              eachWayType: e.eachWayType,
                              selections: t,
                              stake: d
                            })
                          ).returns;
                          console.log("ssa", v.toString()), (m = m.plus(v));
                        }
                        var g = r.i(s.a)(
                          new f.a({
                            betType: "accumulator",
                            eachWay: e.eachWay,
                            eachWayType: e.eachWayType,
                            selections: t,
                            stake: d
                          })
                        ).returns;
                        return (
                          console.log("accumulator", t.length, g.toString()),
                          (m = m.plus(g))
                        );
                      })
                      .reduce(function(e, t) {
                        return e.plus(t);
                      });
                  })
                  .reduce(function(e, t) {
                    return e.plus(t);
                  }));
        var y = g.times(new o.a(d));
        return { returns: p, profit: p.minus(y), outlay: y, bets: m };
      }
      var s = r(4),
        n = r(12),
        i = r(1),
        o = r.n(i),
        u = r(0),
        l = (r.n(u), r(8)),
        c = (r.n(l), r(7)),
        h = (r.n(c), r(3)),
        f = r(2);
      t.a = a;
    },
    function(e, t, r) {
      "use strict";
      var a = r(65),
        s = r(66),
        n = r(63),
        i = r(64);
      r.d(t, "c", function() {
        return a.a;
      }),
        r.d(t, "d", function() {
          return s.a;
        }),
        r.d(t, "a", function() {
          return n.a;
        }),
        r.d(t, "b", function() {
          return i.a;
        });
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        function t(e, t) {
          1 === e && (e = "Single"),
            2 === e && (e = "Double"),
            3 === e && (e = "Treble"),
            e > 3 && (e += "-fold"),
            e in l || (l[e] = { win: 0, place: 0 }),
            "win" === t ? l[e].win++ : l[e].place++;
        }
        e = new c.a(JSON.parse(JSON.stringify(e)));
        var a = new i.a(56),
          n = e.stake,
          l = {};
        e.eachWay && (a = a.times(2)),
          e.stakeType === o.TOTAL_COMBINED_STAKE &&
            (n = parseFloat(new i.a(e.stake).div(a).toString()));
        var p = h.c
            .map(function(a) {
              var i = a
                  .map(function(a) {
                    var i = [e.selections[a - 1]].filter(f.a),
                      o = "win";
                    return (
                      i.length > 0 && (o = "place"),
                      t(1, o),
                      r.i(s.a)(
                        new c.a({
                          betType: "accumulator",
                          eachWay: e.eachWay,
                          eachWayType: e.eachWayType,
                          stake: n,
                          selections: [e.selections[a - 1]]
                        })
                      ).returns
                    );
                  })
                  .reduce(function(e, t) {
                    return e.plus(t);
                  }),
                o = r
                  .i(u.combination)(a, 2)
                  .toArray()
                  .map(function(a) {
                    var i = [
                        e.selections[a[0] - 1],
                        e.selections[a[1] - 1]
                      ].filter(f.a),
                      o = "win";
                    return (
                      i.length > 0 && (o = "place"),
                      t(a.length, o),
                      r.i(s.a)(
                        new c.a({
                          betType: "accumulator",
                          eachWay: e.eachWay,
                          eachWayType: e.eachWayType,
                          stake: n,
                          selections: [
                            e.selections[a[0] - 1],
                            e.selections[a[1] - 1]
                          ]
                        })
                      ).returns
                    );
                  })
                  .reduce(function(e, t) {
                    return e.plus(t);
                  }),
                l = [
                  e.selections[a[0] - 1],
                  e.selections[a[1] - 1],
                  e.selections[a[2] - 1]
                ].filter(f.a),
                h = "win";
              return (
                l.length > 0 && (h = "place"),
                t(a.length, h),
                r
                  .i(s.a)(
                    new c.a({
                      betType: "accumulator",
                      eachWay: e.eachWay,
                      eachWayType: e.eachWayType,
                      stake: n,
                      selections: [
                        e.selections[a[0] - 1],
                        e.selections[a[1] - 1],
                        e.selections[a[2] - 1]
                      ]
                    })
                  )
                  .returns.plus(i)
                  .plus(o)
              );
            })
            .reduce(function(e, t) {
              return e.plus(t);
            }),
          d = a.times(new i.a(n));
        return { returns: p, profit: p.minus(d), outlay: d, bets: l };
      }
      var s = r(4),
        n = r(1),
        i = r.n(n),
        o = r(0),
        u = (r.n(o), r(8)),
        l = (r.n(u), r(7)),
        c = (r.n(l), r(2)),
        h = r(6),
        f = r(3);
      t.a = a;
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        function t(e, t) {
          1 === e && (e = "Single"),
            2 === e && (e = "Double"),
            3 === e && (e = "Treble"),
            e > 3 && (e += "-fold"),
            e in l || (l[e] = { win: 0, place: 0 }),
            "win" === t ? l[e].win++ : l[e].place++;
        }
        e = new c.a(JSON.parse(JSON.stringify(e)));
        var a = new i.a(80),
          n = e.stake,
          l = {};
        e.eachWay && (a = a.times(2)),
          e.stakeType === o.TOTAL_COMBINED_STAKE &&
            (n = parseFloat(new i.a(e.stake).div(a).toString()));
        var p = h.c
            .map(function(a) {
              var i = r
                  .i(u.combination)(a, 2)
                  .toArray()
                  .map(function(a) {
                    var i = [
                        e.selections[a[0] - 1],
                        e.selections[a[1] - 1]
                      ].filter(f.a),
                      o = "win";
                    return (
                      i.length > 0 && (o = "place"),
                      t("SSA", o),
                      r.i(s.a)(
                        new c.a({
                          betType: "ssa",
                          eachWay: e.eachWay,
                          eachWayType: e.eachWayType,
                          stake: n,
                          selections: [
                            e.selections[a[0] - 1],
                            e.selections[a[1] - 1]
                          ]
                        })
                      ).returns
                    );
                  })
                  .reduce(function(e, t) {
                    return e.plus(t);
                  }),
                o = r
                  .i(u.combination)(a, 2)
                  .toArray()
                  .map(function(a) {
                    var i = [
                        e.selections[a[0] - 1],
                        e.selections[a[1] - 1]
                      ].filter(f.a),
                      o = "win";
                    return (
                      i.length > 0 && (o = "place"),
                      t(a.length, o),
                      r.i(s.a)(
                        new c.a({
                          betType: "accumulator",
                          eachWay: e.eachWay,
                          eachWayType: e.eachWayType,
                          stake: n,
                          selections: [
                            e.selections[a[0] - 1],
                            e.selections[a[1] - 1]
                          ]
                        })
                      ).returns
                    );
                  })
                  .reduce(function(e, t) {
                    return e.plus(t);
                  }),
                l = [
                  e.selections[a[0] - 1],
                  e.selections[a[1] - 1],
                  e.selections[a[2] - 1]
                ].filter(f.a),
                h = "win";
              return (
                l.length > 0 && (h = "place"),
                t(a.length, h),
                r
                  .i(s.a)(
                    new c.a({
                      betType: "accumulator",
                      eachWay: e.eachWay,
                      eachWayType: e.eachWayType,
                      stake: n,
                      selections: [
                        e.selections[a[0] - 1],
                        e.selections[a[1] - 1],
                        e.selections[a[2] - 1]
                      ]
                    })
                  )
                  .returns.plus(i)
                  .plus(o)
              );
            })
            .reduce(function(e, t) {
              return e.plus(t);
            }),
          d = a.times(new i.a(n));
        return { returns: p, profit: p.minus(d), outlay: d, bets: l };
      }
      var s = r(4),
        n = r(1),
        i = r.n(n),
        o = r(0),
        u = (r.n(o), r(8)),
        l = (r.n(u), r(7)),
        c = (r.n(l), r(2)),
        h = r(6),
        f = r(3);
      t.a = a;
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        function t(e, t) {
          1 === e && (e = "Single"),
            2 === e && (e = "Double"),
            3 === e && (e = "Treble"),
            e > 3 && (e += "-fold"),
            e in u || (u[e] = { win: 0, place: 0 }),
            "win" === t ? u[e].win++ : u[e].place++;
        }
        e = new l.a(JSON.parse(JSON.stringify(e)));
        var a = new i.a(8),
          n = e.stake,
          u = {};
        e.eachWay && (a = a.times(2)),
          e.stakeType === o.TOTAL_COMBINED_STAKE &&
            (n = parseFloat(new i.a(e.stake).div(a).toString()));
        var f = c.c
            .map(function(a) {
              var i = [
                  e.selections[a[0] - 1],
                  e.selections[a[1] - 1],
                  e.selections[a[2] - 1]
                ].filter(h.a),
                o = "win";
              return (
                i.length > 0 && (o = "place"),
                t(a.length, o),
                r.i(s.a)(
                  new l.a({
                    betType: "accumulator",
                    eachWay: e.eachWay,
                    eachWayType: e.eachWayType,
                    stake: n,
                    selections: [
                      e.selections[a[0] - 1],
                      e.selections[a[1] - 1],
                      e.selections[a[2] - 1]
                    ]
                  })
                ).returns
              );
            })
            .reduce(function(e, t) {
              return e.plus(t);
            }),
          p = a.times(new i.a(n));
        return { returns: f, profit: f.minus(p), outlay: p, bets: u };
      }
      var s = r(4),
        n = r(1),
        i = r.n(n),
        o = r(0),
        u = (r.n(o), r(7)),
        l = (r.n(u), r(2)),
        c = r(6),
        h = r(3);
      t.a = a;
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        function t(e, t) {
          1 === e && (e = "Single"),
            2 === e && (e = "Double"),
            3 === e && (e = "Treble"),
            e > 3 && (e += "-fold"),
            e in l || (l[e] = { win: 0, place: 0 }),
            "win" === t ? l[e].win++ : l[e].place++;
        }
        e = new c.a(JSON.parse(JSON.stringify(e)));
        var a = new i.a(32),
          n = e.stake,
          l = {};
        e.eachWay && (a = a.times(2)),
          e.stakeType === o.TOTAL_COMBINED_STAKE &&
            (n = parseFloat(new i.a(e.stake).div(a).toString()));
        var p = h.c
            .map(function(a) {
              var i = r
                  .i(u.combination)(a, 2)
                  .toArray()
                  .map(function(a) {
                    var i = [
                        e.selections[a[0] - 1],
                        e.selections[a[1] - 1]
                      ].filter(f.a),
                      o = "win";
                    return (
                      i.length > 0 && (o = "place"),
                      t(a.length, o),
                      r.i(s.a)(
                        new c.a({
                          betType: "accumulator",
                          eachWay: e.eachWay,
                          eachWayType: e.eachWayType,
                          stake: n,
                          selections: [
                            e.selections[a[0] - 1],
                            e.selections[a[1] - 1]
                          ]
                        })
                      ).returns
                    );
                  })
                  .reduce(function(e, t) {
                    return e.plus(t);
                  }),
                o = [
                  e.selections[a[0] - 1],
                  e.selections[a[1] - 1],
                  e.selections[a[2] - 1]
                ].filter(f.a),
                l = "win";
              return (
                o.length > 0 && (l = "place"),
                t(a.length, l),
                r
                  .i(s.a)(
                    new c.a({
                      betType: "accumulator",
                      eachWay: e.eachWay,
                      eachWayType: e.eachWayType,
                      stake: n,
                      selections: [
                        e.selections[a[0] - 1],
                        e.selections[a[1] - 1],
                        e.selections[a[2] - 1]
                      ]
                    })
                  )
                  .returns.plus(i)
              );
            })
            .reduce(function(e, t) {
              return e.plus(t);
            }),
          d = a.times(new i.a(n));
        return { returns: p, profit: p.minus(d), outlay: d, bets: l };
      }
      var s = r(4),
        n = r(1),
        i = r.n(n),
        o = r(0),
        u = (r.n(o), r(8)),
        l = (r.n(u), r(7)),
        c = (r.n(l), r(2)),
        h = r(6),
        f = r(3);
      t.a = a;
    },
    function(e, t, r) {
      "use strict";
      var a = (r(4), r(1)),
        s = (r.n(a), r(8));
      r.n(s), r(3), r(2);
    },
    function(e, t, r) {
      "use strict";
      var a = r(69),
        s = r(12);
      r.d(t, "b", function() {
        return s.a;
      }),
        r.d(t, "a", function() {
          return a.a;
        });
    },
    function(e, t, r) {
      "use strict";
      function a() {}
      r.d(t, "a", function() {
        return a;
      });
      var s = r(1);
      r.n(s), r(3), r(2), r(12), r(67);
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        var t = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
        if (!(e instanceof n.a)) throw "slip must be an instance of Slip";
        (e = new n.a(JSON.parse(JSON.stringify(e)))),
          e.selections.forEach(function(e) {
            (e.outcome !== s.VOID && e.outcome !== s.LOSE) ||
              ((e.odds = "0"), (e.place = "1/5"));
          });
        var r = this[e.betType](e);
        return t
          ? {
              returns: r.returns,
              profit: r.profit,
              outlay: r.outlay,
              bets: r.bets || {}
            }
          : {
              returns: r.returns.round(10).toFixed(2),
              profit: r.profit.round(10).toFixed(2),
              outlay: r.outlay.round(10).toFixed(2),
              bets: r.bets || {}
            };
      }
      var s = r(0),
        n = (r.n(s), r(2));
      t.a = a;
    },
    function(e, t, r) {
      "use strict";
      t.a = {
        trixie: { selections: 3, minWinningSelections: 2 },
        yankee: { selections: 4, minWinningSelections: 2 },
        superYankee: { selections: 5, minWinningSelections: 2 },
        canadian: { selections: 5, minWinningSelections: 2 },
        heinz: { selections: 6, minWinningSelections: 2 },
        superHeinz: { selections: 7, minWinningSelections: 2 },
        goliath: { selections: 8, minWinningSelections: 2 },
        block: { selections: 9, minWinningSelections: 2 },
        patent: { selections: 3, minWinningSelections: 1 },
        lucky15: { selections: 4, minWinningSelections: 1 },
        lucky31: { selections: 5, minWinningSelections: 1 },
        lucky63: { selections: 6, minWinningSelections: 1 },
        superHeinzWithSingles: { selections: 7, minWinningSelections: 1 },
        goliathWithSingles: { selections: 8, minWinningSelections: 1 },
        blockWithSingles: { selections: 9, minWinningSelections: 1 }
      };
    },
    function(e, t, r) {
      "use strict";
      t.a = {
        roundRobin: { selections: 3, minWinningSelections: 2 },
        flag: { selections: 4, minWinningSelections: 2 },
        superFlag: { selections: 5, minWinningSelections: 2 },
        heinzFlag: { selections: 6, minWinningSelections: 2 },
        superHeinzFlag: { selections: 7, minWinningSelections: 2 },
        goliathFlag: { selections: 8, minWinningSelections: 2 }
      };
    },
    function(e, t, r) {
      "use strict";
      var a = [
        [1, 2, 3],
        [4, 5, 6],
        [7, 8, 9],
        [1, 4, 7],
        [2, 5, 8],
        [3, 6, 9],
        [1, 5, 9],
        [3, 5, 7]
      ];
      t.a = a;
    },
    function(e, t, r) {
      "use strict";
      function a(e, t) {
        if (e.outcome === o.DEAD_HEAT) {
          if (t) {
            if (!("placesOffered" in e))
              throw new Error(
                'Cannot calculate Dead Heat Each Way selection without "placesOffered" prop'
              );
            if (!("tiedPosition" in e))
              throw new Error(
                'Cannot calculate Dead Heat Each Way selection without "tiedPosition" prop'
              );
          }
          if (!("deadHeatRunners" in e))
            throw new Error(
              'Cannot calculate Dead Heat Each Way selection without "deadHeatRunners" prop'
            );
          var r = new i.a(0),
            a = new i.a(0),
            s = new i.a(0),
            n = new i.a(0),
            u = new i.a(0);
          return (
            (s = new i.a(e.deadHeatRunners)),
            t &&
              ((r = new i.a(e.placesOffered)),
              (a = new i.a(e.tiedPosition)),
              (n = r.minus(a).plus(1)),
              (u = a.minus(1).plus(s))),
            {
              placesOffered: r,
              tiedPosition: a,
              deadHeatRunners: s,
              remainingPlaces: n,
              finishers: u
            }
          );
        }
        return !1;
      }
      function s(e, t, r, s) {
        var n = a(e, !0),
          o = new i.a(1),
          u = new i.a(s);
        if (((t = t.minus(1)), n.tiedPosition.eq(1)))
          if (n.finishers.gt(n.placesOffered)) {
            o = n.remainingPlaces.div(n.deadHeatRunners);
            var l = o.times(u);
            u = l.times(t);
          } else u = u.div(n.deadHeatRunners).times(t);
        else
          n.finishers.gt(n.placesOffered) &&
            ((o = n.remainingPlaces.div(n.deadHeatRunners)), (u = o.times(u)));
        return u;
      }
      r.d(t, "b", function() {
        return a;
      }),
        r.d(t, "a", function() {
          return s;
        });
      var n = r(1),
        i = r.n(n),
        o = r(0);
      r.n(o);
    },
    function(e, t, r) {
      e.exports = r(22).default;
    },
    function(e, t, r) {
      "use strict";
      function a(e, t) {
        var r = new n.a(t);
        return (
          "bookMakersBonus" in e &&
            "allWinnersBonus" in e.bookMakersBonus &&
            (r = r.plus(r.div(100).times(e.bookMakersBonus.allWinnersBonus))),
          r
        );
      }
      var s = r(1),
        n = r.n(s);
      t.a = a;
    },
    function(e, t, r) {
      "use strict";
      function a(e, t, r) {
        var a = r;
        return (
          t.outcome === s.WINNER &&
            "bookMakersBonus" in e &&
            "oneWinnerConsolation" in e.bookMakersBonus &&
            (e.bookMakersBonus.oneWinnerConsolation === s.OWC_DOUBLE
              ? (a = r.times(2).minus(1))
              : e.bookMakersBonus.oneWinnerConsolation === s.OWC_TREBLE &&
                (a = r.times(3).minus(2))),
          a
        );
      }
      var s = r(0);
      r.n(s);
      t.a = a;
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        var t =
          arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0;
        if ("string" != typeof e) throw "Odds must be type string";
        var a = void 0;
        if (((a = e.indexOf("/") > -1 ? r.i(i.b)(e) : new n.a(e)), t > 0)) {
          var s = new n.a(t);
          a = a
            .minus(1)
            .times(new n.a(100).minus(s).div(100))
            .plus(1);
        }
        return a;
      }
      t.a = a;
      var s = r(1),
        n = r.n(s),
        i = r(3);
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        if ("string" != typeof e) throw "fraction must be string";
        var t = e.split("/").map(function(e) {
          return new n.a(e);
        });
        return t[0].div(t[1]).plus(1);
      }
      t.a = a;
      var s = r(1),
        n = r.n(s);
    },
    function(e, t, r) {
      "use strict";
      var a = r(0);
      r.n(a);
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        return (
          e.outcome === s.WINNER ||
          e.outcome === s.PLACED ||
          e.outcome === s.VOID ||
          e.outcome === s.DEAD_HEAT
        );
      }
      var s = r(0);
      r.n(s);
      t.a = a;
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        return e.outcome === s.LOSE;
      }
      var s = r(0);
      r.n(s);
      t.a = a;
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        return e.outcome === s.PLACED;
      }
      var s = r(0);
      r.n(s);
      t.a = a;
    },
    function(e, t, r) {
      "use strict";
      function a(e, t, r) {
        return 1 === e && 1 === t ? [1] : n()(e, t + 1, r);
      }
      t.a = a;
      var s = r(90),
        n = r.n(s);
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        return e.outcome === s.VOID;
      }
      var s = r(0);
      r.n(s);
      t.a = a;
    },
    function(e, t, r) {
      "use strict";
      function a(e) {
        return e.outcome === s.WINNER;
      }
      var s = r(0);
      r.n(s);
      t.a = a;
    },
    function(e, t, r) {
      (t.parse = r(88)), (t.stringify = r(89));
    },
    function(e, t) {
      var r,
        a,
        s,
        n,
        i = {
          '"': '"',
          "\\": "\\",
          "/": "/",
          b: "\b",
          f: "\f",
          n: "\n",
          r: "\r",
          t: "\t"
        },
        o = function(e) {
          throw { name: "SyntaxError", message: e, at: r, text: s };
        },
        u = function(e) {
          return (
            e && e !== a && o("Expected '" + e + "' instead of '" + a + "'"),
            (a = s.charAt(r)),
            (r += 1),
            a
          );
        },
        l = function() {
          var e,
            t = "";
          for ("-" === a && ((t = "-"), u("-")); a >= "0" && a <= "9"; )
            (t += a), u();
          if ("." === a) for (t += "."; u() && a >= "0" && a <= "9"; ) t += a;
          if ("e" === a || "E" === a)
            for (
              t += a, u(), ("-" !== a && "+" !== a) || ((t += a), u());
              a >= "0" && a <= "9";

            )
              (t += a), u();
          if (((e = +t), isFinite(e))) return e;
          o("Bad number");
        },
        c = function() {
          var e,
            t,
            r,
            s = "";
          if ('"' === a)
            for (; u(); ) {
              if ('"' === a) return u(), s;
              if ("\\" === a)
                if ((u(), "u" === a)) {
                  for (
                    r = 0, t = 0;
                    t < 4 && ((e = parseInt(u(), 16)), isFinite(e));
                    t += 1
                  )
                    r = 16 * r + e;
                  s += String.fromCharCode(r);
                } else {
                  if ("string" != typeof i[a]) break;
                  s += i[a];
                }
              else s += a;
            }
          o("Bad string");
        },
        h = function() {
          for (; a && a <= " "; ) u();
        },
        f = function() {
          switch (a) {
            case "t":
              return u("t"), u("r"), u("u"), u("e"), !0;
            case "f":
              return u("f"), u("a"), u("l"), u("s"), u("e"), !1;
            case "n":
              return u("n"), u("u"), u("l"), u("l"), null;
          }
          o("Unexpected '" + a + "'");
        },
        p = function() {
          var e = [];
          if ("[" === a) {
            if ((u("["), h(), "]" === a)) return u("]"), e;
            for (; a; ) {
              if ((e.push(n()), h(), "]" === a)) return u("]"), e;
              u(","), h();
            }
          }
          o("Bad array");
        },
        d = function() {
          var e,
            t = {};
          if ("{" === a) {
            if ((u("{"), h(), "}" === a)) return u("}"), t;
            for (; a; ) {
              if (
                ((e = c()),
                h(),
                u(":"),
                Object.hasOwnProperty.call(t, e) &&
                  o('Duplicate key "' + e + '"'),
                (t[e] = n()),
                h(),
                "}" === a)
              )
                return u("}"), t;
              u(","), h();
            }
          }
          o("Bad object");
        };
      (n = function() {
        switch ((h(), a)) {
          case "{":
            return d();
          case "[":
            return p();
          case '"':
            return c();
          case "-":
            return l();
          default:
            return a >= "0" && a <= "9" ? l() : f();
        }
      }),
        (e.exports = function(e, t) {
          var i;
          return (
            (s = e),
            (r = 0),
            (a = " "),
            (i = n()),
            h(),
            a && o("Syntax error"),
            "function" == typeof t
              ? (function e(r, a) {
                  var s,
                    n,
                    i = r[a];
                  if (i && "object" == typeof i)
                    for (s in i)
                      Object.prototype.hasOwnProperty.call(i, s) &&
                        ((n = e(i, s)),
                        void 0 !== n ? (i[s] = n) : delete i[s]);
                  return t.call(r, a, i);
                })({ "": i }, "")
              : i
          );
        });
    },
    function(e, t) {
      function r(e) {
        return (
          (o.lastIndex = 0),
          o.test(e)
            ? '"' +
              e.replace(o, function(e) {
                var t = u[e];
                return "string" == typeof t
                  ? t
                  : "\\u" + ("0000" + e.charCodeAt(0).toString(16)).slice(-4);
              }) +
              '"'
            : '"' + e + '"'
        );
      }
      function a(e, t) {
        var o,
          u,
          l,
          c,
          h,
          f = s,
          p = t[e];
        switch (
          (p &&
            "object" == typeof p &&
            "function" == typeof p.toJSON &&
            (p = p.toJSON(e)),
          "function" == typeof i && (p = i.call(t, e, p)),
          typeof p)
        ) {
          case "string":
            return r(p);
          case "number":
            return isFinite(p) ? String(p) : "null";
          case "boolean":
          case "null":
            return String(p);
          case "object":
            if (!p) return "null";
            if (
              ((s += n),
              (h = []),
              "[object Array]" === Object.prototype.toString.apply(p))
            ) {
              for (c = p.length, o = 0; o < c; o += 1) h[o] = a(o, p) || "null";
              return (
                (l =
                  0 === h.length
                    ? "[]"
                    : s
                    ? "[\n" + s + h.join(",\n" + s) + "\n" + f + "]"
                    : "[" + h.join(",") + "]"),
                (s = f),
                l
              );
            }
            if (i && "object" == typeof i)
              for (c = i.length, o = 0; o < c; o += 1)
                "string" == typeof (u = i[o]) &&
                  (l = a(u, p)) &&
                  h.push(r(u) + (s ? ": " : ":") + l);
            else
              for (u in p)
                Object.prototype.hasOwnProperty.call(p, u) &&
                  (l = a(u, p)) &&
                  h.push(r(u) + (s ? ": " : ":") + l);
            return (
              (l =
                0 === h.length
                  ? "{}"
                  : s
                  ? "{\n" + s + h.join(",\n" + s) + "\n" + f + "}"
                  : "{" + h.join(",") + "}"),
              (s = f),
              l
            );
        }
      }
      var s,
        n,
        i,
        o = /[\\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
        u = {
          "\b": "\\b",
          "\t": "\\t",
          "\n": "\\n",
          "\f": "\\f",
          "\r": "\\r",
          '"': '\\"',
          "\\": "\\\\"
        };
      e.exports = function(e, t, r) {
        var o;
        if (((s = ""), (n = ""), "number" == typeof r))
          for (o = 0; o < r; o += 1) n += " ";
        else "string" == typeof r && (n = r);
        if (
          ((i = t),
          t &&
            "function" != typeof t &&
            ("object" != typeof t || "number" != typeof t.length))
        )
          throw new Error("JSON.stringify");
        return a("", { "": e });
      };
    },
    function(e, t) {
      function r(e, t, r, a) {
        for (var s = -1, n = k(D((t - e) / (r || 1)), 0), i = Array(n); n--; )
          (i[a ? n : ++s] = e), (e += r);
        return i;
      }
      function a(e, t) {
        return (
          !!(t = null == t ? m : t) &&
          ("number" == typeof e || _.test(e)) &&
          e > -1 &&
          e % 1 == 0 &&
          e < t
        );
      }
      function s(e, t, r) {
        if (!l(r)) return !1;
        var s = typeof t;
        return (
          !!("number" == s
            ? i(r) && a(t, r.length)
            : "string" == s && t in r) && n(r[t], e)
        );
      }
      function n(e, t) {
        return e === t || (e !== e && t !== t);
      }
      function i(e) {
        return null != e && u(e.length) && !o(e);
      }
      function o(e) {
        var t = l(e) ? A.call(e) : "";
        return t == y || t == P;
      }
      function u(e) {
        return "number" == typeof e && e > -1 && e % 1 == 0 && e <= m;
      }
      function l(e) {
        var t = typeof e;
        return !!e && ("object" == t || "function" == t);
      }
      function c(e) {
        return !!e && "object" == typeof e;
      }
      function h(e) {
        return "symbol" == typeof e || (c(e) && A.call(e) == E);
      }
      function f(e) {
        if (!e) return 0 === e ? e : 0;
        if ((e = p(e)) === d || e === -d) {
          return (e < 0 ? -1 : 1) * v;
        }
        return e === e ? e : 0;
      }
      function p(e) {
        if ("number" == typeof e) return e;
        if (h(e)) return g;
        if (l(e)) {
          var t = "function" == typeof e.valueOf ? e.valueOf() : e;
          e = l(t) ? t + "" : t;
        }
        if ("string" != typeof e) return 0 === e ? e : +e;
        e = e.replace(w, "");
        var r = S.test(e);
        return r || O.test(e) ? x(e.slice(2), r ? 2 : 8) : b.test(e) ? g : +e;
      }
      var d = 1 / 0,
        m = 9007199254740991,
        v = 1.7976931348623157e308,
        g = NaN,
        y = "[object Function]",
        P = "[object GeneratorFunction]",
        E = "[object Symbol]",
        w = /^\s+|\s+$/g,
        b = /^[-+]0x[0-9a-f]+$/i,
        S = /^0b[01]+$/i,
        O = /^0o[0-7]+$/i,
        _ = /^(?:0|[1-9]\d*)$/,
        x = parseInt,
        R = Object.prototype,
        A = R.toString,
        D = Math.ceil,
        k = Math.max,
        T = (function(e) {
          return function(t, a, n) {
            return (
              n && "number" != typeof n && s(t, a, n) && (a = n = void 0),
              (t = f(t)),
              void 0 === a ? ((a = t), (t = 0)) : (a = f(a)),
              (n = void 0 === n ? (t < a ? 1 : -1) : f(n)),
              r(t, a, n, e)
            );
          };
        })();
      e.exports = T;
    },
    function(e, t, r) {
      (function(e, a) {
        var s;
        !(function(n) {
          function i(e) {
            throw new RangeError(F[e]);
          }
          function o(e, t) {
            for (var r = e.length, a = []; r--; ) a[r] = t(e[r]);
            return a;
          }
          function u(e, t) {
            var r = e.split("@"),
              a = "";
            return (
              r.length > 1 && ((a = r[0] + "@"), (e = r[1])),
              (e = e.replace(T, ".")),
              a + o(e.split("."), t).join(".")
            );
          }
          function l(e) {
            for (var t, r, a = [], s = 0, n = e.length; s < n; )
              (t = e.charCodeAt(s++)),
                t >= 55296 && t <= 56319 && s < n
                  ? ((r = e.charCodeAt(s++)),
                    56320 == (64512 & r)
                      ? a.push(((1023 & t) << 10) + (1023 & r) + 65536)
                      : (a.push(t), s--))
                  : a.push(t);
            return a;
          }
          function c(e) {
            return o(e, function(e) {
              var t = "";
              return (
                e > 65535 &&
                  ((e -= 65536),
                  (t += $(((e >>> 10) & 1023) | 55296)),
                  (e = 56320 | (1023 & e))),
                (t += $(e))
              );
            }).join("");
          }
          function h(e) {
            return e - 48 < 10
              ? e - 22
              : e - 65 < 26
              ? e - 65
              : e - 97 < 26
              ? e - 97
              : w;
          }
          function f(e, t) {
            return e + 22 + 75 * (e < 26) - ((0 != t) << 5);
          }
          function p(e, t, r) {
            var a = 0;
            for (
              e = r ? I(e / _) : e >> 1, e += I(e / t);
              e > (j * S) >> 1;
              a += w
            )
              e = I(e / j);
            return I(a + ((j + 1) * e) / (e + O));
          }
          function d(e) {
            var t,
              r,
              a,
              s,
              n,
              o,
              u,
              l,
              f,
              d,
              m = [],
              v = e.length,
              g = 0,
              y = R,
              P = x;
            for (r = e.lastIndexOf(A), r < 0 && (r = 0), a = 0; a < r; ++a)
              e.charCodeAt(a) >= 128 && i("not-basic"), m.push(e.charCodeAt(a));
            for (s = r > 0 ? r + 1 : 0; s < v; ) {
              for (
                n = g, o = 1, u = w;
                s >= v && i("invalid-input"),
                  (l = h(e.charCodeAt(s++))),
                  (l >= w || l > I((E - g) / o)) && i("overflow"),
                  (g += l * o),
                  (f = u <= P ? b : u >= P + S ? S : u - P),
                  !(l < f);
                u += w
              )
                (d = w - f), o > I(E / d) && i("overflow"), (o *= d);
              (t = m.length + 1),
                (P = p(g - n, t, 0 == n)),
                I(g / t) > E - y && i("overflow"),
                (y += I(g / t)),
                (g %= t),
                m.splice(g++, 0, y);
            }
            return c(m);
          }
          function m(e) {
            var t,
              r,
              a,
              s,
              n,
              o,
              u,
              c,
              h,
              d,
              m,
              v,
              g,
              y,
              P,
              O = [];
            for (e = l(e), v = e.length, t = R, r = 0, n = x, o = 0; o < v; ++o)
              (m = e[o]) < 128 && O.push($(m));
            for (a = s = O.length, s && O.push(A); a < v; ) {
              for (u = E, o = 0; o < v; ++o)
                (m = e[o]) >= t && m < u && (u = m);
              for (
                g = a + 1,
                  u - t > I((E - r) / g) && i("overflow"),
                  r += (u - t) * g,
                  t = u,
                  o = 0;
                o < v;
                ++o
              )
                if (((m = e[o]), m < t && ++r > E && i("overflow"), m == t)) {
                  for (
                    c = r, h = w;
                    (d = h <= n ? b : h >= n + S ? S : h - n), !(c < d);
                    h += w
                  )
                    (P = c - d),
                      (y = w - d),
                      O.push($(f(d + (P % y), 0))),
                      (c = I(P / y));
                  O.push($(f(c, 0))), (n = p(r, g, a == s)), (r = 0), ++a;
                }
              ++r, ++t;
            }
            return O.join("");
          }
          function v(e) {
            return u(e, function(e) {
              return D.test(e) ? d(e.slice(4).toLowerCase()) : e;
            });
          }
          function g(e) {
            return u(e, function(e) {
              return k.test(e) ? "xn--" + m(e) : e;
            });
          }
          var y = ("object" == typeof t && t && t.nodeType,
          "object" == typeof e && e && e.nodeType,
          "object" == typeof a && a);
          var P,
            E = 2147483647,
            w = 36,
            b = 1,
            S = 26,
            O = 38,
            _ = 700,
            x = 72,
            R = 128,
            A = "-",
            D = /^xn--/,
            k = /[^\x20-\x7E]/,
            T = /[\x2E\u3002\uFF0E\uFF61]/g,
            F = {
              overflow: "Overflow: input needs wider integers to process",
              "not-basic": "Illegal input >= 0x80 (not a basic code point)",
              "invalid-input": "Invalid input"
            },
            j = w - b,
            I = Math.floor,
            $ = String.fromCharCode;
          (P = {
            version: "1.4.1",
            ucs2: { decode: l, encode: c },
            decode: d,
            encode: m,
            toASCII: g,
            toUnicode: v
          }),
            void 0 !==
              (s = function() {
                return P;
              }.call(t, r, t, e)) && (e.exports = s);
        })();
      }.call(t, r(98)(e), r(97)));
    },
    function(e, t, r) {
      "use strict";
      function a(e, t) {
        return Object.prototype.hasOwnProperty.call(e, t);
      }
      e.exports = function(e, t, r, n) {
        (t = t || "&"), (r = r || "=");
        var i = {};
        if ("string" != typeof e || 0 === e.length) return i;
        var o = /\+/g;
        e = e.split(t);
        var u = 1e3;
        n && "number" == typeof n.maxKeys && (u = n.maxKeys);
        var l = e.length;
        u > 0 && l > u && (l = u);
        for (var c = 0; c < l; ++c) {
          var h,
            f,
            p,
            d,
            m = e[c].replace(o, "%20"),
            v = m.indexOf(r);
          v >= 0
            ? ((h = m.substr(0, v)), (f = m.substr(v + 1)))
            : ((h = m), (f = "")),
            (p = decodeURIComponent(h)),
            (d = decodeURIComponent(f)),
            a(i, p)
              ? s(i[p])
                ? i[p].push(d)
                : (i[p] = [i[p], d])
              : (i[p] = d);
        }
        return i;
      };
      var s =
        Array.isArray ||
        function(e) {
          return "[object Array]" === Object.prototype.toString.call(e);
        };
    },
    function(e, t, r) {
      "use strict";
      function a(e, t) {
        if (e.map) return e.map(t);
        for (var r = [], a = 0; a < e.length; a++) r.push(t(e[a], a));
        return r;
      }
      var s = function(e) {
        switch (typeof e) {
          case "string":
            return e;
          case "boolean":
            return e ? "true" : "false";
          case "number":
            return isFinite(e) ? e : "";
          default:
            return "";
        }
      };
      e.exports = function(e, t, r, o) {
        return (
          (t = t || "&"),
          (r = r || "="),
          null === e && (e = void 0),
          "object" == typeof e
            ? a(i(e), function(i) {
                var o = encodeURIComponent(s(i)) + r;
                return n(e[i])
                  ? a(e[i], function(e) {
                      return o + encodeURIComponent(s(e));
                    }).join(t)
                  : o + encodeURIComponent(s(e[i]));
              }).join(t)
            : o
            ? encodeURIComponent(s(o)) + r + encodeURIComponent(s(e))
            : ""
        );
      };
      var n =
          Array.isArray ||
          function(e) {
            return "[object Array]" === Object.prototype.toString.call(e);
          },
        i =
          Object.keys ||
          function(e) {
            var t = [];
            for (var r in e)
              Object.prototype.hasOwnProperty.call(e, r) && t.push(r);
            return t;
          };
    },
    function(e, t, r) {
      "use strict";
      (t.decode = t.parse = r(92)), (t.encode = t.stringify = r(93));
    },
    function(e, t, r) {
      "use strict";
      function a() {
        (this.protocol = null),
          (this.slashes = null),
          (this.auth = null),
          (this.host = null),
          (this.port = null),
          (this.hostname = null),
          (this.hash = null),
          (this.search = null),
          (this.query = null),
          (this.pathname = null),
          (this.path = null),
          (this.href = null);
      }
      function s(e, t, r) {
        if (e && l.isObject(e) && e instanceof a) return e;
        var s = new a();
        return s.parse(e, t, r), s;
      }
      function n(e) {
        return (
          l.isString(e) && (e = s(e)),
          e instanceof a ? e.format() : a.prototype.format.call(e)
        );
      }
      function i(e, t) {
        return s(e, !1, !0).resolve(t);
      }
      function o(e, t) {
        return e ? s(e, !1, !0).resolveObject(t) : t;
      }
      var u = r(91),
        l = r(96);
      (t.parse = s),
        (t.resolve = i),
        (t.resolveObject = o),
        (t.format = n),
        (t.Url = a);
      var c = /^([a-z0-9.+-]+:)/i,
        h = /:[0-9]*$/,
        f = /^(\/\/?(?!\/)[^\?\s]*)(\?[^\s]*)?$/,
        p = ["<", ">", '"', "`", " ", "\r", "\n", "\t"],
        d = ["{", "}", "|", "\\", "^", "`"].concat(p),
        m = ["'"].concat(d),
        v = ["%", "/", "?", ";", "#"].concat(m),
        g = ["/", "?", "#"],
        y = /^[+a-z0-9A-Z_-]{0,63}$/,
        P = /^([+a-z0-9A-Z_-]{0,63})(.*)$/,
        E = { javascript: !0, "javascript:": !0 },
        w = { javascript: !0, "javascript:": !0 },
        b = {
          http: !0,
          https: !0,
          ftp: !0,
          gopher: !0,
          file: !0,
          "http:": !0,
          "https:": !0,
          "ftp:": !0,
          "gopher:": !0,
          "file:": !0
        },
        S = r(94);
      (a.prototype.parse = function(e, t, r) {
        if (!l.isString(e))
          throw new TypeError(
            "Parameter 'url' must be a string, not " + typeof e
          );
        var a = e.indexOf("?"),
          s = -1 !== a && a < e.indexOf("#") ? "?" : "#",
          n = e.split(s),
          i = /\\/g;
        (n[0] = n[0].replace(i, "/")), (e = n.join(s));
        var o = e;
        if (((o = o.trim()), !r && 1 === e.split("#").length)) {
          var h = f.exec(o);
          if (h)
            return (
              (this.path = o),
              (this.href = o),
              (this.pathname = h[1]),
              h[2]
                ? ((this.search = h[2]),
                  (this.query = t
                    ? S.parse(this.search.substr(1))
                    : this.search.substr(1)))
                : t && ((this.search = ""), (this.query = {})),
              this
            );
        }
        var p = c.exec(o);
        if (p) {
          p = p[0];
          var d = p.toLowerCase();
          (this.protocol = d), (o = o.substr(p.length));
        }
        if (r || p || o.match(/^\/\/[^@\/]+@[^@\/]+/)) {
          var O = "//" === o.substr(0, 2);
          !O || (p && w[p]) || ((o = o.substr(2)), (this.slashes = !0));
        }
        if (!w[p] && (O || (p && !b[p]))) {
          for (var _ = -1, x = 0; x < g.length; x++) {
            var R = o.indexOf(g[x]);
            -1 !== R && (-1 === _ || R < _) && (_ = R);
          }
          var A, D;
          (D = -1 === _ ? o.lastIndexOf("@") : o.lastIndexOf("@", _)),
            -1 !== D &&
              ((A = o.slice(0, D)),
              (o = o.slice(D + 1)),
              (this.auth = decodeURIComponent(A))),
            (_ = -1);
          for (var x = 0; x < v.length; x++) {
            var R = o.indexOf(v[x]);
            -1 !== R && (-1 === _ || R < _) && (_ = R);
          }
          -1 === _ && (_ = o.length),
            (this.host = o.slice(0, _)),
            (o = o.slice(_)),
            this.parseHost(),
            (this.hostname = this.hostname || "");
          var k =
            "[" === this.hostname[0] &&
            "]" === this.hostname[this.hostname.length - 1];
          if (!k)
            for (
              var T = this.hostname.split(/\./), x = 0, F = T.length;
              x < F;
              x++
            ) {
              var j = T[x];
              if (j && !j.match(y)) {
                for (var I = "", $ = 0, W = j.length; $ < W; $++)
                  j.charCodeAt($) > 127 ? (I += "x") : (I += j[$]);
                if (!I.match(y)) {
                  var N = T.slice(0, x),
                    C = T.slice(x + 1),
                    L = j.match(P);
                  L && (N.push(L[1]), C.unshift(L[2])),
                    C.length && (o = "/" + C.join(".") + o),
                    (this.hostname = N.join("."));
                  break;
                }
              }
            }
          this.hostname.length > 255
            ? (this.hostname = "")
            : (this.hostname = this.hostname.toLowerCase()),
            k || (this.hostname = u.toASCII(this.hostname));
          var z = this.port ? ":" + this.port : "",
            M = this.hostname || "";
          (this.host = M + z),
            (this.href += this.host),
            k &&
              ((this.hostname = this.hostname.substr(
                1,
                this.hostname.length - 2
              )),
              "/" !== o[0] && (o = "/" + o));
        }
        if (!E[d])
          for (var x = 0, F = m.length; x < F; x++) {
            var H = m[x];
            if (-1 !== o.indexOf(H)) {
              var q = encodeURIComponent(H);
              q === H && (q = escape(H)), (o = o.split(H).join(q));
            }
          }
        var V = o.indexOf("#");
        -1 !== V && ((this.hash = o.substr(V)), (o = o.slice(0, V)));
        var B = o.indexOf("?");
        if (
          (-1 !== B
            ? ((this.search = o.substr(B)),
              (this.query = o.substr(B + 1)),
              t && (this.query = S.parse(this.query)),
              (o = o.slice(0, B)))
            : t && ((this.search = ""), (this.query = {})),
          o && (this.pathname = o),
          b[d] && this.hostname && !this.pathname && (this.pathname = "/"),
          this.pathname || this.search)
        ) {
          var z = this.pathname || "",
            Q = this.search || "";
          this.path = z + Q;
        }
        return (this.href = this.format()), this;
      }),
        (a.prototype.format = function() {
          var e = this.auth || "";
          e &&
            ((e = encodeURIComponent(e)),
            (e = e.replace(/%3A/i, ":")),
            (e += "@"));
          var t = this.protocol || "",
            r = this.pathname || "",
            a = this.hash || "",
            s = !1,
            n = "";
          this.host
            ? (s = e + this.host)
            : this.hostname &&
              ((s =
                e +
                (-1 === this.hostname.indexOf(":")
                  ? this.hostname
                  : "[" + this.hostname + "]")),
              this.port && (s += ":" + this.port)),
            this.query &&
              l.isObject(this.query) &&
              Object.keys(this.query).length &&
              (n = S.stringify(this.query));
          var i = this.search || (n && "?" + n) || "";
          return (
            t && ":" !== t.substr(-1) && (t += ":"),
            this.slashes || ((!t || b[t]) && !1 !== s)
              ? ((s = "//" + (s || "")),
                r && "/" !== r.charAt(0) && (r = "/" + r))
              : s || (s = ""),
            a && "#" !== a.charAt(0) && (a = "#" + a),
            i && "?" !== i.charAt(0) && (i = "?" + i),
            (r = r.replace(/[?#]/g, function(e) {
              return encodeURIComponent(e);
            })),
            (i = i.replace("#", "%23")),
            t + s + r + i + a
          );
        }),
        (a.prototype.resolve = function(e) {
          return this.resolveObject(s(e, !1, !0)).format();
        }),
        (a.prototype.resolveObject = function(e) {
          if (l.isString(e)) {
            var t = new a();
            t.parse(e, !1, !0), (e = t);
          }
          for (
            var r = new a(), s = Object.keys(this), n = 0;
            n < s.length;
            n++
          ) {
            var i = s[n];
            r[i] = this[i];
          }
          if (((r.hash = e.hash), "" === e.href))
            return (r.href = r.format()), r;
          if (e.slashes && !e.protocol) {
            for (var o = Object.keys(e), u = 0; u < o.length; u++) {
              var c = o[u];
              "protocol" !== c && (r[c] = e[c]);
            }
            return (
              b[r.protocol] &&
                r.hostname &&
                !r.pathname &&
                (r.path = r.pathname = "/"),
              (r.href = r.format()),
              r
            );
          }
          if (e.protocol && e.protocol !== r.protocol) {
            if (!b[e.protocol]) {
              for (var h = Object.keys(e), f = 0; f < h.length; f++) {
                var p = h[f];
                r[p] = e[p];
              }
              return (r.href = r.format()), r;
            }
            if (((r.protocol = e.protocol), e.host || w[e.protocol]))
              r.pathname = e.pathname;
            else {
              for (
                var d = (e.pathname || "").split("/");
                d.length && !(e.host = d.shift());

              );
              e.host || (e.host = ""),
                e.hostname || (e.hostname = ""),
                "" !== d[0] && d.unshift(""),
                d.length < 2 && d.unshift(""),
                (r.pathname = d.join("/"));
            }
            if (
              ((r.search = e.search),
              (r.query = e.query),
              (r.host = e.host || ""),
              (r.auth = e.auth),
              (r.hostname = e.hostname || e.host),
              (r.port = e.port),
              r.pathname || r.search)
            ) {
              var m = r.pathname || "",
                v = r.search || "";
              r.path = m + v;
            }
            return (
              (r.slashes = r.slashes || e.slashes), (r.href = r.format()), r
            );
          }
          var g = r.pathname && "/" === r.pathname.charAt(0),
            y = e.host || (e.pathname && "/" === e.pathname.charAt(0)),
            P = y || g || (r.host && e.pathname),
            E = P,
            S = (r.pathname && r.pathname.split("/")) || [],
            d = (e.pathname && e.pathname.split("/")) || [],
            O = r.protocol && !b[r.protocol];
          if (
            (O &&
              ((r.hostname = ""),
              (r.port = null),
              r.host && ("" === S[0] ? (S[0] = r.host) : S.unshift(r.host)),
              (r.host = ""),
              e.protocol &&
                ((e.hostname = null),
                (e.port = null),
                e.host && ("" === d[0] ? (d[0] = e.host) : d.unshift(e.host)),
                (e.host = null)),
              (P = P && ("" === d[0] || "" === S[0]))),
            y)
          )
            (r.host = e.host || "" === e.host ? e.host : r.host),
              (r.hostname =
                e.hostname || "" === e.hostname ? e.hostname : r.hostname),
              (r.search = e.search),
              (r.query = e.query),
              (S = d);
          else if (d.length)
            S || (S = []),
              S.pop(),
              (S = S.concat(d)),
              (r.search = e.search),
              (r.query = e.query);
          else if (!l.isNullOrUndefined(e.search)) {
            if (O) {
              r.hostname = r.host = S.shift();
              var _ =
                !!(r.host && r.host.indexOf("@") > 0) && r.host.split("@");
              _ && ((r.auth = _.shift()), (r.host = r.hostname = _.shift()));
            }
            return (
              (r.search = e.search),
              (r.query = e.query),
              (l.isNull(r.pathname) && l.isNull(r.search)) ||
                (r.path =
                  (r.pathname ? r.pathname : "") + (r.search ? r.search : "")),
              (r.href = r.format()),
              r
            );
          }
          if (!S.length)
            return (
              (r.pathname = null),
              r.search ? (r.path = "/" + r.search) : (r.path = null),
              (r.href = r.format()),
              r
            );
          for (
            var x = S.slice(-1)[0],
              R =
                ((r.host || e.host || S.length > 1) &&
                  ("." === x || ".." === x)) ||
                "" === x,
              A = 0,
              D = S.length;
            D >= 0;
            D--
          )
            (x = S[D]),
              "." === x
                ? S.splice(D, 1)
                : ".." === x
                ? (S.splice(D, 1), A++)
                : A && (S.splice(D, 1), A--);
          if (!P && !E) for (; A--; A) S.unshift("..");
          !P ||
            "" === S[0] ||
            (S[0] && "/" === S[0].charAt(0)) ||
            S.unshift(""),
            R && "/" !== S.join("/").substr(-1) && S.push("");
          var k = "" === S[0] || (S[0] && "/" === S[0].charAt(0));
          if (O) {
            r.hostname = r.host = k ? "" : S.length ? S.shift() : "";
            var _ = !!(r.host && r.host.indexOf("@") > 0) && r.host.split("@");
            _ && ((r.auth = _.shift()), (r.host = r.hostname = _.shift()));
          }
          return (
            (P = P || (r.host && S.length)),
            P && !k && S.unshift(""),
            S.length
              ? (r.pathname = S.join("/"))
              : ((r.pathname = null), (r.path = null)),
            (l.isNull(r.pathname) && l.isNull(r.search)) ||
              (r.path =
                (r.pathname ? r.pathname : "") + (r.search ? r.search : "")),
            (r.auth = e.auth || r.auth),
            (r.slashes = r.slashes || e.slashes),
            (r.href = r.format()),
            r
          );
        }),
        (a.prototype.parseHost = function() {
          var e = this.host,
            t = h.exec(e);
          t &&
            ((t = t[0]),
            ":" !== t && (this.port = t.substr(1)),
            (e = e.substr(0, e.length - t.length))),
            e && (this.hostname = e);
        });
    },
    function(e, t, r) {
      "use strict";
      e.exports = {
        isString: function(e) {
          return "string" == typeof e;
        },
        isObject: function(e) {
          return "object" == typeof e && null !== e;
        },
        isNull: function(e) {
          return null === e;
        },
        isNullOrUndefined: function(e) {
          return null == e;
        }
      };
    },
    function(e, t) {
      var r;
      r = (function() {
        return this;
      })();
      try {
        r = r || Function("return this")() || (0, eval)("this");
      } catch (e) {
        "object" == typeof window && (r = window);
      }
      e.exports = r;
    },
    function(e, t) {
      e.exports = function(e) {
        return (
          e.webpackPolyfill ||
            ((e.deprecate = function() {}),
            (e.paths = []),
            e.children || (e.children = []),
            Object.defineProperty(e, "loaded", {
              enumerable: !0,
              get: function() {
                return e.l;
              }
            }),
            Object.defineProperty(e, "id", {
              enumerable: !0,
              get: function() {
                return e.i;
              }
            }),
            (e.webpackPolyfill = 1)),
          e
        );
      };
    }
  ]);
});
