/* eslint-disable jsx-a11y/label-has-associated-control */
import { memo } from 'react';
import PropTypes from 'prop-types';

import Arthur from '../lib/Arthur';

import Select from './select';
import NumericInput from './numeric_input';

import { ParametersShape, FormStateShape } from '../shapes';

const options = [
  {
    id: Arthur.OWC_NONE,
    label: 'No Bonus',
  },
  {
    id: Arthur.OWC_DOUBLE,
    label: 'Double',
  },
  {
    id: Arthur.OWC_TREBLE,
    label: 'Treble',
  },
];

const propTypes = {
  onChangeParameter: PropTypes.func.isRequired,
  parameters: PropTypes.shape(ParametersShape).isRequired,
  formState: PropTypes.shape(FormStateShape).isRequired,
};

const BookmakerBonus = memo(({
  formState,
  onChangeParameter,
  parameters,
}) => (
  <div>
    <h2>Enter Bookmaker Bonus</h2>
    <div className="bvs-form-group">
      <label htmlFor="bet_calculator___parameters___allWinnersBonus">
        All Winners Bonus %
      </label>
      <NumericInput
        id="bet_calculator___parameters___allWinnersBonus"
        onChange={onChangeParameter('bookmakerBonus.allWinnersBonus')}
        value={parameters['bookmakerBonus.allWinnersBonus']}
        formState={formState}
      />
    </div>

    <div className="bvs-form-group">
      <label htmlFor="bet_calculator___parameters___oneWinnerConsolation">
        One Winner Consolation
      </label>
      <Select
        id="bet_calculator___parameters___oneWinnerConsolation"
        options={options}
        labelKey="label"
        valueKey="id"
        onChange={onChangeParameter('bookmakerBonus.oneWinnerConsolation')}
      />
    </div>
  </div>
));

BookmakerBonus.propTypes = propTypes;

export default BookmakerBonus;
