export default {
  main: {
    questions: [
      {
        q: 'What is a bet calculator?',
        a: 'A bet calculator is a tool that calculates your potential winnings based on the odds and stake of your bet. By entering the odds and your chosen stake, the calculator provides information on the potential amount you could win.',
      },
      {
        q: 'How to Use the Bet Calculator?',
        a: "Utilizing the talkSport Bet Betting Odds Calculator is a straightforward process. Begin by selecting the type of bet you desire, ranging from singles and accumulators to goliaths and Lucky 15s, 31s, and 63s. Once your choice is made, use the calculator, which is conveniently broken down with easy navigational links, and decide if you prefer the odds in fractional or decimal format. <br />Next, input your stake – the amount you wish to bet. Determine if your bet is each way, include any rule 4s, and indicate whether you want to view selection notes or fold options. We've provided explanations for some of these terms to assist if they are unfamiliar. <br />Lastly, enter the odds and the outcome (Winner, Lost, Void, or Dead Heat). If you have multiple selections, input the odds for each one. Click the 'Calculate' option, and the calculator will display both your potential returns and profit.<br />If you already know the bet you want to check, refer to our easy navigational links below for quick access.",
      },
    ],
  },
};
