import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, Heading } from 'bv-components';

import { CalculatorShape } from '../../shapes';
import { useTranslations } from '../../hooks';

const propTypes = {
  calculator: PropTypes.shape(CalculatorShape).isRequired,
};

const Breakdown = ({ calculator: { result: { bets } } }) => {
  const t = useTranslations();

  const total = useMemo(
    () => Object.keys(bets)
      .reduce((acc, entry) => {
        const next = {
          win: acc.win + bets[entry].win,
          place: acc.place + bets[entry].place,
          lost: acc.lost + bets[entry].lost,
        };

        return {
          bets: next.win + next.place + next.lost,
          ...next,
        };
      }, {
        bets: 0, win: 0, place: 0, lost: 0,
      }),
  );

  const breakdowns = Object.keys(bets).map(
    (betName) => {
      const bet = bets[betName];

      const betTotal = bet.win + bet.place + bet.lost;

      return (
        <tr>
          <td>{betName}</td>
          <td>{betTotal}</td>
          <td>{bet.win}</td>
          <td>{bet.place}</td>
          <td>{bet.lost}</td>
        </tr>
      );
    },
  );

  return (
    <Card id="bet_calculator___breakdown">
      <Heading weight="4" tag="h2">{t('Calculator.Result.betBreakdownLite')}</Heading>
      <table className="bet-calculator__table is-breakdown">
        <thead>
          <tr>
            <th>{t('Global.betType.singular')}</th>
            <th>{t('Breakdown.bets')}</th>
            <th>{t('Breakdown.won')}</th>
            <th>{t('Breakdown.placed')}</th>
            <th>{t('Breakdown.lost')}</th>
          </tr>
        </thead>
        <tbody>{breakdowns}</tbody>
        <tfoot>
          <tr>
            <td>{t('Breakdown.total')}</td>
            <td>{total.bets}</td>
            <td>{total.win}</td>
            <td>{total.place}</td>
            <td>{total.lost}</td>
          </tr>
        </tfoot>
      </table>
    </Card>
  );
};

Breakdown.propTypes = propTypes;

export default Breakdown;
