import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { locale as getLocale } from 'bv-i18n';
import { v as bvVar } from 'bv';
import { embeddedPath } from 'bv-services';

import BetTypes from './bet_types';
import { useTranslations } from '../../hooks';

import { getBetTypeById, shortLocaleFromParams } from '../../helpers';

import { getBetTypeId } from '../../selectors';

import { onChangeBetType as onChangeBetTypeActionRedux } from '../../duck';
import { betTypeTemplate } from '../../data/templates';
import * as betInformationByLanguage from '../../data/bet_info';

const brandId = bvVar('brandId');

const onChangeBetTypeActionRoute = (history) => (betType) => {
  const link = ['default'].includes(betType) ? '/bet-calculator' : `/bet-calculator/${betType}`;
  return history.push(embeddedPath(link));
};

const propTypes = {
  betTypeId: PropTypes.string.isRequired,
  onChangeBetType: PropTypes.func.isRequired,
};

const replacePathName = (message = '') => message.replace('{{betCalculatorPath}}', embeddedPath('/bet-calculator'));

const BetTypesContainer = ({ betTypeId, onChangeBetType, ...props }) => {
  const params = useParams();
  const history = useHistory();
  const t = useTranslations();
  const loc = getLocale();
  const lang = shortLocaleFromParams({ locale: loc });
  const betType = getBetTypeById(params.betType) || betTypeTemplate;

  const defaultBetInformation = betInformationByLanguage[lang].default[params.betType]; // BV
  const currentBrandBetInformation = betInformationByLanguage[lang][loc][brandId]?.[params.betType]
    || {};

  const betInformation = { ...defaultBetInformation, ...currentBrandBetInformation };

  const betInfo = (params.betType && params.betType !== 'default')
    ? betInformation
    : { opening: t('BetTypes.subtitle') };

  const boxDescriptor = useMemo(
    () => {
      const { note, warning } = betType.props || {};
      const message = t(note) || replacePathName(t(warning));

      return message && ({
        class: classnames('box', { note, warning }),
        title: (note && t('BetTypes.note.title')) || (warning && t('BetTypes.warning.title')),
        message,
      });
    },
    [params.betType],
  );

  return (
    <BetTypes
      boxDescriptor={boxDescriptor}
      betType={betType}
      betInfo={betInfo}
      onChangeBetType={onChangeBetType(history)}
      {...props}
    />
  );
};

BetTypesContainer.propTypes = propTypes;

const mapStateToProps = (state) => ({
  betTypeId: getBetTypeId(state),
});

const mapDispatchToProps = (dispatch) => ({
  onChangeBetType: (history) => (betType) => {
    onChangeBetTypeActionRoute(history)(betType);
    dispatch(onChangeBetTypeActionRedux(betType));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BetTypesContainer);
