const betCalculatorBannerHrefsObj = {
  1: {
    'en-ca': {
      accountBanner: '/sports/1',
      offerBanner: '/offer',
    },
  },
};

export const getBetCalculatorBannersHrefs = (brandId, locale) => {
  const {
    accountBanner = '/offer/sports',
    offerBanner = '/offer/sports',
  } = betCalculatorBannerHrefsObj?.[brandId]?.[locale] || {};

  return { accountBanner, offerBanner };
};
