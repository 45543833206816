export const betTypes = {
  default: {
    id: 'default',
    default: true,
    name: 'BetTypes.Type.default',
    view: 'FullCover',
    props: { minimumSelections: 1, selectionCount: 1, foldOptionsAvailable: true },
  },
  main: {
    id: 'main',
    name: 'BetTypes.Type.main',
    view: 'FullCover',
    props: { minimumSelections: 1, selectionCount: 1, foldOptionsAvailable: true },
  },
  single: {
    id: 'single',
    name: 'BetTypes.Type.single',
    view: 'FullCover',
    props: { minimumSelections: 1, selectionCount: 1 },
    breakdown: { selections: 1, single: 1 },
  },
  'each-way': {
    id: 'each-way',
    name: 'BetTypes.Type.each-way',
    view: 'FullCover',
    props: { minimumSelections: 1, selectionCount: 1, eachWay: true },
    breakdown: { selections: 1, single: 1 },
  },
  double: {
    id: 'double',
    name: 'BetTypes.Type.double',
    view: 'FullCover',
    props: { minimumSelections: 2, selectionCount: 2 },
    breakdown: { selections: 2, double: 1 },
  },
  treble: {
    id: 'treble',
    name: 'BetTypes.Type.treble',
    view: 'FullCover',
    props: { minimumSelections: 3, selectionCount: 3 },
    breakdown: { selections: 3, treble: 1 },
  },
  accumulator: {
    id: 'accumulator',
    name: 'BetTypes.Type.accumulator',
    view: 'FullCover',
    props: { minimumSelections: 2 },
  },
  trixie: {
    id: 'trixie',
    name: 'BetTypes.Type.trixie',
    view: 'FullCover',
    props: { minimumSelections: 3, selectionCount: 3 },
    breakdown: { selections: 3, double: 3, treble: 1 },
  },
  patent: {
    id: 'patent',
    name: 'BetTypes.Type.patent',
    view: 'FullCover',
    props: { minimumSelections: 3, selectionCount: 3 },
    breakdown: {
      selections: 3, single: 3, double: 3, treble: 1,
    },
  },
  yankee: {
    id: 'yankee',
    name: 'BetTypes.Type.yankee',
    view: 'FullCover',
    props: { minimumSelections: 4, selectionCount: 4 },
    breakdown: {
      selections: 4, double: 6, treble: 4, '4-fold': 1,
    },
  },
  canadian: {
    id: 'canadian',
    name: 'BetTypes.Type.canadian',
    view: 'FullCover',
    props: { minimumSelections: 5, selectionCount: 5 },
    breakdown: {
      selections: 5,
      double: 10,
      treble: 10,
      '4-fold': 5,
      '5-fold': 1,
    },
  },
  'super-yankee': {
    id: 'super-yankee',
    name: 'BetTypes.Type.super-yankee',
    view: 'FullCover',
    props: { minimumSelections: 5, selectionCount: 5 },
    breakdown: {
      selections: 5,
      double: 10,
      treble: 10,
      '4-fold': 5,
      '5-fold': 1,
    },
  },
  heinz: {
    id: 'heinz',
    name: 'BetTypes.Type.heinz',
    view: 'FullCover',
    props: { minimumSelections: 6, selectionCount: 6 },
    breakdown: {
      selections: 6,
      double: 15,
      treble: 20,
      '4-fold': 15,
      '5-fold': 6,
      '6-fold': 1,
    },
  },
  'super-heinz': {
    id: 'super-heinz',
    name: 'BetTypes.Type.super-heinz',
    view: 'FullCover',
    props: { minimumSelections: 7, selectionCount: 7 },
    breakdown: {
      selections: 7,
      double: 21,
      treble: 35,
      '4-fold': 35,
      '5-fold': 21,
      '6-fold': 7,
      '7-fold': 1,
    },
  },
  goliath: {
    id: 'goliath',
    name: 'BetTypes.Type.goliath',
    view: 'FullCover',
    props: { minimumSelections: 8, selectionCount: 8 },
    breakdown: {
      selections: 8,
      double: 28,
      treble: 56,
      '4-fold': 70,
      '5-fold': 56,
      '6-fold': 28,
      '7-fold': 8,
      '8-fold': 1,
    },
  },
  'lucky-15': {
    id: 'lucky-15',
    name: 'BetTypes.Type.lucky-15',
    view: 'FullCover',
    props: { minimumSelections: 4, selectionCount: 4 },
    breakdown: {
      selections: 4,
      single: 4,
      double: 6,
      treble: 4,
      '4-fold': 1,
    },
  },
  'lucky-31': {
    id: 'lucky-31',
    name: 'BetTypes.Type.lucky-31',
    view: 'FullCover',
    props: { minimumSelections: 5, selectionCount: 5 },
    breakdown: {
      selections: 5,
      single: 5,
      double: 10,
      treble: 10,
      '4-fold': 5,
      '5-fold': 1,
    },
  },
  'lucky-63': {
    id: 'lucky-63',
    name: 'BetTypes.Type.lucky-63',
    view: 'FullCover',
    props: { minimumSelections: 6, selectionCount: 6 },
    breakdown: {
      selections: 6,
      single: 6,
      double: 15,
      treble: 20,
      '4-fold': 15,
      '5-fold': 6,
      '6-fold': 1,
    },
  },
  forecast: {
    id: 'forecast',
    name: 'BetTypes.Type.forecast',
    view: 'Forecast',
    props: {
      note: 'BetTypes.note.body',
      minimumSelections: 1,
      selectionCount: 1,
    },
  },
  'round-robin': {
    id: 'round-robin',
    name: 'BetTypes.Type.round-robin',
    view: 'FullCover',
    props: {
      minimumSelections: 3,
      selectionCount: 3,
      warning: 'BetTypes.warning.body',
    },
    breakdown: {
      selections: 3, double: 3, treble: 1, ssa: 3,
    },
  },
  flag: {
    id: 'flag',
    name: 'BetTypes.Type.flag',
    view: 'FullCover',
    props: {
      minimumSelections: 4,
      selectionCount: 4,
      warning: 'BetTypes.warning.body',
    },
    breakdown: {
      selections: 4,
      double: 6,
      treble: 4,
      '4-fold': 1,
      ssa: 6,
    },
  },
  'super-flag': {
    id: 'super-flag',
    name: 'BetTypes.Type.super-flag',
    view: 'FullCover',
    props: {
      minimumSelections: 5,
      selectionCount: 5,
      warning: 'BetTypes.warning.body',
    },
    breakdown: {
      selections: 5,
      double: 10,
      treble: 10,
      '4-fold': 5,
      '5-fold': 1,
      ssa: 10,
    },
  },
  'single-stakes-about': {
    id: 'single-stakes-about',
    name: 'BetTypes.Type.single-stakes-about',
    view: 'FullCover',
    props: {
      minimumSelections: 2,
      selectionCount: 2,
      warning: 'BetTypes.warning.body',
    },
    breakdown: { selections: 2, single: 2 },
  },
  'heinz-flag': {
    id: 'heinz-flag',
    name: 'BetTypes.Type.heinz-flag',
    view: 'FullCover',
    props: { minimumSelections: 6, selectionCount: 6 },
    breakdown: {
      selections: 6,
      double: 15,
      treble: 10,
      '4-fold': 15,
      '5-fold': 6,
      '6-fold': 1,
      ssa: 15,
    },
  },
  'super-heinz-flag': {
    id: 'super-heinz-flag',
    name: 'BetTypes.Type.super-heinz-flag',
    view: 'FullCover',
    props: { minimumSelections: 7, selectionCount: 7 },
    breakdown: {
      selections: 7,
      double: 21,
      treble: 35,
      '4-fold': 35,
      '5-fold': 21,
      '6-fold': 7,
      '7-fold': 1,
      ssa: 21,
    },
  },
  'goliath-flag': {
    id: 'goliath-flag',
    name: 'BetTypes.Type.goliath-flag',
    view: 'FullCover',
    props: {
      minimumSelections: 8,
      selectionCount: 8,
      warning: 'BetTypes.warning.body',
    },
    breakdown: {
      selections: 8,
      double: 28,
      treble: 56,
      '4-fold': 70,
      '5-fold': 56,
      '6-fold': 28,
      '7-fold': 8,
      '8-fold': 1,
      ssa: 28,
    },
  },
  'union-jack-trebles': {
    id: 'union-jack-trebles',
    name: 'BetTypes.Type.union-jack-trebles',
    view: 'FullCover',
    props: {
      minimumSelections: 9,
      selectionCount: 9,
      warning: 'BetTypes.warning.body',
    },
    breakdown: { selections: 9, treble: 8 },
  },
  'union-jack-trixie': {
    id: 'union-jack-trixie',
    name: 'BetTypes.Type.union-jack-trixie',
    view: 'FullCover',
    props: {
      minimumSelections: 9,
      selectionCount: 9,
      warning: 'BetTypes.warning.body',
    },
    breakdown: { selections: 9, double: 24, treble: 8 },
  },
  'union-jack-patent': {
    id: 'union-jack-patent',
    name: 'BetTypes.Type.union-jack-patent',
    view: 'FullCover',
    props: {
      minimumSelections: 9,
      selectionCount: 9,
      warning: 'BetTypes.warning.body',
    },
    breakdown: {
      selections: 9, single: 24, double: 24, treble: 8,
    },
  },
  'union-jack-round-robin': {
    id: 'union-jack-round-robin',
    name: 'BetTypes.Type.union-jack-round-robin',
    view: 'FullCover',
    props: {
      minimumSelections: 9,
      selectionCount: 9,
      warning: 'BetTypes.warning.body',
    },
    breakdown: {
      selections: 9, double: 24, treble: 8, ssa: 24,
    },
  },
};

export const betTypesCollection = Object.values(betTypes);
