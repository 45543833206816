import { useState, useEffect, useMemo } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { getDisplayedModal } from '../../selectors';
import { onChangeBetType, restoreBetState, toggleModal } from '../../duck';

import { fetchTranslations, fetchCurrentBrandTranslations } from '../../api';

import App from './app';
import EachWayModal from '../each_way_modal';
import RuleFourModal from '../rule_four_modal';
import { createHandler } from '../../helpers';

import { TranslationsContext } from '../../contexts';
import { betTypeTemplate } from '../../data/templates';
import { decode } from '../../helpers/transform';

const propTypes = {
  dispatchOnChangeBetType: PropTypes.func.isRequired,
  dispatchRestoreBetState: PropTypes.func.isRequired,
  displayedModal: PropTypes.string,
};

const defaultProps = {
  displayedModal: null,
};

const Modals = {
  RuleFourModal,
  EachWayModal,
};

const AppContainer = ({
  dispatchOnChangeBetType,
  dispatchRestoreBetState,
  displayedModal,
  ...props
}) => {
  const [translations, setTranslations] = useState(null);
  const location = useLocation();
  const params = useParams();

  useEffect(
    () => {
      Promise.all([
        fetchTranslations(),
        fetchCurrentBrandTranslations(),
      ]).then(([defaultTranslations, currentBrandTranslations]) => {
        const readyTranslations = Object.keys(currentBrandTranslations).length
          ? { ...defaultTranslations, ...currentBrandTranslations }
          : defaultTranslations;

        setTranslations(readyTranslations);
      });
    },
    [],
  );

  useEffect(
    () => {
      dispatchOnChangeBetType(params.betType || betTypeTemplate.id);
    },
    [params.betType],
  );

  useEffect(
    () => {
      const share = new URLSearchParams(location.search).get('c');

      if (!share) return;

      const savedBet = decode(share);

      dispatchRestoreBetState(savedBet);
    },
    [location.search],
  );

  const DisplayedModal = useMemo(
    () => displayedModal && Modals[displayedModal],
    [displayedModal],
  );

  return translations && (
    <TranslationsContext.Provider value={translations}>
      <App
        {...props}
        DisplayedModal={DisplayedModal}
      />
    </TranslationsContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  displayedModal: getDisplayedModal(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchOnChangeBetType: compose(dispatch, onChangeBetType),
  dispatchRestoreBetState: compose(dispatch, restoreBetState),
  toggleModal: createHandler(dispatch, toggleModal),
});

AppContainer.propTypes = propTypes;
AppContainer.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
