import { useContext } from 'react';
import { propertyOf } from 'underscore';

import { TranslationsContext } from '../contexts';

export default () => (
  propertyOf(
    useContext(TranslationsContext),
  )
);
