import { v4 as uuid } from 'uuid';
import Arthur from '../../lib/Arthur';

import * as templates from '../../data/templates';
import { encode } from '../../helpers/transform';

const camelCase = (string) => string
  .split('-')
  .map((s, i) => (
    !i
      ? s
      : s.substr(0, 1).toUpperCase().concat(s.substr(1))
  ))
  .join('');

export default (state) => {
  const {
    betType: selectedBetType,
    parameters: {
      betType: accumulatorBetType,
      eachWay,
      eachWayValue,
      oddsFormat,
      outcomes,
      reverse,
    },
    parameters,
    calculator: {
      parameters: {
        stake,
        isTotal,
        isUsedBonusFunds,
      },
    },
  } = state;

  const betType = accumulatorBetType || selectedBetType;

  const formState = {
    hasError: false,
    isSubmitted: true,
  };

  const isAnyOutcomeValueEmpty = parameters.outcomes
    .some((outcome) => {
      const value = betType === 'forecast'
        ? outcome.value
        : outcome[`odds.${oddsFormat}`];

      const requiresInputValue = [Arthur.DEAD_HEAT, Arthur.WINNER].indexOf(outcome.type) > -1;
      const hasValue = (typeof value === 'undefined' || value === '');

      return requiresInputValue && hasValue;
    });

  if (stake === '' || isAnyOutcomeValueEmpty) {
    return {
      ...state,
      formState: {
        ...formState,
        hasError: true,
      },
    };
  }

  const stakeType = isTotal
    ? Arthur.TOTAL_COMBINED_STAKE
    : Arthur.STAKE_PER_BET;

  const slipObject = {
    betType: camelCase(betType),
    stake: Number(stake),
    stakeType,
    eachWay,
    eachWayValue,
    oddsFormat,
    selections: outcomes.map((outcome) => ({
      ...outcome,
      odds: outcome.value || '0',
      rule4: Number(outcome.ruleFour) || 0,
      outcome: outcome.type,
    })),
  };

  if (betType.startsWith('lucky-')) {
    Object.assign(slipObject, {
      bookMakersBonus: {
        allWinnersBonus: parameters['bookmakerBonus.allWinnersBonus'] || '0',
        oneWinnerConsolation: parameters['bookmakerBonus.oneWinnerConsolation'] || '0',
      },
    });
  }

  switch (betType) {
    case 'forecast': {
      const id = reverse ? 'reverse' : 'straight';
      Object.assign(slipObject, {
        betType: `${id}Forecast`,
      });
      break;
    }

    default: {
      Object.assign(slipObject, {
        selections: slipObject.selections.map((outcome) => {
          const odds = outcome[`odds.${oddsFormat}`];
          const value = oddsFormat === 'decimal'
            ? odds
            : String(odds.numerator).concat('/', odds.denominator);

          return { ...outcome, odds: value };
        }),
      });
      break;
    }
  }

  const slip = new Arthur.Slip(slipObject);

  const calculation = { ...templates.generateCalculationTemplate() };

  try {
    Object.assign(calculation, {
      result: Arthur.calculate(slip),
    });

    if (isUsedBonusFunds) {
      Object.assign(calculation, {
        result: {
          ...calculation.result,
          returns: (Math.floor(
            (calculation.result.returns - calculation.result.outlay) * 100,
          ) / 100).toFixed(2),
        },
      });
    }
  } catch (e) {
    Object.assign(formState, { hasError: true });
  }

  const nextCalculator = {
    ...state.calculator,
    ...calculation,
    id: uuid(),
  };

  const encodedBetState = encode({ parameters, calculator: nextCalculator, betType });

  return {
    ...state,
    formState,
    saveSwitch: !state.saveSwitch,
    calculator: nextCalculator,
    savedBets: !state.calculator.parameters.shouldSaveBet ? state.savedBets
      : state.savedBets.concat({
        date: new Date().toISOString(),
        betType: parameters.betType || betType,
        calculator: nextCalculator,
        parameters,
      }),
    encodedBetState,
  };
};
