import { useContext } from 'react';
import { range } from 'underscore';
import { sanitize } from 'dompurify';
import { Card, Heading } from 'bv-components';

import { TranslationsContext } from '../../contexts';

const BetInformationForecast = () => {
  const translations = useContext(TranslationsContext);

  const translationKeys = Object.keys(translations).filter((key) => key.startsWith('BetInformation.forecast.q'));
  const items = range(1, translationKeys.length + 1);

  return (
    <Card>
      {items.map((i) => (
        <div itemScope itemType="http://schema.org/Question">
          <Heading
            weight="5"
            tag="h2"
          >
            {translations[`BetInformation.forecast.q${i}`]}
          </Heading>
          <p
            dangerouslySetInnerHTML={{ __html: sanitize(translations[`BetInformation.forecast.a${i}`]) }}
            itemScope
            itemType="http://schema.org/Answer"
            itemProp="suggestedAnswer"
          />
        </div>
      ))}
    </Card>
  );
};

export default BetInformationForecast;
